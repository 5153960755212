import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import CustomRadioField from "../../components/UIComponents/CustomRadioField/CustomRadioField";
import SelectField from "../../components/UIComponents/SelectField";
import { useResumes } from "../../services/create-resume/queries";

function ExpertHelpExistingUserUpload() {
	const navigate = useNavigate();
	const { data: resumeList, isLoading: isLoadingResumes } = useResumes();

	const [selectedOption, setSelectedOption] = useState("existing"); // new | existing
	const [resumeTitle, setResumeTitle] = useState(null);
	const [resumes, setResumes] = useState([]);

	// TODO:
	// 1. get user resumes and update select field
	// 2. handle form submission

	function onSubmit() {
		// handle form submission
		if (selectedOption === "new") {
			navigate("/expert-help/upload-resume", { state: { selectedOption, resumeTitle } });
		} else {
			navigate("/expert-help/pricing", { state: { selectedOption, resumeTitle } });
		}
	}

	useEffect(() => {
		if (!isLoadingResumes && resumeList) {
			setResumes(resumeList);
		}
	}, []);

	return (
		<div>
			<h1 className="fs-28 text-gray-950 font-weight-bold">Upload Resume (Optional)</h1>
			<p className="text-gray-700 font-weight-semi mb-5">
				If you have an existing resume, you can upload it here to give our experts a starting point.
			</p>

			<div className="form-group">
				<label htmlFor="">Do you want to update your existing resume on Dproz, or create a brand new one?</label>
				<div className="d-flex">
					<CustomRadioField
						label="Improve existing resume on Dproz"
						checked={selectedOption === "existing"}
						onChange={() => setSelectedOption("existing")}
						name="existing"
						value="existing"
					/>
					<CustomRadioField
						label="Create brand new"
						checked={selectedOption === "new"}
						onChange={() => setSelectedOption("new")}
						name="new"
						value="new"
					/>
				</div>
				{selectedOption === "existing" && (
					<div className="form-group">
						<SelectField
							options={resumes.map((resume) => {
								return {
									name: resume.journal.resumeTitle ? resume.journal.resumeTitle : resume.heading.resumeTitle,
									value: resume.journal.resumeTitle ? resume.journal.resumeTitle : resume.heading.resumeTitle,
								};
							})}
							value={resumeTitle}
							name="resume"
							onChange={(e) => setResumeTitle(e.target.value)}
							label="Choose Resume"
						/>
					</div>
				)}
				<button
					disabled={selectedOption === "existing" && !resumeTitle}
					className="btn btn-blue mt-2"
					onClick={onSubmit}
				>
					Continue
				</button>
			</div>
		</div>
	);
}

export default ExpertHelpExistingUserUpload;
