import * as React from "react";

import { cn, getSelectionStyles } from "../../handlers/cn.utils";

export function RadioGroupCards({ options, value, onChange, className }) {
	return (
		<div className={cn("grid grid-cols-3 gap-4", className)}>
			{options.map((option) => {
				const isSelected = option.value === value;
				return (
					<div
						key={option.value}
						type="button"
						onClick={() => onChange(option.value)}
						className={cn(
							"relative flex flex-col items-start gap-2 rounded-lg border p-4 text-left hover:bg-gray-50",
							getSelectionStyles(isSelected),
						)}
					>
						{option.icon && <div className="rounded-lg border border-gray-200 p-2.5 bg-white">{option.icon}</div>}
						<div className="space-y-1">
							<div className="font-medium text-gray-800">{option.label}</div>
							{option.description && <div className="text-sm text-gray-500">{option.description}</div>}
						</div>
					</div>
				);
			})}
		</div>
	);
}
