import axios from "axios";

import { NewRequest } from "../../models/resume/newRequest";
import { StaticValue } from "../../seed/constants";

export class ResumeDataService {
	constructor() {
		this.staticValue = new StaticValue();
		this.staticValues = this.staticValue.getDefaultValues();
		this.url = this.staticValues.apiUrl;
		this.resumeDownloadApi = this.staticValues.resumeDownloadApi;
		axios.defaults.headers.common = { ...this.staticValues.commonHeader };
		axios.interceptors.response.use(null, (error) => {
			if (error.response.status === 401) {
				const allCookies = document.cookie.split(";");

				for (let i = 0; i < allCookies.length; i++) {
					if (
						allCookies[i].startsWith("Authorization") ||
						allCookies[i].startsWith(this.staticValues.localStorageResumeId)
					) {
						document.cookie = document.cookie.replace(allCookies[i], "");
					}
				}

				localStorage.removeItem("resumeList");

				window.location.replace("/signin");
			}

			if (error.response.status === 402) {
				window.location.replace("/payments");
			}

			return Promise.reject(error);
		});
	}

	createHeadingData(inputData) {
		let contactInformation = inputData.data.contactInfo;
		contactInformation.templateId = inputData.data.templateId;
		contactInformation.yearsOfExperience = inputData.data.yearsOfExperience;
		contactInformation.resumeTitle = inputData.data.resumeTitle;

		const createParam = new NewRequest(contactInformation);
		return createParam.getRequestParam();
	}

	async putResumePrintout(inputData, successCallBack, errorCallBack) {
		let resumeData = inputData;

		axios
			.put(`${this.resumeDownloadApi}attachments/${resumeData.resumeId}`, resumeData.payload)
			.then((data) => successCallBack(data))
			.catch((data) => errorCallBack(data));
	}

	async getResumePrintoutById(inputData, successCallBack, errorCallBack) {
		let resumeData = inputData;
		axios
			.get(`${this.resumeDownloadApi}attachments/${resumeData.resumeId}`)
			.then((data) => successCallBack(data))
			.catch((data) => errorCallBack(data));
	}

	async createResume(inputData, successCallBack, errorCallBack) {
		let param = this.createHeadingData(inputData);
		axios
			.post(`${this.url}resume-journal`, param)
			.then((data) => successCallBack(data))
			.catch((err) => errorCallBack(err));
	}

	async duplicateResume(resumeData, successCallBack, errorCallBack) {
		axios
			.post(`${this.url}/resume-journal/copyresume`, resumeData)
			.then((data) => successCallBack(data))
			.catch((data) => errorCallBack(data));
	}

	async getResumeJournalByEmail(inputData, successCallBack, errorCallBack) {
		let contactInformation = inputData;
		axios
			.get(`${this.url}resume-journal`, {
				params: { emailAddress: contactInformation.email },
			})
			.then((data) => successCallBack(data))
			.catch((data) => errorCallBack(data));
	}

	async getResumeJournalById(inputData, successCallBack, errorCallBack) {
		let resumeInformation = inputData;
		axios
			.get(`${this.url}resume-journal`, {
				params: { referenceId: resumeInformation.resumeId },
			})
			.then((data) => successCallBack(data))
			.catch((data) => errorCallBack(data));
	}

	async getResumeLedgerById(resumeId, successCallBack, errorCallBack) {
		await axios
			.get(`${this.url}resume-ledger/${resumeId}`)
			.then((data) => successCallBack(data))
			.catch((data) => errorCallBack(data));
	}

	async getUserResumes(successCallBack, errorCallBack) {
		await axios
			.get(`${this.url}resume-ledger`)
			.then((data) => successCallBack(data))
			.catch((data) => errorCallBack(data));
	}

	async getUser(successCallBack, errorCallBack) {
		await axios
			.get(`${this.url}users/account`)
			.then((data) => successCallBack(data))
			.catch((data) => errorCallBack(data));
	}

	async getPayments(accountId, successCallBack, errorCallBack) {
		await axios
			.get(`${this.url}payments`, {
				params: { accountId: accountId },
			})
			.then((data) => successCallBack(data))
			.catch((data) => errorCallBack(data));
	}

	async createJobPost(jobPostData, successCallBack, errorCallBack) {
		await axios
			.post(`${this.url}job-market`, jobPostData)
			.then((data) => successCallBack(data))
			.catch((data) => errorCallBack(data));
	}

	async updateJobPost(id, jobPostData, successCallBack, errorCallBack) {
		await axios
			.put(`${this.url}job-market/${id}`, jobPostData)
			.then((data) => successCallBack(data))
			.catch((data) => errorCallBack(data));
	}

	async publishJobPost(id, successCallBack, errorCallBack) {
		await axios
			.patch(`${this.url}job-market/${id}`)
			.then((data) => successCallBack(data))
			.catch((data) => errorCallBack(data));
	}

	async getJobs(successCallBack, errorCallBack) {
		await axios
			.get(`${this.url}job-market`)
			.then((data) => successCallBack(data))
			.catch((data) => errorCallBack(data));
	}

	async getJobById(jobId, successCallBack, errorCallBack) {
		await axios
			.get(`${this.url}job-market?jobReferenceId=${jobId}`)
			.then((data) => successCallBack(data))
			.catch((data) => errorCallBack(data));
	}

	async getJobSkills(JD, successCallBack, errorCallBack) {
		await axios
			.post(`${this.url}job-market/skills`, JD)
			.then((data) => successCallBack(data))
			.catch((data) => errorCallBack(data));
	}

	async deleteJobById(jobId, successCallBack, errorCallBack) {
		await axios
			.delete(`${this.url}job-market/${jobId}`)
			.then((data) => successCallBack(data))
			.catch((data) => errorCallBack(data));
	}
	async createCompany(CompanyData, successCallBack, errorCallBack) {
		await axios
			.post(`${this.url}companies`, CompanyData)
			.then((data) => successCallBack(data))
			.catch((data) => errorCallBack(data));
	}

	async updateCompany(id, CompanyData, successCallBack, errorCallBack) {
		await axios
			.put(`${this.url}companies/${id}`, CompanyData)
			.then((data) => successCallBack(data))
			.catch((data) => errorCallBack(data));
	}

	async deleteCompanyById(companyId, successCallBack, errorCallBack) {
		await axios
			.delete(`${this.url}job-market/${companyId}`)
			.then((data) => successCallBack(data))
			.catch((data) => errorCallBack(data));
	}

	async getCompanies(companyId, name, industry, successCallBack, errorCallBack) {
		await axios
			.get(`${this.url}companies?companyId=${companyId}&name=${name}&industry=${industry}`)
			.then((data) => successCallBack(data))
			.catch((data) => errorCallBack(data));
	}

	async getReviewResume(reviewToken, successCallBack, errorCallBack) {
		await axios
			.get(`${this.url}review-resume/${reviewToken}`)
			.then((data) => successCallBack(data))
			.catch((data) => errorCallBack(data));
	}

	async getResumeFeedback(feedbackRequestId, successCallBack, errorCallBack) {
		await axios
			.get(`${this.url}resume-reviews/${feedbackRequestId}`)
			.then((data) => successCallBack(data))
			.catch((data) => errorCallBack(data));
	}

	async patchReviewResume(reviewToken, feedBack, successCallBack, errorCallBack) {
		await axios
			.patch(`${this.url}review-resume/${reviewToken}`, feedBack)
			.then((data) => successCallBack(data))
			.catch((data) => errorCallBack(data));
	}

	async addReviewers(data, successCallBack, errorCallBack) {
		await axios
			.post(`${this.url}reviewers`, data)
			.then((data) => successCallBack(data))
			.catch((data) => errorCallBack(data));
	}

	async getReviewers(successCallBack, errorCallBack) {
		await axios
			.get(`${this.url}reviewers`)
			.then((data) => successCallBack(data))
			.catch((data) => errorCallBack(data));
	}

	async createResumeReview(data, successCallBack, errorCallBack) {
		await axios
			.post(`${this.url}resume-reviews`, data)
			.then((data) => successCallBack(data))
			.catch((data) => errorCallBack(data));
	}

	async updateResumeReview(data, id, successCallBack, errorCallBack) {
		await axios
			.put(`${this.url}resume-reviews/${id}`, data)
			.then((data) => successCallBack(data))
			.catch((data) => errorCallBack(data));
	}

	async deleteResumeReview(id, successCallBack, errorCallBack) {
		await axios
			.delete(`${this.url}resume-reviews/${id}`)
			.then((data) => successCallBack(data))
			.catch((data) => errorCallBack(data));
	}

	async publishResumeReview(id, successCallBack, errorCallBack) {
		await axios
			.put(`${this.url}resume-reviews/publish/${id}`)
			.then((data) => successCallBack(data))
			.catch((data) => errorCallBack(data));
	}

	async getResumeReview(successCallBack, errorCallBack) {
		await axios
			.get(`${this.url}resume-reviews`)
			.then((data) => successCallBack(data))
			.catch((data) => errorCallBack(data));
	}

	async patchResumeLedger(resumeId, inputData, section, successCallBack, errorCallBack, action = "UPDATE") {
		if (section === "HEADING") {
			inputData = this.createHeadingData(inputData);
		}

		const requestData = {
			...inputData,
			section: section,
			action,
		};

		axios
			.patch(`${this.url}resume-ledger/${resumeId}`, requestData)
			.then((data) => {
				//TODO update store with new data

				let resumeList = localStorage.getItem("resumeList");

				if (resumeList) {
					//checking if resumeList exists
					let storedResumes = JSON.parse(localStorage.getItem("resumeList"));

					const updatedResumeIndex = storedResumes.findIndex((resume) => resume.journal.id === data.data.journal.id);

					storedResumes[updatedResumeIndex].journal = data.data.journal;

					localStorage.setItem("resumeList", JSON.stringify(storedResumes));
				}

				return successCallBack(data);
			})
			.catch((data) => errorCallBack(data));
	}

	async postPaymentDetails(inputData, successCallBack, errorCallBack) {
		await axios
			.post(`${this.url}payments`, inputData)
			.then((data) => successCallBack(data))
			.catch((data) => errorCallBack(data));
	}

	async createPaymentOrder(orderData, successCallBack, errorCallBack) {
		await axios
			.post(`${this.url}payments/orders`, orderData)
			.then((data) => successCallBack(data))
			.catch((data) => errorCallBack(data));
	}
	async getOrderStatus(orderID, successCallBack, errorCallBack) {
		await axios
			.patch(`${this.url}payments/orders/${orderID}`)
			.then((data) => successCallBack(data))
			.catch((data) => errorCallBack(data));
	}

	async getSearchedJobs(searchedParams, successCallBack, errorCallBack) {
		await axios
			.get(`${this.url}jobs`, {
				params: searchedParams,
			})
			.then((data) => successCallBack(data))
			.catch((data) => errorCallBack(data));
	}

	async getMyJobs(myJobsParams, successCallback, errorCallback) {
		await axios
			.get(`${this.url}jobs`, {
				params: myJobsParams,
			})
			.then((data) => successCallback(data))
			.catch((data) => errorCallback(data));
	}

	async getSelectedJob(jobId, successCallBack, errorCallBack) {
		await axios
			.get(`${this.url}jobs/${jobId}`)
			.then((data) => successCallBack(data))
			.catch((data) => errorCallBack(data));
	}

	async getFreeTrialsRecommendedJob(param, successCallBack, errorCallBack) {
		await axios
			.get(`${this.url}recommended-jobs/free-trial`, {
				params: param,
			})
			.then((data) => successCallBack(data))
			.catch((data) => errorCallBack(data));
	}

	async getRecommendedJobs(params, successCallBack, errorCallBack) {
		await axios
			.get(`${this.url}recommended-jobs`, {
				params: params,
			})
			.then((data) => successCallBack(data))
			.catch((data) => errorCallBack(data));
	}

	async setInteraction(path, interactionData, successCallBack, errorCallBack) {
		axios
			.patch(`${this.url}${path}${interactionData.jobId}`, interactionData.payload)
			.then((data) => successCallBack(data))
			.catch((data) => errorCallBack(data));
	}

	async getPendingPayments(accountId, successCallBack, errorCallBack) {
		await axios
			.get(`${this.url}payments/pending-payments`, {
				params: { accountId: accountId },
			})
			.then((data) => successCallBack(data))
			.catch((data) => errorCallBack(data));
	}

	async changeTemplateName(inputData, successCallBack, errorCallBack) {
		axios
			.patch(
				`${this.url}resume-journal/${inputData.journaReferenceId}`,
				{
					templateId: inputData.templateId,
				},
				{
					params: {
						journaReferenceId: inputData.journaReferenceId,
					},
				},
			)
			.then((data) => successCallBack(data))
			.catch((data) => errorCallBack(data));
	}

	async createUserAccount(data, successCallBack, errorCallBack) {
		await axios
			.post(`${this.url}users`, data)
			.then((data) => successCallBack(data))
			.catch((data) => errorCallBack(data));
	}

	async jobPreferences(data, successCallBack, errorCallBack) {
		await axios
			.put(`${this.url}users/account`, data)
			.then((data) => successCallBack(data))
			.catch((data) => errorCallBack(data));
	}

	async getJobDescriptions(successCallBack, errorCallBack) {
		await axios
			.get(`${this.url}jds`)
			.then((data) => successCallBack(data))
			.catch((data) => errorCallBack(data));
	}

	async getJobDescriptionByID(jobId, successCallBack, errorCallBack) {
		await axios
			.get(`${this.url}jds?jdReferenceId=${jobId}`)
			.then((data) => successCallBack(data))
			.catch((data) => errorCallBack(data));
	}

	async reviewJobDescription(jobId, reviewData, successCallBack, errorCallBack) {
		await axios
			.patch(`${this.url}jds/${jobId}`, reviewData)
			.then((data) => successCallBack(data))
			.catch((data) => errorCallBack(data));
	}

	async getPaymentsRewards(successCallBack, errorCallBack) {
		await axios
			.get(`${this.url}payments/rewards`)
			.then((data) => successCallBack(data))
			.catch((data) => errorCallBack(data));
	}

	async createPaymentReward(paymentRewardData, successCallBack, errorCallBack) {
		await axios
			.post(`${this.url}payments/rewards`, paymentRewardData)
			.then((data) => successCallBack(data))
			.catch((data) => errorCallBack(data));
	}

	async claimPaymentReward(paymentRewardID, successCallBack, errorCallBack) {
		await axios
			.patch(`${this.url}payments/rewards/${paymentRewardID}`)
			.then((data) => successCallBack(data))
			.catch((data) => errorCallBack(data));
	}

	async jobMarketCheckExistance(params, successCallBack, errorCallBack) {
		await axios
			.get(`${this.url}job-market/check-existance`, {
				params: params,
			})
			.then((data) => successCallBack(data))
			.catch((data) => errorCallBack(data));
	}

	async getSharedLeads(successCallBack, errorCallBack) {
		await axios
			.get(`${this.url}job-market/shared-leads`)
			.then((data) => successCallBack(data))
			.catch((data) => errorCallBack(data));
	}

	async closeSharedLead({ id, reviewDecision, jobMarketReferenceId }, successCallBack, errorCallBack) {
		await axios
			.patch(`${this.url}job-market/shared-leads/${id}`, {
				reviewDecision,
				jobMarketReferenceId,
			})
			.then((data) => successCallBack(data))
			.catch((data) => errorCallBack(data));
	}

	async saveJobInteraction(jobId, body, successCallBack, errorCallBack) {
		await axios
			.patch(`${this.url}jobs/${jobId}`, body)
			.then((data) => successCallBack(data))
			.catch((data) => errorCallBack(data));
	}

	async deleteJobInteraction(jobId, successCallBack, errorCallBack) {
		await axios
			.delete(`${this.url}jobs/${jobId}`)
			.then((data) => successCallBack(data))
			.catch((data) => errorCallBack(data));
	}

	async createSmartProfile(payload, successCallBack, errorCallBack) {
		await axios
			.post(`${this.url}smart-profiles`, payload)
			.then((data) => successCallBack(data))
			.catch((error) => errorCallBack(error));
	}

	async updateDSP(payload, dspId, successCallBack, errorCallBack) {
		await axios
			.patch(`${this.url}smart-profiles/dsp/${dspId}`, payload, { params: { dspId } })
			.then((data) => successCallBack(data))
			.catch((error) => errorCallBack(error));
	}

	async removeDocument(params, dspId, successCallBack, errorCallBack) {
		await axios
			.delete(`${this.url}smart-profiles/docs/${dspId}`, { params: { ...params } })
			.then((data) => successCallBack(data))
			.catch((error) => errorCallBack(error));
	}

	// async getJobCategories(successCallBack, errorCallBack) {
	// 	await axios
	// 		.get(`${this.url}jobs/categories`)
	// 		.then((data) => successCallBack(data))
	// 		.catch((data) => errorCallBack(data));
	// }

	// async getLatestJobs(successCallBack, errorCallBack) {
	// 	await axios
	// 		.get(`${this.url}jobs/latest`)
	// 		.then((data) => successCallBack(data))
	// 		.catch((data) => errorCallBack(data));
	// }

	// async getUpcomingJobs(successCallBack, errorCallBack) {
	// 	await axios
	// 		.get(`${this.url}jobs/upcoming`)
	// 		.then((data) => successCallBack(data))
	// 		.catch((data) => errorCallBack(data));
	// }

	// 	async getFeaturedEmployers(successCallBack, errorCallBack) {
	// 		await axios
	// 			.get(`${this.url}jobs/employers/featured`)
	// 			.then((data) => successCallBack(data))
	// 			.catch((data) => errorCallBack(data));
	// 	}
}
