const initalState = {
	fromDSP: false,
	actionPerformed: "",
};

export default function ProfileDSPReducer(dspSate = initalState, action) {
	switch (action.type) {
		case "SET_DSP_STATE":
			return action.payload;

		case "RESET_DSP_STATE":
			return initalState;

		default:
			return dspSate;
	}
}
