import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { actions } from "../handlers/enums";

const useDSPProfileState = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const onDSPSelect = (option) => {
		let payload = {
			fromDSP: false,
			actionPerformed: "",
		};

		if (option.action === actions.CREATE) {
			payload = {
				fromDSP: true,
				actionPerformed: actions.CREATE,
			};
		}

		if (option.action === actions.UPDATE) {
			payload = {
				fromDSP: true,
				actionPerformed: actions.UPDATE,
			};
		}

		// if (option.action === actions.SAVE) {
		//
		// }

		dispatch({ type: "SET_DSP_STATE", payload: payload });
		window.scrollTo(0, 0);
		navigate("/profile");
	};

	const dspModalData = (action) => {
		if (action === actions.CREATE) {
			return {
				title: "Before you apply",
				subTitle: "To proceed with your application, you need a Smart Profile (DSP). Choose an option below:",
				options: [
					{
						action: "Create",
						title: "Create a  DSP by uploading my resume.",
						subTitle:
							"One-Click Apply requires a DSP to auto-fill your details and attach documents. Upload your resume to create your DSP.",
					},
					{
						action: "Save",
						title: "Save & Apply Later",
						subTitle: "Your job will be saved in Saved Jobs so you can apply when you're ready.",
					},
				],
			};
		}

		return {
			title: "Add More Documents to Your Smart Profile",
			subTitle:
				"To include additional documents in your application, first attach them to your Smart Profile (DSP). Your job application will be saved—once done, continue applying from Saved Jobs.",
			options: [
				{
					action: "Update",
					title: "Go to my Smart Profile and upload documents.",
					subTitle: "Attach additional files (cover letter, portfolio, certificates) to your DSP before applying.",
				},
				{
					action: "Save",
					title: "Save & Apply Later",
					subTitle: "Your job will be saved in Saved Jobs so you can apply when you're ready.",
				},
			],
		};
	};

	return {
		onDSPSelect,
		dspModalData,
	};
};

export default useDSPProfileState;
