import mappings from "../seed/metadata/filemapping";
export default {
	educationLevels: [
		{ name: "Select certificate", value: "" },
		{ name: "O-Level Secondary", value: "O-Level" },
		{ name: "A-Level Secondary", value: "A-Level" },
		{ name: "Certificate", value: "CERTIFICATE" },
		{ name: "Diploma", value: "DIPLOMA" },
		{ name: "Advance Diploma", value: "ADVANCED DIPLOMA" },
		{ name: "Bachelor", value: "BACHELOR" },
		{ name: "Post Graduate Certificate", value: "POST GRADUATE CERTIFICATE" },
		{ name: "Post Graduate Diploma", value: "POST GRADUATE DIPLOMA" },
		{ name: "Masters", value: "MASTERS" },
		{ name: "MBA", value: "MBA" },
		{ name: "PhD", value: "PhD" },
	],

	countries: [
		{ name: "Tanzania", value: "TANZANIA" },
		{ name: "Abroad", value: "ABROAD" },
	],

	fieldsOfStudy: [
		{ name: "Select field of study", value: "" },
		{ name: "Secondary Education", value: "Secondary Education" },
		{ name: "Agriculture", value: "Agriculture" },
		{ name: "Architecture and Planning", value: "Architecture and Planning" },
		{ name: "Arts and Humanities", value: "Arts and Humanities" },
		{ name: "Business", value: "Business" },
		{ name: "Communication", value: "Communication" },
		{ name: "Education", value: "Education" },
		{ name: "Engineering", value: "Engineering" },
		{ name: "Environmental Science", value: "Environmental Science" },
		{ name: "Information and Communication Technology", value: "Information and Communication Technology" },
		{ name: "Journalism, Media Studies and Communication", value: "Journalism, Media Studies and Communication" },
		{ name: "Law", value: "Law" },
		{ name: "Library, Archive and Museum Studies", value: "Library, Archive and Museum Studies" },
		{ name: "Life Sciences", value: "Life Sciences" },
		{ name: "Medicine and Health Sciences", value: "Medicine and Health Sciences" },
		{ name: "Mining and Earth Sciences", value: "Mining and Earth Sciences" },
		{ name: "Physical Sciences and Mathematics", value: "Physical Sciences and Mathematics" },
		{ name: "Social Sciences", value: "Social Sciences" },
		{ name: "Tourism and Hospitality Studies", value: "Tourism and Hospitality Studies" },
	],

	months: [
		{ name: "Select month", value: "" },
		{ name: "January", value: "1" },
		{ name: "February", value: "2" },
		{ name: "March", value: "3" },
		{ name: "April", value: "4" },
		{ name: "May", value: "5" },
		{ name: "June", value: "6" },
		{ name: "July", value: "7" },
		{ name: "August", value: "8" },
		{ name: "September", value: "9" },
		{ name: "October", value: "10" },
		{ name: "November", value: "11" },
		{ name: "December", value: "12" },
	],

	jobExpertiseOptions: [
		"Accounting",
		"Administration and Office Support",
		"Advertising, Arts and Media",
		"Agriculture",
		"Banking",
		"Business Development",
		"Civil Engineering",
		"Community Services and Development",
		"Consulting and Strategy",
		"Customer Service",
		"Design and Architecture",
		"Education",
		"Engineering",
		"Environmental Services",
		"Finance",
		"Healthcare and Medical",
		"Hospitality and Tourism",
		"Human Resources and Recruitment",
		"Information Technology",
		"Insurance",
		"Legal",
		"Marketing and Communications",
		"Operations",
		"Real Estate and Property",
		"Retail and Consumer Goods",
		"Sales",
		"Security",
		"Sports and Recreation",
		"Telecommunications",
		"Transport and Logistics",
	],

	companyTypes: [
		"Educational",
		"Government Agency",
		"Non-Profit",
		"Partnership",
		"Privately Held",
		"Public Company",
		"Self Employed",
		"Self Owned",
	],

	companyIndustriesOptions: mappings.companyIndustries,

	tilTags: ["WWW"],

	phoneCountryCodeOptions: [
		{
			name: "Tanzania",
			// name: "Tanzania, United Republic of Tanzania",
			phoneNumberCode: "+255",
			code: "TZ",
		},
		{
			name: "Afghanistan",
			phoneNumberCode: "+93",
			code: "AF",
		},
		{
			name: "Aland Islands",
			phoneNumberCode: "+358",
			code: "AX",
		},
		{
			name: "Albania",
			phoneNumberCode: "+355",
			code: "AL",
		},
		{
			name: "Algeria",
			phoneNumberCode: "+213",
			code: "DZ",
		},
		{
			name: "AmericanSamoa",
			phoneNumberCode: "+1684",
			code: "AS",
		},
		{
			name: "Andorra",
			phoneNumberCode: "+376",
			code: "AD",
		},
		{
			name: "Angola",
			phoneNumberCode: "+244",
			code: "AO",
		},
		{
			name: "Anguilla",
			phoneNumberCode: "+1264",
			code: "AI",
		},
		{
			name: "Antarctica",
			phoneNumberCode: "+672",
			code: "AQ",
		},
		{
			name: "Antigua and Barbuda",
			phoneNumberCode: "+1268",
			code: "AG",
		},
		{
			name: "Argentina",
			phoneNumberCode: "+54",
			code: "AR",
		},
		{
			name: "Armenia",
			phoneNumberCode: "+374",
			code: "AM",
		},
		{
			name: "Aruba",
			phoneNumberCode: "+297",
			code: "AW",
		},
		{
			name: "Australia",
			phoneNumberCode: "+61",
			code: "AU",
		},
		{
			name: "Austria",
			phoneNumberCode: "+43",
			code: "AT",
		},
		{
			name: "Azerbaijan",
			phoneNumberCode: "+994",
			code: "AZ",
		},
		{
			name: "Bahamas",
			phoneNumberCode: "+1242",
			code: "BS",
		},
		{
			name: "Bahrain",
			phoneNumberCode: "+973",
			code: "BH",
		},
		{
			name: "Bangladesh",
			phoneNumberCode: "+880",
			code: "BD",
		},
		{
			name: "Barbados",
			phoneNumberCode: "+1246",
			code: "BB",
		},
		{
			name: "Belarus",
			phoneNumberCode: "+375",
			code: "BY",
		},
		{
			name: "Belgium",
			phoneNumberCode: "+32",
			code: "BE",
		},
		{
			name: "Belize",
			phoneNumberCode: "+501",
			code: "BZ",
		},
		{
			name: "Benin",
			phoneNumberCode: "+229",
			code: "BJ",
		},
		{
			name: "Bermuda",
			phoneNumberCode: "+1441",
			code: "BM",
		},
		{
			name: "Bhutan",
			phoneNumberCode: "+975",
			code: "BT",
		},
		{
			name: "Bolivia, Plurinational State of",
			phoneNumberCode: "+591",
			code: "BO",
		},
		{
			name: "Bosnia and Herzegovina",
			phoneNumberCode: "+387",
			code: "BA",
		},
		{
			name: "Botswana",
			phoneNumberCode: "+267",
			code: "BW",
		},
		{
			name: "Brazil",
			phoneNumberCode: "+55",
			code: "BR",
		},
		{
			name: "British Indian Ocean Territory",
			phoneNumberCode: "+246",
			code: "IO",
		},
		{
			name: "Brunei Darussalam",
			phoneNumberCode: "+673",
			code: "BN",
		},
		{
			name: "Bulgaria",
			phoneNumberCode: "+359",
			code: "BG",
		},
		{
			name: "Burkina Faso",
			phoneNumberCode: "+226",
			code: "BF",
		},
		{
			name: "Burundi",
			phoneNumberCode: "+257",
			code: "BI",
		},
		{
			name: "Cambodia",
			phoneNumberCode: "+855",
			code: "KH",
		},
		{
			name: "Cameroon",
			phoneNumberCode: "+237",
			code: "CM",
		},
		{
			name: "Canada",
			phoneNumberCode: "+1",
			code: "CA",
		},
		{
			name: "Cape Verde",
			phoneNumberCode: "+238",
			code: "CV",
		},
		{
			name: "Cayman Islands",
			phoneNumberCode: "+ 345",
			code: "KY",
		},
		{
			name: "Central African Republic",
			phoneNumberCode: "+236",
			code: "CF",
		},
		{
			name: "Chad",
			phoneNumberCode: "+235",
			code: "TD",
		},
		{
			name: "Chile",
			phoneNumberCode: "+56",
			code: "CL",
		},
		{
			name: "China",
			phoneNumberCode: "+86",
			code: "CN",
		},
		{
			name: "Christmas Island",
			phoneNumberCode: "+61",
			code: "CX",
		},
		{
			name: "Cocos (Keeling) Islands",
			phoneNumberCode: "+61",
			code: "CC",
		},
		{
			name: "Colombia",
			phoneNumberCode: "+57",
			code: "CO",
		},
		{
			name: "Comoros",
			phoneNumberCode: "+269",
			code: "KM",
		},
		{
			name: "Congo",
			phoneNumberCode: "+242",
			code: "CG",
		},
		{
			name: "Congo, The Democratic Republic of the Congo",
			phoneNumberCode: "+243",
			code: "CD",
		},
		{
			name: "Cook Islands",
			phoneNumberCode: "+682",
			code: "CK",
		},
		{
			name: "Costa Rica",
			phoneNumberCode: "+506",
			code: "CR",
		},
		{
			name: "Cote d'Ivoire",
			phoneNumberCode: "+225",
			code: "CI",
		},
		{
			name: "Croatia",
			phoneNumberCode: "+385",
			code: "HR",
		},
		{
			name: "Cuba",
			phoneNumberCode: "+53",
			code: "CU",
		},
		{
			name: "Cyprus",
			phoneNumberCode: "+357",
			code: "CY",
		},
		{
			name: "Czech Republic",
			phoneNumberCode: "+420",
			code: "CZ",
		},
		{
			name: "Denmark",
			phoneNumberCode: "+45",
			code: "DK",
		},
		{
			name: "Djibouti",
			phoneNumberCode: "+253",
			code: "DJ",
		},
		{
			name: "Dominica",
			phoneNumberCode: "+1767",
			code: "DM",
		},
		{
			name: "Dominican Republic",
			phoneNumberCode: "+1849",
			code: "DO",
		},
		{
			name: "Ecuador",
			phoneNumberCode: "+593",
			code: "EC",
		},
		{
			name: "Egypt",
			phoneNumberCode: "+20",
			code: "EG",
		},
		{
			name: "El Salvador",
			phoneNumberCode: "+503",
			code: "SV",
		},
		{
			name: "Equatorial Guinea",
			phoneNumberCode: "+240",
			code: "GQ",
		},
		{
			name: "Eritrea",
			phoneNumberCode: "+291",
			code: "ER",
		},
		{
			name: "Estonia",
			phoneNumberCode: "+372",
			code: "EE",
		},
		{
			name: "Ethiopia",
			phoneNumberCode: "+251",
			code: "ET",
		},
		{
			name: "Falkland Islands (Malvinas)",
			phoneNumberCode: "+500",
			code: "FK",
		},
		{
			name: "Faroe Islands",
			phoneNumberCode: "+298",
			code: "FO",
		},
		{
			name: "Fiji",
			phoneNumberCode: "+679",
			code: "FJ",
		},
		{
			name: "Finland",
			phoneNumberCode: "+358",
			code: "FI",
		},
		{
			name: "France",
			phoneNumberCode: "+33",
			code: "FR",
		},
		{
			name: "French Guiana",
			phoneNumberCode: "+594",
			code: "GF",
		},
		{
			name: "French Polynesia",
			phoneNumberCode: "+689",
			code: "PF",
		},
		{
			name: "Gabon",
			phoneNumberCode: "+241",
			code: "GA",
		},
		{
			name: "Gambia",
			phoneNumberCode: "+220",
			code: "GM",
		},
		{
			name: "Georgia",
			phoneNumberCode: "+995",
			code: "GE",
		},
		{
			name: "Germany",
			phoneNumberCode: "+49",
			code: "DE",
		},
		{
			name: "Ghana",
			phoneNumberCode: "+233",
			code: "GH",
		},
		{
			name: "Gibraltar",
			phoneNumberCode: "+350",
			code: "GI",
		},
		{
			name: "Greece",
			phoneNumberCode: "+30",
			code: "GR",
		},
		{
			name: "Greenland",
			phoneNumberCode: "+299",
			code: "GL",
		},
		{
			name: "Grenada",
			phoneNumberCode: "+1473",
			code: "GD",
		},
		{
			name: "Guadeloupe",
			phoneNumberCode: "+590",
			code: "GP",
		},
		{
			name: "Guam",
			phoneNumberCode: "+1671",
			code: "GU",
		},
		{
			name: "Guatemala",
			phoneNumberCode: "+502",
			code: "GT",
		},
		{
			name: "Guernsey",
			phoneNumberCode: "+44",
			code: "GG",
		},
		{
			name: "Guinea",
			phoneNumberCode: "+224",
			code: "GN",
		},
		{
			name: "Guinea-Bissau",
			phoneNumberCode: "+245",
			code: "GW",
		},
		{
			name: "Guyana",
			phoneNumberCode: "+595",
			code: "GY",
		},
		{
			name: "Haiti",
			phoneNumberCode: "+509",
			code: "HT",
		},
		{
			name: "Holy See (Vatican City State)",
			phoneNumberCode: "+379",
			code: "VA",
		},
		{
			name: "Honduras",
			phoneNumberCode: "+504",
			code: "HN",
		},
		{
			name: "Hong Kong",
			phoneNumberCode: "+852",
			code: "HK",
		},
		{
			name: "Hungary",
			phoneNumberCode: "+36",
			code: "HU",
		},
		{
			name: "Iceland",
			phoneNumberCode: "+354",
			code: "IS",
		},
		{
			name: "India",
			phoneNumberCode: "+91",
			code: "IN",
		},
		{
			name: "Indonesia",
			phoneNumberCode: "+62",
			code: "ID",
		},
		{
			name: "Iran, Islamic Republic of Persian Gulf",
			phoneNumberCode: "+98",
			code: "IR",
		},
		{
			name: "Iraq",
			phoneNumberCode: "+964",
			code: "IQ",
		},
		{
			name: "Ireland",
			phoneNumberCode: "+353",
			code: "IE",
		},
		{
			name: "Isle of Man",
			phoneNumberCode: "+44",
			code: "IM",
		},
		{
			name: "Israel",
			phoneNumberCode: "+972",
			code: "IL",
		},
		{
			name: "Italy",
			phoneNumberCode: "+39",
			code: "IT",
		},
		{
			name: "Jamaica",
			phoneNumberCode: "+1876",
			code: "JM",
		},
		{
			name: "Japan",
			phoneNumberCode: "+81",
			code: "JP",
		},
		{
			name: "Jersey",
			phoneNumberCode: "+44",
			code: "JE",
		},
		{
			name: "Jordan",
			phoneNumberCode: "+962",
			code: "JO",
		},
		{
			name: "Kazakhstan",
			phoneNumberCode: "+77",
			code: "KZ",
		},
		{
			name: "Kenya",
			phoneNumberCode: "+254",
			code: "KE",
		},
		{
			name: "Kiribati",
			phoneNumberCode: "+686",
			code: "KI",
		},
		{
			name: "Korea, Democratic People's Republic of Korea",
			phoneNumberCode: "+850",
			code: "KP",
		},
		{
			name: "Korea, Republic of South Korea",
			phoneNumberCode: "+82",
			code: "KR",
		},
		{
			name: "Kuwait",
			phoneNumberCode: "+965",
			code: "KW",
		},
		{
			name: "Kyrgyzstan",
			phoneNumberCode: "+996",
			code: "KG",
		},
		{
			name: "Laos",
			phoneNumberCode: "+856",
			code: "LA",
		},
		{
			name: "Latvia",
			phoneNumberCode: "+371",
			code: "LV",
		},
		{
			name: "Lebanon",
			phoneNumberCode: "+961",
			code: "LB",
		},
		{
			name: "Lesotho",
			phoneNumberCode: "+266",
			code: "LS",
		},
		{
			name: "Liberia",
			phoneNumberCode: "+231",
			code: "LR",
		},
		{
			name: "Libyan Arab Jamahiriya",
			phoneNumberCode: "+218",
			code: "LY",
		},
		{
			name: "Liechtenstein",
			phoneNumberCode: "+423",
			code: "LI",
		},
		{
			name: "Lithuania",
			phoneNumberCode: "+370",
			code: "LT",
		},
		{
			name: "Luxembourg",
			phoneNumberCode: "+352",
			code: "LU",
		},
		{
			name: "Macao",
			phoneNumberCode: "+853",
			code: "MO",
		},
		{
			name: "Macedonia",
			phoneNumberCode: "+389",
			code: "MK",
		},
		{
			name: "Madagascar",
			phoneNumberCode: "+261",
			code: "MG",
		},
		{
			name: "Malawi",
			phoneNumberCode: "+265",
			code: "MW",
		},
		{
			name: "Malaysia",
			phoneNumberCode: "+60",
			code: "MY",
		},
		{
			name: "Maldives",
			phoneNumberCode: "+960",
			code: "MV",
		},
		{
			name: "Mali",
			phoneNumberCode: "+223",
			code: "ML",
		},
		{
			name: "Malta",
			phoneNumberCode: "+356",
			code: "MT",
		},
		{
			name: "Marshall Islands",
			phoneNumberCode: "+692",
			code: "MH",
		},
		{
			name: "Martinique",
			phoneNumberCode: "+596",
			code: "MQ",
		},
		{
			name: "Mauritania",
			phoneNumberCode: "+222",
			code: "MR",
		},
		{
			name: "Mauritius",
			phoneNumberCode: "+230",
			code: "MU",
		},
		{
			name: "Mayotte",
			phoneNumberCode: "+262",
			code: "YT",
		},
		{
			name: "Mexico",
			phoneNumberCode: "+52",
			code: "MX",
		},
		{
			name: "Micronesia, Federated States of Micronesia",
			phoneNumberCode: "+691",
			code: "FM",
		},
		{
			name: "Moldova",
			phoneNumberCode: "+373",
			code: "MD",
		},
		{
			name: "Monaco",
			phoneNumberCode: "+377",
			code: "MC",
		},
		{
			name: "Mongolia",
			phoneNumberCode: "+976",
			code: "MN",
		},
		{
			name: "Montenegro",
			phoneNumberCode: "+382",
			code: "ME",
		},
		{
			name: "Montserrat",
			phoneNumberCode: "+1664",
			code: "MS",
		},
		{
			name: "Morocco",
			phoneNumberCode: "+212",
			code: "MA",
		},
		{
			name: "Mozambique",
			phoneNumberCode: "+258",
			code: "MZ",
		},
		{
			name: "Myanmar",
			phoneNumberCode: "+95",
			code: "MM",
		},
		{
			name: "Namibia",
			phoneNumberCode: "+264",
			code: "NA",
		},
		{
			name: "Nauru",
			phoneNumberCode: "+674",
			code: "NR",
		},
		{
			name: "Nepal",
			phoneNumberCode: "+977",
			code: "NP",
		},
		{
			name: "Netherlands",
			phoneNumberCode: "+31",
			code: "NL",
		},
		{
			name: "Netherlands Antilles",
			phoneNumberCode: "+599",
			code: "AN",
		},
		{
			name: "New Caledonia",
			phoneNumberCode: "+687",
			code: "NC",
		},
		{
			name: "New Zealand",
			phoneNumberCode: "+64",
			code: "NZ",
		},
		{
			name: "Nicaragua",
			phoneNumberCode: "+505",
			code: "NI",
		},
		{
			name: "Niger",
			phoneNumberCode: "+227",
			code: "NE",
		},
		{
			name: "Nigeria",
			phoneNumberCode: "+234",
			code: "NG",
		},
		{
			name: "Niue",
			phoneNumberCode: "+683",
			code: "NU",
		},
		{
			name: "Norfolk Island",
			phoneNumberCode: "+672",
			code: "NF",
		},
		{
			name: "Northern Mariana Islands",
			phoneNumberCode: "+1670",
			code: "MP",
		},
		{
			name: "Norway",
			phoneNumberCode: "+47",
			code: "NO",
		},
		{
			name: "Oman",
			phoneNumberCode: "+968",
			code: "OM",
		},
		{
			name: "Pakistan",
			phoneNumberCode: "+92",
			code: "PK",
		},
		{
			name: "Palau",
			phoneNumberCode: "+680",
			code: "PW",
		},
		{
			name: "Palestinian Territory, Occupied",
			phoneNumberCode: "+970",
			code: "PS",
		},
		{
			name: "Panama",
			phoneNumberCode: "+507",
			code: "PA",
		},
		{
			name: "Papua New Guinea",
			phoneNumberCode: "+675",
			code: "PG",
		},
		{
			name: "Paraguay",
			phoneNumberCode: "+595",
			code: "PY",
		},
		{
			name: "Peru",
			phoneNumberCode: "+51",
			code: "PE",
		},
		{
			name: "Philippines",
			phoneNumberCode: "+63",
			code: "PH",
		},
		{
			name: "Pitcairn",
			phoneNumberCode: "+872",
			code: "PN",
		},
		{
			name: "Poland",
			phoneNumberCode: "+48",
			code: "PL",
		},
		{
			name: "Portugal",
			phoneNumberCode: "+351",
			code: "PT",
		},
		{
			name: "Puerto Rico",
			phoneNumberCode: "+1939",
			code: "PR",
		},
		{
			name: "Qatar",
			phoneNumberCode: "+974",
			code: "QA",
		},
		{
			name: "Romania",
			phoneNumberCode: "+40",
			code: "RO",
		},
		{
			name: "Russia",
			phoneNumberCode: "+7",
			code: "RU",
		},
		{
			name: "Rwanda",
			phoneNumberCode: "+250",
			code: "RW",
		},
		{
			name: "Reunion",
			phoneNumberCode: "+262",
			code: "RE",
		},
		{
			name: "Saint Barthelemy",
			phoneNumberCode: "+590",
			code: "BL",
		},
		{
			name: "Saint Helena, Ascension and Tristan Da Cunha",
			phoneNumberCode: "+290",
			code: "SH",
		},
		{
			name: "Saint Kitts and Nevis",
			phoneNumberCode: "+1869",
			code: "KN",
		},
		{
			name: "Saint Lucia",
			phoneNumberCode: "+1758",
			code: "LC",
		},
		{
			name: "Saint Martin",
			phoneNumberCode: "+590",
			code: "MF",
		},
		{
			name: "Saint Pierre and Miquelon",
			phoneNumberCode: "+508",
			code: "PM",
		},
		{
			name: "Saint Vincent and the Grenadines",
			phoneNumberCode: "+1784",
			code: "VC",
		},
		{
			name: "Samoa",
			phoneNumberCode: "+685",
			code: "WS",
		},
		{
			name: "San Marino",
			phoneNumberCode: "+378",
			code: "SM",
		},
		{
			name: "Sao Tome and Principe",
			phoneNumberCode: "+239",
			code: "ST",
		},
		{
			name: "Saudi Arabia",
			phoneNumberCode: "+966",
			code: "SA",
		},
		{
			name: "Senegal",
			phoneNumberCode: "+221",
			code: "SN",
		},
		{
			name: "Serbia",
			phoneNumberCode: "+381",
			code: "RS",
		},
		{
			name: "Seychelles",
			phoneNumberCode: "+248",
			code: "SC",
		},
		{
			name: "Sierra Leone",
			phoneNumberCode: "+232",
			code: "SL",
		},
		{
			name: "Singapore",
			phoneNumberCode: "+65",
			code: "SG",
		},
		{
			name: "Slovakia",
			phoneNumberCode: "+421",
			code: "SK",
		},
		{
			name: "Slovenia",
			phoneNumberCode: "+386",
			code: "SI",
		},
		{
			name: "Solomon Islands",
			phoneNumberCode: "+677",
			code: "SB",
		},
		{
			name: "Somalia",
			phoneNumberCode: "+252",
			code: "SO",
		},
		{
			name: "South Africa",
			phoneNumberCode: "+27",
			code: "ZA",
		},
		{
			name: "South Sudan",
			phoneNumberCode: "+211",
			code: "SS",
		},
		{
			name: "South Georgia and the South Sandwich Islands",
			phoneNumberCode: "+500",
			code: "GS",
		},
		{
			name: "Spain",
			phoneNumberCode: "+34",
			code: "ES",
		},
		{
			name: "Sri Lanka",
			phoneNumberCode: "+94",
			code: "LK",
		},
		{
			name: "Sudan",
			phoneNumberCode: "+249",
			code: "SD",
		},
		{
			name: "Suriname",
			phoneNumberCode: "+597",
			code: "SR",
		},
		{
			name: "Svalbard and Jan Mayen",
			phoneNumberCode: "+47",
			code: "SJ",
		},
		{
			name: "Swaziland",
			phoneNumberCode: "+268",
			code: "SZ",
		},
		{
			name: "Sweden",
			phoneNumberCode: "+46",
			code: "SE",
		},
		{
			name: "Switzerland",
			phoneNumberCode: "+41",
			code: "CH",
		},
		{
			name: "Syrian Arab Republic",
			phoneNumberCode: "+963",
			code: "SY",
		},
		{
			name: "Taiwan",
			phoneNumberCode: "+886",
			code: "TW",
		},
		{
			name: "Tajikistan",
			phoneNumberCode: "+992",
			code: "TJ",
		},

		{
			name: "Thailand",
			phoneNumberCode: "+66",
			code: "TH",
		},
		{
			name: "Timor-Leste",
			phoneNumberCode: "+670",
			code: "TL",
		},
		{
			name: "Togo",
			phoneNumberCode: "+228",
			code: "TG",
		},
		{
			name: "Tokelau",
			phoneNumberCode: "+690",
			code: "TK",
		},
		{
			name: "Tonga",
			phoneNumberCode: "+676",
			code: "TO",
		},
		{
			name: "Trinidad and Tobago",
			phoneNumberCode: "+1868",
			code: "TT",
		},
		{
			name: "Tunisia",
			phoneNumberCode: "+216",
			code: "TN",
		},
		{
			name: "Turkey",
			phoneNumberCode: "+90",
			code: "TR",
		},
		{
			name: "Turkmenistan",
			phoneNumberCode: "+993",
			code: "TM",
		},
		{
			name: "Turks and Caicos Islands",
			phoneNumberCode: "+1649",
			code: "TC",
		},
		{
			name: "Tuvalu",
			phoneNumberCode: "+688",
			code: "TV",
		},
		{
			name: "Uganda",
			phoneNumberCode: "+256",
			code: "UG",
		},
		{
			name: "Ukraine",
			phoneNumberCode: "+380",
			code: "UA",
		},
		{
			name: "United Arab Emirates",
			phoneNumberCode: "+971",
			code: "AE",
		},
		{
			name: "United Kingdom",
			phoneNumberCode: "+44",
			code: "GB",
		},
		{
			name: "United States",
			phoneNumberCode: "+1",
			code: "US",
		},
		{
			name: "Uruguay",
			phoneNumberCode: "+598",
			code: "UY",
		},
		{
			name: "Uzbekistan",
			phoneNumberCode: "+998",
			code: "UZ",
		},
		{
			name: "Vanuatu",
			phoneNumberCode: "+678",
			code: "VU",
		},
		{
			name: "Venezuela, Bolivarian Republic of Venezuela",
			phoneNumberCode: "+58",
			code: "VE",
		},
		{
			name: "Vietnam",
			phoneNumberCode: "+84",
			code: "VN",
		},
		{
			name: "Virgin Islands, British",
			phoneNumberCode: "+1284",
			code: "VG",
		},
		{
			name: "Virgin Islands, U.S.",
			phoneNumberCode: "+1340",
			code: "VI",
		},
		{
			name: "Wallis and Futuna",
			phoneNumberCode: "+681",
			code: "WF",
		},
		{
			name: "Yemen",
			phoneNumberCode: "+967",
			code: "YE",
		},
		{
			name: "Zambia",
			phoneNumberCode: "+260",
			code: "ZM",
		},
		{
			name: "Zimbabwe",
			phoneNumberCode: "+263",
			code: "ZW",
		},
	],

	years(noOFYears) {
		const numbers = Array.from(Array(noOFYears).keys());
		const years = numbers.map((item) => {
			return { name: item + 2000, value: item + 2000 };
		});

		return [{ name: "Select year", value: "" }].concat(years);
	},
};
