import React from "react";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";

import { useAuth } from "../../context/Auth/AuthContext";

function ExpertTopNav({ screenWidth, headingIcon, headingTitle, setShowSideBar }) {
	const isDropzOpsUser = localStorage.getItem("isDropzOpsUser");
	const { picture } = JSON.parse(localStorage.getItem("profileData"))
		? JSON.parse(localStorage.getItem("profileData"))
		: { firstName: "", lastName: "", email: "" };
	const { logout } = useAuth();
	const [__, _] = useCookies(["Authorization"]);

	return (
		<div className="expert-review-top-nav">
			{screenWidth <= 768 && (
				<i
					className="bi bi-list cursor-pointer"
					style={{ color: "#484E5C", fontSize: "20px" }}
					onClick={() => setShowSideBar(true)}
				/>
			)}
			{screenWidth > 768 && (
				<div className="d-flex align-items-center">
					{headingIcon && (
						<i className={`bi bi-${headingIcon}`} style={{ color: "#3E424E", marginRight: 9, fontSize: "20px" }} />
					)}
					{headingTitle && (
						<div className="add-custom-skill-button-text" style={{ color: "#000000", fontSize: "16px" }}>
							{headingTitle}
						</div>
					)}
				</div>
			)}

			<div className="flex items-center justify-end space-x-2 w-2/3 md:w-1/6">
				<div className="dropdown">
					<div
						className="flex items-center space-x-2 cursor-pointer dropdown-toggle hide-toggle"
						data-toggle="dropdown"
						aria-expanded="false"
					>
						{/* Hamburger Icon */}
						<div className="w-4 h-4 flex flex-col justify-between">
							<span className="w-full h-0.5 bg-gray-800 rounded-full" />
							<span className="w-full h-0.5 bg-gray-800 rounded-full" />
							<span className="w-full h-0.5 bg-gray-800 rounded-full" />
						</div>
						{/* Profile Image */}
						<div className="w-8 h-8 rounded-full bg-gray-200 flex items-center justify-center overflow-hidden">
							<img
								src={picture}
								alt="User profile"
								width={32}
								height={32}
								className="rounded-full object-cover"
								placeholder="blur"
							/>
						</div>
					</div>

					{/* Dropdown menu */}
					<div className="dropdown-menu dropdown-menu-right custom-dropdown mt-2 shadow-sm rounded border-0 p-1">
						<Link to="/profile" className="dropdown-item">
							Smart Profile
						</Link>
						<Link to="/my-resumes" className="dropdown-item">
							My Resumes
						</Link>
						<Link to="/jobs" className="dropdown-item">
							Jobs
						</Link>
						<Link to="/settings" className="dropdown-item">
							Settings
						</Link>
						{isDropzOpsUser && (
							<>
								<Link to="/job-market" className="dropdown-item">
									Control Center
								</Link>
								<Link to="/media-kit" className="dropdown-item">
									Media Kit
								</Link>
							</>
						)}
						<Link
							onClick={() => {
								logout();
								window.location.href = "/";
							}}
							className="dropdown-item"
						>
							Logout
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ExpertTopNav;
