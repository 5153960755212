const sections = {
	HEADING: "HEADING",
	EXPERIENCE: "EXPERIENCE",
	EDUCATION: "EDUCATION",
	SKILLS: "SKILLS",
	SUMMARY: "SUMMARY",
	REFERENCE: "REFERENCE",
	CERTIFICATION: "CERTIFICATION",
	AFFILIATION: "AFFILIATION",
	ADDITIONAL_INFO: "ADDITIONAL_INFO",
	LANGUAGES: "LANGUAGES",
	ACHIEVEMENTS: "ACHIEVEMENTS",
	PERSONAL: "PERSONAL",
	LINKS: "LINKS",
	CUSTOM: "CUSTOM",
	ACCOMPLISHMENTS: "ACCOMPLISHMENTS",
};

const interactions = {
	VIEWED: "Viewed",
	SAVED_FOR_LATER: "Saved",
	APPLYING: "Applying",
	APPLIED: "Applied",
	WITHDRAW_APPLICATION: "Withdrew",
	ADD_NOTES: "Notes",
	NOT_RELEVANT: "Not Relevant",
};

const actions = {
	REGISTER: "Register",
	UPDATE: "Update",
	SAVE: "Save",
	CREATE: "Create",
	DELETE: "Delete",
	EDIT: "Edit",
};

const docSubTypes = Object.freeze({
	RESUME: {
		name: "Resume",
		icon: "document-v3",
	},
	PORTFOLIO: {
		name: "Portifolio",
		icon: "envelope-open",
	},
	COVER_LETTER: {
		name: "Cover letter",
		icon: "link-2",
	},
	CERTIFICATE: {
		name: "Certificate",
		icon: "certificate",
	},
	CREDENTIAL: {
		name: "Credential",
		icon: "link-2",
	},
	META_DATA: {
		name: "Meta Data",
		icon: "link-2",
	},
	OTHER: {
		name: "Other",
		icon: "link-2",
	},
});

const dspDocTypes = Object.freeze({
	RESUME: "Resume",
	GENERAL_DOC: "General Doc",
	DSP_DOC: "DSP Doc",
	META_DATA: "Meta Data",
});

export { actions, docSubTypes, dspDocTypes, interactions, sections };
