import React, { useEffect, useState } from "react";

import EditableTD from "../../components/EditableTD";
import Icon from "../../components/Icon/Icon";
import UserAvatar from "../../components/UserAvatar";
import { dateObjToYMD } from "../../handlers/utilityFunctions";
import Dropdown from "../Dropdown/Dropdown";
import DropdownOptions from "../Dropdown/DropdownOptions";
import DropdownSearch from "../Dropdown/DropdownSearch";
import EditableFilter from "./EditableFilter";
import EditColumnsModal from "./EditColumnsModal";
import Tabs from "./Tabs";

export const SEARCH_CATEGORIES = {
	EMPTY: "Is empty",
	NOT_EMPTY: "Is not empty",
	CONTAINS: "Contains",
	DOESNT_CONTAIN: "Doesn't contain",
};

function Table({
	data,
	columns: allColumns,
	checkAll,
	onCheckAll,
	onCheck,
	onUpdate,
	sortByColumn,
	showAddForm,
	AddFormComponent,
	showProfile,
	ProfileComponent,
	setShowProfile,
	setSelectedItem,
	selectedItem,
	tabs,
	loadingAction,
	currentTab,
	setCurrentTab,
	onSubmit,
	fetchData,
}) {
	const [columns, setColumns] = useState(allColumns.filter((item) => item.visible));

	const page = window.location.pathname.split("/").pop();
	const storageKey = `columns-${page}`;
	const [pageSize, setPageSize] = useState(10);

	useEffect(() => {
		const storedPreferences = localStorage.getItem(storageKey);
		if (storedPreferences) {
			setColumns(JSON.parse(storedPreferences).columns);
			setPageSize(JSON.parse(storedPreferences).pageSize || 10);
		}
	}, [storageKey]);

	const [sortDirections, setSortDirections] = useState(
		columns.reduce((acc, column) => {
			acc[column.key] = "asc";
			return acc;
		}, {}),
	);

	const [tableData, setTableData] = useState(data);
	const [search, setSearch] = useState("");
	const [filters, setFilters] = useState([]);
	const [dropdownItems, setDropdownItems] = useState(columns);
	const [searchOption, setSearchOption] = useState("");
	const [dismissDropdown, setDismissDropdown] = useState(Math.random());

	const [currentPage, setCurrentPage] = useState(1);

	const totalPages = Math.ceil(tableData.length / pageSize);

	useEffect(() => {
		setTableData(data);
	}, [data]);

	useEffect(() => {
		let filteredData = data;
		filters.forEach((item) => {
			if (item.filter === SEARCH_CATEGORIES.EMPTY || item.filter === SEARCH_CATEGORIES.NOT_EMPTY) {
				filteredData = filteredData.filter((row) =>
					item.filter === SEARCH_CATEGORIES.EMPTY ? !row[item.column] : row[item.column],
				);
			}

			if (item.filter === SEARCH_CATEGORIES.CONTAINS || item.filter === SEARCH_CATEGORIES.DOESNT_CONTAIN) {
				filteredData = filteredData.filter((row) =>
					item.filter === SEARCH_CATEGORIES.CONTAINS
						? row[item.column]?.toLowerCase().includes(item.value.toLowerCase())
						: !row[item.column]?.toLowerCase().includes(item.value.toLowerCase()),
				);
			}
		});
		setTableData(filteredData);
	}, [data, filters]);

	const handleSort = (key) => {
		const newDirection = sortDirections[key] === "asc" ? "desc" : "asc";
		setSortDirections((prev) => ({ ...prev, [key]: newDirection }));
		sortByColumn(key, newDirection);
	};

	const handleSearch = (e) => {
		const value = e.target.value.toLowerCase();
		setSearch(value);
		setTableData(
			data.filter((item) =>
				Object.values(item).some((val) => typeof val === "string" && val.toLowerCase().includes(value)),
			),
		);
	};

	const removeFilter = (index) => {
		setFilters((prev) => prev.filter((_, i) => i !== index));
	};

	const onSearchDropdown = (e) => {
		const value = e.target.value.toLowerCase();
		setSearchOption(value);
		setDropdownItems(columns.filter((item) => item.label.toLowerCase().includes(value)));
	};

	const handlePageChange = (newPage) => {
		if (newPage > 0 && newPage <= totalPages) {
			setCurrentPage(newPage);
		}
	};

	const paginatedData = tableData.slice((currentPage - 1) * pageSize, currentPage * pageSize);

	const [showEditColumnModal, setShowEditColumnModal] = useState(false);

	return (
		<div className="p-3">
			<div className="flex items-center justify-between gap-2 px-1 py-2 text-sm">
				<div className="flex items-start justify-center rounded-l-md">
					<span className="mr-1 capitalize text-gray-500">
						<i className="fa-solid fa-list mx-1" />
						All
					</span>
					&#183;<span className="mx-1 text-gray-500">{data.length}</span>
				</div>
				<div className="flex items-center gap-2">
					<input
						type="text"
						placeholder="Search"
						className="w-96 rounded-md border-gray-100 px-2 py-1 text-sm shadow-sm"
						value={search}
						onChange={handleSearch}
					/>
					<Dropdown label="Filter" dismiss={dismissDropdown}>
						<DropdownSearch value={searchOption} onChange={onSearchDropdown} />
						<DropdownOptions
							data={dropdownItems}
							onSelect={({ key: value }) => {
								const appliedFilters = {
									column: value,
									filter: SEARCH_CATEGORIES.CONTAINS,
									value: "",
								};

								setFilters((prev) => [...prev, appliedFilters]);
								setDismissDropdown(Math.random());
							}}
						/>
					</Dropdown>
					<button className="rounded border border-gray-200 px-2 py-1" onClick={() => setShowEditColumnModal(true)}>
						<i className="bi bi-gear" />
					</button>
				</div>
			</div>
			<div className="flex gap-1 py-1">
				{filters.map((filter, index) => (
					<EditableFilter
						filter={filter}
						removeFilter={removeFilter}
						index={index}
						key={index}
						setFilters={setFilters}
						columns={columns}
						data={data}
					/>
				))}
			</div>
			<div className="border">
				{tabs && <Tabs currentView={currentTab} setCurrentView={setCurrentTab} tabs={tabs} />}
				<table className="table w-full">
					<thead>
						<tr className="!border-0 bg-white">
							{checkAll && onCheckAll && (
								<th className="flex items-start rounded-l-md">
									<input type="checkbox" checked={checkAll} onChange={onCheckAll} />
								</th>
							)}
							{columns
								.filter((item) => item.visible)
								.map((column) => (
									<th key={column.key} onClick={() => handleSort(column.key)}>
										<span className="flex items-center font-semibold">
											<Icon size={14} className="mr-1" name={column.icon} />
											{column.label}
											{sortDirections[column.key] === "asc" ? (
												<i className="bi bi-arrow-up ml-1" />
											) : (
												<i className="bi bi-arrow-down ml-1" />
											)}
										</span>
									</th>
								))}
						</tr>
					</thead>
					<tbody>
						{showAddForm && <AddFormComponent />}
						{paginatedData.length === 0 && <div className="p-4">No items found</div>}
						{paginatedData.map((item, index) => (
							<tr key={index}>
								{onCheck && (
									<td>
										<input type="checkbox" checked={item.checked} onChange={(e) => onCheck(e, index)} />
									</td>
								)}
								{columns
									.filter((item) => item.visible)
									.map((column) => {
										let imageUrl = "/images/co-placeholder.png";
										if (column.image) {
											const [parent, child] = column.image.split(".");
											if (child) {
												imageUrl = item[parent]?.[child] === null ? imageUrl : item[parent]?.[child];
											} else {
												imageUrl = item[parent] === null ? imageUrl : item[parent];
											}
										}

										return (
											<React.Fragment key={column.key}>
												{column.key === "deadline" ? (
													<td>{dateObjToYMD(item.deadlineDate)}</td>
												) : column.editable ? (
													<EditableTD
														onChange={(value) => onUpdate(column.key, value, index)}
														value={item[column.key]}
													/>
												) : column.avatar ? (
													<td>
														<UserAvatar name={item[column.key]} />
													</td>
												) : (
													<td
														onClick={() => {
															if (column.showProfile) {
																setSelectedItem({ ...item, index });
																setShowProfile(true);
															}
														}}
														className="group"
													>
														<div className="flex items-center justify-between">
															<div className="flex items-center space-x-2">
																{column.showProfile && column.image && (
																	<div className="w-12">
																		<img
																			alt="Company Logo"
																			src={imageUrl}
																			onError={(e) => (e.target.src = "/images/co-placeholder.png")}
																		/>
																	</div>
																)}
																<div>
																	<div>{item[column.key]}</div>
																	{column.key === "jobTitle" && column.showProfile && (
																		<div className="text-sm text-gray-600">{item.companyName}</div>
																	)}
																</div>
															</div>
															{column.showProfile && (
																<span className="hidden rounded border border-gray-100 p-2 shadow-sm group-hover:flex">
																	<i className="fa-solid fa-arrow-right -rotate-45" />
																</span>
															)}
														</div>
													</td>
												)}
											</React.Fragment>
										);
									})}
							</tr>
						))}
					</tbody>
				</table>
			</div>
			{showProfile && (
				<ProfileComponent
					selectedItem={selectedItem}
					setShowProfile={setShowProfile}
					onUpdate={onUpdate}
					onSubmit={onSubmit}
					fetchData={fetchData}
					setSelectedItem={setSelectedItem}
					loadingAction={loadingAction}
				/>
			)}
			<div className="mt-4 flex items-center justify-end gap-4 text-sm">
				<button
					className="rounded border border-gray-50 px-2 py-1"
					onClick={() => handlePageChange(currentPage - 1)}
					disabled={currentPage === 1}
				>
					<i className="fa-solid fa-arrow-left mr-1" />
					Previous
				</button>
				<span>
					Page {currentPage} of {totalPages}
				</span>
				<button
					className="rounded border border-gray-50 px-2 py-1"
					onClick={() => handlePageChange(currentPage + 1)}
					disabled={currentPage === totalPages}
				>
					Next
					<i className="fa-solid fa-arrow-right ml-1" />
				</button>
			</div>
			<EditColumnsModal
				allColumns={allColumns}
				columns={columns}
				setColumns={setColumns}
				showEditColumnModal={showEditColumnModal}
				setShowEditColumnModal={setShowEditColumnModal}
				setPageSize={setPageSize}
				pageSize={pageSize}
				storageKey={storageKey}
			/>
		</div>
	);
}

export default Table;
