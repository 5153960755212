export default {
	async uploadResumeFileToBucketWithFetchAPI(inputData, successCallBack, errorCallBack) {
		fetch(inputData.uploadUrl, {
			method: "PUT",
			body: inputData.file,
		})
			.then((data) => successCallBack(data))
			.catch((data) => errorCallBack(data));
	},
};
