import React, { useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import tips from "../../../../../assets/copy/tips";
import AutoSuggestion from "../../../../../components/AutoSuggestion";
import ErrorToast from "../../../../../components/ErrorToast";
import Spinner from "../../../../../components/Spinner";
import Tips from "../../../../../components/Tips";
import InputField from "../../../../../components/UIComponents/InputField";
import SelectField from "../../../../../components/UIComponents/SelectField";
import ResumeContext from "../../../../../context/ResumeContext";
import { FormValidation } from "../../../../../handlers/formValidation";
import { resumeObjectToContextFormat } from "../../../../../handlers/valueTransformation";
import useHasChange from "../../../../../hooks/use-HasChange";
import { Reference } from "../../../../../models/resume/reference";
import { StaticValue } from "../../../../../seed/constants";
import options from "../../../../../seed/dropDownOptions";
import mapping from "../../../../../seed/metadata/filemapping";
import { ResumeDataService } from "../../../../../services/create-resume/resume-data";

const ReferenceInformation = () => {
	const [cookies] = useCookies(["resume"]);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const reference = useSelector((state) => state.ReferenceInfoReducer);
	const resumeDataService = new ResumeDataService();
	const staticValue = new StaticValue();
	const { hasChanges } = useHasChange();
	const formValidation = new FormValidation();
	const staticValues = staticValue.getDefaultValues();
	const [serverErrors, setServerErrors] = useState([]);
	const [address, setAddress] = useState(reference.workplaceLocation);
	const [country, setCountry] = useState(reference.country || "Tanzania");
	const locations = mapping["districtsRegions"][0].districtRegions;

	const navigationState = useLocation().state;

	//Declaring the form states
	const [refInfo, setRefInfo] = useState({
		id: reference.referenceId,
		name: reference.fullName,
		designation: reference.designation,
		workPlace: reference.workPlace,
		phoneNumber: reference.phoneNumber,
		email: reference.emailAddress,
	});

	const { resumeData, setResumeData } = useContext(ResumeContext);
	const { references } = resumeData.extras;

	useEffect(() => {
		if (navigationState) {
			const data = references.filter((item) => item.id === navigationState.referenceId)[0];
			setRefInfo(() => {
				return data;
			});

			if (references.length !== 0) {
				setAddress(() => {
					return data["address"];
				});
				setCountry(() => {
					return data["country"];
				});
			}
		}
	}, [resumeData]);

	// The form event handler
	const onChangeInput = (e) => {
		if (e.target.name === "address") {
			setAddress(e.target.value);
		} else if (e.target.name === "country") {
			setCountry(e.target.value);
		} else {
			setRefInfo((prevState) => {
				return {
					...prevState,
					[e.target.name]: e.target.value,
				};
			});
		}
	};

	const [errors, setErrors] = useState({
		name: "",
		designation: "",
		workPlace: "",
		address: "",
		phoneNumber: "",
		email: "",
	});

	const [loading, setLoading] = useState(false);

	const { name, designation, workPlace, phoneNumber, email } = refInfo;
	let formData = {
		name,
		designation,
		workPlace,
		address,
		phoneNumber,
		email,
	};

	const formComplete = () => {
		if (formValidation.isFormComplete(formData)) {
			return true;
		}

		return false;
	};

	//form submission handler
	const addReference = () => {
		let validations = formValidation.validateReferenceInfo(errors, formData);

		if (validations.status) {
			setErrors(validations.errors);
		} else {
			return setErrors(validations.errors);
		}

		if (!formComplete()) {
			// navigate(`/resume/reference-summary`);
			return;
		} else {
			const [city, region] = formData.address.split(",");

			const payload = {
				city: city.trim(),
				region: (region || "").trim(),
				country: country,
				name: formData.name,
				email: formData.email,
				designation: formData.designation,
				workPlace: formData.workPlace,
				phoneNumber: formData.phoneNumber,
			};

			const refInfoPayload = new Reference(payload);
			const refReq = refInfoPayload.getReferenceRequest();

			if (refInfo.id !== "") {
				refReq.referenceId = refInfo.id;
			}

			const resumeId = cookies[staticValues.localStorageResumeId];

			const section = "REFERENCE";

			if (hasChanges(section, refReq)) {
				setLoading(true);
				resumeDataService.patchResumeLedger(resumeId, refReq, section, onSuccess, onError);
			} else {
				navigate("/resume/reference-summary");
			}
		}
	};
	const dispatchAddExtras = (payload) => {
		dispatch({ type: "ADD_EXTRAS", payload: payload });
	};

	function onSuccess(data) {
		setLoading(false);
		const response = data.data;
		dispatch({ type: "RESUME_DATA", payload: response });
		resumeObjectToContextFormat(response, setResumeData, dispatchAddExtras);
		navigate("/resume/reference-summary");
	}

	function onError(error) {
		setLoading(false);
		const serverResponse = error.response.data;
		Array.isArray(serverResponse.message)
			? setServerErrors((prev) => {
					return [...prev, ...serverResponse.message];
				})
			: setServerErrors((prev) => {
					return [...prev, serverResponse.message];
				});
	}

	return (
		<>
			<div className="container min-vh-100">
				<div className="section education-information">
					{serverErrors.map((item, index) => {
						return <ErrorToast error={item} key={index} />;
					})}
					<div className="col-md-6">
						<div className="d-flex justify-content-end mb-2">
							<Tips content={tips.referees} />
						</div>
						<div className="mb-3 text-dark">
							<p>Enter the details of your referee.</p>
						</div>

						<div>
							<InputField
								type="text"
								label="Name"
								name="name"
								value={refInfo.name}
								onChange={onChangeInput}
								error={errors.name}
								placeholder="Add name..."
							/>
						</div>
						<div>
							<InputField
								type="text"
								label="Designation"
								name="designation"
								value={refInfo.designation}
								onChange={onChangeInput}
								error={errors.designation}
								placeholder="Add Designation..."
							/>
						</div>
						<div>
							<InputField
								type="text"
								label="Workplace"
								name="workPlace"
								value={refInfo.workPlace}
								onChange={onChangeInput}
								error={errors.workPlace}
								placeholder="Add reference&#39;s company name..."
							/>
						</div>

						<div className="row">
							<div className="col-6">
								<SelectField
									name="country"
									value={country}
									onChange={onChangeInput}
									options={options.phoneCountryCodeOptions}
									label="Country"
								/>
							</div>

							<div className="col-6">
								{!(country === "Tanzania") && (
									<InputField
										type="text"
										label="District/Region"
										name="address"
										value={address}
										onChange={onChangeInput}
										placeholder="Enter Address "
										error={errors.address}
									/>
								)}
								{country === "Tanzania" && (
									<AutoSuggestion
										suggestions={locations}
										label="District/Region"
										type="text"
										name="address"
										userInput={address}
										setUserInput={setAddress}
										isForm={true}
										error={errors.address}
									/>
								)}
							</div>
						</div>

						<div>
							<InputField
								type="text"
								label="Phone number (Eg. +255788000000)"
								name="phoneNumber"
								value={refInfo.phoneNumber}
								onChange={onChangeInput}
								error={errors.phoneNumber}
								placeholder="Enter Reference&#39;s phone number E.g. +255767000000"
							/>
						</div>
						<div>
							<InputField
								type="email"
								label="Email address"
								name="email"
								value={refInfo.email}
								onChange={onChangeInput}
								error={errors.email}
								placeholder="Enter Reference&#39;s Email Address"
							/>
						</div>

						<div className=" mt-4 d-flex align-items-center justify-content-end">
							<button
								onClick={() => {
									navigate(-1);
								}}
								className="btn text-primary font-weight-semi  mr-2"
							>
								Skip for now
							</button>
							<button onClick={addReference} className="btn btn-primary">
								{loading ? (
									<>
										Loading <Spinner />
									</>
								) : (
									"Save and Continue"
								)}
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ReferenceInformation;
