import clsx from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs) {
	return twMerge(clsx(inputs));
}

export function getSelectionStyles(isSelected) {
	if (isSelected) {
		return "border-2 border-blue-600 bg-white bg-gray-50";
	}
	return "";
}
