import axios from "axios";
import { Cookies } from "react-cookie";

import { StaticValue } from "../seed/constants";

const staticValue = new StaticValue();
const staticValues = staticValue.getDefaultValues();

export const API = axios.create({
	baseURL: staticValues.apiUrl,
	headers: {
		"Content-Type": "application/json",
		...staticValues.commonHeader,
	},
});

const cookies = new Cookies();
API.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		if (error.response.status === 401) {
			cookies.remove("Authorization");
			localStorage.removeItem("profileData");
			localStorage.removeItem("isDropzOpsUser");
			window.location.href = "/signin";
		}
		return Promise.reject(error);
	},
);
