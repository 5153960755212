import React from "react";

import Faqs from "../../components/FAQ";
import Header from "../../components/Header";
import Footer from "../StaticPages/Footer";

function FAQ() {
	return (
		<>
			<Header />
			<section className="custom-banner">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-md-6">
							<div className="banner-content">
								<h1 className="text-white">FAQs</h1>
							</div>
						</div>
					</div>
				</div>
			</section>
			<div className="fancy-border-radius" />
			<div className="fancy-box">
				<div className="fancy-border-radius-right bg-white" />
			</div>
			<div className="minus-margin">
				<Faqs />
			</div>
			<Footer />
		</>
	);
}

export default FAQ;
