import { useEffect, useState } from "react";

const featureFlags = {
	expertHelp: false,
	oneClickApply: false,
	dsp: false,
};

export function useFeatureFlag(flagName) {
	const [isEnabled, setIsEnabled] = useState(false);

	useEffect(() => {
		setIsEnabled(!!featureFlags[flagName]);
	}, [flagName]);

	// eslint-disable-next-line no-undef
	return process.env.NODE_ENV === "development" ? true : isEnabled;
}
