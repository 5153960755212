import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "sonner";

import LoadingBars from "../../components/LoadingBars";
import { ResumeDataService } from "../../services/create-resume/resume-data";
import LeadReviewModal from "./LeadReviewModal";

function LeadsList() {
	const [errors, setErrors] = useState([]);
	const [leads, setLeads] = useState([]);
	const [loading, setLoading] = useState(true);
	const [selectedLead, setSelectedLead] = useState(null);

	const apiService = new ResumeDataService();

	useEffect(() => {
		apiService.getSharedLeads(
			(response) => {
				setLeads(response.data);
				setLoading(false);
			},
			(error) => {
				setLoading(false);
				toast.error("Error fetching shared leads");
				setErrors(error);
			},
		);
	}, []);

	useEffect(() => {}, [leads]);
	return (
		<div>
			{errors.length > 0 && (
				<div className="alert alert-danger">
					{Array.isArray(errors) ? errors.map((error, index) => <div key={index}>{error}</div>) : errors}
				</div>
			)}

			{loading ? (
				<div
					style={{
						width: "100%",
						padding: "100px 0",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<LoadingBars />
				</div>
			) : (
				<div
					style={{
						maxHeight: "100vh",
						overflowY: "scroll",
					}}
				>
					{leads.map((lead, index) => (
						<div key={index} className="card mb-3">
							<div className="card-body">
								<div className="row">
									<div className="col">
										<h6 className="card-title mb-0">
											{lead.jobTitle} | {lead.jobCompanyName}
										</h6>
										<p className="card-text">
											<small className="text-muted">{dayjs(lead.initiatedDate).format("DD MMMM, YYYY HH:mm")}</small>
										</p>
										<Link className="btn btn-outline-secondary text-dark mr-2" to={lead.jobLink} target="_blank">
											View Job <i className="fa-solid fa-external-link-alt" />
										</Link>
										<button
											className="btn btn-outline-secondary text-dark"
											onClick={() => {
												setSelectedLead(lead);
											}}
										>
											Close Lead
										</button>
									</div>
								</div>
							</div>
						</div>
					))}
				</div>
			)}
			{selectedLead && <LeadReviewModal sharedLeadID={selectedLead._id} setData={setSelectedLead} />}
		</div>
	);
}

export default LeadsList;
