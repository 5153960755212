import React, { useMemo } from "react";

import {
	BriefcaseIcon,
	MapPinIcon,
	OutlinedDocumentIcon,
	VerticalFiltersIcon,
} from "../../../assets/icons/interface/interface";
import { capitalizeFirstLetterOfWords } from "../../../handlers/utilityFunctions";
import { useFeatureFlag } from "../../../hooks/useFeatureFlag";
import { useUserAccount } from "../../../services/create-resume/queries";
import { Button } from "../../JobsPage/components/Button";
import { Card, CardContent } from "../../JobsPage/components/Card";

const ProfileCard = ({ onEditPreferences, setUploadResumeModal, smartProfileData }) => {
	const showDSP = useFeatureFlag("dsp");
	const savedProfile = JSON.parse(localStorage.getItem("profileData"));

	const { data: userAccount, isLoading } = useUserAccount();

	const profileData = useMemo(() => {
		const out = {
			name: savedProfile?.firstName,
			picture: savedProfile?.picture || "",
			jobTitle: "",
			location: "",
			status: "",
			skills: [],
			preferredJobTypes: ["Full-time", "Remote"],
			preferredIndustries: [],
		};

		if (!isLoading && userAccount) {
			if (userAccount?.job_preference_progress_status.completeness_pct > 0) {
				out.jobTitle = userAccount?.jobPreferences?.desiredTitle || "Your Desired Title Goes Here";
				out.location = userAccount?.jobPreferences?.locationsPreferred
					? userAccount?.jobPreferences?.locationsPreferred?.[0] || "Your Location Goes Here"
					: "Your Location Goes Here";
				out.status = userAccount?.jobPreferences.currentJobsearchStatus;
				out.skills = userAccount?.jobPreferences.skills || [];
				//FOR NOW BACKEND RETURNS A STRING SO WE CONVERT IT TO AN ARRAY
				out.preferredJobTypes = [userAccount?.jobPreferences?.openForWorkTypes] || [];
				out.preferredIndustries = userAccount?.jobPreferences.areasOfExpertise;
				out.completenessPercentage = userAccount?.job_preference_progress_status.completeness_pct;
			}
		}

		return out;
	}, [userAccount, isLoading, savedProfile]);

	if (isLoading) {
		return (
			<Card className="w-full">
				<CardContent className="p-6">
					<div className="flex items-center justify-center">
						<span className="text-gray-500">Loading...</span>
					</div>
				</CardContent>
			</Card>
		);
	}

	const { name, location } = profileData;

	return (
		<Card className="w-full">
			{/* Profile Strength Indicator */}
			<div className="p-6 border-b border-primary-200 bg-blue-50/50">
				<div className="flex items-center justify-between mb-2">
					<span className="text-sm font-medium text-primary-600">Profile Strength</span>
					<span className="text-sm font-medium text-gray-900">{profileData.completenessPercentage}%</span>
				</div>
				<div className="w-full bg-gray-200 rounded-full h-1.5">
					<div
						className="bg-primary-600 h-1.5 rounded-full"
						style={{ width: `${profileData.completenessPercentage}%` }}
					/>
				</div>
			</div>

			<CardContent className="p-6">
				<div className="flex items-start">
					{/* Profile Image */}
					<div className="mr-2 md:mr-6">
						<div className="rounded-full border border-gray-200 p-1">
							<img
								src={profileData.picture ? profileData.picture : "/images/default-profile.png"}
								alt={`${name}'s profile picture`}
								className="rounded-full object-cover h-[40px] w-[40px] md:h-[120px] md:w-[120px]"
							/>
						</div>
					</div>
					<div className="flex-grow">
						<h1 className="text-2xl font-bold font-plus-jakarta text-gray-800 mb-1">
							Welcome, {capitalizeFirstLetterOfWords(profileData.name)}
						</h1>
						{/* Job title and location with icons */}
						<div className="flex flex-col md:flex-row text-sm text-gray-700 font-inter">
							<span className="flex items-center">
								<BriefcaseIcon className="w-4 h-4 mr-1" />
								{!profileData.jobTitle && <span className="w-36 h-4 bg-gray-900 opacity-10 rounded-md" />}
								{profileData.jobTitle && <span>{profileData.jobTitle}</span>}
							</span>
							<span className="hidden md:block mx-2">•</span>
							<span className="flex items-center">
								<MapPinIcon className="w-4 h-4 mr-1" />
								{!location && <span className="w-36 h-4 bg-gray-900 opacity-10 rounded-md" />}
								{location && <span>{location}</span>}
							</span>
						</div>

						{showDSP && (
							<div className="grid grid-cols-1 md:grid-cols-2 gap-[16px] mt-[32px]">
								<div className="flex flex-col items-center justify-center border rounded-[8px] py-[16px] px-[40px] space-y-[16px]">
									<OutlinedDocumentIcon />
									<div className="text-[14px] font-[600]">Dproz Smart Profile (DSP)</div>
									<Button
										variant="outline"
										className="w-full"
										onClick={() => {
											setUploadResumeModal(true);
										}}
									>
										{Object.keys(smartProfileData).length > 0 ? "Create Another DSP" : "Create DSP"}
									</Button>
								</div>
								<div className="flex flex-col items-center justify-center border rounded-[8px] py-[16px] px-[40px] space-y-[16px]">
									<VerticalFiltersIcon />
									<div className="text-[14px] font-[600]">Job Preferences</div>
									<Button variant="outline" className="w-full" onClick={onEditPreferences}>
										Edit Preferences
									</Button>
								</div>
							</div>
						)}

						{showDSP && (
							<div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-2 md:gap-0 border rounded-[8px] px-[12px] py-[18px] mt-[16px]">
								<div className="flex flex-col items-center justify-center py-[6px] space-y[8px] border-r-0 md:border-r">
									<div className="text-[24px] font-[700]">0</div>
									<div className="text-[14px] font-[400]">Applications</div>
								</div>
								<div className="flex flex-col items-center justify-center py-[6px] space-y[8px] border-r-0 md:border-r">
									<div className="text-[24px] font-[700]">0</div>
									<div className="text-[14px] font-[400]">Interviews</div>
								</div>
								<div className="flex flex-col items-center justify-center py-[6px] space-y[8px] border-r-0 md:border-r">
									<div className="text-[24px] font-[700]">0</div>
									<div className="text-[14px] font-[400]">Profile Views</div>
								</div>
								<div className="flex flex-col items-center justify-center py-[6px] space-y[8px]">
									<div className="text-[24px] font-[700]">0</div>
									<div className="text-[14px] font-[400]">Saved Jobs</div>
								</div>
							</div>
						)}

						{/* Open to Opportunities Label */}
						{profileData.status === "Open to Opportunities" && (
							<span className="inline-block bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded-full mb-3">
								Open to Opportunities
							</span>
						)}

						{/* Skills */}
						{!showDSP && (
							<div className="mt-4">
								<h2 className="text-sm font-semibold text-gray-700 font-inter mb-2">Skills</h2>
								<div className="flex flex-wrap gap-2">
									{profileData.skills.map((skill, index) => (
										<span key={index} className="bg-gray-100 text-gray-700 text-xs font-inter px-2 py-1 rounded">
											{skill}
										</span>
									))}
								</div>
							</div>
						)}

						{/* Preferred Job Types */}
						{!showDSP && (
							<div className="mt-4">
								<h2 className="text-sm font-semibold text-gray-700 font-inter mb-2">Preferred Job Types</h2>
								<div className="flex flex-wrap gap-2">
									{profileData.preferredJobTypes.map((jobType, index) => (
										<span key={index} className="bg-gray-100 text-gray-700 text-xs font-inter px-2 py-1 rounded">
											{jobType}
										</span>
									))}
								</div>
							</div>
						)}

						{/* Preferred Industries */}
						{!showDSP && (
							<div className="mt-4">
								<h2 className="text-sm font-semibold text-gray-700 font-inter mb-2">Preferred Industries</h2>
								<div className="flex flex-wrap gap-2">
									{profileData.preferredIndustries.map((industry, index) => (
										<span key={index} className="bg-gray-100 text-gray-700 text-xs font-inter px-2 py-1 rounded">
											{industry}
										</span>
									))}
								</div>
							</div>
						)}

						{!showDSP && (
							<Button variant="outline" className="w-full mt-8" onClick={onEditPreferences}>
								Edit Preferences
							</Button>
						)}
					</div>
				</div>
			</CardContent>
		</Card>
	);
};

export default ProfileCard;
