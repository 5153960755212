import React from "react";

import Icon from "../../../../components/Icon";
import { docSubTypes } from "../../../../handlers/enums";

function ApplicationConfirmation({ onPrev, dsp }) {
	return (
		<div className="mx-auto">
			<div className="flex justify-between">
				<h2 className="text-sm font-semibold mb-4">My Documents</h2>
				<h2 className="text-sm font-semibold mb-4 text-blue-500 cursor-pointer" onClick={() => onPrev()}>
					Edit
				</h2>
			</div>
			<div className="space-y-2">
				<div className="border rounded-lg p-3 cursor-pointer transition-all border-gray-300">
					<div className="flex justify-between items-start">
						<div>
							<h3 className="text-md font-inter font-bold"> {dsp.title} </h3>
							<p className="text-xs font-inter font-medium text-gray-500"> {dsp.email} </p>
							<ul className="mt-1 text-xs text-gray-700">
								{dsp.documents.map((doc) => (
									<li key={doc.id} className="flex items-center gap-1">
										<Icon iconName={docSubTypes[doc.type].icon} size={15} />
										<span>
											<span className="font-inter text-xs font-semibold text-gray-600">
												{docSubTypes[doc.type].name}:
											</span>
											<span className="font-inter text-xs font-medium text-gray-600">{` ${doc.name}`}</span>
										</span>
									</li>
								))}
							</ul>
						</div>
					</div>
				</div>
			</div>

			{/* Documents List */}
			<div>
				<div className="mt-4 mb-4">
					<h3 className="text-md font-semibold">Email Preview</h3>
				</div>

				<div className="font-sans space-y-4">
					<h1 className="text-sm font-semibold">Subject: Application for Senior Product Designer - John Doe</h1>

					<p className="mb-4">Dear Hiring Manager,</p>

					<p className="mb-4">
						I&#39;m excited to apply for the Senior Product Designer position at TechCorp. With my experience in Product
						Design, I’m confident I can bring value to your team.
					</p>

					<h2 className="text-sm font-semibold mt-4 mb-2">Quick Overview:</h2>
					<ul className="list-disc list-inside mb-4">
						<li>Experience: [Years of experience & relevant roles]</li>
						<li>Skills: [Top 3-5 key skills]</li>
						<li>Availability: 2 weeks notice</li>
					</ul>

					<p className="mb-4">
						I&#39;ve attached my resume and supporting documents for your review. Additionally, I&#39;ve provided
						responses to your screening questions below:
					</p>

					<h2 className="text-sm font-semibold mt-4">Why are you interested in this role?</h2>
					<p className="mb-4">
						I specialize in designing mobile and web applications, focusing on thoughtful interactions, clean
						aesthetics, and solving real-world problems through design.
					</p>

					<h2 className="text-sm font-semibold font-semibold mt-4">
						Briefly describe your professional background or career goals
					</h2>
					<p className="mb-4">
						I am a UI/UX designer and web developer with experience in creating user-centric digital solutions. My
						career goal is to design seamless and intuitive products that enhance user experience across various
						platforms. I specialize in designing mobile and web applications, focusing on thoughtful interactions, clean
						aesthetics, and solving real-world problems through design.
					</p>

					<p className="mb-4">
						Thank you for considering my application. I look forward to the possibility of discussing how I can
						contribute to your team.
					</p>

					<p>
						Best regards,
						<br />
						John Doe
						<br />
						john.doe@gmail.com
					</p>
				</div>
			</div>
		</div>
	);
}

export default ApplicationConfirmation;
