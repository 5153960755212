import { Multiselect } from "multiselect-react-dropdown";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import { toast } from "sonner";

import dropDownOptions from "../../../seed/dropDownOptions";
import mapping from "../../../seed/metadata/filemapping";
import { ResumeDataService } from "../../../services/create-resume/resume-data";
import AutoSuggestion from "../../AutoSuggestion";
import Loader from "../../Loader";
import JobDetails from "../JobDetails";
import JobListItem from "../JobListItem";

const JobSearch = ({ searchTab }) => {
	const [searchParams] = useSearchParams();
	let timeOut = 0;
	const dispatch = useDispatch();
	const [jobs, setJobs] = useState([]);
	const [title, setTitle] = useState("");
	const [company, setCompany] = useState("");
	const [activeJob, setActiveJob] = useState({});
	const expertiseOptions = dropDownOptions.jobExpertiseOptions;
	const [location, setLocation] = useState("");
	const [endCount, setEndCount] = useState(0);
	// const [expertise, setExpertise] = useState([]);
	const [loading, setLoading] = useState(false);
	const addresses = mapping["districtsRegions"][0].districtRegions;
	const [muteNext, setMuteNext] = useState(true);
	const [pagesCount, setPagesCount] = useState(1);
	const [startCount, setStartCount] = useState(0);
	const [searchedJobs, setSearchedJobs] = useState({});
	const [pageNumber, setPageNumber] = useState(1);
	const [searchReq, setSearchReq] = useState(false);
	const resumeDataService = new ResumeDataService();
	const [showUpSale, setShowUpSale] = useState(false);
	const [paginateReq, setPaginateReq] = useState(false);
	const [mutePrevious, setMutePrevious] = useState(true);
	const [employmentType, setEmploymentType] = useState("");
	const [screenWidth, setScreenWidth] = useState(window.innerWidth);
	const [selectedExpertise, setSelectedExpertise] = useState([]);
	const counts = useSelector((state) => state.JobsCountReducers);
	const [placeHolderText, setPlaceHolderText] = useState("Search Jobs");
	const [hidePlaceHolderText, setHidePlaceHolderText] = useState(false);
	const browserLocation = useLocation();

	const jobOnclick = (index) => {
		setActiveJob(searchedJobs.jobs[index]);
	};

	const handleOnChange = (event) => {
		if (event.target.name === "title") {
			setTitle(event.target.value);
		}

		if (event.target.name === "company") {
			setCompany(event.target.value);
		}

		if (event.target.name === "fullTime" || event.target.name === "partTime" || event.target.name === "anyTime") {
			setEmploymentType(event.target.value);
		}
	};

	const jobListBox = jobs.map((jobItem, index) => (
		<JobListItem
			key={jobItem._id}
			job={jobItem}
			index={index}
			isSmallScreen={screenWidth < 768}
			jobOnClick={jobOnclick}
		/>
	));

	const onSearchJobs = () => {
		clearTimeout(timeOut);
		setPageNumber(1);
		setLoading(true);
		setSearchReq(true);
		dispatch({
			type: "UPDATE_COUNT",
			payload: { start: 0, end: 0, pageNumber: 0 },
		});
	};

	const handlePagination = (event) => {
		const paginate = event.target.id === "prev" ? -1 : 1;

		if (event.target.id === "prev" && !mutePrevious) {
			onPagination(paginate);
		}

		if (event.target.id === "next" && !muteNext) {
			onPagination(paginate);
		}
	};

	const setPaginationCounts = () => {
		let _startCount;
		let _endCount;

		if (searchedJobs.pageNumber > counts.pageNumber) {
			_startCount = counts.end + 1;
			_endCount = counts.end + searchedJobs.jobs.length;
		} else {
			_startCount = counts.start - searchedJobs.jobs.length;
			_endCount = counts.start - 1;
		}

		setEndCount(_endCount);
		setStartCount(_startCount);

		dispatch({
			type: "UPDATE_COUNT",
			payload: { start: _startCount, end: _endCount, pageNumber: searchedJobs.pageNumber },
		});
	};

	const onPagination = (_paginate) => {
		const _pageNumber = pageNumber + _paginate;
		setLoading(true);
		setPageNumber(_pageNumber);
		setPaginateReq(true);
	};

	const fetchJobs = (jobReferenceId = null) => {
		const address = location.split(",");

		const searchQueryParams = {
			companyReferenceId: company,
			title: title,
			country: "Tanzania",
			region: address[1],
			city: address[0],
			jobExpertise: selectedExpertise.map((expertise) => expertise.key),
			// employmentType: "Full-Time",
			pageNumber: pageNumber,
		};

		if (employmentType !== "") {
			searchQueryParams["employmentType"] = employmentType;
		}

		if (jobReferenceId) {
			searchQueryParams["jobReferenceId"] = jobReferenceId;
			searchQueryParams["jobExpertise"] = ["Information Technology"];
		}

		if (searchQueryParams.jobExpertise.length !== 0) {
			resumeDataService.getSearchedJobs(searchQueryParams, successCallBack, errorCallBack);
		}

		if (searchQueryParams.jobExpertise.length === 0) {
			setLoading(false);
		}
	};
	const successCallBack = (response) => {
		setSearchedJobs(response.data);
		setPageNumber(response.data.pageNumber);
		setLoading(false);

		if (localStorage.getItem("isSubscribed") === "NOT_SUBSCRIBED") {
			if (response.data.jobsCount > 0) {
				timeOut = setTimeout(() => setShowUpSale(true), 5000);
			}
		}
	};

	const errorCallBack = () => {
		setLoading(false);
		toast.error("Something went wrong");
	};

	const formattedOptions = (options) => {
		return options.map((option) => {
			return { key: option };
		});
	};

	const onSelectExpertise = (selectedItem) => {
		setSelectedExpertise(selectedItem);
		onSearchJobs();
	};

	const onRemoveExpertise = (selectedItem) => {
		setSelectedExpertise(selectedItem);
		onSearchJobs();
	};

	const onSelectedValueDecorator = (selectedValue) => {
		const trimmedText = selectedValue.length < 10 ? selectedValue : selectedValue.substring(0, 10);
		return trimmedText.concat("...");
	};

	const expertiseCategoriesStyles = {
		searchBox: {
			height: "37px",
			borderColor: "#6c757d",
			borderRadius: "0.2rem",
			backgroundColor: "#ffffff",
		},
		inputField: {
			paddingBottom: "15px",
			paddingLeft: "15px",
		},
	};

	useEffect(() => {
		if (browserLocation?.state?.jobExpertise) {
			onSelectExpertise([{ key: browserLocation?.state?.jobExpertise }]);
		}
	}, []);

	useEffect(() => {
		if (paginateReq) {
			fetchJobs();
			setPaginateReq(false);
		}

		if (searchReq) {
			fetchJobs();
			setSearchReq(false);
		}
	}, [paginateReq, searchReq]);

	useEffect(() => {
		if (Object.keys(searchedJobs).length !== 0) {
			const _jobs = [];
			for (let i = 0; i < searchedJobs.jobs.length; i++) {
				if (i === 0) {
					_jobs.push({ ...searchedJobs.jobs[i], active: true });
					setActiveJob(searchedJobs.jobs[i]);
				} else {
					_jobs.push({ ...searchedJobs.jobs[i], active: false });
				}
			}

			if (searchedJobs.pagesCount !== 1) {
				if (searchedJobs.pageNumber === 1) {
					setMuteNext(false);
					setMutePrevious(true);
				} else if (searchedJobs.pageNumber === searchedJobs.pagesCount) {
					setMuteNext(true);
					setMutePrevious(false);
				} else {
					setMuteNext(false);
					setMutePrevious(false);
				}
			}

			setPaginationCounts();

			setJobs(_jobs);
			setPagesCount(searchedJobs.pagesCount);
		}
	}, [searchedJobs]);

	useEffect(() => {
		const handleResize = () => {
			setScreenWidth(window.innerWidth);
		};
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, [screenWidth]);

	useEffect(() => {
		if (searchTab) {
			const jobReferenceId = searchParams.get("jobId");
			if (jobReferenceId) {
				setLoading(true);
				fetchJobs(jobReferenceId);
			}
		}
	}, []);

	useEffect(() => {
		if (selectedExpertise.length > 0) {
			setPlaceHolderText("Choose another one");
		}

		if (selectedExpertise.length === 4) {
			setHidePlaceHolderText(true);
		} else {
			setHidePlaceHolderText(false);
		}
	});

	if (!searchTab) return null;

	return (
		<>
			<div className="employee-dashboard-main">
				<div className="">
					<div className="job-search-bar py-3 px-3 mt-3 mb-3 bg-light" style={{ borderRadius: "10px" }}>
						<div className="row">
							{/* Expertise Category*/}
							<div className="col-lg-8">
								<Multiselect
									options={formattedOptions(expertiseOptions)}
									displayValue="key"
									placeholder={placeHolderText}
									hidePlaceholder={hidePlaceHolderText}
									selectionLimit={4}
									closeOnSelect={false}
									style={expertiseCategoriesStyles}
									selectedValues={selectedExpertise}
									onSelect={onSelectExpertise}
									onRemove={onRemoveExpertise}
									selectedValueDecorator={onSelectedValueDecorator}
									avoidHighlightFirstOption={true}
								/>
							</div>

							<div className="col-lg-4 dropdown">
								<div className="dropdown">
									<button
										className="btn btn-block text-dark bg-white border border-secondary rounded-sm px-3 py-2 d-flex justify-content-between"
										type="button"
										id="moreFiltersDropdown"
										data-toggle="dropdown"
										aria-haspopup="true"
										aria-expanded="false"
									>
										More <span className="fa fa-chevron-down" />
									</button>
									<div className="dropdown-menu w-100" aria-labelledby="moreFiltersDropdown">
										<span onClick={(event) => event.stopPropagation()}>
											<div className="col-lg mt-3">
												Location
												<AutoSuggestion
													suggestions={addresses}
													label=""
													type="text"
													name="location"
													userInput={location}
													setUserInput={setLocation}
													placeholder="Eg: District, Region"
													icon="fa fa-location-arrow"
												/>
											</div>

											<div className="col-lg mt-3">
												Job Title
												<div className="input-group mb-3 mb-lg-0 border rounded">
													<div className="input-group-prepend bg-white d-flex align-items-center p-2">
														<span className="fa fa-user text-primary" />
													</div>
													<input
														type="text"
														className="form-control pl-0 border-0"
														value={title}
														onChange={handleOnChange}
														name="title"
														placeholder="Job Title"
													/>
												</div>
											</div>

											<div className="col-lg mt-3 mb-3">
												Company Name
												<div className="input-group mb-3 mb-lg-0 border rounded">
													<div className="input-group-prepend bg-white d-flex align-items-center p-2">
														<span className="fa fa-building text-primary" />
													</div>
													<input
														type="text"
														className="form-control pl-0 border-0"
														value={company}
														onChange={handleOnChange}
														name="company"
														placeholder="Company"
													/>
												</div>
											</div>

											<div className="col-lg mt-3 mb-3 d-block">
												<div>Employment Type</div>
												<div className="d-flex justify-content-between">
													<label>
														<input
															type="radio"
															name="anyTime"
															value=""
															checked={employmentType === ""}
															onChange={handleOnChange}
														/>
														<span
															style={{
																float: "right",
																paddingTop: "7px",
																paddingLeft: "10px",
															}}
														>
															Any
														</span>
													</label>
													<label>
														<input
															type="radio"
															name="fullTime"
															value="Full-Time"
															checked={employmentType === "Full-Time"}
															onChange={handleOnChange}
														/>
														<span
															style={{
																float: "right",
																paddingTop: "7px",
																paddingLeft: "10px",
															}}
														>
															Full Time
														</span>
													</label>
													<label>
														<input
															type="radio"
															name="partTime"
															value="Part-Time"
															checked={employmentType === "Part-Time"}
															onChange={handleOnChange}
														/>
														<span
															style={{
																float: "right",
																paddingTop: "7px",
																paddingLeft: "10px",
															}}
														>
															Part Time
														</span>
													</label>
												</div>
											</div>
										</span>

										<div className="col-lg d-flex justify-content-end">
											<a href="#" className="btn btn-primary h-100 w-100" onClick={onSearchJobs}>
												Search{" "}
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				{loading ? (
					<div className="" style={{ height: "400px" }}>
						<div className="d-flex p-4 align-center h-75 justify-content-center">
							<div className="position-relative" style={{ top: "35%" }}>
								<Loader />
							</div>
						</div>
					</div>
				) : Object.keys(searchedJobs).length === 0 ? (
					<div className="" style={{ height: "500px" }}>
						<div className="d-flex p-4 align-center justify-content-center position-relative" style={{ top: "35%" }}>
							<div className="float-right">
								<p style={{ fontSize: "1.5rem" }}>Search Jobs on the form above!</p>
							</div>
						</div>
					</div>
				) : jobs.length === 0 ? (
					<div className="" style={{ height: "500px" }}>
						<div className="d-flex p-4 align-center justify-content-center position-relative" style={{ top: "35%" }}>
							<div className="float-right">
								<p style={{ fontSize: "1.5rem" }}>No Jobs Found!</p>
							</div>
						</div>
					</div>
				) : (
					<div className="">
						<div className="tab-content">
							{/*active job dashboard*/}
							<div role="tabpanel" className="tab-pane active" id="job-dashboard">
								{/*posted job dashboard*/}
								<div className="posted-job-dashboard employer-common-dashboard ">
									<div className="dashboard-main" style={{ borderRadius: "10px" }}>
										<div className="employee-dashboard">
											<div className="row no-gutters">
												{/*job list*/}
												<div className="col-md-4 ">
													<div className="employee-dashboard-left">
														<ul className="nav nav-tabs applied-job-list border-0" role="tablist">
															{/*job item*/}
															<li className="d-flex border-bottom align-items-center justify-content-between py-4 px-3">
																{startCount === endCount ? (
																	<span className="text-muted">
																		Showing {startCount} of {searchedJobs.jobsCount} jobs found
																	</span>
																) : (
																	<span className="text-muted">
																		Showing {startCount}-{endCount} of {searchedJobs.jobsCount} jobs found
																	</span>
																)}
															</li>
															{jobListBox}
															{pagesCount > 1 ? (
																<li className="d-flex border-bottom bg-white align-items-center justify-content-between py-4 px-3">
																	<div
																		style={{ cursor: "pointer" }}
																		className={`${mutePrevious ? "text-muted" : ""}`}
																		onClick={handlePagination}
																		id="prev"
																	>
																		<span
																			id="prev"
																			className={`fa fa-long-arrow-left text-primary ${
																				mutePrevious ? "text-muted" : ""
																			}`}
																		/>{" "}
																		Prev
																	</div>
																	<div
																		style={{ cursor: "pointer" }}
																		className={`${muteNext ? "text-muted" : ""}`}
																		onClick={handlePagination}
																		id="next"
																	>
																		Next{" "}
																		<span
																			id="next"
																			className={`fa fa-long-arrow-right text-primary ${muteNext ? "text-muted" : ""}`}
																		/>
																	</div>
																</li>
															) : null}
														</ul>
													</div>
												</div>
												{/*job list*/}

												{/*job right section*/}
												{screenWidth > 768 ? (
													<JobDetails details={activeJob} showUpSale={showUpSale} reSetShowUpSale={setShowUpSale} />
												) : null}
												{/*job right section*/}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>

			<div
				className="modal modal-fullscreen-xl"
				id="modal-fullscreen-xl"
				tabIndex="-1"
				role="dialog"
				aria-hidden="true"
			>
				<div className="modal-dialog" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Apply Job</h5>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							{jobs.length !== 0 ? <JobDetails details={activeJob} showUpSale={showUpSale} /> : null}
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-secondary" data-dismiss="modal">
								Close
							</button>
							<button type="button" className="btn btn-primary">
								Save changes
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default JobSearch;
