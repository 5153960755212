import React, { useContext, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";

import Loader from "../components/Loader";
import Spinner from "../components/Spinner";
import ResumeContext from "../context/ResumeContext";
import { ResumeDataService } from "../services/create-resume/resume-data";
import GenerateResumePopup from "../Views/ManageResumes/ResumeListItem/GenerateResumePopup";
import useSetResumeIdCookie from "./use-SetResumeIdCookie";

const useMyResumesUtilities = (resume) => {
	const navigate = useNavigate();

	const { setResumeIdCookie } = useSetResumeIdCookie();

	const { resumeData } = useContext(ResumeContext);

	const [evokeTooltip, initTooltip] = useState(false);
	const target = useRef(null);

	const [resumeGenerateSpinner, setresumeGenerateSpinner] = useState(false);

	let extrasSections = Object.getOwnPropertyNames(resumeData.extras).filter(
		(section) => section !== "activeReferencesIndex",
	);
	let lastUpdatedOn = resumeData.lastUpdatedOn;
	const resumeDataService = new ResumeDataService();
	const [, setresumeGenerated] = useState(false);
	const [generateNewResume, setGenerateNewResume] = useState(false);
	const [showResumePrintPopup, setShowResumePrintPopup] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [isDownloadingExistingResume, setIsDownloadingExistingResume] = useState(false);
	const [printOldResume, setPrintOldResume] = useState(false);
	let extrasSectionsChecks = [];
	const [showMessage, setShowMessage] = useState(false);
	const [resultMessage, setResultMessage] = useState("");
	const [downloadLoading, setDownloadLoading] = useState(false);

	let useTemplateName = resumeData.selectedTemplate.templateName;

	if (resumeData.journal) {
		//if contains q substring from q_to_end
		const indexOfq = resumeData.journal.templateId.indexOf("q");
		let intermediateName = resumeData.journal.templateId;

		if (indexOfq >= 0) {
			intermediateName = intermediateName.substring(indexOfq + 1);
		}

		//replace x with _
		intermediateName = intermediateName.replaceAll("x", "_");

		useTemplateName = intermediateName;
	}
	let Sections = [
		"PERSONAL",
		"REFERENCE",
		"LINKS",
		"AFFILIATION",
		"ADDITIONAL_INFO",
		"CUSTOM",
		"LANGUAGES",
		"ACHIEVEMENTS",
		"CERTIFICATION",
	];
	let ResumeMapping = [
		"PERSONALPROFILE",
		"REFERENCES",
		"PERSONALLINKS",
		"AFFILIATIONS",
		"ADDITIONALINFO",
		"CUSTOMEXTRAS",
		"LANGUAGES",
		"ACCOMPLISHMENTS",
		"CERTIFICATIONS",
	];

	function switchToCreateResume() {
		setresumeGenerated(false);
		setPrintOldResume(false);
		setGenerateNewResume(true);
	}

	function navigateToViewPdf(url, title) {
		return navigate("/view-pdf", {
			state: {
				pdfUrl: url,
				pdfTitle: title,
			},
		});
	}

	function handleCheck(section) {
		if (extrasSectionsChecks[section] === "checked") {
			extrasSectionsChecks.splice(section, 1);
		} else {
			extrasSectionsChecks[section] = "checked";
		}
	}

	function downloadExistingResume(resumeId) {
		setIsDownloadingExistingResume(true);
		resumeDataService.getResumePrintoutById(
			{ resumeId: resumeId },
			(res) => {
				setresumeGenerated(true);
				let url = res.data.url;
				let title = res.data.fullNname;

				navigateToViewPdf(url, title);
			},
			() => {
				toast.error("Something went wrong, please try again");
				setIsDownloadingExistingResume(false);
			},
		);
	}

	//this logic will be moved somewhere else
	function downloadResume(resumeId) {
		setShowResumePrintPopup(true);
		setResumeIdCookie(resumeId);
		setDownloadLoading(true);
		resumeDataService.getResumePrintoutById(
			{ resumeId: resumeId },
			(res) => {
				setDownloadLoading(false);
				setresumeGenerated(true);
				let url = res.data.url;
				let title = res.data.fullNname;
				let printCreated = new Date(res.data.createdAt);
				let resumeUpdated = new Date(lastUpdatedOn);

				// window.open(url, &#39;_blank&#39;);
				if (printCreated > resumeUpdated) {
					navigateToViewPdf(url, title);
				} else {
					setGenerateNewResume(false);
					setPrintOldResume(true);
					setIsLoading(false);
				}
			},
			(err) => {
				setDownloadLoading(false);
				if (err.response.status === 404) {
					setGenerateNewResume(true);
					setIsLoading(false);
				}
			},
		);
	}

	// const [successMessage, setSuccessMessage] = useState();

	function createResumeDownload(resumeId) {
		let removeSections = [];

		extrasSections.map((section) => {
			let sectionCheck = document.getElementById(section);
			if (sectionCheck.checked) {
				let indexForSection = ResumeMapping.indexOf(section.toUpperCase());
				removeSections.push(Sections[indexForSection]);
			}
		});

		let printResumeButton = document.getElementById("create-resume");
		printResumeButton.disabled = true;
		printResumeButton.style.display = "none";
		setresumeGenerateSpinner(true);

		resumeDataService.putResumePrintout(
			{
				resumeId: resumeId,
				payload: {
					templateName: useTemplateName,

					excludedExtraSections: removeSections,
				},
			},
			(res) => {
				let url = res.data.url;
				let title = res.data.fullNname;
				navigateToViewPdf(url, title);
			},
			(err) => {
				if (err.response.status === 402) {
					setShowMessage(true);
					//These messages will be collected in one place, and we will use message codes to display them
					setResultMessage("You do not have a valid subscription. Please click on the link below");

					setTimeout(() => {
						navigate("/payments");
					}, 3000);
				}
				// redirect to http://localhost:3000/subscription-options  402
			},
		);
	}

	// const buttonRef = useRef();

	// const [, setLoadingPreview] = useState(false);

	// function openResumePreview(resumeId, resumeJournalId) {
	//     setResumeIdCookie(resumeId);
	//
	//     if (resumeData.journal._id === resumeJournalId) {
	//         return buttonRef.current.click();
	//     }
	//     if (resumeData.journal._id !== resumeJournalId) {
	//         setLoadingPreview(true);
	//         fetchResumeData(resumeId, () => {
	//             buttonRef.current.click();
	//             setLoadingPreview(false);
	//         });
	//     }
	// }

	const downloadResumeModal = () => {
		return (
			<div className="controlled-modal" role="document">
				<div
					className="modal-content"
					style={{
						width: "fit-content",
					}}
				>
					{isLoading && (
						<div className="d-flex mx-5 mt-5 align-center justify-content-center">
							<Loader />
						</div>
					)}
					{!isLoading && (
						<>
							{generateNewResume && (
								<GenerateResumePopup
									setGenerateNewResume={setGenerateNewResume}
									setShowResumePrintPopup={setShowResumePrintPopup}
									resume={resume}
									resumeGenerateSpinner={resumeGenerateSpinner}
									createResumeDownload={createResumeDownload}
									showMessage={showMessage}
									resultMessage={resultMessage}
									extrasSections={extrasSections}
									handleCheck={handleCheck}
									evokeTooltip={evokeTooltip}
									initTooltip={initTooltip}
									target={target}
								/>
							)}
							{printOldResume && (
								<div role="document">
									<div className="modal-content" style={{ width: "100%" }}>
										<div className="modal-header">
											<h5 className="modal-title" id="exampleModalLongTitle">
												Download Resume
											</h5>
											<button
												type="button"
												className="close"
												data-dismiss="modal"
												aria-label="Close"
												onClick={() => {
													setPrintOldResume(false);
													setShowResumePrintPopup(false);
													setIsLoading(true);
												}}
											>
												<span aria-hidden="true">&times;</span>
											</button>
										</div>
										<div className="modal-body p-3">
											<p className="mb-3">
												You made more changes to your resume after you generated the PDF, do still you want to download
												the existing PDF
											</p>
											<button
												disabled={isDownloadingExistingResume}
												type="button"
												className="btn btn-primary mr-3 mt-3 mt-lg-0"
												onClick={() => downloadExistingResume(resume.id)}
											>
												{isDownloadingExistingResume ? (
													<>
														Loading <Spinner />
													</>
												) : (
													"Download Existing PDF"
												)}
											</button>
											<button
												type="button"
												className="btn btn-primary mt-3 mt-lg-0"
												onClick={() => switchToCreateResume(resume.id)}
											>
												Generate New Resume
											</button>
										</div>
									</div>
								</div>
							)}
						</>
					)}
				</div>
			</div>
		);
	};

	return { downloadResumeModal, downloadResume, showResumePrintPopup, downloadLoading };
};

export default useMyResumesUtilities;
