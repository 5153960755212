import React from "react";

import Header from "../../components/Header";
import Footer from "../StaticPages/Footer";

function TermsAndConditions() {
	return (
		<>
			<Header />
			<section className="custom-banner">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-md-6">
							<div className="banner-content">
								<h1 className="text-white">Terms And Conditions</h1>
							</div>
						</div>
					</div>
				</div>
			</section>
			<div className="fancy-border-radius" />
			<div className="fancy-box">
				<div className="fancy-border-radius-right bg-white" />
			</div>

			<div className="all-view section-padding minus-margin">
				<div className="container">
					<div className="section">
						<p className="mb-3 text-larger">
							Welcome to Dproz! It is our pleasure to provide the services described for your professional development
							in accordance with the Terms and Conditions and all other legal documents incorporated herein by reference
							(collectively, the “Terms”). Please read these Terms carefully.
						</p>
						<p className="mb-3 text-larger">
							If you need any support or have questions, you can reach out to our exceptional customer service team
							through our Contact Us page or email <a href="mailto:info@dproz.com">info@dproz.com</a>
						</p>
						<p className="mb-3 text-larger">
							PLEASE READ THESE TERMS CAREFULLY BEFORE USING THE SERVICE, BECAUSE THEY AFFECT YOUR LEGAL RIGHTS AND
							OBLIGATIONS. IF YOU DO NOT AGREE TO BE BOUND BY THESE TERMS, OR IF AT ANY TIME, THE TERMS ARE NO LONGER
							ACCEPTABLE TO YOU, PLEASE CEASE THE USE OF THE SERVICE IMMEDIATELY. THIS SITE IS INTENDED FOR A TANZANIA
							(“Tanzania”)AUDIENCE. IF YOU ARE NOT A US RESIDENT, WE ENCOURAGE YOU TO ACCESS OUR LOCALIZED SITES
							APPROPRIATE FOR YOUR PLACE OF RESIDENCE OR PREFERABLE LANGUAGE.
						</p>
						<p className="mb-3 text-larger">
							<strong>Service Description, Acceptance of these Terms, Contractual Relationship:</strong>
						</p>
						<p className="mb-3 text-larger">
							These Terms will be governed by and interpreted in accordance with the Data Protection Act of 2018, as the
							services provider and the owner of all intellectual property in the site (hereinafter collectively
							referred to as “Dproz”, “we”, or “us”) and each user (“user”, “you”, or “your”) in connection to the
							access and use of our website, and internet properties as linked and any software that we provide you as
							offered by us, our subsidiaries, and our affiliated companies (collectively the “Site”). The Site may also
							include unless otherwise specified in these Terms, the use of our online platform, proprietary content,
							tools, software, materials, and other services offered through the site (collectively, hereinafter, the
							“Service”). You represent and warrant that you are of age under the laws of your jurisdiction and/or
							lawfully able to enter into contracts. If you are not legally able to enter into contracts, you shall not
							use the Service at any time or in any manner or submit any information to Dproz or the Service
						</p>
						<p className="mb-3 text-larger">
							<strong>Changes to These Terms:</strong>
						</p>
						<p className="mb-3 text-larger">
							<strong>Terms:</strong> Dproz can change, update, add or remove provisions of these Terms, at any time by
							posting the updated Terms on the Site. If you do not agree with any of the updated Terms, you must stop
							using the Service.
						</p>
						<p className="mb-3 text-larger">
							<strong>Service:</strong> Dproz may make changes to the Service at any time, without notice. If you object
							to any changes to the Service, your only recourse will be to cease using it. Continued use of the Service
							following the posting of any such changes will indicate your acknowledgment of such changes and
							satisfaction with the Service as modified. We also reserve the right to discontinue the Service or any
							component of it, at any time without notice. Some elements of the Service may only be offered in the
							desktop version of the Site and may not be, for example, available in the mobile version of the Site. We
							will not be liable to you or any third party should we exercise our right to modify or discontinue the
							Service.
						</p>
						<p className="mb-3 text-larger">
							<strong>Subscription Payment:</strong> Please note that if you are underpaid subscription services with
							us, in the event, we change the price for the services which you have previously agreed to pay or if we
							substantially change the services you are paying for, we will notify you of such changes as contemplated
							in Terms Section Any changes will become effective as of, and reflected on, your next scheduled payment.
							If you do not agree with such changes you may cancel your paid subscription by communicating with us in
							accordance with the Cancellation section below.
						</p>
						<p className="mb-3 text-larger">
							<strong>Additional Terms:</strong> In addition, certain features of the Service may be subject to
							additional terms of use (“Additional Terms”), which shall be provided to you at the moment you choose to
							use such features. By using such features, or any part thereof, you agree to be bound by the Additional
							Terms applicable to such features. In the event that any of the Additional Terms governing such an area
							conflict with these Terms, the Additional Terms will govern.
						</p>
						<p className="mb-3 text-larger">
							<strong>Registration & Accounts:</strong>
							We provide resume and curriculum vitae building services and tools. For as long as you agree to these
							Terms and abide by them, you may use the Service. These Terms apply to all Users of the Service, including
							Visitors and Registered Users.
						</p>
						<p className="mb-3 text-larger">
							<strong>Visiting our Site:</strong> Visitors may browse the Site in accordance with these Terms, but will
							not have full access to the Service (which may include but are not limited to contributing content,
							downloading tools, posting comments, or signing up for special programs) without first becoming
							“Registered Users”.
						</p>
						<p className="mb-3 text-larger">
							<strong>Registered Users & Accounts:</strong> In order to access certain features of the Service you may
							be required to become a Registered User. A “Registered User” is a User who has registered an account with
							us (your “Account”) or who has a valid account on the social networking service (“SNS”) through which the
							User has connected to the Service (each such account, a “Third-Party Account”). As a Registered User, you
							will be able to upload documents as part of creating your resume or cover letter. Those documents will
							remain stored in your account for as long as your registered Account is open.
						</p>
						<p className="mb-3 text-larger">
							<strong>Subscribed Users:</strong> As a Subscribed User you will have broader access to our Site and will
							be able to save, print, share and download the content you create. Different offer options will be
							available to best accommodate your needs.
						</p>
						<p className="mb-3 text-larger">
							<strong>Registration Data:</strong> In registering for the Service, you agree to
							<ol type="I">
								<li>
									provide true, accurate, current, and complete information about yourself as prompted by the
									Service&#39;s registration form (the “Registration Data”); and
								</li>
								<li>
									maintain and promptly update the Registration Data to keep it true, accurate, current, and complete.
								</li>
							</ol>
						</p>
						<p className="mb-3 text-larger">
							You are responsible for all activities that occur under your Account. You may not share your Account or
							password with anyone, and you agree to (A) notify Dproz immediately of any unauthorized use of your
							password or any other breach of security at info@dproz.com and (B) exit from your Account at the end of
							each session. If you provide any information that is untrue, inaccurate, not current, or incomplete, or
							Dproz has reasonable grounds to suspect that such information is untrue, inaccurate, not current, or
							incomplete, Dproz has the right to suspend or terminate your Account and refuse any and all current or
							future use of the Service (or any portion thereof). Dproz shall be entitled to monitor your username and
							password and, at its discretion, require you to change them. If you use a username and password that Dproz
							considers insecure, Dproz will be entitled to require this to be changed and/or terminate your Account.
							Dproz reserves the right to remove or reclaim any usernames at any time and for any reason, including but
							not limited to, claims by a third party that a username violates the third party&#39;s rights. You agree
							not to register for an Account on behalf of an individual other than yourself, or register for an Account
							on behalf of any group or entity unless you are authorized to bind such person, Dproz restricts one
							account per individual and not otherwise. By registering another person, or entity you hereby represent
							that you are authorized to do so.
						</p>
						<p className="mb-3 text-larger">
							<strong>Access through Social Networking Sites:</strong> If you access the Service through an SNS as part
							of the functionality of the Service, you may link your Account with Third-Party Accounts, by allowing the
							Provider to access your Third-Party Account, as is permitted under the applicable terms and conditions
							that govern your use of each Third-Party Account. You represent that you are entitled to disclose your
							Third-Party Account login information to Provider and/or grant Provider access to your Third-Party Account
							(including, but not limited to, for use for the purposes described herein) without breach by you of any of
							the terms and conditions that govern your use of the applicable Third-Party Account and without obligating
							Provider to pay any fees or making Provider subject to any usage limitations imposed by such third-party
							service providers. By granting Provider access to any Third-Party Accounts, you understand that Provider
							may access, make available, and store (if applicable) any information, data, text, software, music, sound,
							photographs, graphics, video, messages, tags, and/or other materials accessible through the Provider
							internet properties that you have provided to and stored in your Third-Party Account (“SNS Content”) so
							that it is available on and through the Provider internet properties via your Account. Unless otherwise
							specified in the Terms, all SNS Content shall be considered to be your User Content (as defined in
							Registration & Accounts) for all purposes of these Terms. Depending on the Third-Party Accounts you choose
							and subject to the privacy settings that you have set in such Third-Party Accounts, information that you
							post to your Third-Party Accounts may be available on and through your Account on the Provider&#39;s
							internet properties. Please note that if a Third-Party Account or associated service becomes unavailable
							or Provider&#39;s access to such Third-Party Account is terminated by the third-party service provider,
							then SNS Content will no longer be available on and through the Provider&#39;s internet properties. You
							have the ability to disable the connection between your Account and your Third-Party Accounts at any time
							by accessing the “Settings” section of the Service.
						</p>
						<p className="mb-3 text-larger">
							PLEASE NOTE THAT YOUR RELATIONSHIP WITH THE THIRD-PARTY SERVICE PROVIDERS ASSOCIATED WITH YOUR THIRD-PARTY
							ACCOUNTS IS GOVERNED SOLELY BY YOUR AGREEMENT(S) WITH SUCH THIRD-PARTY SERVICE PROVIDERS, AND THE PROVIDER
							DISCLAIMS ANY LIABILITY FOR PERSONAL INFORMATION THAT MAY BE PROVIDED TO IT BY SUCH THIRD-PARTY SERVICE
							PROVIDERS IN VIOLATION OF THE PRIVACY SETTINGS THAT YOU HAVE SET IN SUCH THIRD-PARTY ACCOUNTS.
						</p>
						<p className="mb-3 text-larger">
							Provider makes no effort to review any SNS Content for any purpose, including but not limited to, for
							accuracy, legality, or non-infringement, and Provider is not responsible for any SNS Content.
						</p>
						<p className="mb-3 text-larger">
							<strong>Shared Accounts:</strong> Dproz restricts one account per individual and not otherwise. The
							Account Owner may not invite or authorize other third parties to share in their subscription.
						</p>
						<p className="mb-3 text-larger">
							<strong>Deletion or Deactivation of User Content and/or Your Account:</strong> You may request we delete
							resumes and cover letters by logging into your Account through Contact Us page or email at info@dproz.com.
							When you request that your public User Content be deleted, your resume or profiles will no longer be
							visible to other users within the Service, but if your User Content, including your information, was
							previously accessed, stored, and/or copied by others, we are unable to delete the information from their
							systems. You can request your account to be deactivated by any of the means provided on our Contact Us
							page. When you deactivate your Account or ask that we delete your User Content we will retain logs and
							non-personally identifiable information about you along with an archival copy of your information, which
							is not accessible by you or third parties within the Service but which might be used for recordkeeping and
							internal legitimate business purposes. You also agree and understand that some of the content that we may
							create for you may not be able to be deleted once uploaded to the extent permitted by applicable law.
						</p>
						<p className="mb-3 text-larger">
							<strong>Deletion of User Content:</strong> You further understand that Dproz may keep and use data in
							various ways as outlined in these Terms and in the Privacy Statement. If you request that your User
							Content be deleted by us, it will no longer be publicly available and to the extent permissible by law, we
							will take all reasonable steps to delete it. Dproz has no obligation to maintain any Account you open and
							may delete it if you violate these Terms as determined in Dproz&#39;s sole and absolute discretion (please
							see also our Acceptable Use Policy).
						</p>
						<p className="mb-3 text-larger">
							<strong>Collection and Use of Your Information:</strong>
							Your privacy is important to us. Please review Dproz&#39;s Privacy Statement which explains how we use the
							information that you submit to Dproz and the choices you can make about the way this information is
							collected and used. The Privacy Statement is hereby incorporated by reference.
						</p>
						<p className="mb-3 text-larger">
							<strong>Intellectual Property, Trademarks, Copyright, Ownership, & License:</strong>
						</p>
						<p className="mb-3 text-larger">
							<strong>Your Content:</strong> As a Registered User you may upload, post, share, reframe, transmit or
							otherwise make available (“Make Available”) your User Content through the Service. “User Content” means,
							without limitation, your Account information, resume, career history, educational history, reviews,
							responses, profile entries, posts, questions, career materials, testimonials, submissions, and/or any
							other information you provide on or through the Service or that we may create for you. When you create an
							Account or provide career information on or through the Service (including via forms available on the Site
							or by uploading a resume) you agree that you are solely responsible for the accuracy of your User Content.
							You agree that Dproz may offer information that is of most interest to you.
						</p>
						<p className="mb-3 text-larger">
							You may choose to make some of your User Content public on the Service. For some of our features, other
							members may be able to request email notifications of your new public User Content or publish their own
							comments to your comments. We may use the public User Content to improve our service, make connections
							with potential employers, personalize site views, market services or identify or feature popular members.
						</p>
						<p className="mb-3 text-larger">
							For clarity, you retain ownership of your User Content at all times. However, by posting, uploading,
							and/or Making Available any User Content within the Service, and/or by providing any communication or
							material to Dproz, you automatically:
						</p>
						<p className="mb-3 text-larger">
							Moral Rights Waiver: To the extent permitted by applicable law, waive all moral rights in the User Content
							which may be available to you in any part of the world and confirm that no such rights have been asserted;
						</p>
						<p className="mb-3 text-larger">
							Agency: Appoint Dproz as your agent with full power to enter into any document and/or do any act Dproz may
							consider appropriate to confirm the grant and assignment, consent and waiver set out above;
						</p>
						<p className="mb-3 text-larger">
							User Content Ownership: Warrant that you are the owner of the User Content and entitled to enter into
							these Terms and that the User Content does not infringe the proprietary or privacy rights of any third
							party;
						</p>
						<p className="mb-3 text-larger">
							No Encumbrance: Confirm that no such User Content will be subject to any obligation, of confidence or
							otherwise, to you or any other person and that Dproz shall not be liable for any use or disclosure of such
							User Content.
						</p>
						<p className="mb-3 text-larger">
							User Content Rules: Agree and understand that your User Content is subject at all times to our Acceptable
							Use Policy.
						</p>
						<p className="mb-3 text-larger">
							Our Intellectual Property, Trademarks & Copyrights: Except with respect to your User Content and other
							Registered Users&#39; Content, you agree that, as between you and us, Dproz (and our affiliated companies
							and suppliers) own all rights, title, and interest in the Service and all tools, and all related
							intellectual property rights. The Service as a whole is copyrighted as a collective work, and individual
							works or content appearing on or accessible through the Site owned by or licensed to Dproz or its content
							providers are likewise subject to copyright protection domestically and internationally. Likewise, all
							data files, program files, software, code, proprietary methods, systems, and other materials that are made
							available to download from or used to provide the Service (“Materials”) may not be copied, modified,
							reproduced, republished, posted, transmitted, sold, offered for sale, or redistributed in any way without
							our prior written permission and the prior written permission of our applicable licensors. The Materials
							are protected by copyrights, patents, trade secrets, or other proprietary rights.
						</p>
						<p className="mb-3 text-larger">
							You must abide by all copyright notices, information, or restrictions contained in or attached to any of
							our Materials. Nothing in this Agreement grants you any right to receive delivery of a copy of our
							Materials or to obtain access to our Materials except as generally and ordinarily permitted through the
							Service according to these Terms. Certain names, logos, distinctive features, source identifiers, and
							other materials displayed on the Site or in the Services, including their “look and feel”, constitute
							trademarks, tradenames, service marks, trade dress, or logos (“Trademarks”) of us or other entities. All
							Trademarks not owned by Dproz that appear on this site are the property of their respective owners. You
							are not authorized to use any such Trademarks. Ownership of all such Trademarks and the goodwill
							associated therewith remains with us or those other entities. Unless you first obtain the copyright
							owner&#39;s prior written consent, you may not copy, distribute, publicly perform, publicly display,
							digitally perform (in the case of sound recordings), or create derivative works from any copyrighted work
							made available or accessible via the Service. You will not remove, alter or obscure any copyright,
							trademark, service mark, or other proprietary rights notices incorporated in or accompanying the Service.
						</p>
						<p className="mb-3 text-larger">
							Your Use of Materials: Your right to make use of the Service and any Material or other content appearing
							on it is subject to your compliance with these Terms. Modification or use of the Material or any other
							content on the Service for any purpose not permitted by these Terms may be a violation of our intellectual
							property rights as protected by law and these Terms and is prohibited.
						</p>
						<p className="mb-3 text-larger">
							You may copy, access, download, and display Materials and all other content displayed on the Service for
							non-commercial, personal, or entertainment use on a single computer only. The Materials and all other
							content on the Service may not otherwise be copied, reproduced, republished, uploaded, displayed, posted,
							transmitted, distributed, licensed, or used in any way unless specifically authorized by Dproz. Any
							authorization to copy or download Material granted by Dproz in any part of the Service for any reason is
							restricted to making a single copy for non-commercial, personal, entertainment use on a single computer
							only, and is subject to your keeping intact all copyright and other proprietary notices. Using any
							Material on any other website or networked computer environment is prohibited. Also, decompiling, reverse
							engineering, disassembling, or otherwise reducing the code used in any software on the Service into a
							readable form in order to examine the construction of such software and/or to copy or create other
							products based (in whole or in part) on such software, is prohibited (please refer to our Acceptable Use
							Policy).
						</p>
						<strong>Notice And Procedure for Claims of Copyright Infringement:</strong>
						<p className="mb-3 text-larger">
							We respect the intellectual property rights of others and encourage you to do the same. Accordingly, we
							have a policy of removing content that violates the intellectual property rights of others, suspending
							access to the Service (or any portion thereof) to any user who uses the Service in violation of someone
							else&#39;s intellectual property rights, and/or terminate in appropriate circumstances the Account of any
							user who uses the Service in violation of someone else&#39;s intellectual property rights.
						</p>
						<p className="mb-3 text-larger">
							You can submit a notification for removing infringement content through the Dproz Contact Us page.
						</p>
						<strong>Dproz “Dos And Don&#39;ts”:</strong>
						<p className="mb-3 text-larger">
							This section provides the acceptable use policy (“AUP”) which defines acceptable practices relating to the
							use of the Service, including limitations on User Content, system abuse, and security. The Service must be
							used in a manner that is consistent with the intended purpose of the Service and the terms of the
							applicable agreement with the Provider, including our Terms. By using the Service, you consent to be bound
							by the terms of this AUP. If you do not agree with anything in this section, you must discontinue the use
							of the Service. For purposes of this section, “Provider” includes all of Provider&#39;s affiliates,
							including direct and indirect subsidiaries.
						</p>
						<ol>
							<li>
								Summary of Generally Prohibited Conduct: You will not use the Service to transmit, distribute or store
								material in a manner that: (i) violates any applicable law or regulation; (ii) may adversely affect the
								Service or other Users; (iii) may expose Provider to criminal or civil liability, or (iv) violate,
								infringe upon or otherwise misappropriate any third-party rights, including intellectual property
								rights, rights of publicity and privacy rights. You are prohibited from facilitating the violation of
								any part of this section or applicable third-party policies, including, but not limited to transmitting,
								distributing, or otherwise making available any product or service that violates this section or another
								provider&#39;s policy.
							</li>
							<li>
								Responsible Use of the Service: Please act responsibly when using the Service. You may only use the
								Service and its contents for lawful purposes and in accordance with applicable law and you are
								prohibited from storing, distributing, or transmitting any unlawful material through the Service. You
								may not collect or store personal information from other users. You recognize that storing,
								distributing, or transmitting unlawful material could expose you to criminal and/or civil liability. You
								agree that if a third party claims that material you have contributed to the Service is unlawful, you
								will bear the burden of establishing that it is lawful. You understand and agree that all materials
								publicly posted or privately transmitted on or through the Service are the sole responsibility of the
								sender, not the Provider, and that you are responsible for all material you upload, publicly post, or
								otherwise transmit to or through the Service.
							</li>
							<li>
								Content Limitations: We require that you do not post resumes, cover letters, send e-mails or submit to
								or publish through forums available on the Service, or otherwise make available on the Service any
								content, or act in a way, which in our opinion:
								<ul>
									<li>
										Libels, defames, invades privacy, stalks, is obscene, pornographic, racist, abusive, harassing,
										threatening, or offensive;
									</li>
									<li>
										Disparages, criticizes, belittles, parodies, or otherwise portrays in a negative light any actor
										appearing in the content;
									</li>
									<li>
										Seeks to exploit or harm children by exposing them to inappropriate content, asking for personally
										identifiable details or otherwise;
									</li>
									<li>
										Infringes any intellectual property or another right of any entity or person, including violating
										anyone&#39;s copyrights or trademarks or their rights of publicity;
									</li>
									<li>Violates any law or may be considered to violate any law;</li>
									<li>
										You do not have the right to transmit under any contractual or other relationship (e.g., inside
										information, proprietary or confidential information received in the context of an employment or a
										non-disclosure agreement);
									</li>
									<li>Advocates or promotes illegal activity;</li>
									<li>
										Impersonates or misrepresents your connection to any other entity or person or otherwise manipulates
										headers or identifiers to disguise the origin of the content;
									</li>
									<li>Solicits funds, advertisers, or sponsors;</li>
									<li>
										Includes programs that contain viruses, worms, and/or “Trojan horses” or any other computer code,
										files, or programs designed to interrupt, overload, collapse, destroy or limit the functionality of
										any computer software or hardware, or telecommunications;
									</li>
									<li>
										Disrupts the normal flow of dialogue, causes a screen to scroll faster than other users are able to
										type, or otherwise act in a way that affects the ability of other people to engage in real-time
										activities via the Service;
									</li>
									<li>Copies any other pages or images on the Service except with appropriate authority;</li>
									<li>Includes illegally sourced MP3 format files;</li>
									<li>Amounts to a “pyramid” or similar scheme;</li>
									<li>
										Amounts to “data warehousing” (i.e., using any web space made available to you as storage for large
										files or large amounts of data which are only linked from other sites);
									</li>
									<li>
										Disobeys any policy or regulations established from time to time regarding use of the Service or any
										networks connected to the Service; or
									</li>
									<li>
										Contains links to other sites that contain the kind of content that falls within the descriptions
										described above.
									</li>
									<li>
										In addition, you are prohibited from removing any sponsorship banners or other material inserted by
										Provider anywhere on the Service (e.g., on any web space made available for your use).
									</li>
								</ul>
							</li>
							<p className="mb-3 text-larger">
								PLEASE BE AWARE THAT WE COOPERATE WITH LAW ENFORCEMENT AND REPORT SUSPICIOUS ACTIVITY.
							</p>
							<li>
								System Abuse: Without limitation, you agree not to:
								<ul>
									<li>
										Send, create or reply to so-called “mailbombs” (i.e., emailing copies of a single message to many
										users, or sending large or multiple files or messages to a single user with malicious intent) or
										engage in “spamming” (i.e., unsolicited emailing for business or other purposes) or undertake any
										other activity which may adversely affect the operation or enjoyment of this Service by any other
										person;
									</li>
									<li>
										Copy, display, distribute, duplicate, aggregate, redistribute, alter or modify, any of the content
										available within the Service, or User Content in any medium, or to any other individual or entity,
										other than as may be reasonably necessary to use the Service for their intended purpose;
									</li>
									<li>
										Use any automated software or devices, such as spiders, robots, or data mining techniques such as
										scraping, spidering, crawling, or any other techniques to download, store, distribute or otherwise
										reproduce content within the Service and/or the Service itself;
									</li>
									<li>
										Use or copy the Service including any data you view on and/or obtain from the Service to provide any
										product or service that is competitive to the Service determined in Provider&#39;s sole discretion;
									</li>
									<li>
										Interfere with, interrupt, destroy or limit the functionality of the Service or any computer
										software or hardware, or telecommunications equipment;
									</li>
									<li>
										Use the Service in any manner that could damage, disable, overburden, or impair any Provider&#39;s
										server, or networks connected to any Provider&#39;s server, or interfere with any other party&#39;s
										use and enjoyment of the Service;
									</li>
									<li>
										Gain unauthorized access to the Service, other accounts, computer systems, or networks connected to
										any Provider&#39;s server or to the Service, through hacking, cracking, and distribution of
										counterfeit software, password mining, or any other means;
									</li>
									<li>
										Reverse engineer, decompile or disassemble any software accessed through the Service, including any
										proprietary communications protocol used by Provider;
									</li>
									<li>
										Use information obtained from the Service to transmit any commercial, advertising, or promotional
										materials without Provider&#39;s written permission and except as expressly authorized by Provider,
										advertise or offer to sell or buy any goods or services for any purpose;
									</li>
									<li>
										Provide any contact information which is not current and accurate, impersonate or create a false
										identity or falsify any information;
									</li>
									<li>
										Use, download or otherwise copy, or provide (whether or not for a fee) to a person or entity any
										directory of users of the Service, or other user or usage information or any portion thereof;
									</li>
									<li>
										Exploit, distribute or publicly communicate any error, miscue or bug which gives an unintended
										advantage; or
									</li>
									<li>
										Reproduce, sell, resell or otherwise exploit any resource, or access to any resource, contained on
										this Service.
									</li>
								</ul>
							</li>
							<li>
								Violation of Security Systems: You are prohibited from using any services or facilities provided in
								connection with the Service to compromise its security or tamper with system resources and/or accounts.
								The use or distribution of tools designed for compromising security (e.g. password guessing programs,
								cracking tools, or network probing tools) is strictly prohibited. If you become involved in any
								violation of system security, the Provider reserves the right to release your details to system
								administrators at other sites or to law enforcement in order to assist them in resolving security
								incidents.
							</li>
							<li>
								Responsibility for Content: The provider takes no responsibility for any material created or accessible
								on or through the Service and will not exercise any editorial control over such material. The provider
								is not obligated to monitor such material but reserves the right to do so.
							</li>
						</ol>
						<p className="mb-3 text-larger">
							You acknowledge that Provider has no obligation to pre-screen User Content, although Provider reserves the
							right in its sole discretion to pre-screen, refuse or remove any User Content. By entering into these
							Terms, you hereby provide your irrevocable consent to such monitoring. You acknowledge and agree that you
							have no expectation of privacy concerning the transmission of your User Content, including without
							limitation videos, comments, and chat, text, or voice communications that may be facilitated through the
							Service. In the event that Provider pre-screens, refuses, or removes any User Content, you acknowledge
							that Provider will do so for Provider&#39;s benefit, not yours. The provider may provide you with tools to
							flag User Content, however, Provider makes no promises or representations regarding the removal of flagged
							user content.
						</p>
						<p className="mb-3 text-larger">
							<strong>Additional Services:</strong>
						</p>
						<p className="mb-3 text-larger">
							Job Alerts: Depending on your location or jurisdiction, Dproz may provide job alerts or may use third
							parties and automated search engines to provide job alerts, matching employment opportunities found on
							third-party job searching sites with your resume or career profile, job search requests submitted by you,
							and/or other usage data relating to your use of the Service. Dproz tries to match the information
							available to ensure employment opportunities presented to you in job alerts and match the type of career
							opportunities desired by you but Dproz makes no guarantee that you will receive career opportunities or
							that the job alert leads you to desire.
						</p>

						<p className="mb-3 text-larger">
							Resume Review: Dproz may offer resume review services as part of its subscription service. Only
							full-access paid, active subscribers are eligible to receive the resume review service, if available. This
							service may be provided through the combination of automated computer software systems using certain
							predetermined fields and non-automated resume reviewer input. Resumes of full-access paid, active
							subscribers must meet a minimum document completion threshold in order to receive a resume review. Only
							one resume review is included with a full-access, paid subscription.
						</p>

						<p className="mb-3 text-larger">
							Job Posting & Resume Submission: Dproz may also offer job posting and resume submission features as part
							of the Service in certain locations or jurisdictions. These features may be used only by individuals
							seeking employment and/or career information. In the same vein, certain Dproz services may only be used by
							employers seeking employees.
						</p>

						<p className="mb-3 text-larger">
							Notifications through email and text: Additional notifications may be added to keep you up to date and
							notify you by text or email each time your resume is parsed or if you have opted in to receive
							notifications of any of the features available on our Site. These Terms and Conditions will apply as well
							to those services, if and when they are available. Notifications through email and text will vary
							depending on your mobile device, wireless connection, coverage, internet speed, and any other event that
							could interfere with the notification process. Standard message and data rates may apply. You understand
							that you are not required to provide text message notification consent as a condition of purchasing or
							using any of Resume Now&#39;s services. Please visit our Privacy Policy to get more details about how we
							manage your information. You may opt out of receiving text notifications at any moment by replying STOP.
						</p>

						<p className="mb-3 text-larger">Other Career Enhancing Services</p>
						<p className="mb-3 text-larger">
							As part of Provider services, you will be part of our career-enhancing and professional development
							offerings that will provide you with a wider set of options to help you broaden your career. We want to
							help you get your resume out there and enhance your career in any possible way. Therefore, Provider may
							show or send you information about opportunities available. By using our services, you also agree for the
							Provider to share your information with third parties that we work with and that helps us in the process
							of building career services. Sometimes, the sharing of information with third parties may involve monetary
							compensation. You may manage your privacy settings at any time by login into your Account page, and
							clicking on the Settings section, or by contacting our friendly Customer Service team.
						</p>
						<p className="mb-3 text-larger">
							As part of our services, our writers have prepared articles to help you in your career journey. Our
							professional writers will always seek to provide the most accurate information. However, their advice is
							not meant to constitute legal advice.
						</p>

						<p className="mb-3 text-larger">Payments and Subscriptions:</p>
						<p className="mb-3 text-larger">
							If you purchase any services that Provider offers for a fee, either on a one-time or on a subscription
							basis, you agree that Provider&#39;s third-party vendors may store your payment information. You also
							allow the Provider, through such third-party vendors, to use your payment method on file to process your
							subscription recurring charges and to process un-scheduled purchase transactions made by you through the
							Site. You also agree to pay the applicable fees for the services you order (including, without limitation,
							periodic fees for monthly or annual subscriptions, or any additional administrative charge that may be
							applied) as they become due plus all related taxes, and to reimburse us for all collection costs and
							interest for any overdue amounts.
						</p>
						<p className="mb-3 text-larger">
							Once your subscription is active, it will automatically renew as described on the offer page until you
							terminate the contract. A subscription fee is charged in advance, at the end of each billing period. Any
							recurring charge will be fixed at the price agreed to at the time of purchase. The specific billing terms
							for the services you order will be described on the offer page on the Site. The payment will be collected
							from the payment method chosen by you and provided to your account. Payments are collected on the basis of
							the data provided by the User regarding the payment method used for this purpose (e.g. credit card). Note
							that when you receive an updated credit card, as part of their service, your credit card provider may
							notify us of the update and give us the updated card details. This service is not controlled by Provider
							and your credit card merchant is solely responsible.
						</p>
						<p className="mb-3 text-larger">
							When you sign up you agree to automatic or recurring billing. You will be again notified of the billing
							terms and how to stop recurring billing on your transaction receipt. By signing up for Provider services
							with recurring billing, you will be deemed to agree to those billing terms as described specifically on
							the Site. In the event that your payment method does not successfully process you authorize us to complete
							a partial payment of the available balance on your card. The remaining balance will be charged on a
							separate transaction either to the same card or any other card on file.
						</p>
						<p className="mb-3 text-larger">
							For un-scheduled purchases, such as individual resume downloads, prints, or emails, when such purchases
							are available, you agree that the Provider will charge your payment method on file for each of such
							un-scheduled purchases, individually, at the time of purchase. Such individual transactions are final and
							therefore, if you want a new modified version of your downloaded, printed, or emailed resume, it will
							require a separate purchase.
						</p>
						<p className="mb-3 text-larger">
							The provider may offer certain premium services such as Resume Review, Resume Writing, Premium Career
							reports, and Cover Letter Writing, which may not be included in the cost of the Provider&#39;s basic
							subscription services. Failure to pay for these premium services, if purchased, may result in the
							termination of your subscription. Also, trial offers may give you access to certain limited services and
							include one download, print, or email only, with additional downloads, prints, or emails charged
							separately, during the trial period. Please read your trial offer carefully.
						</p>
						<p className="mb-3 text-larger">
							Depending on where you transact with us, the type of payment method used, and where your payment method
							was issued, your transaction may be subject to foreign exchange fees or differences in prices, because of
							exchange rates. Your transaction may also be subject to foreign currency exchange fees assessed by your
							bank or card issuer. The provider is not responsible for any foreign currency exchange fees or surcharges
							and will not make any compensation or reimbursement for charges imposed by your bank or card issuer. The
							provider does not support all payment methods, currencies, or locations for payment. If the payment method
							you use with us, such as a credit card, reaches its expiration date and you do not edit your payment
							method information or cancel your Account or the applicable service, you authorize us to continue billing
							that payment method and you remain responsible for any uncollected amounts. Your obligation to pay fees
							continues through the end of the subscription period until you cancel your subscription. All applicable
							taxes are calculated based on the billing information you provide us at the time of purchase.
						</p>
						<p className="mb-3 text-larger">
							The provider has the discretion to alter prices for services at any time, subject to the notification
							terms of Modifications.
						</p>
						<p className="mb-3 text-larger">
							As part of the Site&#39;s offering, you may have the option to pause your subscription for one month. This
							will temporarily suspend your subscription, and you will not be charged for your next scheduled billing.
							Once a pause subscription request is received, it will be applied from your next recurring bill date and
							will be in place for one month or until you resume your subscription. During a paused subscription period,
							you will have limited access to your account (i.e., you will not have access to your documents) but will
							always have the option to resume your subscription and access all of your documents as you normally do. If
							you resume your subscription during the pause period, you will set a new recurring billing date. After the
							pause period has ended, you will be automatically charged your monthly subscription fee. Please refer to
							the confirmation email for the exact dates and contact our Customer Service team for any questions.
						</p>

						<p className="mb-3 text-larger">Cancellations:</p>
						<p className="mb-3 text-larger">
							You may cancel your subscription anytime by contacting our customer service department or by contacting
							Dproz through Contact Us page or info@dproz.com. The provider will send an email with a cancellation
							number to confirm any cancellation request. If you do not receive this confirmation and still wish to
							cancel, please contact our customer service department directly for further assistance. The provider may
							issue a refund if notice of cancellation of your subscription is provided before the end of the initial
							period if the initial period is offered.
						</p>
						<p className="mb-3 text-larger">
							We do our best to describe every product or service offered on the Service as accurately as possible.
							However, we are human, and therefore we do not warrant that product specifications, pricing, or other
							content on the online services is complete, accurate, reliable, current, or error-free. In the event of
							any errors relating to the pricing or specifications, the Provider shall have the right to refuse or
							cancel any orders at its sole discretion. Additional terms may apply.
						</p>

						<p className="mb-3 text-larger">Our Intellectual Property, Trademarks, and Copyrights:</p>
						<p className="mb-3 text-larger">
							Except with respect to your User Content and other Registered Users&#39; Content, you agree that, as
							between you and us, Provider (and our affiliated companies and suppliers) own all rights, title, and
							interest in the Service and all tools, and all related intellectual property rights. The Service as a
							whole is copyrighted as a collective work, and individual works or content appearing on or accessible
							through the Site owned by or licensed to the Provider or its content providers are likewise subject to
							copyright protection domestically and internationally. Likewise, all data files, program files, software,
							code, proprietary methods, systems, and other materials that are made available to download from or used
							to provide the Service (“Materials”) may not be copied, modified, reproduced, republished, posted,
							transmitted, sold, offered for sale, or redistributed in any way without our prior written permission and
							the prior written permission of our applicable licensors. The Materials are protected by copyrights,
							patents, trade secrets, or other proprietary rights.
						</p>
						<p className="mb-3 text-larger">
							You must abide by all copyright notices, information, or restrictions contained in or attached to any of
							our Materials. Nothing in this Agreement grants you any right to receive delivery of a copy of our
							Materials or to obtain access to our Materials except as generally and ordinarily permitted through the
							Service according to these Terms. Certain names, logos, distinctive features, source identifiers, and
							other materials displayed on the Site or in the Services, including their “look and feel”, constitute
							trademarks, tradenames, service marks, trade dress, or logos (“Trademarks”) of us or other entities. All
							Trademarks not owned by Provider that appear on this site are the property of their respective owners. You
							are not authorized to use any such Trademarks. Ownership of all such Trademarks and the goodwill
							associated therewith remains with us or those other entities. Unless you first obtain the copyright
							owner&#39;s prior written consent, you may not copy, distribute, publicly perform, publicly display,
							digitally perform (in the case of sound recordings), or create derivative works from any copyrighted work
							made available or accessible via the Service. You will not remove, alter or obscure any copyright,
							trademark, service mark, or other proprietary rights notices incorporated in or accompanying the Service.
						</p>
						<p className="mb-3 text-larger">
							The Site, and all intellectual property thereunder, are owned by Bold Limited.
						</p>

						<p className="mb-3 text-larger">Your Use of Materials:</p>
						<p className="mb-3 text-larger">
							Your right to make use of the Service and any Material or other content appearing on it is subject to your
							compliance with these Terms. Modification or use of the Material or any other content on the Service for
							any purpose not permitted by these Terms may be a violation of our intellectual property rights (see
							Section 11 above) as protected by law and these Terms and is prohibited.
						</p>
						<p className="mb-3 text-larger">
							You may copy, access, download, and display Materials and all other content displayed on the Service for
							non-commercial, personal, or entertainment use on a single computer only. The Materials and all other
							content on the Service may not otherwise be copied, reproduced, republished, uploaded, displayed, posted,
							transmitted, distributed, licensed or used in any way unless specifically authorized by Provider. Any
							authorization to copy or download Material granted by Provider in any part of the Service for any reason
							is restricted to making a single copy for non-commercial, personal, entertainment use on a single computer
							only, and is subject to your keeping intact all copyright and other proprietary notices. Using any
							Material on any other website or networked computer environment is prohibited. Also, decompiling, reverse
							engineering, disassembling, or otherwise reducing the code used in any software on the Service into a
							readable form in order to examine the construction of such software and/or to copy or create other
							products based (in whole or in part) on such software, is prohibited (please refer to our Acceptable Use
							Policy for more information).
						</p>

						<p className="mb-3 text-larger">Invited Submissions:</p>
						<p className="mb-3 text-larger">
							From time to time, areas on the Site may expressly request submissions of ideas or improvements through
							promotions, surveys, or otherwise (“Invited Submissions”&#39;). Sometimes providers may incentivize users
							to participate in Invited Submissions. Where this is the case, please carefully read any Additional Terms
							that govern those submissions, as they will affect your legal rights. If no Additional Terms govern those
							submissions, then these Terms will apply in full to any Invited Submissions you make. IN ANY EVENT, ANY
							MATERIAL YOU SEND TO US WILL NOT BE TREATED AS CONFIDENTIAL. Regardless of any industry custom or
							practice, we will not pay you for the use of any content or submissions that you submit to the Site. The
							provider may use such content and submissions as user testimonials.
						</p>

						<p className="mb-3 text-larger">Disclaimer Regarding Testimonials:</p>
						<p className="mb-3 text-larger">
							The Service may contain testimonials and opinions (“Testimonials”) by users of our services. These
							testimonials reflect the real-life experiences and opinions of such users. However, the experiences are
							personal to those particular users, and may not necessarily be representative of all users of our
							services. We do not claim, and you should not assume, that all users will have the same experiences. YOUR
							INDIVIDUAL RESULTS MAY VARY.
						</p>
						<p className="mb-3 text-larger">
							The testimonials on the Service are submitted in various forms such as text, audio, and/or video, and are
							reviewed by us before being posted. They appear on the Service as given by the users, except for the
							correction of grammar or typing errors. Some testimonials may have been shortened for the sake of brevity
							where the full testimonial contained extraneous information not relevant to the general public. Photos
							added next to testimonials may not always represent the person giving the testimonial. The views and
							opinions contained in the testimonials belong solely to the individual user and do not reflect our views
							and opinions. We are not affiliated with users who provide testimonials, and users are not paid in cash or
							compensated for their testimonials unless the testimonial is part of an incentivized Invited Submission.
						</p>
						<p className="mb-3 text-larger">Disclaimer, Limit of Liability, Indemnity & Investigations:</p>
						<p className="mb-3 text-larger">User Interactions and Release:</p>
						<p className="mb-3 text-larger">
							You are solely responsible for your interactions with other users of the Service and any other parties
							with whom you interact through the Service. If you have a dispute with one or more users (including
							merchants), you hereby release the Provider Group from any claims, demands, liabilities, costs or expenses
							and damages (actual and consequential of every kind and nature, known and unknown, arising out of or in
							any way connected with such disputes. In entering into this release, you expressly waive any protections
							(whether statutory or otherwise) to the extent permitted by applicable law, that would otherwise limit the
							coverage of this release to include only those claims that you may know or suspect to exist in your favor
							at the time of agreeing to this release.
						</p>
						<p className="mb-3 text-larger">Disclaimer of Warranties:</p>
						<p className="mb-3 text-larger">
							YOUR USE OF THE SERVICE IS AT YOUR OWN RISK. THE SERVICE AND ALL THE MATERIALS, INFORMATION, SOFTWARE, AND
							OTHER CONTENT IN THE SERVICE ARE PROVIDED “AS IS” AND “AS AVAILABLE” WITHOUT WARRANTIES OR CONDITIONS OF
							ANY KIND, AND TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, PROVIDER AND ANY SUBSIDIARIES
							OR AFFILIATED COMPANIES OF THE PROVIDER (“PROVIDER GROUP”) DISCLAIM ALL WARRANTIES AND CONDITIONS, EXPRESS
							IMPLIED OR COLLATERAL, RELATED TO THE SERVICE, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OR
							CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. THE PROVIDER GROUP
							DOES NOT WARRANT THAT THE FUNCTIONS CONTAINED IN THE SERVICE WILL BE AVAILABLE, UNINTERRUPTED OR
							ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE SERVICE OR THE SERVERS THAT MAKE THE SERVICE
							AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. THE PROVIDER GROUP DOES NOT WARRANT OR MAKE ANY
							REPRESENTATIONS OR CONDITIONS REGARDING THE USE OR THE RESULTS OF THE USE OF THE MATERIAL, INFORMATION,
							SOFTWARE, FACILITIES, SERVICES OR OTHER CONTENT ON THE SERVICE OR ANY WEB SERVICES LINKED TO THE SERVICE
							IN TERMS OF THEIR CORRECTNESS, ACCURACY, RELIABILITY, OR OTHERWISE. THE PROVIDER GROUP MAKES NO WARRANTIES
							OR CONDITIONS THAT YOUR USE OF SERVICE, OR THE MATERIALS, INFORMATION, SOFTWARE, FACILITIES, OR OTHER
							CONTENT IN THE SERVICE OR ANY WEB SERVICE WILL NOT INFRINGE THE RIGHTS OF OTHERS AND THE PROVIDER GROUP
							ASSUMES NO LIABILITY OR RESPONSIBILITY FOR ERRORS OR OMISSIONS IN SUCH MATERIALS, INFORMATION, SOFTWARE,
							FACILITIES, OR OTHER CONTENT OF THE SERVICE OR ANY OTHER WEB SERVICE.
						</p>
						<p className="mb-3 text-larger">
							IF APPLICABLE LAW DOES NOT ALLOW THE EXCLUSION OF SOME OR ALL OF THE ABOVE IMPLIED WARRANTIES AND
							CONDITIONS, THE ABOVE EXCLUSIONS WILL APPLY TO YOU ONLY TO THE EXTENT PERMITTED BY APPLICABLE LAW.
						</p>
						<p className="mb-3 text-larger">
							IF YOU ARE ACCESSING THE SERVICE FROM THE EUROPEAN UNION OR OTHER JURISDICTIONS THAT DO NOT RECOGNIZE
							DISCLAIMERS OF CERTAIN WARRANTIES, NOTHING IN THIS AGREEMENT SHALL EXCLUDE OR LIMIT LIABILITY FOR DEATH OR
							PERSONAL INJURY RESULTING FROM THE NEGLIGENCE OF EITHER PARTY OR THEIR SERVANTS, AGENTS, OR EMPLOYEES.
						</p>

						<p className="mb-3 text-larger">Limitation of Liability:</p>
						<p className="mb-3 text-larger">
							THE PROVIDER GROUP DISCLAIMS ALL LIABILITY WHETHER BASED ON CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT
							LIABILITY, OR OTHERWISE, AND DOES NOT ACCEPT ANY LIABILITY FOR ANY LOSS OR DAMAGE (DIRECT, INDIRECT,
							PUNITIVE, ACTUAL, CONSEQUENTIAL, INCIDENTAL, SPECIAL, EXEMPLARY OR OTHERWISE) RESULTING FROM ANY USE OR
							MISUSE OF, OR INABILITY TO USE, THE SITE OR ANY OTHER WEB SITE, OR THE MATERIAL, INFORMATION, SOFTWARE,
							FACILITIES, SERVICES OR OTHER CONTENT ON THE SITE OR ANY OTHER WEB SITE, REGARDLESS OF THE BASIS UPON
							WHICH LIABILITY IS CLAIMED AND EVEN IF ANY MEMBER OF THE PROVIDER GROUP HAS BEEN ADVISED OF THE
							POSSIBILITY OF SUCH LOSS OR DAMAGE. WITHOUT LIMITATION, YOU (AND NOT ANY MEMBER OF THE PROVIDER GROUP)
							ASSUME THE ENTIRE COST OF ALL NECESSARY SERVICING, REPAIR OR CORRECTION IN THE EVENT OF ANY SUCH LOSS OR
							DAMAGE ARISING.
						</p>
						<p className="mb-3 text-larger">
							IF APPLICABLE LAW DOES NOT ALLOW ALL OR ANY PART OF THE ABOVE LIMITATION OF LIABILITY TO APPLY TO YOU, THE
							LIMITATIONS WILL APPLY TO YOU ONLY TO THE EXTENT PERMITTED BY APPLICABLE LAW.
						</p>

						<p className="mb-3 text-larger">Indemnity:</p>
						<p className="mb-3 text-larger">
							To the extent permitted by applicable law in your jurisdiction, you agree to indemnify and hold harmless
							Provider and its officers, directors, employees, agents, distributors, and affiliates from and against any
							and all claims, demands, liabilities, costs or expenses, including reasonable legal fees, resulting from
							your breach of these Terms, including any of the foregoing provisions, representations, warranties or
							conditions, and/or from your placement or transmission of any content onto Provider&#39;s servers, and/or
							from any and all use of your Account.
						</p>

						<p className="mb-3 text-larger">Investigations:</p>
						<p className="mb-3 text-larger">
							Provider reserves the right to investigate suspected violations of these Terms, including without
							limitation any violation arising from any submission, posting, or e-mails you make or send to any Forum.
							The provider may seek to gather information from the user who is suspected of violating these Terms, and
							from any other user. The provider may suspend any users whose conduct or postings are under investigation
							and may remove such material from its servers as it deems appropriate and without notice. If Provider
							believes, in its sole discretion, that a violation of these Terms has occurred, it may take any corrective
							action it deems appropriate, as permitted by applicable law. The provider will fully cooperate with any
							law enforcement authorities or court order requesting or directing the Provider to disclose the identity
							of anyone posting any e-mails, or publishing or otherwise making available any materials that are believed
							to violate these Terms. BY ACCEPTING THIS AGREEMENT YOU WAIVE AND HOLD HARMLESS ALL MEMBERS OF THE
							PROVIDER GROUP FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY ANY MEMBER OF THE PROVIDER GROUP DURING
							OR AS A RESULT OF ITS INVESTIGATIONS AND/OR FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY
							EITHER A MEMBER OF THE PROVIDER GROUP OR LAW ENFORCEMENT AUTHORITIES.
						</p>

						<p className="mb-3 text-larger">Third-Party Sites:</p>
						<p className="mb-3 text-larger">
							The Service may link you to other sites on the Internet that may not be affiliated with Provider. These
							sites may contain information about job opportunities, employers, job candidates, or material that some
							people may find inappropriate or offensive. These other sites are not under the control of Provider, and
							you acknowledge that (whether or not such sites are affiliated in any way with Provider), Provider is not
							responsible for the accuracy of job postings, employer descriptions, candidate resumes, legality, decency,
							or any other aspect of the content of such sites. The inclusion of such a link does not imply endorsement
							of any site by the Provider or any association with its operators. If you elect to use such third-party
							sites, and/or if you elect to ‘click&#39; on a link or button, you understand that (a) you will be leaving
							our Site and (b) your use of any such third-party sites will be subject to any terms and conditions and
							privacy policies required by the applicable third-party provider(s). The provider does not make any
							representations or warranties as to the security of any information (including, without limitation, credit
							card and other personal information) you might be requested to give any third party, and you irrevocably
							waive any claim against us with respect to such sites. We strongly encourage you to use caution before
							providing any sensitive information (e.g., social security numbers or financial information) to any third
							party and to make whatever investigation you feel necessary or appropriate before proceeding with any
							communications with any of these third parties.
						</p>
					</div>
				</div>
			</div>

			<Footer />
		</>
	);
}

export default TermsAndConditions;
