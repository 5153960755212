import React from "react";
import { toast } from "sonner";

const SkillsEditorListItems = (props) => {
	const editorListHandler = (event) => {
		//get the text content of the selected li
		let selectedItem = event.target.textContent;
		let selectedItemID = event.target.id;

		/** if the selected item is button inside the li
		 * then get the text content of the respective li
		 */
		if (event.target.tagName === "I" || event.target.tagName === "BUTTON") {
			selectedItem = event.target.closest("li").firstChild.textContent;
			selectedItemID = event.currentTarget.closest("li").id;
		}

		//remove the button text from the li text content
		const liContent = selectedItem.trim();
		let newEditorContent = "";

		//check if the editor content has an existing list
		const hasUl = props.editorContent.indexOf("<ul>");

		//if it has an existing list already
		if (hasUl !== -1) {
			//if the clicked item is present in the list
			if (props.editorContent.indexOf(liContent) !== -1) {
				//remove the item from the editor&#39;s list
				newEditorContent = props.editorContent.replace(`<li>${liContent}</li>`, "");
			} else {
				//add the item to the editors list
				newEditorContent = props.editorContent
					.substring(0, props.editorContent.indexOf("</ul>"))
					.concat("<li>", liContent, "</li></ul>");
			}
		} else {
			//create a new list and add a li
			newEditorContent = props.editorContent.concat("<ul><li>", liContent, "</li></ul>");
		}

		// //copy the editor list items
		const changedList = [...props.editorListItems];

		// //find the item that was clicked in the editors list items
		const indexChanged = changedList.findIndex((changed) => Number(changed.id) === Number(selectedItemID));

		// //check if skill already added
		const splitArray = changedList[indexChanged].title.split(/:(.+)/);
		let wordBeforeColon = splitArray[0].trim();
		const skillExistIndex = props.groupedSkills.findIndex((item) => item.title.trim() === wordBeforeColon);
		if (skillExistIndex !== -1) {
			let newGroupedSkills = [...props.groupedSkills];
			newGroupedSkills[skillExistIndex].collapsed = false;
			props.setGroupedSkills(newGroupedSkills);
			toast.error(wordBeforeColon + " exist to add more item under it click Add custom button", {
				duration: 12000,
			});
			return;
		}

		//changed the added property of the clicked editors item to add and remove the css class
		changedList[indexChanged].added = !changedList[indexChanged].added;

		props.setEditorContent(newEditorContent);
		let groupedSkills = [];
		if (splitArray.length > 1) {
			groupedSkills = groupedSkills.concat(
				splitArray[1]
					.replace(/\([^)]*\)/g, "")
					.split(",")
					.map((skillItem) => {
						let value = skillItem.replace("[", "").replace("]", "").replace("{", "").replace("}", "").trim();
						return { added: false, skill: value };
					}),
			);
		}
		const addedSkill = { title: wordBeforeColon, skills: groupedSkills, collapsed: false };
		let newGroupedSkills = props.groupedSkills;
		if (changedList[indexChanged].added) {
			newGroupedSkills = [...newGroupedSkills, addedSkill];
		} else {
			newGroupedSkills = newGroupedSkills.filter((skillItem) => skillItem.title !== wordBeforeColon);
		}
		props.setGroupedSkills(newGroupedSkills);
	};

	const theList = props.editorListItems.map((editorListItem) => {
		return (
			<li
				onClick={editorListHandler}
				key={editorListItem.id}
				id={editorListItem.id}
				className="d-flex justify-content-between align-items-start py-3 border-bottom"
			>
				{editorListItem.title}
				<button
					id={editorListItem.id}
					className="add-skill-button ml-3"
					style={{ background: "#d6e7ff", ...(editorListItem.added && { background: "#28B870" }) }}
				>
					{!editorListItem.added && <i className="fa fa-plus" />}
					{editorListItem.added && <i className="fa fa-check text-white" />}
				</button>
			</li>
		);
	});
	return <ul className="list-unstyled px-3">{theList}</ul>;
};

export default SkillsEditorListItems;
