import { Loader } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";

import { API } from "../../../services/apiService";

const setCookie = (name, value, expires) => {
	document.cookie = `${name}=${value}; ${expires}; path=/`;
};

const LinkedInCallback = () => {
	const [isLoading, setIsLoading] = useState(true);

	const queryParams = new URLSearchParams(window.location.search);
	const code = queryParams.get("code");

	const navigate = useNavigate();

	useEffect(() => {
		const fetchLinkedInData = async () => {
			if (code) {
				try {
					const { data } = await API.post("auth/linkedin", {
						linkedInAuthCode: code,
					});

					if (data.access_token) {
						setCookie("Authorization", data.access_token, new Date(Date.now() + data.expires_in * 1000));

						localStorage.setItem("isLoggingIn", true);
						API.defaults.headers.common["Authorization"] = `${data.access_token}`;
						window.location.pathname = "/profile";
					} else {
						toast.error("LinkedIn login failed");
						navigate("/signin");
					}
				} catch (error) {
					toast.error("LinkedIn login failed");
					navigate("/signin");
				} finally {
					setIsLoading(false);
				}
			}
		};

		fetchLinkedInData();
	}, [code]);

	return <div className="hidden">{isLoading ? <Loader /> : null}</div>;
};

export default LinkedInCallback;
