import React from "react";

import { Button } from "../../JobsPage/components/Button";

const LinkedInLogin = () => {
	const CLIENT_ID = "86x5lo08wq4hgz"; //86x5lo08wq4hgz
	const REDIRECT_URI = "https://www.dproz.com"; // Backend endpoint

	const handleLogin = () => {
		const state = Math.random().toString(36).substring(7); // Random state for security
		const scope = "openid profile email"; // Request basic profile + email

		const linkedInAuthURL = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${encodeURIComponent(
			REDIRECT_URI,
		)}&scope=${encodeURIComponent(scope)}&state=${state}`;

		const a = document.createElement("a");
		a.href = linkedInAuthURL;
		a.target = "_blank"; // Open in the same tab
		a.click();
	};

	return (
		<Button variant="outline" className="gap-3 bg-[#0077B5] text-white" onClick={handleLogin}>
			<i className="fa-brands fa-linkedin text-white text-xl" /> Continue with LinkedIn
		</Button>
	);
};

export default LinkedInLogin;
