import dayjs from "dayjs";
import React, { createRef, useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "sonner";

import Icon from "../../components/Icon";
import { cn } from "../../handlers/cn.utils";
import { getBrowserName, getOperatingSystem } from "../../handlers/utilityFunctions";

const packages = {
	starter: 1950,
	career: 5850,
	executive: 51500,
};

export function PricingDetailsModal({ setShowDetailsModal }) {
	const modalDetailsRef = createRef();

	useEffect(() => {
		function handleClickOutside(event) {
			if (modalDetailsRef.current && !modalDetailsRef.current.contains(event.target)) {
				setShowDetailsModal(false);
			}
		}

		document.addEventListener("mousedown", handleClickOutside);
		return () => document.removeEventListener("mousedown", handleClickOutside);
	}, [modalDetailsRef]);

	const showAlt = useMemo(() => {
		return !window.location.pathname.includes("expert-help");
	}, [window.location.pathname]);

	return (
		<div className="modal d-flex align-items-center justify-content-center">
			<div
				className="p-4 bg-white rounded-lg"
				style={{
					maxWidth: "640px",
				}}
				ref={modalDetailsRef}
			>
				<div className="mb-4 d-flex justify-content-between">
					{showAlt ? (
						<div>
							<h3 className="fs-28 text-gray-950">How does this work?</h3>
						</div>
					) : (
						<div>
							<h3 className="fs-28 text-gray-950">We’ll take it from here!</h3>
							<p className="text-gray-700 fs-16 font-weight-semi">Here’s what happens next.</p>
						</div>
					)}
					<button onClick={() => setShowDetailsModal(false)}>
						<Icon iconName="close" size={20} />
					</button>
				</div>
				<div className="">
					<div className="d-flex mb-4">
						<Icon iconName="expert" size={48} className="mr-3" />
						<div>
							<p className="text-gray-950 font-weight-semi">Expert Assignment</p>
							<p className="text-gray-700">
								Within 24 hours of your purchase, a dedicated expert writer will be assigned to your resume project.
								They will carefully review your information and start crafting a tailored resume for your profile.
							</p>
						</div>
					</div>
					<div className="d-flex mb-4">
						<Icon iconName="expert" size={48} className="mr-3" />
						<div>
							<p className="text-gray-950 font-weight-semi">Initial Consultation</p>
							<p className="text-gray-700">
								If our expert requires additional information, they will reach out to you within 1-2 business days to
								schedule a convenient time for your first consultation and discuss your requirements.
							</p>
						</div>
					</div>
					<div className="d-flex mb-4">
						<Icon iconName="expert" size={48} className="mr-3" />
						<div>
							<p className="text-gray-950 font-weight-semi">Resume Drafting</p>
							<p className="text-gray-700">
								Using the insights gathered from your initial consultation and the information you provided during the
								onboarding process, your expert writer will carefully draft your new resume. x
							</p>
						</div>
					</div>
					<div className="d-flex mb-4">
						<Icon iconName="expert" size={48} className="mr-3" />
						<div>
							<p className="text-gray-950 font-weight-semi">Revisions and Feedback</p>
							<p className="text-gray-700">
								Once the initial draft of your resume is ready, your expert writer will send it to you for review.
								You&#39;ll have the opportunity to provide feedback and request revisions based on your package&#39;s
								revision policy.
							</p>
						</div>
					</div>
					<div className="d-flex mb-4">
						<Icon iconName="expert" size={48} className="mr-3" />
						<div>
							<p className="text-gray-950 font-weight-semi">Final Delivery</p>
							<p className="text-gray-700">
								After completing any necessary revisions, your expert writer will prepare the final version of your
								resume. You will receive your new, professionally crafted resume in the agreed-upon format (e.g., PDF,
								Word).
							</p>
						</div>
					</div>
					{showAlt && (
						<Link to="/expert-help/select-resume" className="btn btn-blue">
							Yes, I want Expert help
						</Link>
					)}
				</div>
			</div>
		</div>
	);
}

function Pricing() {
	const location = useLocation();
	const navigate = useNavigate();
	const user = JSON.parse(localStorage.getItem("profileData"));
	const [showDetailsModal, setShowDetailsModal] = useState(false);
	const [selectedPackage, setSelectedPackage] = useState(packages.career);
	const [submiting, setSubmiting] = useState(false);
	const [contactData, setContactData] = useState({
		date: dayjs().format("YYYY-MM-DD"),
		name: `${user?.firstName || ""} ${user?.lastName || ""}`,
		email: user?.email || "",
		inquiry: window.location.href,
		message: "",
		phone: user?.phoneNumber || "",
		requestCategory: "",
		howDoYouFeel: "",
		moreExplanation: "",
		isThereAlternative: "",
		browser: getBrowserName(),
		operatingSystem: getOperatingSystem(),
		fileUrl: "",
		source: "need expert requests",
	});

	function submitRequest(event, contactData) {
		event.preventDefault();
		setSubmiting(true);
		let data = JSON.stringify(contactData);

		let URL =
			"https://script.google.com/macros/s/AKfycby-3kyn6G80z6AVvQqLGCnUZ01fUrOAZe7ogBqrR4rm3UWI_YW5ZpGVMmYHv9dnE0hc/exec";

		fetch(URL, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"Access-Control-Allow-Origin": "*",
			},
			body: data,
			mode: "no-cors",
		})
			.then(() => {
				toast.success(
					"Thank you! Your request has been submitted successfully. Our team will review it and get back to you as soon as possible.",
				);
				navigate("/");
			})
			.catch((error) => {
				toast.error(error);
				setSubmiting(false);
			});
	}

	useEffect(() => {
		if (location.state?.selectedOption && location.state?.resumeTitle) {
			setContactData((prevState) => {
				return {
					...prevState,
					requestCategory: location.state.selectedOption,
					fileUrl: location.state.resumeTitle,
				};
			});
		}
	}, [location.state]);

	return (
		<section>
			<div className="expert-pricing-sidenav">
				<button onClick={() => navigate("/")} className="bg-gray-100 circle">
					<Icon iconName="close" size={20} />
				</button>
				<div className="resume-order-item">
					<p className="text-gray-950 font-weight-medium">
						{user?.firstName || ""} {user?.lastName || ""}
					</p>
					<p className="text-gray-400">{user?.email || ""}</p>
					<p className="text-gray-400">{user?.phoneNumber || ""}</p>

					<span className="w-fit-content mb-4 d-flex align-items-center mt-3 bg-gray-50 rounded-pill px-3 py-2 font-weight-medium text-gray-700">
						<Icon iconName="expert" size={16} className="mr-2" /> {user?.firstName || ""}-{user?.lastName || ""}
						-resume.pdf
					</span>

					<hr className="w-100" />

					<Link to="/expert-help/personal-info" className="mt-3">
						<p className="text-blue font-weight-semi cursor-pointer">Edit your information</p>
					</Link>
				</div>
			</div>
			<div className="expert-pricing-content">
				<div className="container px-5">
					<div className="text-center mb-5">
						<h1 className="fs-28 font-weight-bold text-gray-950">Choose the package that best fits your goals</h1>
						<p className="text-gray-700 fs-16 font-weight-semi">
							Our expert writers will craft a compelling resume that showcases your unique strengths and helps you stand
							out in a competitive job market.
						</p>
					</div>
					<div className="row">
						<div
							className="col-md-4 cursor-pointer"
							onClick={() => {
								setSelectedPackage(packages.starter);
							}}
						>
							<div className={cn("expert-pricing-card", { active: selectedPackage === packages.starter })}>
								<p className="fs-16 text-gray-950">Starter</p>
								<p className="text-gray-700 mb-4">
									Perfect for entry-level professionals or those seeking a basic resume refresh.
								</p>
								<hr className="dashed" />
								<div className="my-4">
									<p className="text-gray-700">Tsh</p>
									<h2 className="text-gray-950 fs-40 font-weight-medium">1,950.00</h2>
								</div>
								<button
									disabled={submiting}
									className="btn btn-blue rounded-pill"
									onClick={(event) => {
										submitRequest(event, {
											...contactData,
											message: "Able to pay Starter package of TZS" + packages.starter,
										});
									}}
								>
									Get Started
								</button>
								<ul className="mt-3 ul-circle-check">
									<li>Professional resume writing</li>
									<li>Keyword optimization</li>
									<li>1 round of revisions</li>
									<li>7-day turnaround</li>
								</ul>
							</div>
						</div>
						<div
							className="col-md-4 cursor-pointer"
							onClick={() => {
								setSelectedPackage(packages.career);
							}}
						>
							<div className={cn("expert-pricing-card", { active: selectedPackage === packages.career })}>
								<p className="fs-16 text-gray-950">Career Boost</p>
								<p className="text-gray-700 mb-4">
									Ideal for job seekers looking to take their application to the next level.
								</p>
								<hr className="dashed" />
								<div className="my-4">
									<p className="text-gray-700">Tsh</p>
									<h2 className="text-gray-950 fs-40 font-weight-medium">5,850.00</h2>
								</div>
								<button
									disabled={submiting}
									className="btn btn-blue rounded-pill"
									onClick={(event) => {
										submitRequest(event, {
											...contactData,
											message: "Able to pay Career Boost package of TZS" + packages.career,
										});
									}}
								>
									Get Started
								</button>
								<ul className="mt-3 ul-circle-check">
									<li>Professional resume writing</li>
									<li>Keyword optimization</li>
									<li>Cover letter writing</li>
									<li>LinkedIn profile optimization</li>
									<li>2 rounds of revisions</li>
									<li>5-day turnarounds</li>
								</ul>
							</div>
						</div>
						<div
							className="col-md-4 cursor-pointer"
							onClick={() => {
								setSelectedPackage(packages.executive);
							}}
						>
							<div className={cn("expert-pricing-card", { active: selectedPackage === packages.executive })}>
								<p className="fs-16 text-gray-950">Executive Edge</p>
								<p className="text-gray-700 mb-4">Our premier package for senior-level professionals and executives.</p>
								<hr className="dashed" />
								<div className="my-4">
									<p className="text-gray-700">Tsh</p>
									<h2 className="text-gray-950 fs-40 font-weight-medium">51,500.00</h2>
								</div>
								<button
									disabled={submiting}
									className="btn btn-blue rounded-pill"
									onClick={(event) => {
										submitRequest(event, {
											...contactData,
											message: "Able to pay Executive Edge package of TZS" + packages.executive,
										});
									}}
								>
									Get Started
								</button>
								<ul className="mt-3 ul-circle-check">
									<li>Professional resume writing</li>
									<li>Keyword optimization</li>
									<li>Cover letter writing</li>
									<li>LinkedIn profile optimization</li>
									<li>Interview coaching session</li>
									<li>Unlimited revisions</li>
									<li>3-day turnarounds</li>
								</ul>
							</div>
						</div>
					</div>
					<div className="mt-4 text-center">
						<p className="secondary-link" onClick={() => setShowDetailsModal(true)}>
							What to expect after you choose a package
						</p>
					</div>
				</div>
			</div>
			{showDetailsModal && <PricingDetailsModal setShowDetailsModal={setShowDetailsModal} />}
		</section>
	);
}

export default Pricing;
