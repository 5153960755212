import React from "react";
import { useNavigate } from "react-router-dom";

import {
	AboutUs1,
	AboutUs2,
	AboutUs3,
	AboutUs4,
	AboutUs5,
	AboutUs6,
	AboutUs7,
	AboutUs8,
	AboutUs9,
	AboutUs10,
} from "../../../assets/icons/interface/interface";
import useIsLoggedIn from "../../../hooks/use-IsLoggedIn";
import { Button } from "../../JobsPage/components/Button";
import Header from "../../Profile/components/Header";
import Footer from "../Footer";

function AboutUsNewDesign() {
	const navigate = useNavigate();
	const isLoggedIn = useIsLoggedIn();

	return (
		<div className="bg-[#F8F9FA] leading-normal">
			<Header isLoggedIn={isLoggedIn} />
			<div className="relative">
				<img
					src="https://s3-alpha-sig.figma.com/img/04ac/6824/bccfa7951ffe71079de9ace8afe71293?Expires=1741564800&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=uV-XLepl6ZFHxk8LxMWN2bojIaieaOW5tRejj4dQWIXG4LiO0tTh1VhaBAYStKOz1Es8gJpiYj7Qxz9qnRk0CqKSiu2WsVQClr2-SDpApgUKLynnSzo6XB53SAURbjeCiE3yRg4swrSI7bAXTKXFL3ElGqWoYLzIWBfG4BnavX1ARzG4AOLmnNANlWFT87E2L-bterb9CJh5eksLd-kFTRw2hHM8p9M0ORHgIfonrtGPnIuT8a--NVkRJycrwaKX69BeDji36Pp8235dOfOkufXAv9x877abV~N8J~80YQUjrYI4dhe8lc7uS5lMtir4FCyf2qmfgtWWdkN5nhTfGQ__"
					alt="About Us"
					className="w-full h-auto"
				/>
				<div className="absolute inset-0 bg-[#007BFFB2] opacity-70" />
				<div className="absolute inset-0 flex flex-col items-center justify-center container space-y-[24px]">
					<h1 className="text-white line-clamp-2 text-[42px] font-[700] text-center">
						Welcome to Dproz – Tanzania’s Premier Hiring Platform
					</h1>
					<div className="text-white hidden md:block text-[24px] font-[400] text-center">
						Your gateway to seamless hiring and meaningful career opportunities, bridging the gap between talent and
						opportunity in Tanzania.
					</div>
					<Button
						className="bg-white text-[#0A6FE2] hover:bg-white rounded-[8px] w-[281px] h-[59px]"
						onClick={() => {
							navigate("/register");
						}}
					>
						Start Your Journey Today
					</Button>
				</div>
			</div>
			<div className="py-[32px]">
				<div className="container">
					<div className="py-[32px] space-y-[8px]">
						<div className="text-[34px] text-[#007BFF] font-[700] text-center">What We Do</div>
						<div className="text-[24px] text-[#555555] font-[400] text-center">
							At Dproz, we’re on a mission to eliminate the hiring headaches that cost you time and money. We built our
							platform by listening closely to HR professionals and hiring managers—real people facing real challenges.
							In Tanzania, recruitment is often manual, time-consuming, and prone to bias. Dproz is the data-driven,
							AI-powered solution that transforms these challenges into measurable results.
						</div>
					</div>
					<div className="flex flex-col md:flex-row gap-[25px]">
						<div className="bg-white rounded-[8px] shadow p-[32px] flex-1">
							<div className="space-y-[24px]">
								<div className="text-[24px] font-[700]">For Job Seekers</div>
								<div className="flex items-start gap-[16px]">
									<AboutUs1 />
									<div className="space-y-[6px]">
										<div className="text-[20px] font-[600]">Tailored Job Recommendations</div>
										<div className="text-[18px] font-[500] text-[#555555]">
											Receive opportunities that precisely match your skills and career goals.
										</div>
									</div>
								</div>
								<div className="flex items-start gap-[16px]">
									<AboutUs2 />
									<div className="space-y-[6px]">
										<div className="text-[20px] font-[600]">One-Click Applications</div>
										<div className="text-[18px] font-[500] text-[#555555]">
											Apply quickly without tedious forms, so you never miss out on a great opportunity.
										</div>
									</div>
								</div>
								<div className="flex items-start gap-[16px]">
									<AboutUs3 />
									<div className="space-y-[6px]">
										<div className="text-[20px] font-[600]">Personalized Feedback</div>
										<div className="text-[18px] font-[500] text-[#555555]">
											Enhance your resume with actionable insights that help you stand out.
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="bg-white rounded-[8px] shadow p-[32px] flex-1">
							<div className="space-y-[24px]">
								<div className="text-[24px] font-[700]">For Employers</div>
								<div className="flex items-start gap-[16px]">
									<AboutUs4 />
									<div className="space-y-[6px]">
										<div className="text-[20px] font-[600]">AI-Powered Matching</div>
										<div className="text-[18px] font-[500] text-[#555555]">
											Instantly identify top candidates—cutting manual resume screening time by hours.
										</div>
									</div>
								</div>
								<div className="flex items-start gap-[16px]">
									<AboutUs5 />
									<div className="space-y-[6px]">
										<div className="text-[20px] font-[600]">Streamlined Processes</div>
										<div className="text-[18px] font-[500] text-[#555555]">
											Automate repetitive tasks like shortlisting and screening so your team can focus on strategic
											decisions.
										</div>
									</div>
								</div>
								<div className="flex items-start gap-[16px]">
									<AboutUs6 />
									<div className="space-y-[6px]">
										<div className="text-[20px] font-[600]">Real-Time Collaboration</div>
										<div className="text-[18px] font-[500] text-[#555555]">
											Our centralized dashboard eliminates endless emails and miscommunication, keeping your team
											aligned.
										</div>
									</div>
								</div>
								<div className="flex items-start gap-[16px]">
									<AboutUs7 />
									<div className="space-y-[6px]">
										<div className="text-[20px] font-[600]">Data-Driven Insights</div>
										<div className="text-[18px] font-[500] text-[#555555]">
											Make smarter hiring decisions with actionable analytics that reduce costs and boost candidate
											quality.
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="bg-white py-[32px] mt-[32px]">
				<div className="container">
					<div className="space-y-[8px] py-[32px]">
						<div className="text-center text-[34px] font-700 text-[#007BFF]">Why Dproz?</div>
						<div className="text-center text-[24px] font-400 text-[#555555]">
							We know that in Tanzania, the hiring process can be inefficient and outdated. That’s why Dproz was built
							to deliver clear, measurable benefits:
						</div>
					</div>
					<div className="flex flex-col md:flex-row gap-[24.5px]">
						{[
							{
								icon: <AboutUs8 />,
								title: "Efficiency",
								description: "Save time and reduce recruitment costs with automated screening and centralized data.",
							},
							{
								icon: <AboutUs9 />,
								title: "Fairness",
								description: "Minimize bias by standardizing and streamlining every step of the hiring process.",
							},
							{
								icon: <AboutUs10 />,
								title: "Growth",
								description:
									"Connect top talent with leading companies to drive success on both sides of the hiring equation.",
							},
						].map((item, index) => {
							return (
								<div key={index} className="p-[32px] rounded-[8px] shadow flex-1">
									<div className="space-y-[10px]">
										<div className="flex items-center gap-[10px]">
											{item.icon}
											<div className="text-[20px] font-[600]">{item.title}</div>
										</div>
										<div className="text-[18px] font-[500] text-[#555555]">{item.description}</div>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</div>
			<div className="py-[32px]">
				<div className="container">
					<div className="space-y-[8px] py-[32px]">
						<div className="text-center text-[34px] font-700 text-[#007BFF]">Our Vision</div>
						<div className="text-center text-[24px] font-400 text-[#555555]">
							To become Tanzania’s leading job destination marketplace by revolutionizing recruitment into a fast,
							data-driven, and transparent process that works for everyone.
						</div>
					</div>
				</div>
			</div>
			<div className="bg-white pt-[96px] pb-[64px]">
				<div className="container text-center space-y-[16px]">
					<h2 className="text-[36px] font-[700]">Ready to Transform Your Hiring or Find Your Next Opportunity?</h2>
					<div className="mx-[8px] md:mx-[90px]">
						<p className="text-[24px] font-[400] pb-[16px]">
							Whether you’re an employer eager to fill roles faster with the best candidates, or a job seeker ready for
							opportunities tailored to your skills, Dproz is your partner for success. Discover how we’re turning
							hiring challenges into opportunities
						</p>
					</div>
					<Button
						className="rounded-[8px] w-[185px] h-[56px]"
						onClick={() => {
							navigate("/register");
						}}
					>
						Get Started
					</Button>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default AboutUsNewDesign;
