import React, { useContext, useEffect, useMemo, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import tips from "../../../assets/copy/tips";
import ErrorToast from "../../../components/ErrorToast";
import Modal from "../../../components/Modal/Modal";
import EditorListItems from "../../../components/ResumeBuilder/EditorListItems";
import Spinner from "../../../components/Spinner";
import TextEditor from "../../../components/TextEditor";
import Tips from "../../../components/Tips";
import ResumeContext from "../../../context/ResumeContext";
import { FormValidation } from "../../../handlers/formValidation";
import { NavigationHandler } from "../../../handlers/navigationHandler";
import { convertUlToArrayDefault } from "../../../handlers/utilityFunctions";
import { PreviousButton } from "../../../handlers/valueTransformation";
import useHasChange from "../../../hooks/use-HasChange";
import { StaticValue } from "../../../seed/constants";
import { ResumeDataService } from "../../../services/create-resume/resume-data";
import summaryListItems from "./resume-summary.json";

const ResumeBriefSummary = () => {
	const [cookies] = useCookies(["resume"]);
	const navigate = useNavigate();
	const navigationHandler = new NavigationHandler();
	const link = useLocation().pathname.split("/")[2];
	const dispatch = useDispatch();
	const { hasChanges } = useHasChange();
	const formValidation = new FormValidation();

	const [loading, setLoading] = useState(false);

	const { resumeData, setResumeData } = useContext(ResumeContext);

	let { journal } = resumeData;
	let resumeCurrentStatus = journal ? journal.currentStatus : false;
	let isComplete = resumeCurrentStatus == "COMPLETED";

	const previousTxt = PreviousButton(isComplete, "Extras", navigationHandler, 0);

	function previous() {
		navigationHandler.getPrevious(navigationHandler.template.navigationFlow, link, isComplete);
		navigate(-1);
	}

	const allSummaryItems = useMemo(() => {
		return summaryListItems
			.flatMap((item) => {
				return Object.keys(item).map((key) => {
					return item[key];
				});
			})
			.flat()
			.map((item, index) => {
				return {
					id: index,
					title: item,
					added: false,
				};
			});
	}, []);

	const functionSummaryItems = useMemo(() => {
		return summaryListItems
			.filter((item) => {
				return Object.keys(item).includes(resumeData.contactInfo.resumeFunction);
			})
			.flatMap((item) => {
				return Object.keys(item).map((key) => {
					return item[key];
				});
			})
			.flat()
			.map((item, index) => {
				return {
					id: index,
					title: item,
					added: false,
				};
			});
	}, [resumeData.contactInfo.resumeFunction]);

	const listItems = useMemo(() => {
		if (resumeData.contactInfo.resumeFunction) {
			return functionSummaryItems;
		} else {
			return allSummaryItems;
		}
	}, [resumeData.contactInfo.resumeFunction]);

	const [summaryList, setSummaryList] = useState([...listItems]);

	useEffect(() => {
		setSummaryList([...functionSummaryItems]);
	}, [resumeData.contactInfo.resumeFunction]);

	const [searchValue, setSearchValue] = useState("");
	function filterSummaryList(input) {
		const filteredList = allSummaryItems.filter((item) => {
			return item.title.toLowerCase().includes(input.toLowerCase());
		});
		setSummaryList([...filteredList]);
	}

	useEffect(() => {
		if (searchValue.length > 0) {
			filterSummaryList(searchValue);
		} else {
			setSummaryList([...functionSummaryItems]);
		}
	}, [searchValue]);

	const [breifSummary, setBreifSummary] = useState(resumeData.summary);
	const [userInput, setUserInput] = useState(resumeData.contactInfo.resumeTitle);

	useEffect(() => {
		const addedItems = summaryList.filter((item) => {
			return item.added;
		});
		const notAddedItems = summaryList.filter((item) => {
			return !item.added;
		});
		setSummaryList([...addedItems, ...notAddedItems]);
	}, [breifSummary]);

	useEffect(() => {
		setBreifSummary(resumeData.summary);
	}, [resumeData]);

	const [isInputCompleted, setIsInputCompleted] = useState(false);

	const staticValue = new StaticValue();
	const staticValues = staticValue.getDefaultValues();

	const resumeDataService = new ResumeDataService();
	const [serverErrors, setServerErrors] = useState([]);

	let resumeId = cookies[staticValues.localStorageResumeId];

	function onSuccess(data) {
		//update store
		setLoading(false);
		const response = data.data;
		dispatch({ type: "RESUME_DATA", payload: response });

		navigationHandler.getNext(navigationHandler.template.navigationFlow, link, isComplete);
		navigate("/resume/extras");
	}

	function onError(error) {
		setLoading(false);
		const serverResponse = error.response.data;
		Array.isArray(serverResponse.message)
			? setServerErrors((prev) => {
					return [...prev, ...serverResponse.message];
				})
			: setServerErrors((prev) => {
					return [...prev, serverResponse.message];
				});
	}
	//submition handler
	const addBriefSummary = () => {
		let summaryData = [...convertUlToArrayDefault(breifSummary)].reduce((prev, value) => {
			return [
				...prev,
				{
					bp: true,
					value,
				},
			];
		});

		summaryData = Array.isArray(summaryData) ? summaryData : [{ bp: false, value: summaryData }];

		if (isInputCompleted) {
			setResumeData((prevState) => {
				return {
					...prevState,
					summary: breifSummary,
				};
			});

			if (hasChanges("SUMMARY", summaryData)) {
				setLoading(true);
				resumeDataService.patchResumeLedger(resumeId, { data: summaryData }, "SUMMARY", onSuccess, onError);
			} else {
				skipSection();
			}
		}
	};

	useEffect(() => {
		const validation = formValidation.validateBriefDetails(breifSummary);
		if (!validation) {
			setIsInputCompleted(false);
		} else {
			setIsInputCompleted(true);
		}
	}, [breifSummary]);

	const skipSection = () => {
		navigationHandler.getNext(navigationHandler.template.navigationFlow, link, isComplete);
		navigate("/resume/extras");
	};

	useEffect(() => {
		setUserInput(resumeData.contactInfo.resumeTitle);
	}, [resumeData]);

	return (
		<>
			<div
				className="modal fade"
				id="skipSectionModal"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="skipSectionModalTitle"
				aria-hidden="false"
			>
				<Modal skip={skipSection} />
			</div>

			<div className="container min-vh-100">
				<div className="section">
					{serverErrors.map((item, index) => {
						return <ErrorToast error={item} key={index} />;
					})}
					<div className="common-recommendations-section summary-recommendations-section">
						<div className="row">
							<div className="col-md-6">
								<div className="left">
									<p className="mb-3 text-medium">Briefly tell us about your background</p>

									<div className="search mb-4">
										<div className="form-group">
											<div className="input-group">
												<input
													type="text"
													className="form-control"
													placeholder={userInput}
													onChange={(e) => {
														setSearchValue(e.target.value);
													}}
												/>
												<div className="input-group-append">
													<button className="btn btn-primary px-md-5" type="button">
														<span className="fa fa-search" />
													</button>
												</div>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-12">
											<div className="recommendation-topics">
												<EditorListItems
													editorListItems={summaryList}
													editorContent={breifSummary}
													setEditorContent={setBreifSummary}
													setEditorListItems={setSummaryList}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-6 d-flex flex-column mt-4 justify-content-end">
								<div className="editor-side">
									<div className="d-flex justify-content-between mb-1">
										<div />
										<div>
											<Tips content={tips.professionalSummary} />
										</div>
									</div>
									<div className="editor-wrapper">
										<TextEditor
											value={breifSummary}
											setValue={setBreifSummary}
											placeholder="Write your summary here..."
										/>
									</div>
								</div>
							</div>
							<div className="col-12 mt-4 d-flex justify-content-end">
								<button onClick={() => previous()} className="btn btn-dark rounded-pill btn-lg mr-2">
									{previousTxt}
								</button>
								<button
									data-toggle="modal"
									data-target={!isInputCompleted ? "#skipSectionModal" : ""}
									onClick={addBriefSummary}
									className="btn btn-primary"
								>
									{loading ? (
										<>
											Loading <Spinner />
										</>
									) : (
										"Continue"
									)}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ResumeBriefSummary;
