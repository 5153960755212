import React, { useState } from "react";

import { CheckIcon } from "../../../../assets/icons/interface/interface";
import Icon from "../../../../components/Icon";
import { actions, docSubTypes } from "../../../../handlers/enums";
import useDSPProfileState from "../../../../hooks/use-DSPProfileState";
import { useSmartProfile } from "../../../../services/create-resume/queries";
import DSPModal from "./DSPModal";

function DSPSelection({ smartApplication, onDSPChange }) {
	const { data: smartProfile } = useSmartProfile();
	const [selected, setSelected] = useState(smartApplication.dsp.dsp_id);
	const [showDSPModal, setShowDSPModal] = useState(false);
	const dspProfileState = useDSPProfileState();
	const dspModalData = dspProfileState.dspModalData(actions.CREATE);

	const handleDSPModalSelect = (option) => {
		dspProfileState.onDSPSelect(option);
		setShowDSPModal(false);
	};

	const onSelectDSP = (profileId) => {
		const selectedDSP = smartProfile.dsps.find((dsp) => dsp.dsp_id === profileId);
		onDSPChange(selectedDSP);
		setSelected(profileId);
	};

	return (
		<div className="mx-auto">
			<h2 className="text-md font-inter-600 mb-4">
				Which Smart Profile (DSP) do you want to use for this application?
			</h2>
			<div className="space-y-2">
				{smartProfile.dsps.map((dsp) => (
					<div
						key={dsp.dsp_id}
						className={`border rounded-lg p-3 cursor-pointer transition-all ${
							selected === dsp.dsp_id ? "border-blue-500" : "border-gray-300"
						}`}
						onClick={() => onSelectDSP(dsp.dsp_id)}
					>
						<div className="flex justify-between items-start">
							<div>
								<h2 className="text-md font-inter font-bold">{dsp.dsp_title}</h2>
								<p className="text-xs font-inter font-medium text-gray-500">{ smartApplication.email }</p>
								<ul className="mt-1 text-xs text-gray-700">
									{dsp.dsp_docs && dsp.dsp_docs.length > 0 ? (
										dsp.dsp_docs.map((doc) => (
											<li key={doc.id} className="flex items-center gap-1">
												<Icon iconName={Object.values(docSubTypes).find(docSubType => docSubType.name === doc.doc_sub_type).icon} size={15} />
												<span>
													<span className="font-inter text-xs font-semibold text-gray-600">
														{Object.values(docSubTypes).find(docSubType => docSubType.name === doc.doc_sub_type).name}:{" "}
													</span>
													<span className="font-inter text-xs font-medium text-gray-600">{` ${doc.file_name}`}</span>
												</span>
											</li>
										))
									) : (
										<p>No documents available</p> // Optional: message when no docs are available
									)}
								</ul>
							</div>
							{selected === dsp.dsp_id ? (
								<CheckIcon className="text-blue-500 text-sm" />
							) : (
								<div className="w-4 h-4 border border-gray-400 rounded-full" />
							)}
						</div>
					</div>
				))}
			</div>
			<div className="flex mt-4 cursor-pointer" onClick={() => setShowDSPModal(true)}>
				<Icon iconName="union-plus" size={13} className="mt-1" />
				<h2 className="text-sm font-inter text-blue-500 font-medium ml-1 mb-4">Add another DSP</h2>
			</div>
			<DSPModal
				data={dspModalData}
				isOpen={showDSPModal}
				onSubmit={handleDSPModalSelect}
				onClose={() => setShowDSPModal(false)}
			/>
		</div>
	);
}

export default DSPSelection;
