import React from "react";
import { Link } from "react-router-dom";

import EmbededVideo from "../../../../components/EmbededVideo";
import { useAuth } from "../../../../context/Auth/AuthContext";

function PreferencesUpsell() {
	const { userData } = useAuth();

	return (
		<section className="pt-24 pb-16 px-4 md:px-8">
			<div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-12 gap-8">
				<div className="text-center md:text-left max-w-4xl mx-auto mt-4 md:col-span-5">
					<h2 className="text-3xl font-bold text-gray-900 mb-6">Struggling to Find Your Dream Job?</h2>
					<p className="text-xl text-gray-900 font-semibold mb-3">
						Dproz bridges your talent to opportunities that fit you best.
					</p>
					<p className="text-md text-gray-900 mb-3">
						Get personalized job recommendations, match scores, and focus on quality jobs to boost your chances.
					</p>

					<Link
						to={userData ? "/profile" : "/register"}
						className="inline-block bg-primary-600 text-white px-4 py-2 rounded-md text-base font-medium hover:bg-primary-700 transition-colors font-inter mb-8"
					>
						Set Preferences Now
					</Link>
				</div>
				<div className="md:col-span-7">
					<EmbededVideo videoLink="https://www.youtube.com/watch?v=sLZCPuJq630" />
				</div>
			</div>
		</section>
	);
}

export default PreferencesUpsell;
