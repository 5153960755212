import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "sonner";

import { DocumentIcon, JobsIcon, PreferencesIcon } from "../../assets/icons/interface/interface";
import Drawer from "../../components/Drawer/Drawer";
import { actions } from "../../handlers/enums";
import useIsLoggedIn from "../../hooks/use-IsLoggedIn";
import { useFeatureFlag } from "../../hooks/useFeatureFlag";
import { useResumes, useSmartProfile, useUserAccount } from "../../services/create-resume/queries";
import { ResumeDataService } from "../../services/create-resume/resume-data";
import ViewJob from "../StaticPages/HomePage/components/ViewJob";
import DocumentCenter from "./components/DocumentCenter";
import DSPItem from "./components/DSPItem";
import FloatingNav from "./components/FloatingNav";
import Header from "./components/Header";
import JobPreferencesModal from "./components/JobPreferencesModal";
import { Modal } from "./components/Modal";
import ProfileCard from "./components/ProfileCard";
import RecommendedJobs from "./components/RecommendedJobs";
import SavedJobs from "./components/SavedJobs";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./components/Tabs";
import UploadResumeModal from "./components/UploadResumeModal";

function transformToRequestBody(currentPayload, personalInfo) {
	return {
		emailAddress: personalInfo.emailAddress,
		firstName: personalInfo.firstName,
		middleName: personalInfo.middleName,
		lastName: personalInfo.lastName,
		phoneNumber: personalInfo.phoneNumber,
		jobPreferences: {
			primaryResumeReferenceId: currentPayload.primaryResumeReferenceId,
			currentJobsearchStatus: currentPayload.currentJobsearchStatus,
			openForWorkTypes: currentPayload.openForWorkTypes,
			areasOfExpertise: currentPayload.areasOfExpertise,
			seniorityLevel: currentPayload.seniorityLevel,
			locationPreferenceType: currentPayload.locationPreferenceType,
			locationsPreferred: currentPayload.locationsPreferred,
			recentGraduate: currentPayload.recentGraduate,
			graduationDate: {
				month: currentPayload.graduationDate?.month,
				year: currentPayload.graduationDate?.year,
			},
			professionalSince: {
				month: currentPayload.professionalSince.month,
				year: currentPayload.professionalSince.year,
			},
			educationLevels: currentPayload.educationLevels,
			desiredTitle: currentPayload.desiredTitle,
			address: {
				city: currentPayload?.address?.city,
				country: "Tanzania",
			},
			skills: currentPayload.skills,
		},
	};
}

const navItems = [
	{
		id: "profile",
		label: "Preferences",
		icon: <PreferencesIcon className="w-5 h-5" />,
	},
	{
		id: "job-recommendations",
		label: "Job Picks",
		icon: <JobsIcon className="w-5 h-5" />,
	},
	{
		id: "documents",
		label: "Documents",
		icon: <DocumentIcon className="w-5 h-5" />,
	},
];

function Profile() {
	const showDSP = useFeatureFlag("dsp");

	const isLoggedIn = useIsLoggedIn();
	const dispatch = useDispatch();
	const resumeDataService = useMemo(() => new ResumeDataService(), []);

	const [isScrolled, setIsScrolled] = useState(false);
	const [showJobDetailsModal, setShowJobDetailsModal] = useState(false);
	const [uploadResumeModal, setUploadResumeModal] = useState(false);
	const [selectedJob, setSelectedJob] = useState(null);
	const [selectedDSPId, setSelectedDSPId] = useState(null);

	const [showPreferencesModal, setShowPreferencesModal] = useState(false);
	const [completenessPercentage] = useState(0);
	const dspProfileState = useSelector((state) => state.ProfileDSPReducer);

	const { data: resumeList, isLoading: isLoadingResumes } = useResumes();

	useEffect(() => {
		if (isLoadingResumes) return;
	}, [resumeList, isLoadingResumes]);

	const [jobPreferences, setJobPreferences] = useState({});

	const { data: accountData, isLoading: isLoadingAccount, refetch } = useUserAccount();
	const { data: smartProfileData, isLoading: isLoadingSmartProfileData } = useSmartProfile();

	useEffect(() => {
		if (isLoadingAccount) return;
		setJobPreferences(accountData?.jobPreferences);
		if (
			accountData?.jobPreferences?.areasOfExpertise?.length === 0 ||
			accountData?.jobPreferences?.areasOfExpertise === undefined
		) {
			setShowPreferencesModal(true);
			toast.info("Please update your job preferences to get better job recommendations");
		}
	}, [accountData, isLoadingAccount]);

	const onChangeJobStatus = (status) => {
		setJobPreferences({
			...jobPreferences,
			currentJobsearchStatus: status,
		});
		resumeDataService.jobPreferences(
			transformToRequestBody(jobPreferences, {
				emailAddress: accountData?.email,
				firstName: accountData?.firstName,
				middleName: accountData?.middleName,
				lastName: accountData?.lastName,
				phoneNumber: accountData?.phoneNumber,
			}),
			(data) => {
				refetch();
				localStorage.setItem("jobPreferences", JSON.stringify(data?.data));
			},
			(error) => {
				const errorMessages = error.response.data.message;
				toast.error(errorMessages);
			},
		);
	};

	useEffect(() => {
		const handleScroll = () => {
			setIsScrolled(window.scrollY > 0);
		};

		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll);
	}, []);

	useEffect(() => {
		const payload = {
			fromDSP: false,
			actionPerformed: "",
		};

		if (dspProfileState.fromDSP && dspProfileState.actionPerformed === actions.CREATE) {
			setUploadResumeModal(true);
		}

		if (dspProfileState.fromDSP && dspProfileState.actionPerformed === actions.UPDATE) {
			const element = document.getElementById("documents");
			if (element) element.scrollIntoView({ behavior: "smooth" });
		}

		dispatch({ type: "RESET_DSP_STATE", payload });
	}, []);

	if (isLoadingResumes || isLoadingSmartProfileData) {
		return <div className="w-full h-screen bg-white flex items-center justify-center">Loading...</div>;
	}

	return (
		<>
			<div className={`sticky top-0 z-50 bg-white transition-all duration-300 ${isScrolled ? "shadow-sm" : ""}`}>
				<Header
					isLoggedIn={isLoggedIn}
					variant="applicant"
					currentJobStatus={jobPreferences?.currentJobsearchStatus}
					onChangeJobStatus={onChangeJobStatus}
				/>
			</div>

			<Drawer
				isOpen={showJobDetailsModal}
				onClose={() => {
					setShowJobDetailsModal(false);
				}}
			>
				<ViewJob data={selectedJob} setData={setSelectedJob} setShowJobDetailsModal={setShowJobDetailsModal} />
			</Drawer>
			<Modal
				isOpen={uploadResumeModal}
				onClose={() => {
					setUploadResumeModal(false);
				}}
				title="Get noticed. Get Hired – Upload your resume!"
				size="xl"
			>
				<UploadResumeModal onClose={() => setUploadResumeModal(false)} />
			</Modal>

			<div className="relative max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
				<FloatingNav items={navItems} />

				{/* Profile Section */}
				<section id="profile" className="scroll-mt-24">
					<ProfileCard
						onEditPreferences={() => setShowPreferencesModal(true)}
						setUploadResumeModal={setUploadResumeModal}
						smartProfileData={smartProfileData}
					/>
				</section>

				{/* Job Preferences Modal */}
				<JobPreferencesModal
					isOpen={showPreferencesModal}
					onClose={() => {
						setShowPreferencesModal(false);
						refetch();
					}}
					setJobPreferences={setJobPreferences}
					jobPreferences={jobPreferences}
					resumeList={resumeList}
					transformToRequestBody={transformToRequestBody}
				/>

				{/* Job Recommendations Section */}
				<section id="job-recommendations" className="scroll-mt-24 mt-12">
					<div className="mb-4">
						<h2 className="text-xl font-bold text-gray-800 font-plus-jakarta">Job picks for you</h2>
					</div>

					<Tabs
						defaultValue={accountData?.jobPreferences?.areasOfExpertise?.length > 0 ? "recommended" : "saved"}
						className="w-full"
					>
						<TabsList className="flex border-b-0">
							<TabsTrigger
								value="recommended"
								className="ignore-focus-behavior rounded-none text-sm text-gray-700 mr-6"
							>
								Recommended
							</TabsTrigger>
							<TabsTrigger value="saved" className="ignore-focus-behavior rounded-none text-sm text-gray-700">
								Saved Jobs
							</TabsTrigger>
						</TabsList>

						<TabsContent value="recommended">
							<RecommendedJobs
								setShowPreferencesModal={setShowPreferencesModal}
								setSelectedJob={setSelectedJob}
								setShowJobDetailsModal={setShowJobDetailsModal}
							/>
						</TabsContent>

						<TabsContent value="saved">
							<SavedJobs />
						</TabsContent>
					</Tabs>

					{completenessPercentage > 0 && (
						<div className="mt-8">
							<Link to="/jobs">
								<div className="w-full py-2 px-4 bg-white text-gray-700 border border-gray-300 rounded-md hover:bg-gray-50 transition-colors duration-200 text-sm font-inter text-center">
									View all jobs
								</div>
							</Link>
						</div>
					)}
				</section>

				{showDSP && Object.keys(smartProfileData).length > 0 && (
					<section className="mt-[32px]">
						<DSPItem
							dspList={smartProfileData ? smartProfileData.dsps : []}
							setDSPId={(value) => setSelectedDSPId(value)}
						/>
					</section>
				)}

				{/* Documents Section */}
				{Object.keys(smartProfileData).length > 0 && (
					<section id="documents" className="scroll-mt-24">
						<DocumentCenter smartProfile={smartProfileData} selectedDSPId={selectedDSPId} />
					</section>
				)}
			</div>
		</>
	);
}

export default Profile;
