import React, { useContext, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "sonner";

import tips from "../../../../assets/copy/tips";
import Loader from "../../../../components/Loader";
import Tips from "../../../../components/Tips";
import ResumeContext from "../../../../context/ResumeContext";
import { NavigationHandler } from "../../../../handlers/navigationHandler";
import { resumeObjectToContextFormat } from "../../../../handlers/valueTransformation";
import { NextButton } from "../../../../handlers/valueTransformation";
import { StaticValue } from "../../../../seed/constants";
import { ResumeDataService } from "../../../../services/create-resume/resume-data";
import EducationSummaryBox from "./../../../../components/ResumeBuilder/EducationSummaryBox/index";

const ResumeEducationSummary = () => {
	const navigate = useNavigate();

	const navigationHandler = new NavigationHandler();
	const link = useLocation().pathname.split("/")[2];
	const { resumeData, setResumeData } = useContext(ResumeContext);

	let { education, workHistory, journal } = resumeData;
	let skipOne = 0;

	let resumeCurrentStatus = journal ? journal.currentStatus : false;
	let isComplete = resumeCurrentStatus == "COMPLETED";

	const [loading, setLoading] = React.useState(true);
	useEffect(() => {
		if (resumeData.contactInfo.firstName === "") {
			return setLoading(true);
		}
		return setLoading(false);
	}, [resumeData]);

	// if (yearsOfExperience === "No Experience") {
	skipOne = workHistory.length > 0 ? 2 : 0;
	// }

	const nextTxt = NextButton(isComplete, "Work Details", navigationHandler, skipOne);

	function next(e) {
		e.preventDefault();

		let nextLink = navigationHandler.getNext(navigationHandler.template.navigationFlow, link, isComplete, skipOne).value
			.url;

		if (nextLink === "review") return navigate("/my-resumes");
		return navigate(`/resume/${nextLink}`);
	}

	useEffect(() => {}, [resumeData]);

	const addAnotherEduHandler = () => {
		navigationHandler.getPrevious(navigationHandler.template.navigationFlow, link, isComplete, 0);

		return navigate("/resume/education-information");
	};

	const dispatch = useDispatch();

	const dispatchAddExtras = (payload) => {
		dispatch({ type: "ADD_EXTRAS", payload: payload });
	};

	function onSuccess(data) {
		const response = data.data;
		resumeObjectToContextFormat(response, setResumeData, dispatchAddExtras);
		dispatch({ type: "RESUME_DATA", payload: response });
	}

	function onError() {
		toast.error("Something went wrong");
	}
	const [cookies] = useCookies(["resume"]);

	const staticValue = new StaticValue();
	const staticValues = staticValue.getDefaultValues();
	const resumeId = cookies[staticValues.localStorageResumeId];

	const resumeDataService = new ResumeDataService();

	const deleteHandler = (eduId) => {
		resumeDataService.patchResumeLedger(
			resumeId,
			{
				referenceId: eduId,
			},
			"EDUCATION",
			onSuccess,
			onError,
			"DELETE",
		);
	};

	useEffect(() => {
		if (!loading && education.length === 0) {
			navigate("/resume/education-information");
		}
	}, [education]);

	const summaryBox = education.map((summaryItem, index) => (
		<EducationSummaryBox
			item={summaryItem}
			deleteEduSummary={deleteHandler}
			key={index}
			index={summaryItem.referenceId}
			number={index + 1}
		/>
	));

	useEffect(() => {}, [resumeData]);

	return (
		<>
			<div className="container min-vh-100">
				{/* resume summary section  */}
				<div className="section resume-summary-section">
					<div className="col-md-8">
						<div className="d-flex justify-content-end mb-2">
							<Tips content={tips.educationHistory} />
						</div>
					</div>

					{loading ? (
						<Loader />
					) : (
						<div className="col-md-8">
							{summaryBox}
							<span className="text-primary py-3 cursor-pointer font-weight-semi" onClick={addAnotherEduHandler}>
								<span className="fa fa-plus mr-3" />
								Add another education
							</span>
							<div className="action d-flex align-items-center mt-4 justify-content-end">
								<Link to="/resume/skills" className="btn btn-primary rounded-pill btn-lg" onClick={next}>
									{nextTxt}
								</Link>
							</div>
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default ResumeEducationSummary;
