import React from "react";

import { useSavedJobs } from "../../../services/create-resume/queries";
import JobListingItem from "../../JobsPage/components/JobListingItem";

function SavedJobs({ setSelectedJob, setShowJobDetailsModal }) {
	const { data: savedJobs, isLoading: isLoadingSavedJobs } = useSavedJobs();

	if (isLoadingSavedJobs) {
		return (
			<div className="animate-pulse">
				{[...new Array(5)].map((_, index) => (
					<div className="h-24 bg-gray-900 opacity-10 mb-3 rounded-md" key={index} />
				))}
			</div>
		);
	}

	return (
		<div>
			{!isLoadingSavedJobs && savedJobs.length > 0 ? (
				<div className="divide-y divide-gray-200">
					{savedJobs.map((job, index) => (
						<JobListingItem
							key={index}
							job={{
								...job,
								logo: job.company.logo_url,
							}}
							onApply={() => {
								setSelectedJob(job);
								setShowJobDetailsModal(true);
							}}
							onClick={() => {
								setSelectedJob(job);
								setShowJobDetailsModal(true);
							}}
							showCompany={true}
							titleClassName="text-base"
							hideSaveButton={true}
							deleteButton={true}
							showMatchScore
						/>
					))}
				</div>
			) : (
				<div className="text-center py-8 text-gray-500">No saved jobs yet. Save jobs to view them here later.</div>
			)}
		</div>
	);
}

export default SavedJobs;
