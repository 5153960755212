import React, { useEffect, useState } from "react";
import { toast } from "sonner";

import { DownloadIcon, FileIcon, HelpCircle, Trash } from "../../../assets/icons/interface/interface";
import { Alert, AlertDescription, AlertTitle } from "../../../components/Alert/Alert";
import { Badge } from "../../../components/Badge/Badge";
import { Label } from "../../../components/Label/Label";
import Spinner from "../../../components/Spinner";
import { docSubTypes, dspDocTypes } from "../../../handlers/enums";
import { generateFileHash } from "../../../handlers/utilityFunctions";
import { useDeleteDocument, useUpdateSmartProfile } from "../../../services/create-resume/queries";
import FileUploadService from "../../../services/fileUploadService";
import { Button } from "../../JobsPage/components/Button";
import { Card, CardContent } from "../../JobsPage/components/Card";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../JobsPage/components/Select";
import FileUpload from "./FileUpload";
import { Modal } from "./Modal";

const initialErrorsState = { documentType: null, file: null, description: null };

export default function DocumentCenter({ smartProfile, selectedDSPId }) {
	const [loading, setLoading] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [selectedDocId, setSelectedDocId] = useState(null);
	const [errors, setErrors] = useState(initialErrorsState);
	const [deleteDoc, setDeleteDoc] = useState(null);
	const [showUploadSection, setShowUploadSection] = useState(true);
	const [documents, setDocuments] = useState([]);
	const [formData, setFormData] = useState({ file: null, documentType: "", description: "" });

	const { mutate: deleteDocumentMutation, isPending: deleting } = useDeleteDocument({
		onSuccess: () => {
			setSelectedDocId(null);
			toast.success("Document deleted successfully.");
		},
		onError: (error) => {
			setSelectedDocId(null);
			toast.error(error.response.data.message);
		},
	});

	const { mutate: updateSmartProfile } = useUpdateSmartProfile({
		onSuccess: async (data) => {
			await FileUploadService.uploadResumeFileToBucketWithFetchAPI(
				{
					uploadUrl: data.uploadUrl,
					file: formData.file,
					contentType: data["content_type"],
				},
				() => {
					setLoading(false);
					setFormData({ file: null, documentType: "", description: "" });
					toast.success("SmartProfile updated successfully");
				},
				(error) => {
					setLoading(false);
					toast.error(error.response.data.message);
				},
			);
		},
		onError: (error) => {
			setLoading(false);
			toast.error(error.response.data.message);
		},
	});

	const deleteDocument = () => {
		const payload = { ...deleteDoc, dspId: smartProfile.dsps[0].dsp_id };
		setSelectedDocId(deleteDoc.doc_id);
		deleteDocumentMutation(payload);
		setShowDeleteModal(false);
	};

	function handleSubmit() {

		// Reset errors
		setErrors(initialErrorsState);
		let formHasErrors = false;

		//Form Validation
		if (formData.documentType === "") {
			formHasErrors = true;
			setErrors((prevState) => {
				return {
					...prevState,
					documentType: "Please Select a document type.",
				};
			});
		}

		if (formData.description === "") {
			formHasErrors = true;
			setErrors((prevState) => {
				return {
					...prevState,
					description: "Please fill in document description",
				};
			});
		}

		if (formData.file === null) {
			formHasErrors = true;
			setErrors((prevState) => {
				return {
					...prevState,
					file: "Please upload a document file.",
				};
			});
		}

		if (formHasErrors) return;

		//Submitting form data to the API
		setLoading(true);

		generateFileHash(formData.file)
			.then((fileHash) => {
				const payload = {
					dspId: smartProfile.dsps[0].dsp_id,
					dsp_title: formData.file?.name,
					dsp_description: formData.description,
					dsp_doc_type: getDspDocType(formData.documentType),
					doc_sub_type: formData.documentType,
					file_name: formData.file?.name,
					file_hash: fileHash,
				};

				updateSmartProfile(payload);
			})
			.catch((err) => {
				setLoading(false);
				return toast.error("Error generating file hash:", err);
			});
	}

	function getDspDocType(documentType) {
		let docType;

		switch (documentType) {
			case docSubTypes.RESUME.name:
				docType = dspDocTypes.RESUME;
				break;
			case docSubTypes.META_DATA.name:
				docType = dspDocTypes.META_DATA;
				break;
			case docSubTypes.CERTIFICATE.name:
			case docSubTypes.CREDENTIAL.name:
			case docSubTypes.PORTFOLIO.name:
				docType = dspDocTypes.GENERAL_DOC;
				break;
			case docSubTypes.COVER_LETTER.name:
				docType = dspDocTypes.DSP_DOC;
				break;
			default:
				docType = dspDocTypes.DSP_DOC;
		}

		return docType;
	}

	useEffect(() => {
		const generalDocs = smartProfile.general_docs || [];
		const dsp = smartProfile.dsps.find((dsp) => dsp.dsp_id === selectedDSPId);

		if (!dsp) {
			if (generalDocs.length > 0) setShowUploadSection(false);
			setDocuments(generalDocs);
		}

		const dspDocs = dsp?.dsp_docs || [];
		const _docs = [...generalDocs, ...dspDocs];

		if (_docs.length > 0) setShowUploadSection(false);

		if (_docs.length === 0) setShowUploadSection(true);

		return setDocuments(_docs);
	}, [selectedDSPId, smartProfile]);

	return (
		<div className="w-full mt-8">
			<h2 className="text-xl font-bold mb-4 font-plus-jakarta text-gray-800">Documents</h2>
			<Card className="border border-gray-200">
				<CardContent className="p-6">
					{/* Upload Section */}
					<div
						className={`upload-section max-h-0 overflow-hidden transition-all duration-700 ease-in-out ${showUploadSection ? "max-h-screen" : ""}`}
					>
						<div className="mb-6 pl-1 pr-1">
							<h3 className="text-base font-semibold font-inter text-gray-900 mb-4">Upload New Document</h3>
							<div className="space-y-4">
								<div className>
									<Label htmlFor="document-type" className="text-sm font-medium text-gray-700 mb-1.5 block">
										Document Type
									</Label>
									<Select
										value={formData.documentType}
										onValueChange={(val) => {
											setFormData((prev) => {
												return { ...prev, documentType: val };
											});
										}}
									>
										<SelectTrigger id="document-type" className="h-[36px]">
											<SelectValue placeholder="Select document type" />
										</SelectTrigger>
										<SelectContent>
											{Object.values(docSubTypes)
												.map((item) => item.name)
												.map((type) => (
													<SelectItem key={type} value={type}>
														{type}
													</SelectItem>
												))}
										</SelectContent>
									</Select>
									<div className="text-red-500">{ errors.documentType }</div>
								</div>
								<div>
									<Label htmlFor="document-type" className="text-sm font-medium text-gray-700 mb-1.5 block">
										Document Description
									</Label>
									<input
										type="text"
										className="w-full text-sm placeholder:text-sm text-gray-800 border border-gray-300 rounded-md px-3 py-2 outline-none focus:ring-0"
										placeholder="Enter description"
										value={formData.description}
										onChange={(e) => {
											setFormData((prev) => {
												return { ...prev, description: e.target.value };
											});
										}}
									/>
									<div className="text-red-500">{ errors.description }</div>
								</div>
								<FileUpload
									processDataOfUploadedFile={(file) => {
										setFormData((prev) => {
											return { ...prev, file: file };
										});
									}}
									removeFile={() => {
										setFormData((prev) => {
											return { ...prev, file: null };
										});
									}}
									fileName={formData?.file?.name || ""}
									fileError={errors.file}
									setFileError={(error) => setErrors({ ...errors, file: error })}
									multiple={false}
									supportedFiles={[
										{ name: "PDF", extension: ".pdf", mimeType: "application/pdf" },
										{ name: "DOC", extension: ".doc", mimeType: "application/msword" },
										{
											name: "DOCX",
											extension: ".docx",
											mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
										},
									]}
									disablFileInput={false}
								/>
							</div>
							<div className="flex justify-end mt-4">
								<Button className="whitespace-nowrap" onClick={handleSubmit}>
									{loading ? <>Submitting...</> : "Submit"}
								</Button>
							</div>
						</div>

						{/* Divider */}
						<div className="border-t border-gray-200 mb-6" />
					</div>

					{/* Documents List */}
					<div className="pr-1 pr-1">
						<div className="mb-4 flex justify-between">
							<h3 className="text-base font-semibold font-inter text-gray-900">My Documents</h3>
							{documents.length > 0 && (
								<h2
									className="text-sm font-bold font-inter mb-4 text-blue-500 cursor-pointer"
									onClick={() => setShowUploadSection(!showUploadSection)}
								>
									<span className={`fa ${showUploadSection ? "fa-close" : "fa-plus"} mr-2`} />
									{showUploadSection ? "Close" : "Add"}
								</h2>
							)}
						</div>

						<div className="space-y-2">
							{documents.length === 0 ? (
								<Alert>
									<HelpCircle className="h-4 w-4" />
									<AlertTitle>No documents</AlertTitle>
									<AlertDescription>
										You haven&apos;t uploaded any documents yet. Use the form above to add your first document.
									</AlertDescription>
								</Alert>
							) : (
								documents.map((doc) => (
									<div
										key={doc.doc_id}
										className="flex items-center justify-between p-3.5 bg-gray-50 rounded-lg transition-colors hover:bg-gray-100"
									>
										<div className="flex items-center gap-3">
											<div className="p-2 bg-primary-50 rounded-md">
												<FileIcon className="w-4 h-4 text-primary-700" />
											</div>
											<div>
												<h4 className="text-[14px] font-medium text-gray-900 font-inter mb-0.5">{doc.file_name}</h4>
												<div className="flex items-center gap-3">
													<span className="text-xs text-gray-600">Uploaded on 2024-11-01</span>
													<span className="text-xs text-gray-600">892 KB</span>
												</div>
											</div>
										</div>

										<div className="flex items-center gap-6">
											<Badge variant="secondary" className="text-xs font-medium bg-gray-100 text-gray-700">
												{doc.doc_sub_type}
											</Badge>
											<div className="flex items-center gap-2">
												<Button variant="ghost" size="sm" title="Download" className="h-8 w-8 p-0 hover:bg-gray-100">
													<DownloadIcon className="w-4 h-4 text-gray-600" />
													<span className="sr-only">Download</span>
												</Button>
												{deleting && selectedDocId === doc.doc_id && (
													<div className="ignore-focus-behavior inline-flex items-center justify-center focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background rounded-md h-8 w-8 p-0">
														<Spinner />
													</div>
												)}
												{(!deleting || (deleting && selectedDocId !== doc.doc_id)) && (
													<Button
														variant="ghost"
														size="sm"
														title="Delete"
														disabled={deleting && selectedDocId !== doc.doc_id}
														className="h-8 w-8 p-0 hover:bg-red-50 hover:text-red-600"
														onClick={() => {
															setDeleteDoc(doc);
															setShowDeleteModal(true);
														}}
													>
														<Trash className="w-4 h-4" />
														<span className="sr-only">Delete</span>
													</Button>
												)}
											</div>
										</div>
									</div>
								))
							)}
						</div>
					</div>
				</CardContent>
			</Card>
			<Modal
				isOpen={showDeleteModal}
				onClose={() => setShowDeleteModal(false)}
				title="Delete document confirmation"
				secondaryAction={{
					label: "Cancel",
					onClick: () => setShowDeleteModal(false),
				}}
				primaryAction={{
					label: "Delete",
					onClick: () => deleteDocument(),
				}}
			>
				<div className="overflow-y-auto">
					<div className="">
						<div className="pb-3 max-h-[calc(100vh-70px)] overflow-y-auto pr-2">
							<div className="space-y-3 mb-3">Are you Sure you want to delete this document?</div>
						</div>
					</div>
				</div>
			</Modal>
		</div>
	);
}
