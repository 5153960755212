import React from "react";

import { cn } from "../../../handlers/cn.utils";

function Dropdown({ label, data, onSelect, selectedOption }) {
	const [open, setOpen] = React.useState(false);

	const buttonRef = React.useRef();

	const buttonWidth = buttonRef.current?.offsetWidth;

	const dropdownRef = React.useRef();

	React.useEffect(() => {
		const handleClick = (e) => {
			if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
				setOpen(false);
			}
		};

		document.addEventListener("mousedown", handleClick);

		return () => {
			document.removeEventListener("mousedown", handleClick);
		};
	}, []);

	return (
		<div className="relative w-full">
			<button
				type="button"
				role="combobox"
				dir="ltr"
				onClick={() => setOpen(!open)}
				ref={buttonRef}
				className={cn(
					"flex items-center text-nowrap text-left justify-between !rounded border bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 w-full md:w-[180px] h-9 !border-gray-200",
					{
						"outline-none ring-2 ring-primary-500 ring-offset-2": open,
					},
				)}
			>
				{selectedOption === "" ? label : selectedOption}

				<i className="fa-solid fa-chevron-down text-gray-400" />
			</button>

			{open && (
				<div
					className="absolute bg-white !shadow !px-2 !py-1 !rounded-sm z-50 mt-2"
					style={{
						width: buttonWidth,
						maxHeight: "300px",
						overflowY: "auto",
						overflowX: "hidden",
					}}
					ref={dropdownRef}
				>
					{data.map((item, index) => (
						<div
							key={index}
							className="flex cursor-pointer items-center !rounded p-2 hover:bg-gray-100/80"
							onClick={() => {
								onSelect(item);
								setOpen(false);
							}}
						>
							<span>
								<i
									className={cn("fa-solid fa-circle-check text-sm text-black !mr-2", {
										"text-transparent": selectedOption !== item,
									})}
								/>
							</span>
							<span className="text-left text-sm">{item}</span>
						</div>
					))}
				</div>
			)}
		</div>
	);
}

export default Dropdown;
