import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../../context/Auth/AuthContext";
import SigninForm from "../Auth/SigninScreen/SigninForm";

function ExpertHelpLogin() {
	const navigate = useNavigate();

	const { userData } = useAuth();

	useEffect(() => {
		if (userData) {
			navigate("/expert-help/personal-info");
		}
	}, []);
	return (
		<div>
			<h1 className="fs-28 text-gray-950 font-weight-bold">Log in</h1>
			<p className="text-gray-700 font-weight-semi mb-4">Log in to your account to match with experts.</p>

			<SigninForm />
		</div>
	);
}

export default ExpertHelpLogin;
