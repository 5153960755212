import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "sonner";

import ExpertResumesLayout from "../../../components/ExpertResumesLayout/ExpertResumesLayout";
import { getSettingssRoutes } from "../../../handlers/navigationItems";
import { ResumeDataService } from "../../../services/create-resume/resume-data";
import Profile from "./Profile/Profile";

function Settings() {
	const dispatch = useDispatch();
	const resumeDataService = new ResumeDataService();

	const data = useSelector((state) => state.SettingsReducer);

	const submitGeneral = (data) => {
		dispatch({
			type: "EDIT_GENERAL",
			payload: data,
		});
	};

	const onSuccessSettings = (response) => {
		const general = { ...data.general, email: response.data.emailAddress };

		dispatch({ type: "EDIT_ GENERAL", payload: general });
	};

	const onErrorSettings = () => {
		toast.error("Something went wrong, please try again");
		window.location.replace("/signin");
	};

	useEffect(() => {
		resumeDataService.getUser(onSuccessSettings, onErrorSettings);
	}, []);

	// const submitComunications = (data) => {
	// 	dispatch({
	// 		type: "EDIT_COMMUNICATION",
	// 		payload: data,
	// 	});
	// };

	return (
		<ExpertResumesLayout navItems={getSettingssRoutes()} headingTitle="Profile" showExpertDetails>
			{/* <MyResumesHeader /> */}
			<div className="my-resumes-section">
				<div className="container">
					<Profile data={data.general} submitData={submitGeneral} />
				</div>
			</div>
		</ExpertResumesLayout>
	);
}

export default Settings;
