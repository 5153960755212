import dayjs from "dayjs";
import React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";

import ExpertResumesLayout from "../../../components/ExpertResumesLayout/ExpertResumesLayout";
import Loader from "../../../components/Loader";
import EllipsisPagination from "../../../components/UIComponents/EllipsisPagination";
import { getSettingssRoutes } from "../../../handlers/navigationItems";
import { formatAmount } from "../../../handlers/utilityFunctions";
import useIsLoggedIn from "../../../hooks/use-IsLoggedIn";
import { ResumeDataService } from "../../../services/create-resume/resume-data";

function PendingPayments() {
	const { firstName } = JSON.parse(localStorage.getItem("profileData"))
		? JSON.parse(localStorage.getItem("profileData"))
		: { firstName: "" };
	const navigate = useNavigate();
	const [pendingPayments, setPendingPayments] = React.useState([]);
	const [accountId, setAccountId] = React.useState("");
	const [settingsLoading, setSettingsLoading] = React.useState(true);
	const [pendingPaymentsLoading, setPendingPaymentsLoading] = React.useState(true);
	const [page, setPage] = React.useState(1);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const resumeDataService = React.useMemo(() => new ResumeDataService(), []);
	const isLoggedIn = useIsLoggedIn();
	const totalPages = Math.ceil(pendingPayments?.length / rowsPerPage);
	const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);

	React.useEffect(() => {
		const handleResize = () => {
			setScreenWidth(window.innerWidth);
		};
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, [screenWidth]);

	const onSuccessSettings = React.useCallback(
		(response) => {
			setSettingsLoading(false);
			setAccountId(response.data._id);

			resumeDataService.getPendingPayments(response.data._id, onSuccessPayment, onErrorPayment);
		},
		[resumeDataService],
	);

	const handlePageChange = (pageNumber) => {
		setPage(pageNumber);
	};

	if (!isLoggedIn) {
		window.location.replace("/signin");
	}

	const onErrorSettings = React.useCallback(() => {
		setSettingsLoading(false);
		window.location.replace("/signin");
	}, []);

	const onSuccessPayment = (response) => {
		setPendingPaymentsLoading(false);
		setPendingPayments(response.data);
	};

	const onErrorPayment = () => {
		setPendingPaymentsLoading(false);
		toast.error("Something went wrong, please try again");
	};

	React.useEffect(() => {
		resumeDataService.getUser(onSuccessSettings, onErrorSettings);
	}, [resumeDataService, onSuccessSettings, onErrorSettings]);

	return (
		<ExpertResumesLayout navItems={getSettingssRoutes()} headingTitle="Pending Payments" showExpertDetails>
			<div className="my-resumes-section pt-3">
				{!settingsLoading && !pendingPaymentsLoading && (
					<div className="container">
						<div className="mb-4">
							<div className="d-flex">
								<div className="ml-auto">
									<div className="d-flex justify-content-end justify-content-md-center flex-wrap">
										<button className="bg-grey border-0 rounded-sm px-3 py-2 mr-0 mr-md-3">Payment Settings</button>
										<div className="dropdown">
											<button
												className="bg-white border border-secondary rounded-sm px-3 py-2 dropdown-toggle"
												type="button"
												id="PaymentSettingDropdown"
												data-toggle="dropdown"
												aria-haspopup="true"
												aria-expanded="false"
											>
												{firstName ? `${firstName} (${accountId})` : ""}
											</button>
											<div className="dropdown-menu" aria-labelledby="PaymentSettingDropdown">
												<div className="dropdown-item">{firstName ? `${firstName} ${accountId}` : ""}</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						{screenWidth > 768 ? (
							<div className="bg-white rounded-sm shadow">
								<div className="table-responsive">
									<table className="table table-hover">
										<thead>
											<tr>
												<th scope="col">Transaction</th>
												<th scope="col">Created Date</th>
												<th scope="col">Amount(TZS)</th>
												<th scope="col">Payment Method</th>
												<th scope="col">Payment Status</th>
												<th scope="col">Action</th>
											</tr>
										</thead>
										<tbody>
											{!pendingPaymentsLoading && pendingPayments.length === 0 ? (
												<tr>
													<th colSpan={6} className="text-center">
														No Pending Payments!
													</th>
												</tr>
											) : (
												pendingPayments
													.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage)
													.map((item, index) => (
														<tr key={index}>
															<td>
																<a href={`/payments?order=${item._id}`} className="text-primary font-weight-bold">
																	{item._id}
																</a>
																<br />
																<p className="text-dark">{item.packageDetail}</p>
															</td>
															<td>{dayjs(item.createdAt).format("MMM DD, YYYY")}</td>
															<td>{item.amount && formatAmount(item.amount)}</td>
															<td>
																<span className="d-flex">
																	<span className="fa fa-mobile-screen text-primary fs-20 mr-1 py-1" />
																	<span className="text-dark fs-15 px-2 py-1">{item.phoneNumber}</span>
																</span>
															</td>
															<td>
																{item.paymentStatus === "COMPLETE" && (
																	<span className="text-success fs-12 px-2 py-1">Paid</span>
																)}
																{item.paymentStatus === "PENDING" && (
																	<span className="lh-1">
																		<i className="text-warning fas fa-circle-half-stroke fs-20 mr-1 py-1" />
																		<span className="text-dark fs-15 px-2 py-1">Pending</span>
																	</span>
																)}
																{item.paymentStatus === "CANCELLED" && (
																	<span className="d-flex lh-1">
																		<i className="text-danger fas fa-circle fs-20 mr-1 py-1" />
																		<span className="text-dark fs-15 px-2 py-1">Cancelled</span>
																	</span>
																)}
															</td>
															<td>
																<button
																	className="btn btn-primary"
																	onClick={() => navigate(`/payments?order=${item._id}`)}
																>
																	Pay Now
																</button>
															</td>
														</tr>
													))
											)}
										</tbody>
									</table>
								</div>
								{pendingPayments.length > 0 && (
									<div className="w-100 px-2">
										<EllipsisPagination
											totalPages={totalPages}
											currentPage={page}
											onPageChange={handlePageChange}
											rowsPerPage={rowsPerPage}
											setRowsPerPage={setRowsPerPage}
											activePage={page}
											length={pendingPayments.length}
											dataName="Pending Payments"
										/>
									</div>
								)}
								{pendingPaymentsLoading && <Loader />}
							</div>
						) : (
							<>
								{!pendingPaymentsLoading && pendingPayments.length === 0 ? (
									<div>
										<p className="h4 text-center">No Pending Payments!</p>
									</div>
								) : (
									<>
										{pendingPayments
											.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage)
											.map((item, index) => {
												return (
													<div key={index} className="card w-100 mb-3">
														<div className="card-body">
															<div>
																<a href={`/payments?order=${item._id}`} className="text-primary font-weight-bold">
																	{item._id}
																</a>
																<br />
																<p className="text-dark">{item.packageDetail}</p>
															</div>
															<p className="fs-15">
																<strong>Amount(TZS): </strong>
																{item.amount && formatAmount(item.amount)}
															</p>
															<p>
																<strong>Created Date: </strong>
																{dayjs(item.createdAt).format("MMM DD, YYYY")}
															</p>
															<div className="d-flex align-items-center">
																<span>
																	<span className="fa fa-mobile-screen text-primary fs-20 mr-2" />
																	<span className="text-dark fs-15 px-2">{item.phoneNumber}</span>
																</span>
																<div>
																	{item.paymentStatus === "COMPLETE" && (
																		<span className="text-success fs-12 px-2 py-1">Paid</span>
																	)}
																	{item.paymentStatus === "PENDING" && (
																		<>
																			<i className="text-warning fas fa-circle-half-stroke fs-20 pr-2 py-1" />
																			<span className="text-dark fs-15 px-2 py-1">Pending</span>
																		</>
																	)}
																</div>
															</div>
															<button
																className="btn btn-primary mt-2 rounded btn-block"
																onClick={() => navigate(`/payments?order=${item._id}`)}
															>
																Complete Payment
															</button>
														</div>
													</div>
												);
											})}
										{pendingPayments.length > 0 && (
											<div className="w-100 px-2">
												<EllipsisPagination
													totalPages={totalPages}
													currentPage={page}
													onPageChange={handlePageChange}
													rowsPerPage={rowsPerPage}
													setRowsPerPage={setRowsPerPage}
													activePage={page}
													length={pendingPayments.length}
													dataName="Pending Payments"
												/>
											</div>
										)}
									</>
								)}
							</>
						)}
					</div>
				)}
				{(settingsLoading || pendingPaymentsLoading) && <Loader />}
			</div>
		</ExpertResumesLayout>
	);
}

export default PendingPayments;
