import React, { useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

import { Reference } from "../../../models/resume/reference";
import { StaticValue } from "../../../seed/constants";
import { ResumeDataService } from "../../../services/create-resume/resume-data";

const ReferenceSummaryBox = ({ item }) => {
	const [cookies] = useCookies(["resume"]);
	const navigate = useNavigate();

	const refInfoHandler = () => {
		navigate("/resume/reference-information", {
			state: {
				referenceId: item.id,
			},
		});
	};

	function onSuccess() {
		navigate(0);
	}

	function onError() {
		//TODO: handle error
	}

	const staticValue = new StaticValue();
	const staticValues = staticValue.getDefaultValues();
	let resumeId = cookies[staticValues.localStorageResumeId];

	const resumeDataService = new ResumeDataService();

	function deleteReference() {
		const address = item.address.split(",");

		const payload = {
			city: address[0],
			region: address[1],
			country: address[2],
			name: item.name,
			email: item.email,
			designation: item.designation,
			workPlace: item.workPlace,
			phoneNumber: item.phoneNumber,
		};

		const refInfoPayload = new Reference(payload);
		const refReq = refInfoPayload.getReferenceRequest();

		resumeDataService.patchResumeLedger(
			resumeId,
			{ ...refReq, referenceId: item.id },
			"REFERENCE",
			onSuccess,
			onError,
			"DELETE",
		);
	}

	const [showDeleteWarning, setShowDeleteWarning] = useState(false);

	return (
		<>
			<div className="summary-box border d-flex">
				<div className="row m-0 w-100">
					<div className="col-md-8 p-0">
						<p className="font-weight-semi text-dark fs-16 m-0">{item.name}</p>
						<p>
							{item.designation}, {item.workPlace}
						</p>
						<p>{item.address}</p>
						<p>{item.phoneNumber}</p>
						<p> {item.email}</p>
					</div>
					<div className="col-md-4 p-0">
						<div className="d-flex align-items-center justify-content-end">
							<span onClick={refInfoHandler} className="border light-button mr-2 cursor-pointer">
								<i className="fa-regular fa-pen-to-square" /> Edit
							</span>
							<span onClick={deleteReference} className="btn border rounded fs-16">
								<span className="bi bi-trash text-danger" />
							</span>
						</div>
					</div>
				</div>
			</div>
			{showDeleteWarning && (
				<div
					className="modal fade"
					style={{
						display: "grid",
						opacity: "1",
						backgroundColor: "rgb(0 0 0 / 60%)",
						overflow: "visible",
						placeItems: "center",
					}}
				>
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title tr-title mb-0">You are about to delete this item</h5>
								<button
									type="button"
									className="close"
									data-dismiss="modal"
									aria-label="Close"
									onClick={() => setShowDeleteWarning(false)}
								>
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body pb-5">
								<p>Are you sure you want to delete this item?</p>

								<div className="d-flex justify-content-end">
									<button
										type="button"
										className="btn btn-secondary mr-2"
										data-dismiss="modal"
										onClick={() => setShowDeleteWarning(false)}
									>
										Cancel
									</button>

									<button
										type="button"
										className="btn btn-primary"
										onClick={() => {
											deleteReference();
											setShowDeleteWarning(false);
										}}
									>
										Delete
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default ReferenceSummaryBox;
