import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";

import { ChevronRightIcon, Tick } from "../../../assets/icons/interface/interface";
import { Label } from "../../../components/Label/Label";
import { docSubTypes, dspDocTypes } from "../../../handlers/enums";
import { generateFileHash } from "../../../handlers/utilityFunctions";
import { useCreateSmartProfile } from "../../../services/create-resume/queries";
import FileUploadService from "../../../services/fileUploadService";
import { Button } from "../../JobsPage/components/Button";
import FileUpload from "./FileUpload";

const initialErrorsState = { title: null, description: null, file: null };

function UploadResumeModal({ onClose }) {
	const navigate = useNavigate();
	const [errors, setErrors] = useState(initialErrorsState);
	const [loading, setLoading] = useState(false);
	const [formData, setFormData] = useState({ title: "", description: "", file: null });

	const { mutate: createSmartProfile } = useCreateSmartProfile({
		onSuccess: async (data) => {
			const uploadDetails = data.upload_info;

			await FileUploadService.uploadResumeFileToBucketWithFetchAPI(
				{
					uploadUrl: uploadDetails.uploadUrl,
					file: formData.file,
					contentType: uploadDetails["content_type"],
				},
				() => {
					onClose();
					setLoading(false);
					toast.success("SmartProfile created successfully");
				},
				(error) => {
					setLoading(false);
					toast.error(error.response.data.message);
				},
			);
		},
		onError: (error) => {
			setLoading(false);
			toast.error(error.response.data.message);
		},
	});

	function handleSubmit() {
		// Reset errors
		setErrors(initialErrorsState);
		let formHasErrors = false;

		//Form Validation
		if (formData.title === "") {
			formHasErrors = true;
			setErrors((prevState) => {
				return {
					...prevState,
					title: "Please Select a document type.",
				};
			});
		}

		if (formData.description === "") {
			formHasErrors = true;
			setErrors((prevState) => {
				return {
					...prevState,
					description: "Please fill in document description",
				};
			});
		}

		if (formData.file === null) {
			formHasErrors = true;
			setErrors((prevState) => {
				return {
					...prevState,
					file: "Please upload a document file.",
				};
			});
		}

		if (formHasErrors) return;

		//Submitting form data to the API
		setLoading(true);

		generateFileHash(formData.file)
			.then((fileHash) => {
				const payload = {
					dsp_title: formData.title,
					dsp_description: formData.description,
					dsp_doc_type: dspDocTypes.RESUME,
					doc_sub_type: docSubTypes.RESUME.name,
					file_name: formData.file?.name,
					file_hash: fileHash,
				};

				createSmartProfile(payload);
			})
			.catch((error) => {
				setLoading(false);
				return toast.error("Error generating file hash:", error);
			});
	}

	return (
		<div className="space-y-8 max-h-[calc(100vh-150px)] overflow-y-auto pr-2">
			<div className="flex flex-col md:flex-row leading-normal gap-6">
				<div className="w-full lg:w-1/2 space-y-8">
					<div className="text-[16px] font-[400] text-[#5C5C5C]">
						Create your Dproz Smart Profile (DSP) for instant visibility. Employers find you faster, and you unlock
						One-Click Apply for effortless job applications.
					</div>
					<div className="space-y-4">
						<div>
							<Label htmlFor="document-type" className="text-sm font-medium text-gray-700 mb-1.5 block">
								Title
							</Label>
							<input
								type="text"
								className="w-full text-sm placeholder:text-sm text-gray-800 border border-gray-300 rounded-md px-3 py-2 outline-none focus:ring-0"
								placeholder="Enter title"
								value={formData.title}
								onChange={(e) => {
									setFormData((prev) => {
										return { ...prev, title: e.target.value };
									});
								}}
							/>
							<div className="text-red-500">{ errors.title }</div>
						</div>
						<div>
							<Label htmlFor="document-type" className="text-sm font-medium text-gray-700 mb-1.5 block">
								Document Description
							</Label>
							<input
								type="text"
								className="w-full text-sm placeholder:text-sm text-gray-800 border border-gray-300 rounded-md px-3 py-2 outline-none focus:ring-0"
								placeholder="Enter description"
								value={formData.description}
								onChange={(e) => {
									setFormData((prev) => {
										return { ...prev, description: e.target.value };
									});
								}}
							/>
							<div className="text-red-500">{ errors.description }</div>
						</div>
						<FileUpload
							processDataOfUploadedFile={(file) => {
								setFormData((prev) => {
									return { ...prev, file: file };
								});
							}}
							removeFile={() => {
								setFormData((prev) => {
									return { ...prev, file: null };
								});
							}}
							fileName={formData?.file?.name || ""}
							fileError={errors.file}
							setFileError={(error) => setErrors({ ...errors, file: error })}
							multiple={false}
							supportedFiles={[
								{ name: "PDF", extension: ".pdf", mimeType: "application/pdf" }
							]}
							disablFileInput={false}
						/>
					</div>
					<div className="flex justify-end">
						<Button className="whitespace-nowrap" onClick={handleSubmit}>
							{loading ? <>Submitting...</> : "Submit"}
						</Button>
					</div>
				</div>
				<div className="w-full lg:w-1/2 bg-gray-50">
					<div className="bg-primary-100 rounded-xl px-6 py-7 flex flex-col md:flex-row items-center w-full justify-between gap-2">
						<div className="flex flex-col md:flex-row items-center gap-4">
							<img src="/images/resume-cta-1.png" alt="" />
							<div className="">
								<h3 className="text-[24px] font-[700] text-gray-950">No resume? No problem. Create one in minutes.</h3>
								<p className="text-[16px] font-[400] text-gray-950">
									Craft a job-winning resume effortlessly with Dproz’s easy to-to-use tool.
								</p>
							</div>
						</div>
						<Button
							className="text-nowrap"
							onClick={() => {
								navigate("/resume/select-experience");
							}}
						>
							Start Here
						</Button>
					</div>
					<div className="p-[12px] mt-[48px] space-y-[8px] pb-12">
						<div>
							<div className="text-[20px] font-[700] text-[#161616]">Want to Stand Out? Master Your Resume!</div>
							<div className="text-[16px] font-[400] text-[#495057]">
								Want to improve your resume? Learn how to create a standout resume that catches employers&apos;
								attention!
							</div>
						</div>
						<div className="p-[16px] space-y-[8px]">
							<div className="text-[16px] font-[600] text-[#3E424E]">Here are a few tips.</div>
							<div className="flex items-center gap-[8px] text-[16px] font-[400] text-[#3E424E]">
								<Tick className="w-[16px] h-[16px]" />
								Use a clean, professional layout with consistent formatting throughout the document.
							</div>
							<div className="flex items-center gap-[8px] text-[16px] font-[400] text-[#3E424E]">
								<Tick className="w-[16px] h-[16px]" />
								Use bullet points and short paragraphs to make information easy to scan.
							</div>
							<div className="flex items-center gap-[8px] text-[16px] font-[400] text-[#3E424E]">
								<Tick className="w-[16px] h-[16px]" />
								Focus on the most relevant and recent experiences that showcase your qualifications.
							</div>
						</div>
						<Button variant="ghost" className="text-[#2086FF]">
							Learn More
							<ChevronRightIcon className="w-[16px] h-[16px] ml-[4px]" />
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default UploadResumeModal;
