import React, { useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import tips from "../../../../assets/copy/tips";
import ErrorToast from "../../../../components/ErrorToast";
import EditorListItems from "../../../../components/ResumeBuilder/EditorListItems";
import Spinner from "../../../../components/Spinner";
import TextEditor from "../../../../components/TextEditor";
import Tips from "../../../../components/Tips";
import ResumeContext from "../../../../context/ResumeContext";
import { convertArrayToUl } from "../../../../handlers/utilityFunctions";
import useHasChange from "../../../../hooks/use-HasChange";
import { Affiliation } from "../../../../models/resume/affiliation";
import { StaticValue } from "../../../../seed/constants";
import { ResumeDataService } from "../../../../services/create-resume/resume-data";
import affiliationListSuggestions from "./affiliationList";

const Affiliations = () => {
	const [cookies] = useCookies(["resume"]);
	const navigate = useNavigate();
	const { hasChanges } = useHasChange();

	const staticValue = new StaticValue();
	const resumeDataService = new ResumeDataService();
	const staticValues = staticValue.getDefaultValues();
	const [loading, setLoading] = useState(false);
	const [serverErrors, setServerErrors] = useState([]);
	const [affiliationList, setAffiliationList] = useState([...affiliationListSuggestions]);

	const { resumeData, setResumeData } = useContext(ResumeContext);

	//Declaring the affiliations state
	const [affiliations, setAffiliations] = useState(resumeData.extras.affiliations);

	const [userInput, setUserInput] = useState(resumeData.contactInfo.resumeTitle);

	useEffect(() => {
		setAffiliations(resumeData.extras.affiliations);
	}, [resumeData]);

	const addAffiliations = () => {
		const payload = new Affiliation(affiliations);
		const request = payload.getAffiliationRequest();

		const resumeId = cookies[staticValues.localStorageResumeId];

		const section = "AFFILIATION";
		if (hasChanges("AFFILIATION", request)) {
			setLoading(true);
			resumeDataService.patchResumeLedger(resumeId, request, section, successPatchAffiliation, errorPatchAffiliation);
		} else {
			navigate("/resume/extras");
		}
	};

	const dispatch = useDispatch();

	function successPatchAffiliation(data) {
		setLoading(false);
		const resArray = data.data.affiliations.data;

		const affiliationList = convertArrayToUl(resArray);

		//TODO update redux store with whole resume
		dispatch({
			type: "RESUME_DATA",
			payload: data.data,
		});

		setResumeData((prevState) => {
			return {
				...prevState,
				extras: {
					...prevState.extras,
					affiliations: affiliationList,
				},
			};
		});

		navigate("/resume/extras");
	}

	function errorPatchAffiliation(data) {
		setLoading(false);
		const serverResponse = data.response.data;
		Array.isArray(serverResponse.message)
			? setServerErrors((prev) => {
					return [...prev, ...serverResponse.message];
				})
			: setServerErrors((prev) => {
					return [...prev, serverResponse.message];
				});
	}

	useEffect(() => {
		setUserInput(resumeData.contactInfo.resumeTitle);
	}, [resumeData]);

	return (
		<>
			<div className="container min-vh-100">
				<div className="section additional-information">
					{serverErrors.map((item, index) => {
						return <ErrorToast error={item} key={index} />;
					})}
					<div className="common-recommendations-section accomplishments-recommendations-section">
						<div className="row">
							<div className="col-md-6">
								<div className="left">
									<p className="mb-3 text-medium">Add details about your proffssional Affiliations.</p>

									<div className="search mb-4">
										<div className="form-group">
											<div className="input-group">
												<input type="text" className="form-control" placeholder={userInput} />
												<div className="input-group-append">
													<button className="btn btn-primary px-md-5" type="button">
														<span className="fa fa-search" />
													</button>
												</div>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-12">
											<div className="recommendation-topics">
												<EditorListItems
													editorListItems={affiliationList}
													editorContent={affiliations}
													setEditorContent={setAffiliations}
													setEditorListItems={setAffiliationList}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-6 d-flex flex-column mt-4 justify-content-end">
								<div className="editor-side">
									<div className="mb-1 d-flex justify-content-between">
										<div className="" />
										<Tips content={tips.affiliations} />
									</div>
									<div className="editor-wrapper">
										<TextEditor
											value={affiliations}
											setValue={setAffiliations}
											placeholder="Add your affiliations here..."
										/>
									</div>
								</div>
							</div>
							<div className="col-12 mt-4 d-flex align-items-center justify-content-end">
								<button
									onClick={() => {
										navigate("/resume/extras");
									}}
									className="btn btn-dark rounded-pill btn-lg mr-2"
								>
									Cancel
								</button>
								<button onClick={addAffiliations} className="btn btn-primary">
									{loading ? (
										<>
											Loading <Spinner />
										</>
									) : (
										"Continue"
									)}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Affiliations;
