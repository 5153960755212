import React, { useContext } from "react";
import { useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";

import ResumeContext from "../../../context/ResumeContext";
import { dateToMY } from "../../../handlers/utilityFunctions";
import { resumeObjectToContextFormat } from "../../../handlers/valueTransformation";
import { StaticValue } from "../../../seed/constants";
import { ResumeDataService } from "../../../services/create-resume/resume-data";

const SummaryBox = ({ item, index }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { resumeData, setResumeData } = useContext(ResumeContext);

	const details = item.details;

	const dispatchAddExtras = (payload) => {
		dispatch({ type: "ADD_EXTRAS", payload: payload });
	};

	function onSuccess(data) {
		const response = data.data;
		resumeObjectToContextFormat(response, setResumeData, dispatchAddExtras);
		dispatch({ type: "RESUME_DATA", payload: response });
	}

	function onError() {
		//TODO: handle error
		toast.error("Something went wrong");
	}
	const [cookies] = useCookies(["resume"]);

	const staticValue = new StaticValue();
	const staticValues = staticValue.getDefaultValues();
	const resumeId = cookies[staticValues.localStorageResumeId];

	const resumeDataService = new ResumeDataService();

	const deleteWorkHandler = () => {
		const itemToBeDeleted = item;

		const newActiveIndex = resumeData.activeWorkHistoryIndex === 0 ? 0 : resumeData.activeWorkHistoryIndex - 1;

		const filteredWorkDetails = resumeData.workHistory.filter((item) => item !== itemToBeDeleted);
		setResumeData((prevState) => {
			return {
				...prevState,
				workHistory: filteredWorkDetails,
				activeWorkHistoryIndex: newActiveIndex,
			};
		});
		setShowDeleteWarning(false);

		resumeDataService.patchResumeLedger(
			resumeId,
			{
				referenceId: index,
			},
			"EXPERIENCE",
			onSuccess,
			onError,
			"DELETE",
		);
	};

	function edit() {
		navigate("/resume/work-history", {
			state: {
				referenceId: index,
			},
		});
	}

	const [showDeleteWarning, setShowDeleteWarning] = useState(false);

	return (
		<>
			<div className="summary-box font-inter">
				<div className="col-12 p-0">
					<div className="row">
						<div className="col-md-9">
							<div className="mb-3">
								<p className="text-black font-weight-semi fs-16 m-0">
									{item.jobTitle}, {item.employer}
								</p>
								<p className="fs-14">
									{item.city}, {item.region}
									{item.country !== "undefined" && ", " + item.country} • {dateToMY(item.startDate)} -{" "}
									{item.endDate ? dateToMY(item.endDate) : "Present"}
								</p>
							</div>
							<div className="bottom">
								<div
									dangerouslySetInnerHTML={{
										__html: details.length > 250 ? details.substring(0, 200) + "..." : details,
									}}
								/>
							</div>
						</div>
						{/* edit and delete buttons in the work summary */}
						<div className="col-md-3">
							<div className="d-flex justify-content-end align-items-center">
								<span className="border light-button mr-2 cursor-pointer" onClick={edit}>
									<i className="fa-regular fa-pen-to-square" /> Edit
								</span>
								<span onClick={() => setShowDeleteWarning(true)} className="border cursor-pointer light-button mr-2">
									<span className="bi bi-trash text-danger" />
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			{showDeleteWarning && (
				<div
					className="modal fade"
					style={{
						display: "grid",
						opacity: "1",
						backgroundColor: "rgb(0 0 0 / 60%)",
						overflow: "visible",
						placeItems: "center",
					}}
				>
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title tr-title mb-0">You are about to delete this item</h5>
								<button
									type="button"
									className="close"
									data-dismiss="modal"
									aria-label="Close"
									onClick={() => setShowDeleteWarning(false)}
								>
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body pb-5">
								<p>Are you sure you want to delete this item?</p>

								<div className="d-flex justify-content-end">
									<button
										type="button"
										className="btn btn-secondary mr-2"
										data-dismiss="modal"
										onClick={() => setShowDeleteWarning(false)}
									>
										Cancel
									</button>

									<button type="button" className="btn btn-primary" onClick={deleteWorkHandler}>
										Delete
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default SummaryBox;
