import "crypto-js/lib-typedarrays";

import { GoogleLogin } from "@react-oauth/google";
import { AuthenticationDetails, CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { decodeToken } from "react-jwt";
import { Link } from "react-router-dom";
import { toast } from "sonner";

import poolData from "../../../../userpool";
import ErrorToast from "../../../components/ErrorToast";
import Spinner from "../../../components/Spinner";
import { useAuth } from "../../../context/Auth/AuthContext";
import { StaticValue } from "../../../seed/constants";
import LinkedInLogin from "./LinkedInLogin";

const userPool = new CognitoUserPool(poolData);
const staticValue = new StaticValue();
const staticValues = staticValue.getDefaultValues();

const HOME_PAGES = ["/", "/signin"];
const PROFILE = "/profile";

const referrer = document.referrer ? new URL(document.referrer) : null;
const goToOptions = referrer ? `${referrer.pathname}${referrer.search}` : PROFILE;
const goTo = HOME_PAGES.includes(goToOptions) ? PROFILE : goToOptions;

// const referer = new URLSearchParams(window.location.search).get("ref");
// const redirectTo =
// 	(referer && referer.includes("expert-help")) || window.location.pathname.includes("expert-help")
// 		? "/expert-help/personal-info"
// 		: goTo;

function SigninForm() {
	const checkboxRef = React.createRef();

	const [authData, setAuthData] = useState({
		email: "",
		password: "",
		remember_me: false,
	});

	const { setUserData } = useAuth();

	useEffect(() => {
		const remember_me = JSON.parse(localStorage.getItem("remember_me"));

		if (remember_me) {
			setAuthData({
				...authData,
				email: remember_me.email,
				remember_me: remember_me.remember_me,
			});
		}
	}, []);

	const { email, password, remember_me } = authData;
	const [_, setCookie, removeCookie] = useCookies(["Authorization"]);
	const [error, setError] = useState({ email: "", password: "", serverErrors: "" });

	function onChange(event) {
		setAuthData({
			...authData,
			[event.target.name]: event.target.value,
		});
	}
	const [loading, setLoading] = useState(false);
	useEffect(() => {}, [loading]);

	function handleSubmit(event) {
		event.preventDefault();
		setError({
			email: "",
			password: "",
			serverErrors: "",
		});
		setLoading(true);

		if (email.length === 0) {
			setError({ ...error, email: "Email is required" });
			setLoading(false);
			return;
		}

		if (password.length === 0) {
			setError({ ...error, password: "Password is required" });
			setLoading(false);
			return;
		}

		if (remember_me) {
			localStorage.setItem("remember_me", JSON.stringify({ email, remember_me }));
		} else {
			localStorage.removeItem("remember_me");
		}

		let authenticationData = {
			Username: email,
			Password: password,
		};

		let authenticationDetails = new AuthenticationDetails(authenticationData);

		let userData = {
			Username: email,
			Pool: userPool,
		};

		let cognitoUser = new CognitoUser(userData);

		cognitoUser.authenticateUser(authenticationDetails, {
			onSuccess: function (result) {
				let idToken = `CO::${result.getIdToken().getJwtToken()}`;
				const expireDate = new Date();
				expireDate.setDate(expireDate.getDate() + 30);
				setCookie("Authorization", idToken, {
					path: "/",
					expires: expireDate,
				});

				let cognitoUser = userPool.getCurrentUser();

				if (cognitoUser != null) {
					cognitoUser.getSession(function (err, result) {
						if (result) {
							removeCookie(staticValues.localStorageResumeId);
							localStorage.removeItem("resumeList");
							setLoading(false);
							const decodedToken = decodeToken(result.getIdToken().getJwtToken());

							const data = {
								email: decodedToken.email,
								lastName: decodedToken.family_name,
								firstName: decodedToken.given_name,
								picture: decodedToken.picture,
							};

							localStorage.setItem("profileData", JSON.stringify(data));
							setUserData(data);

							window.location.href = goTo;
						}
					});
				}
			},
			onFailure: function (err) {
				if (err.code === "NotAuthorizedException") {
					setError({ ...error, serverErrors: "Incorrect username or password" });
				} else {
					setError({ ...error, serverErrors: "Unable to sign in, Check your email to activate your account." });
				}
				setLoading(false);
			},
		});
	}

	useEffect(() => {}, [error]);

	function onGoogleSignIn(googleUser) {
		if (googleUser.credential) {
			const credential = `GO::${googleUser.credential}`;

			const expireDate = new Date();
			expireDate.setDate(expireDate.getDate() + 30);
			setCookie("Authorization", credential, {
				path: "/",
				expires: expireDate,
			});

			removeCookie(staticValues.localStorageResumeId);
			localStorage.removeItem("resumeList");
			setLoading(false);
			const decodedToken = decodeToken(googleUser.credential);

			const data = {
				...decodeToken(googleUser.credential),
				email: decodedToken.email,
				firstName: decodedToken.given_name,
				lastName: decodedToken.family_name,
				picture: decodedToken.picture,
			};

			localStorage.setItem("profileData", JSON.stringify(data));
			setUserData(data);

			window.location.href = goTo;
		}
	}

	const [showEmailOptions, setShowEmailOptions] = useState(false);

	const buttonRef = useRef();

	const buttonWidth = useMemo(() => {
		if (buttonRef.current) {
			return buttonRef.current.offsetWidth;
		}
		return 0;
	}, [buttonRef.current]);

	return (
		<>
			<div className="mb-4 grid md:grid-cols-2 gap-4">
				<LinkedInLogin />
				<div className="flex items-center justify-center" ref={buttonRef}>
					<GoogleLogin
						onSuccess={onGoogleSignIn}
						onFailure={() => toast.error("Login Failed")}
						size="large"
						width={`${buttonWidth}px`}
						text="continue_with"
						useOneTap
					/>
				</div>
			</div>
			<div className="mb-3 text-center">
				<p className="text-medium font-medium">OR</p>
			</div>
			<div className="">{error.serverErrors !== "" && <ErrorToast error={error.serverErrors} />}</div>
			{showEmailOptions ? (
				<form onSubmit={handleSubmit} className="tr-form">
					<div className="flex flex-col gap-1 mb-4">
						<input
							type="email"
							className="border border-gray-200 h-9 text-sm placeholder:text-sm rounded px-2 py-1 placeholder:text-[#7A7A7A]"
							placeholder="Enter your email address"
							value={email}
							onChange={onChange}
							name="email"
						/>
						{error.email && <p className="text-sm text-error-default">{error.email}</p>}
					</div>
					<div className="flex flex-col gap-1 mb-4">
						<input
							type="password"
							className="border border-gray-200 h-9 text-sm placeholder:text-sm rounded px-2 py-1 placeholder:text-[#7A7A7A]"
							placeholder="Create a password"
							onChange={onChange}
							value={password}
							name="password"
						/>
						{error.password && <p className="text-sm text-error-default">{error.password}</p>}
					</div>
					<div className="flex justify-between mb-4">
						<label className="flex items-center p-0" htmlFor="legalNameToSAcknowledged">
							<input
								type="checkbox"
								ref={checkboxRef}
								name="remember_me"
								value={remember_me}
								checked={remember_me}
								onChange={onChange}
							/>
							<span
								className="checkmark"
								onClick={() => {
									checkboxRef.current.click();
								}}
							/>
							<div className="pl-2">
								<p className="text-base font-medium m-0 text-black">Remember me</p>
							</div>
						</label>
						<div className="forgot-password">
							<Link to="/forgot-password" className="font-medium">
								I forgot password
							</Link>
						</div>
					</div>

					<button
						className="w-full rounded-md bg-primary-700 text-white font-inter text-base py-2 flex items-center justify-center"
						onClick={handleSubmit}
					>
						{loading ? <Spinner /> : "Login"}
					</button>
				</form>
			) : (
				<>
					<button
						className="w-full rounded-md hover:bg-gray-100 border border-gray-500 text-gray-700 font-inter text-base py-2 flex items-center justify-center"
						onClick={() => setShowEmailOptions(true)}
					>
						Continue with Email
					</button>
				</>
			)}
			<div className="text-sm text-[#5C5C5C] mt-4">
				<span>
					Can&#39;t log in?{" "}
					<Link to={"/register?ref=" + window.location.pathname} className="font-semibold text-primary-700">
						Create a New Account
					</Link>
				</span>
			</div>
		</>
	);
}

export default SigninForm;
