import { ChevronDown, ChevronUp } from "lucide-react";
import React, { useState } from "react";

import { cn } from "../../../handlers/cn.utils";
import { sections } from "../../../handlers/enums";

const backgroundColors = ["bg-[#CFE4E4]", "bg-[#E2E3D6]", "bg-[#F5D7C3]", "bg-[#D3E4D9]"];

function DSPSection({ index, data }) {
	const [expanded, setExpanded] = useState(false);

	return (
		<div>
			{data.section === sections.SUMMARY && (
				<>
					<div
						className={cn(
							"flex items-center justify-between h-[52px] p-[14px] rounded-[8px] w-full cursor-pointer",
							backgroundColors[index % backgroundColors.length],
						)}
						onClick={() => {
							setExpanded(!expanded);
						}}
					>
						<span className="text-[16px] font-[600]">{data.title}</span>
						{expanded ? <ChevronUp /> : <ChevronDown />}
					</div>
					{expanded && (
						<>
							<div className="mt-[16px] p-[16px] border rounded-[8px]">
								<div className="text-[14px] text-[#495057] font-[400]">
									{`${data.value.firstName} ${data.value.middleName} ${data.value.lastName} | ${data.value.phoneNumber} 
										| ${data.value.emailAddress} | ${data.value.linkedInProfile} | ${data.value.location}`}
								</div>
							</div>
							{data.value.professionalSummary && (
								<div className="mt-[16px] p-[16px] border rounded-[8px]">
									<div className="text-[14px] text-[#495057] font-[400]">{data.value.professionalSummary}</div>
								</div>
							)}
						</>
					)}
				</>
			)}

			{data.section === sections.SKILLS && data.value.length > 0 && (
				<>
					<div
						className={cn(
							"flex items-center justify-between h-[52px] p-[14px] rounded-[8px] w-full cursor-pointer",
							backgroundColors[index % backgroundColors.length],
						)}
						onClick={() => {
							setExpanded(!expanded);
						}}
					>
						<span className="text-[16px] font-[600]">{data.title}</span>
						{expanded ? <ChevronUp /> : <ChevronDown />}
					</div>
					{expanded && (
						<div className="mt-[16px] p-[16px] border rounded-[8px]">
							<div className="text-[14px] text-[#495057] font-[400]">
								{data.value.map((skill, index) => `${skill} ${index === data.value.length - 1 ? "" : "|"} `)}
							</div>
						</div>
					)}
				</>
			)}

			{data.section === sections.EXPERIENCE && data.value.length > 0 && (
				<>
					<div
						className={cn(
							"flex items-center justify-between h-[52px] p-[14px] rounded-[8px] w-full cursor-pointer",
							backgroundColors[index % backgroundColors.length],
						)}
						onClick={() => {
							setExpanded(!expanded);
						}}
					>
						<span className="text-[16px] font-[600]">{data.title}</span>
						{expanded ? <ChevronUp /> : <ChevronDown />}
					</div>
					{expanded && (
						<>
							{data.value.map((experience, index) => (
								<div className="mt-[16px] p-[16px] border rounded-[8px]" key={index}>
									<div className="text-[14px] text-[#495057] font-[400]">
										<strong> Position: </strong> {`${experience.title} at ${experience.company}`} <br />
										<strong> Duration: </strong> {`${experience.from} at ${experience.to}`} <br />
										<strong> Contributions: </strong> {`${experience.contributions}`} <br />
									</div>
								</div>
							))}
						</>
					)}
				</>
			)}

			{data.section === sections.EDUCATION && data.value.length > 0 && (
				<>
					<div
						className={cn(
							"flex items-center justify-between h-[52px] p-[14px] rounded-[8px] w-full cursor-pointer",
							backgroundColors[index % backgroundColors.length],
						)}
						onClick={() => {
							setExpanded(!expanded);
						}}
					>
						<span className="text-[16px] font-[600]">{data.title}</span>
						{expanded ? <ChevronUp /> : <ChevronDown />}
					</div>
					{expanded && (
						<>
							{data.value.map((education, index) => (
								<div className="mt-[16px] p-[16px] border rounded-[8px]" key={index}>
									<div className="text-[14px] text-[#495057] font-[400]">
										<strong> Institution: </strong> {`${education.institution}`} <br />
										<strong> Degree: </strong> {`${education.degree}`} <br />
										<strong> Completion Year: </strong> {`${education.year}`} <br />
									</div>
								</div>
							))}
						</>
					)}
				</>
			)}

			{data.section === sections.CERTIFICATION && data.value.length > 0 && (
				<>
					<div
						className={cn(
							"flex items-center justify-between h-[52px] p-[14px] rounded-[8px] w-full cursor-pointer",
							backgroundColors[index % backgroundColors.length],
						)}
						onClick={() => {
							setExpanded(!expanded);
						}}
					>
						<span className="text-[16px] font-[600]">{data.title}</span>
						{expanded ? <ChevronUp /> : <ChevronDown />}
					</div>
					{expanded && (
						<>
							{data.value.map((certification, index) => (
								<div className="mt-[16px] p-[16px] border rounded-[8px]" key={index}>
									<div className="text-[14px] text-[#495057] font-[400]">
										<strong> Institution: </strong> {`${certification.issuingOrganization}`} <br />
										<strong> Degree: </strong> {`${certification.name}`} <br />
										<strong> Completion Year: </strong> {`${certification.year}`} <br />
									</div>
								</div>
							))}
						</>
					)}
				</>
			)}

			{data.section === sections.LANGUAGES && data.value.length > 0 && (
				<>
					<div
						className={cn(
							"flex items-center justify-between h-[52px] p-[14px] rounded-[8px] w-full cursor-pointer",
							backgroundColors[index % backgroundColors.length],
						)}
						onClick={() => {
							setExpanded(!expanded);
						}}
					>
						<span className="text-[16px] font-[600]">{data.title}</span>
						{expanded ? <ChevronUp /> : <ChevronDown />}
					</div>
					{expanded && (
						<div className="mt-[16px] p-[16px] border rounded-[8px]">
							<div className="text-[14px] text-[#495057] font-[400]">
								<ul className="list-disc pl-4">
									{data.value.map((language, index) => {
										return <li key={index}>{language}</li>;
									})}
								</ul>
							</div>
						</div>
					)}
				</>
			)}

			{data.section === sections.REFERENCE && data.value.length > 0 && (
				<>
					<div
						className={cn(
							"flex items-center justify-between h-[52px] p-[14px] rounded-[8px] w-full cursor-pointer",
							backgroundColors[index % backgroundColors.length],
						)}
						onClick={() => {
							setExpanded(!expanded);
						}}
					>
						<span className="text-[16px] font-[600]">{data.title}</span>
						{expanded ? <ChevronUp /> : <ChevronDown />}
					</div>
					{expanded && (
						<>
							{data.value.map((reference, index) => (
								<div className="mt-[16px] p-[16px] border rounded-[8px]" key={index}>
									<div className="text-[14px] text-[#495057] font-[400]">
										<strong> Name: </strong> {`${reference.name}`} <br />
										<strong> Position: </strong> {`${reference.position}`} <br />
										<strong> Phone Number: </strong> {`${reference.phoneNumber}`} <br />
										<strong> Email: </strong> {`${reference.emailAddress}`} <br />
									</div>
								</div>
							))}
						</>
					)}
				</>
			)}
		</div>
	);
}

export default DSPSection;
