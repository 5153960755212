import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "sonner";

import Icon from "../../../components/Icon";
import Loader from "../../../components/Loader";
import Feedback from "../../../components/Reviews/FeedBack";
import Spinner from "../../../components/Spinner";
import ResumeContext from "../../../context/ResumeContext";
import { dateToDMY } from "../../../handlers/utilityFunctions";
import useMyResumesUtilities from "../../../hooks/use-MyResumesUtilities";
import useSetResumeIdCookie from "../../../hooks/use-SetResumeIdCookie";
import { ResumeDataService } from "../../../services/create-resume/resume-data";
import Resume from "./Resume";

function Review() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const currentLocation = window.location.href;
	const resumeDataService = new ResumeDataService();
	const { resumeData } = useContext(ResumeContext);
	const [filteredFeedbacks, setFilteredFeedbacks] = useState([]);
	const [reviewRequests, setReviewRequests] = useState([]);
	const [feedbackLoading, setFeedbackLoading] = useState(false);
	const [, setLoading] = useState(false);
	const [pageLoading, setPageLoading] = useState(true);
	const [activeSection, setActiveSection] = useState("");
	const { hasFeedback, openFeedback, feedBacks } = useSelector((state) => state.FeedbackReducers);
	const { setResumeIdCookie } = useSetResumeIdCookie();
	const storedProfile = localStorage.getItem("profileData");
	const [resume, setResume] = useState({});
	const [resumeList, setResumeList] = useState([]);
	const storedResumes = localStorage.getItem("resumeList");
	const { downloadResumeModal, downloadResume, showResumePrintPopup, downloadLoading } = useMyResumesUtilities(resume);
	const [error, setError] = useState("");
	const [sections, setSections] = useState([]);
	const [sectionsChecked, setSectionsChecked] = useState({});

	function onSuccess(response) {
		setLoading(false);
		if ([...response.data].length === 0) {
			setResumeList([]);
		} else {
			setResumeList(response.data);
			localStorage.setItem("resumeList", JSON.stringify(response.data));
		}
	}

	function onError(error) {
		setError(error.message);
		setLoading(false);
	}

	const fetchResumeList = async () => {
		if (storedResumes) {
			setResumeList(JSON.parse(storedResumes));
			setLoading(false);
		} else {
			await resumeDataService.getUserResumes(onSuccess, onError);
		}
	};

	const onSelectResume = (resumeId) => {
		setResumeIdCookie(resumeId);
		window.location.replace("/resume/review");
	};

	const onDownloadResume = () => {
		const _resume = resumeList.find((resume) => resume._id === resumeData.id);
		setResume(_resume);
		downloadResume(_resume.id);
	};

	const dispatchFeedbackState = (name, value) => {
		dispatch({ type: "UPDATE_FEEDBACK", payload: { name, value } });
	};

	const handleCheckboxChange = (event) => {
		const { name, checked } = event.target;
		setSectionsChecked((prevState) => ({
			...prevState,
			[name]: checked,
		}));
	};

	const getFilterFeedbacks = () => {
		const _checkedSections = [];
		for (let sectionChecked in sectionsChecked) {
			if (sectionsChecked[sectionChecked]) {
				_checkedSections.push(sectionChecked);
			}
		}

		const _filteredFeedbacks = feedBacks.filter((feedback) => {
			return _checkedSections.some((_checkedSection) => _checkedSection === feedback.section);
		});

		if (_filteredFeedbacks.length === 0) {
			setFilteredFeedbacks(feedBacks);
		} else {
			setFilteredFeedbacks(_filteredFeedbacks);
		}
	};

	function successGetResumeReviews(response) {
		const _sections = [];
		const _feedBacks = [];
		const _checkedSections = {};
		const feedBackDetails = response.data;

		for (let feedBackDetail in feedBackDetails) {
			feedBackDetails[feedBackDetail].forEach((feedBackDetail) => {
				_feedBacks.push(feedBackDetail);
			});
			_sections.push(feedBackDetail);
			_checkedSections[feedBackDetail] = false;
		}

		setFeedbackLoading(false);
		setFilteredFeedbacks(_feedBacks);
		setSections(_sections);
		setSectionsChecked(_checkedSections);
		dispatchFeedbackState("feedBacks", _feedBacks);
		dispatchFeedbackState("feedBackSearched", true);
	}

	const sectionNavigate = (section) => {
		const anchor = document.querySelector(`#${section}`);
		anchor.scrollIntoView({ behavior: "smooth", block: "center" });
		setActiveSection(section);
	};

	function errorGetResumeReviews() {
		setFeedbackLoading(false);
		toast.error("Something went wrong!");
		dispatchFeedbackState("feedBackSearched", true);
	}

	function getReviewFeedBack(reviewRequestId) {
		setFeedbackLoading(true);
		dispatchFeedbackState("feedBacks", []);
		dispatchFeedbackState("feedBackSearched", false);

		resumeDataService.getResumeFeedback(reviewRequestId, successGetResumeReviews, errorGetResumeReviews);
	}

	useEffect(() => {
		if (resumeData.reviewRequests !== undefined) {
			const _reviewRequests = resumeData.reviewRequests.filter((reviewRequest) => reviewRequest.status === "PUBLISHED");

			if (_reviewRequests.length > 0) {
				dispatchFeedbackState("hasFeedback", true);
				getReviewFeedBack(_reviewRequests[0]._id);

				if (currentLocation.includes("feedback=active")) {
					dispatchFeedbackState("openFeedback", true);

					const topReviewRequest = _reviewRequests[0];
					getReviewFeedBack(topReviewRequest._id);
				}
			}

			if (_reviewRequests.length <= 0) {
				dispatchFeedbackState("hasFeedback", false);
			}

			setReviewRequests(_reviewRequests);

			setPageLoading(false);
		}
	}, [resumeData]);

	useEffect(() => {
		fetchResumeList();
	}, []);

	useEffect(() => {
		getFilterFeedbacks();
	}, [sectionsChecked]);

	return (
		<>
			<header>
				<nav className="navbar navbar-expand-lg">
					<div className="navbar-header">
						<Link className="navbar-brand" to="/">
							<img className="img-fluid" src="/images/logo.png" alt="Logo" />
						</Link>
					</div>
				</nav>
			</header>
			<div className="font-inter mt-5" style={{ paddingLeft: "5.5%", backgroundColor: "#F5F5F5", minHeight: "95vh" }}>
				{/* Download Resume modal*/}
				{showResumePrintPopup && downloadResumeModal()}
				{pageLoading || feedbackLoading ? (
					<div>
						<div className="container-fluid h-100">
							<div className="d-flex p-4 align-center h-75 justify-content-center center-loader">
								<Loader />
							</div>
						</div>
					</div>
				) : (
					<>
						<div className={openFeedback ? "right-sidebar open-right-sidebar min-vh-100" : "right-sidebar min-vh-100"}>
							<div className="d-flex align-items-center justify-content-between feedback-modal-header">
								<h5 className="modal-title tr-title mb-0">Resume Feedbacks</h5>
								<div className="d-flex justify-content-between">
									<div className="dropdown">
										<button
											className="border-0 bg-transparent font-weight-semi dropdown-toggle"
											type="button"
											id="MoreOptions"
											data-toggle="dropdown"
											aria-haspopup="true"
											aria-expanded="false"
										>
											Review Requests
										</button>
										<ul className="dropdown-menu" aria-labelledby="MoreOptions">
											{reviewRequests.length > 0 ? (
												reviewRequests.map((reviewRequest, index) => (
													<li
														key={reviewRequest._id}
														className="dropdown-item cursor-pointer"
														onClick={() => getReviewFeedBack(reviewRequest._id)}
													>
														{`${index + 1}. ${dateToDMY(reviewRequest.createdOn)}`}
													</li>
												))
											) : (
												<li className="text-center font-weight-semi">No Published Request</li>
											)}
										</ul>
									</div>
									<button
										type="button"
										className="close float-end ml-5"
										aria-label="Close"
										onClick={() => dispatchFeedbackState("openFeedback", false)}
									>
										<span aria-hidden="true">&times;</span>
									</button>
								</div>
							</div>
						</div>

						<div className="pr-2">
							{error !== "" && (
								<div className="alert alert-danger" role="alert">
									{error}
								</div>
							)}

							<div className="row">
								<div className={`pt-5 ${filteredFeedbacks.length > 0 ? "col-md-7 scrollable-feedback" : "col-md-7"}`}>
									<div className="row">
										<div className="">
											<div className="resume-review-welcome-text mb-2 text-wrap">
												Welcome, {JSON.parse(storedProfile)?.firstName}
											</div>
											<div className="dropdown align-items-start mb-4">
												<span
													className="border bg-white shadow-sm border-secondary dropdown-toggle px-3 py-2 rounded hide-toggle"
													data-toggle="dropdown"
													aria-expanded="false"
												>
													<div className="d-flex align-items-center">
														<Icon iconName="invoice" size={28} />
														<div className="mx-3 text-wrap">
															<p className="m-0 font-weight-semi">
																{resumeData.journal.resumeTitle
																	? resumeData.journal.resumeTitle
																	: resumeData.resumeTitle}
															</p>
															<p className="m-0 fs-12 font-gray">
																{`Last updated ${dateToDMY(resumeData.journal.lastUpdatedOn)}`}
															</p>
														</div>
														<i className="bi bi-code fs-12 text-secondary rotate-90" />
													</div>
												</span>
												<div
													className="dropdown-menu mt-2 shadow-sm rounded border-0 p-1"
													style={{ minWidth: "316px" }}
												>
													{resumeList.map((resume) => {
														return (
															<span
																className="dropdown-item rounded px-3"
																key={resume._id}
																onClick={() => onSelectResume(resume.id)}
															>
																<p className="m-0 font-weight-semi">
																	{resume.journal.resumeTitle ? resume.journal.resumeTitle : resume.heading.resumeTitle}
																</p>
																<p className="m-0 fs-12 font-gray">
																	{`Last updated ${dateToDMY(resume.journal.lastUpdatedOn)}`}
																</p>
															</span>
														);
													})}
												</div>
											</div>
										</div>
									</div>
									{/* Header and dropdown */}

									<div className="row rounded-md border p-4 bg-white mb-3">
										<div className="col-md-6 p-0">
											<p className="m-0 font-weight-semi fs-medium">
												{resumeData.journal.resumeTitle ? resumeData.journal.resumeTitle : resumeData.resumeTitle}
											</p>
											<p className="m-0 text-secondary fs-small">
												{`Created ${dateToDMY(resumeData.createdOn)} · Last updated ${dateToDMY(
													resumeData.journal.lastUpdatedOn,
												)}`}
											</p>
										</div>
										<div className="resume-options col-md-6 p-0 d-flex justify-content-end align-items-center">
											{/* Edit Button */}
											<button
												type="button"
												className="border border-secondary bg-transparent font-medium fs-14 lh-1 rounded font-inter mr-3"
												onClick={() => navigate("/my-resumes")}
											>
												<i className="fa fa-pencil pr-2" />
												Edit
											</button>

											{/* Close button*/}
											{resumeData.journal.currentStatus === "COMPLETED" ? (
												<button
													type="button"
													className="btn bg-secondary rounded font-inter mr-3"
													data-toggle="modal"
													onClick={() => navigate("/my-resumes")}
												>
													Close
												</button>
											) : null}

											<div className="d-flex">
												{/* Download Resume */}
												{resumeData.journal.currentStatus === "COMPLETED" ? (
													<span
														title="Download"
														data-placement="top"
														data-toggle="modal"
														onClick={() => onDownloadResume()}
														className="d-flex px-2 mr-2 mb-0 flex-column justify-content-center cursor-pointer align-items-center"
													>
														{downloadLoading ? <Spinner /> : <Icon iconName="download" className="mr-2" size={24} />}
													</span>
												) : null}

												{/* Resume Dropdown */}
												<div className="dropdown">
													<button
														className="border-0 bg-transparent d-flex flex-column align-items-center"
														type="button"
														data-toggle="dropdown"
														aria-expanded="false"
													>
														<i className="text-secondary fs-20 fa fa-ellipsis-h" />
													</button>
													<div className="dropdown-menu rounded p-1 border-0 cursor-pointer dropdown-menu-right">
														<span onClick={() => navigate("/my-resumes")} className="dropdown-item px-2">
															Close
														</span>
														<span
															onClick={() =>
																navigate(
																	`/resume/select-template?templateId=${resumeData.journal.templateId}&journalReferenceId=${resumeData.journal._id}`,
																)
															}
															className="dropdown-item px-2"
														>
															Change Template
														</span>
													</div>
												</div>
												{/* dropdown end */}
											</div>
										</div>
										<div className="col-12 p-0 border-top mt-4">
											<Resume
												resumeData={resumeData}
												openFeedback={openFeedback}
												hasFeedback={hasFeedback}
												activeSection={activeSection}
											/>
										</div>
									</div>
								</div>

								{/*View Feedback Sidebar Start*/}
								{filteredFeedbacks.length > 0 ? (
									<div className="col-md-5 scrollable-feedback">
										<div className="pt-5" style={{ maxWidth: "373px" }}>
											<div className="d-flex justify-content-end">
												{/*dropdown start*/}
												<div className="dropdown">
													<button
														className="border border-secondary bg-white d-flex shadow-sm align-items-center rounded font-weight-semi fs-14 lh-1 font-inter"
														style={{
															margin: "19px 0",
														}}
														type="button"
														data-toggle="dropdown"
														aria-expanded="false"
													>
														Filter by Section
														<i className="text-secondary fs-16 ml-2 rotate-90 bi bi-code lh-1" />
													</button>
													<div className="dropdown-menu p-1 border-0 rounded dropdown-menu-right">
														{sections.map((section, index) => {
															return (
																<div className="dropdown-item px-2" key={index}>
																	<label className="form-check d-flex flex-row align-items-center p-0 m-0">
																		<input
																			type="checkbox"
																			name={section}
																			checked={sectionsChecked[section]}
																			onChange={handleCheckboxChange}
																		/>
																		<span className="checkmark top-0" />
																		<div className="ml-4 pl-2">
																			<p className="">
																				{section.toLowerCase().charAt(0).toUpperCase() + section.toLowerCase().slice(1)}
																			</p>
																		</div>
																	</label>
																	<div className="d-flex m-0 align-items-center" />
																</div>
															);
														})}
													</div>
												</div>
												{/*dropdown end*/}
											</div>
											<p className="font-weight-semi">Feedback</p>

											<div className="mt-3">
												{filteredFeedbacks.map((feedback) => (
													<Feedback
														key={feedback._id}
														feedback={feedback}
														id={`sectionHeading${feedback.section}`}
														sectionNavigate={sectionNavigate}
														navigateToSections={true}
													/>
												))}
											</div>
										</div>
									</div>
								) : null}
								{/*View Feedback Sidebar End*/}
							</div>
						</div>
					</>
				)}
			</div>
		</>
	);
}

export default Review;
