import dayjs from "dayjs";
import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "sonner";

import Loader from "../../../components/Loader";
import Tabs from "../../../components/Tabs";
import { cn } from "../../../handlers/cn.utils";
import { ResumeDataService } from "../../../services/create-resume/resume-data";
import JobPostForm from "../../JobPostForm/JobPostForm";

const TABS = [{ name: "Overview", key: "overview" }];
const jobStatus = { draft: "Draft", posted: "Posted" };

function ViewJob({ selectedItem, setShowProfile, fetchData }) {
	const jobService = new ResumeDataService();
	const [currentView, setCurrentView] = useState("overview");
	const [loading, setLoading] = useState(false);
	const [loadingIframe, setLoadingIframe] = useState(true);
	const [, setErrors] = useState("");
	const [showDescription, setShowDescription] = useState(false);
	const [jobLink, setJobLink] = useState("");

	const onClickTab = (value) => {
		setCurrentView(value);
	};

	function publishJob(id) {
		setLoading(true);
		jobService.publishJobPost(
			id,
			() => {
				fetchData();
				setShowProfile(false);
			},
			(error) => {
				toast.error(error?.response?.data?.message);
				setErrors(error);
				setLoading(false);
			},
		);
	}

	function unPublishJob(id) {
		setLoading(true);
		jobService.getJobById(
			id,
			(response) => {
				const data = { ...response.data, companyReferenceId: response.data.company };
				delete data._id;
				delete data.createdByUserReferenceId;
				delete data.companyName;
				delete data.company;
				delete data.jobStatus;
				delete data.createdAt;
				delete data.lastUpdatedAt;
				delete data.closeAt;
				jobService.updateJobPost(
					id,
					data,
					() => {
						fetchData();
						setShowProfile(false);
					},
					(error) => {
						toast.error(error?.response?.data?.message);
						setErrors(error);
						setLoading(false);
					},
				);
			},
			(error) => {
				toast.error(error?.response?.data?.message);
				setErrors(error);
				setLoading(false);
			},
		);
	}

	function deleteJob(id) {
		setLoading(true);
		jobService.deleteJobById(
			id,
			() => {
				fetchData();
				setShowProfile(false);
			},
			(error) => {
				toast.error(error?.response?.data?.message);
				setErrors(error);
				setLoading(false);
			},
		);
	}

	return (
		<div className="fixed top-0 bottom-0 left-0 right-0 h-full w-full" style={{ background: "rgba(0,0,0,0.4)" }}>
			<div
				className={cn("fixed left-0 lg:left-auto right-0 top-0 z-30 h-full rounded bg-white shadow-lg", {
					"lg:w-full": showDescription,
					"lg:w-6/12": !showDescription,
				})}
			>
				<div className="flex justify-end">
					<button onClick={() => setShowProfile(false)} className="aspect-h-1 aspect-w-1 p-4 hover:bg-gray-100">
						<i className="bi bi-x-lg text-gray-950" />
					</button>
				</div>
				<div className="p-4 h-full">
					<div className="flex justify-between mb-12">
						<div className="flex items-center mr-4 space-x-4">
							<div className="w-14">
								<img alt="Company Logo" src={selectedItem?.company?.logo_url} />
							</div>
							<div>
								<Link to="/">
									<h5 className="card-title m-0">{selectedItem.jobTitle}</h5>
								</Link>
								<div className="d-flex align-items-center align-middle">
									<p className="m-0 card-text text-dark lh-1">{selectedItem.companyName}</p>
									<p className="m-0 text-secondary mx-1 lh-1"> - </p>
									<p className="m-0 card-text text-muted lh-1">
										Created on: {dayjs(selectedItem.createdAt).format("MMMM D, YYYY")}
									</p>
								</div>
							</div>
						</div>
						<h5
							className={`${selectedItem.jobStatus === jobStatus.draft ? "text-warning" : "text-success"} text-uppercase`}
						>
							{selectedItem.jobStatus}
						</h5>
					</div>
					<div className="w-full mb-6">
						<div className={cn("flex items-center flex-wrap space-y-2", { "justify-between": showDescription })}>
							<div className={cn("", { "mr-auto": !showDescription })}>
								<Tabs currentView={currentView} setCurrentView={onClickTab} tabs={TABS} />
							</div>
							<button
								className="btn btn-sm btn-secondary rounded-pill p-2 px-3 lh-1 d-flex align-items-center justify-content-center"
								style={{
									fontSize: "0.9rem",
								}}
								onClick={() => {
									setLoadingIframe(true);
									setShowDescription(!showDescription);
								}}
							>
								<i
									className={cn("bi mr-2 p-0", { "bi-eye": !showDescription, "bi-eye-slash": showDescription })}
									style={{
										fontSize: "1rem",
									}}
								/>
								{showDescription ? "Close Description" : "Show Description"}
							</button>
							<div className="flex items-center">
								<Dropdown>
									<Dropdown.Toggle
										variant="secondary px-3 py-2 mx-2"
										id="dropdown-basic"
										className="btn-sm rounded-pill"
									>
										Manage
									</Dropdown.Toggle>
									<Dropdown.Menu>
										<Dropdown.Item
											disabled={loading}
											onClick={() => {
												selectedItem.jobStatus === jobStatus.draft
													? publishJob(selectedItem._id)
													: unPublishJob(selectedItem._id);
											}}
										>
											{loading ? "Loading..." : selectedItem.jobStatus === jobStatus.draft ? "Publish" : "Unpublish"}
										</Dropdown.Item>
										{selectedItem.jobStatus === jobStatus.draft && (
											<>
												{/* <Dropdown.Item onClick={() => deleteJob(selectedItem._id)}>Reject</Dropdown.Item> */}
												<Dropdown.Item disabled={loading} onClick={() => deleteJob(selectedItem._id)}>
													{loading ? "Loading..." : "Delete"}
												</Dropdown.Item>
											</>
										)}
									</Dropdown.Menu>
								</Dropdown>
								<button
									disabled={loading}
									className="btn btn-sm btn-primary rounded-pill p-2 px-3 lh-1 d-flex align-items-center justify-content-center"
									style={{
										fontSize: "0.9rem",
									}}
									onClick={() => {
										selectedItem.jobStatus === jobStatus.draft
											? publishJob(selectedItem._id)
											: unPublishJob(selectedItem._id);
									}}
								>
									<i
										className="bi bi-send-fill mr-2 p-0"
										style={{
											fontSize: "1rem",
										}}
									/>
									{loading
										? "Loading..."
										: selectedItem.jobStatus === jobStatus.draft || !selectedItem.jobStatus
											? "Publish"
											: "Unpublish"}
								</button>
							</div>
						</div>
					</div>
					<div className="flex space-x-4 pb-32 h-full">
						{showDescription && (
							<div className="flex-1 h-full overflow-y-auto pb-16 border-2 border-gray-300 shadow-lg">
								{loadingIframe && (
									<div className="h-full flex flex-col items-center justify-center">
										<Loader />
									</div>
								)}
								<iframe
									src={jobLink}
									width="100%"
									height="100%"
									onLoad={() => {
										setLoadingIframe(false);
									}}
								/>
							</div>
						)}
						{(currentView === TABS[0].key || showDescription) && (
							<div className="flex-1 h-full overflow-y-auto pb-32">
								<JobPostForm ignoreLayout disableJobDescription jobItem={selectedItem} setJobLink={setJobLink} />
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default ViewJob;
