import React, { useState } from "react";

import { CheckIcon } from "../../../../assets/icons/interface/interface";
import { Modal } from "../../../Profile/components/Modal";

const DSPModal = ({ isOpen, onClose, onSubmit, data }) => {
	const [selected, setSelected] = useState(null);

	const handleSubmit = () => {
		if (selected !== null) {
			const option = data.options[selected];
			onSubmit(option);
		}
	};

	return (
		<Modal
			isOpen={isOpen}
			size="medium"
			onClose={onClose}
			title={data.title}
			subTitle={data.subTitle}
			secondaryAction={{
				label: "Cancel",
				onClick: () => onClose(),
			}}
			primaryAction={{
				label: "Create DSP",
				disabled: selected === null,
				onClick: () => handleSubmit(),
			}}
		>
			<div className="overflow-y-auto">
				<div className="">
					<div className="pb-3 max-h-[calc(100vh-70px)] overflow-y-auto pr-2">
						<div className="space-y-3 mb-3">
							{data.options.map((option, index) => (
								<div
									key={index}
									className={`border rounded-lg p-3 cursor-pointer transition-all ${
										selected === index ? "border-blue-500" : "border-gray-300"
									}`}
									onClick={() => setSelected(index)}
								>
									<div className="flex items-start">
										<div className="mr-5 mt-2">
											{selected === index ? (
												<CheckIcon className="text-blue-500" />
											) : (
												<div className="w-5 h-5 border border-gray-400 rounded-full" />
											)}
										</div>
										<div>
											<h3 className="text-sm font-inter-600">{option.title}</h3>
											<p className="text-xs font-inter-400 text-gray-600">{option.subTitle}</p>
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default DSPModal;
