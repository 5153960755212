import dayjs from "dayjs";
import React from "react";

import { Badge } from "../../../components/Badge/Badge";
import SearchableMultiSelectInput from "../../../components/SearchableMultiSelectInput/SearchableMultiSelectInput";
import SearchableSelectInput from "../../../components/SearchableSelectInput/SearchableSelectInput";
import { generateYearArray, months } from "../../../handlers/utilityFunctions";
import dropDownOptions from "../../../seed/dropDownOptions";
import {
	educationLevelsAttained,
	jobSearchStatusOptions,
	jobTypes,
	recentGraduateValues,
	regions,
	relocatingForWork,
	seniorityLevels,
} from "../../../seed/job-preferences-data";
import { useUserAccount } from "../../../services/create-resume/queries";
import { ResumeDataService } from "../../../services/create-resume/resume-data";
import { Button } from "../../JobsPage/components/Button";
import { Modal } from "./Modal";

const country = "Tanzania";

// const requestBody = {
// 	emailAddress: accountData.emailAddress,
// 	firstName: user?.firstName,
// 	middleName: user?.middleName,
// 	lastName: user?.lastName,
// 	phoneNumber: user?.phone,
// 	jobPreferences: {
// 		primaryResumeReferenceId: accountData.jobPreferences?.primaryResumeReferenceId,
// 		currentJobsearchStatus: accountData.jobPreferences?.currentJobsearchStatus,
// 		waysToHelpme: accountData.jobPreferences?.waysToHelpme,
// 		openForWorkTypes: accountData.jobPreferences?.openForWorkTypes,
// 		areasOfExpertise: accountData.jobPreferences?.areasOfExpertise,
// 		locationPreferenceType: accountData.jobPreferences?.locationPreferenceType,
// 		locationsPreferred: accountData.jobPreferences?.locationsPreferred,
// 		seniorityLevel: accountData.jobPreferences?.seniorityLevel,
// 		recentGraduate: accountData.jobPreferences?.recentGraduate,
// 		graduationDate: accountData.jobPreferences?.graduationDate,
// 		professionalSince: accountData.jobPreferences?.professionalSince,
// 		educationLevels: accountData.jobPreferences?.educationLevels,
// 		skills: accountData.jobPreferences?.skills,
// 		desiredTitle: accountData.jobPreferences?.desiredTitle,
// 		address: accountData.jobPreferences?.address,
// 	},
// }

const JobPreferencesModal = ({
	isOpen,
	onClose,
	setJobPreferences,
	jobPreferences,
	resumeList,
	transformToRequestBody,
}) => {
	const resumeDataService = React.useMemo(() => new ResumeDataService(), []);
	const expertiseAreas = dropDownOptions.jobExpertiseOptions;
	const [submitting, setSubmitting] = React.useState(false);
	const [inputSkillValue, setInputSkillValue] = React.useState("");
	const [errors, setErrors] = React.useState([]);

	const addSkill = () => {
		setJobPreferences({
			...jobPreferences,
			skills: jobPreferences?.skills ? [...jobPreferences.skills, inputSkillValue.trim()] : [inputSkillValue.trim()],
		});
		setInputSkillValue("");
	};

	const { data: accountData, isLoading: isLoadingAccount } = useUserAccount();

	const updateJobPreferences = () => {
		if (!submitting && !isLoadingAccount) {
			setSubmitting(true);
			resumeDataService.jobPreferences(
				transformToRequestBody(jobPreferences, {
					emailAddress: accountData.email,
					firstName: accountData.firstName,
					middleName: accountData.middleName,
					lastName: accountData.lastName,
					phoneNumber: accountData.phoneNumber,
				}),
				(data) => {
					setJobPreferences(data?.data?.jobPreferences);
					localStorage.setItem("jobPreferences", JSON.stringify(data?.data));
					setErrors([]);
					setSubmitting(false);
					onClose();
				},
				(error) => {
					const errorMessages = error?.response?.data?.message;
					setErrors(errorMessages);
					setSubmitting(false);
				},
			);
		}
	};

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			title="Job Preferences"
			size="large"
			primaryAction={{
				label: submitting ? "Loading..." : "Save Preferences",
				onClick: updateJobPreferences,
			}}
			secondaryAction={{
				label: "Cancel",
				onClick: onClose,
			}}
		>
			<div className="space-y-4 mb-4 max-h-[calc(100vh-200px)] overflow-y-auto pr-2">
				{(errors?.length || 0) > 0 && (
					<div className="bg-error-default text-white p-4 rounded-md mb-4">
						{Array.isArray(errors) ? errors.map((error, index) => <div key={index}>{error}</div>) : errors}
					</div>
				)}
				{/* Desired Job Title */}
				<div className="space-y-1">
					<label className="font-semibold text-sm text-gray-700">What’s your dream job title?</label>
					<input
						type="text"
						className="border w-full border-gray-200 h-9 text-sm placeholder:text-sm rounded px-2 py-1 placeholder:text-[#7A7A7A]"
						placeholder="Enter your desired job title"
						value={jobPreferences?.desiredTitle}
						onChange={(e) => {
							setJobPreferences({
								...jobPreferences,
								desiredTitle: e.target.value,
							});
						}}
					/>
				</div>

				{/* Current Status */}
				<div className="space-y-1">
					<label className="font-semibold text-sm text-gray-700">What’s your current job status?</label>
					<SearchableSelectInput
						options={jobSearchStatusOptions}
						onSelect={(value) => {
							setJobPreferences({
								...jobPreferences,
								currentJobsearchStatus: value,
							});
						}}
						value={jobPreferences?.currentJobsearchStatus}
						defaultLabel="Select job status"
					/>
				</div>

				{/* Areas of Expertise */}
				<div className="space-y-1">
					<label className="font-semibold text-sm text-gray-700">What’s your area of expertise?</label>
					<SearchableMultiSelectInput
						options={expertiseAreas.map((area) => {
							return { label: area, value: area };
						})}
						onSelect={(value) => {
							setJobPreferences({
								...jobPreferences,
								areasOfExpertise: value,
							});
						}}
						value={jobPreferences?.areasOfExpertise?.slice(0, 5) || []}
						placeholder="Select area of expertise"
					/>
				</div>

				{/* Skills */}
				<div className="space-y-1">
					<label className="font-semibold text-sm text-gray-700">What are your superpower skills?</label>
					<div className="flex flex-wrap gap-2 mb-2">
						{jobPreferences?.skills?.map((chip, index) => (
							<Badge key={index}>
								{chip}
								<button
									type="button"
									onClick={() => {
										setJobPreferences({
											...jobPreferences,
											skills: jobPreferences?.skills.filter((_, skillIndex) => skillIndex !== index),
										});
									}}
									className="text-blue-600 hover:text-blue-800 focus:outline-none"
								>
									&times;
								</button>
							</Badge>
						))}
					</div>
					<div className="flex items-center gap-4">
						<input
							type="text"
							placeholder="Add skill"
							value={inputSkillValue}
							onChange={(e) => setInputSkillValue(e.target.value)}
							onKeyDown={(e) => {
								if (e.key === "Enter" && inputSkillValue.trim() !== "") {
									addSkill();
								}
							}}
							className="border w-full border-gray-200 h-9 text-sm placeholder:text-sm rounded px-2 py-1 placeholder:text-[#7A7A7A]"
						/>
						<Button
							onClick={() => {
								addSkill();
							}}
							disabled={inputSkillValue.trim() === ""}
							className="h-[36px] bg-primary-700 hover:bg-primary-800 text-white py-2 text-[14px] font-normal font-inter"
						>
							Add
						</Button>
					</div>
				</div>

				{/* Job Types */}
				<div className="space-y-1">
					<label className="font-semibold text-sm text-gray-700">What type of roles are you looking for?</label>
					<div className="flex flex-wrap gap-2">
						{jobTypes.map((type) => (
							<button
								key={type}
								onClick={() => {
									setJobPreferences({
										...jobPreferences,
										openForWorkTypes: jobPreferences?.openForWorkTypes?.includes(type)
											? jobPreferences?.openForWorkTypes?.filter((item) => item !== type)
											: [...(jobPreferences?.openForWorkTypes || []), type],
									});
								}}
								className={`ignore-focus-behavior px-4 py-2 rounded-full border text-[14px] font-inter
                  ${
										jobPreferences?.openForWorkTypes?.includes(type)
											? "border-primary-600 bg-primary-50 text-primary-700"
											: "hover:bg-gray-50 text-gray-700"
									}`}
							>
								{type}
							</button>
						))}
					</div>
				</div>

				{/* Relocating For Work */}
				<div className="space-y-1">
					<label className="font-semibold text-sm text-gray-700">Are you open to relocating for work?</label>
					<SearchableSelectInput
						options={relocatingForWork}
						onSelect={(value) => {
							setJobPreferences({
								...jobPreferences,
								locationPreferenceType: value,
							});
						}}
						value={jobPreferences?.locationPreferenceType ? jobPreferences?.locationPreferenceType : ""}
						defaultLabel="Select all that apply"
					/>
				</div>

				{/* Locations Preferred */}
				{jobPreferences?.locationPreferenceType === relocatingForWork[1].value && (
					<div className="space-y-1">
						<label className="font-semibold text-sm text-gray-700">What’s your preferred work locations?</label>
						<SearchableMultiSelectInput
							options={regions.map((item) => {
								return { label: item, value: item };
							})}
							onSelect={(value) => {
								setJobPreferences({
									...jobPreferences,
									locationsPreferred: value,
								});
							}}
							value={jobPreferences?.locationsPreferred?.slice(0, 5) || []}
							placeholder="Select area of expertise"
						/>
					</div>
				)}

				{/* Recent Graduate */}
				<div className="space-y-1">
					<label className="font-semibold text-sm text-gray-700">⁠Are you a recent graduate?</label>
					<div className="flex flex-wrap gap-2">
						{recentGraduateValues.map((value) => (
							<button
								key={value}
								onClick={() => {
									setJobPreferences({
										...jobPreferences,
										recentGraduate: value,
									});
								}}
								className={`ignore-focus-behavior px-4 py-2 rounded-full border text-[14px] font-inter
                  ${
										jobPreferences?.recentGraduate === value
											? "border-primary-600 bg-primary-50 text-primary-700"
											: "hover:bg-gray-50 text-gray-700"
									}`}
							>
								{value}
							</button>
						))}
					</div>
				</div>

				{/* When Graduate */}
				{jobPreferences?.recentGraduate === recentGraduateValues[0] && (
					<div className="space-y-1">
						<label className="font-semibold text-sm text-gray-700">When did you graduate or expect to graduate?</label>
						<div className="flex gap-2">
							<SearchableSelectInput
								options={months.map((item, index) => {
									return { label: item, value: index + 1 };
								})}
								onSelect={(value) => {
									setJobPreferences({
										...jobPreferences,
										graduationDate: { ...jobPreferences?.graduationDate, month: value },
									});
								}}
								value={jobPreferences?.graduationDate?.month || ""}
								defaultLabel="Select Month"
							/>
							<SearchableSelectInput
								options={generateYearArray().map((year) => {
									return { label: year.toString(), value: year };
								})}
								onSelect={(value) => {
									setJobPreferences({
										...jobPreferences,
										graduationDate: { ...jobPreferences?.graduationDate, year: value },
									});
								}}
								value={jobPreferences?.graduationDate?.year || ""}
								defaultLabel="Select Year"
							/>
						</div>
					</div>
				)}

				{/* Professional Career Start */}
				<div className="space-y-1">
					<label className="font-semibold text-sm text-gray-700">When did you start your professional career?</label>
					<div className="flex gap-2">
						<SearchableSelectInput
							options={months.map((item, index) => {
								return { label: item, value: index + 1 };
							})}
							onSelect={(value) => {
								setJobPreferences({
									...jobPreferences,
									professionalSince: { ...jobPreferences?.professionalSince, month: value },
								});
							}}
							value={jobPreferences?.professionalSince?.month || ""}
							defaultLabel="Select Month"
						/>
						<SearchableSelectInput
							options={generateYearArray().map((year) => {
								return { label: year.toString(), value: year };
							})}
							onSelect={(value) => {
								setJobPreferences({
									...jobPreferences,
									professionalSince: { ...jobPreferences?.professionalSince, year: value },
								});
							}}
							value={jobPreferences?.professionalSince?.year || ""}
							defaultLabel="Select Year"
						/>
					</div>
				</div>

				{/* Seniority Level */}
				<div className="space-y-1">
					<label className="font-semibold text-sm text-gray-700">What seniority level are you targeting?</label>
					<SearchableSelectInput
						options={seniorityLevels}
						onSelect={(value) => {
							setJobPreferences({
								...jobPreferences,
								seniorityLevel: value,
							});
						}}
						value={jobPreferences?.seniorityLevel ? jobPreferences?.seniorityLevel : ""}
						defaultLabel="Select all that apply"
					/>
				</div>

				{/* Currently Located */}
				<div className="space-y-1">
					<label className="font-semibold text-sm text-gray-700">Where are you currently located?</label>
					<SearchableSelectInput
						options={regions.map((item) => {
							const address = `${item},${country}`;

							return { label: address, value: address };
						})}
						onSelect={(value) => {
							const address = value.split(",");

							setJobPreferences({
								...jobPreferences,
								address: { ...jobPreferences?.address, city: address[0] || "", country: address[1] || "" },
							});
						}}
						value={`${jobPreferences?.address?.city},${jobPreferences?.address?.country}` || ""}
						defaultLabel="Select your location"
					/>
				</div>

				{/* Education Levels Attained */}
				<div className="space-y-1">
					<label className="font-semibold text-sm text-gray-700">What’s your education levels attained?</label>
					<SearchableMultiSelectInput
						options={educationLevelsAttained}
						onSelect={(value) => {
							setJobPreferences({
								...jobPreferences,
								educationLevels: value,
							});
						}}
						value={jobPreferences?.educationLevels || []}
						placeholder="Select education levels attained"
					/>
				</div>

				{/* Resume Selection */}
				<div className="space-y-1">
					<label className="font-semibold text-sm text-gray-700">Which resume should be used for preferred jobs?</label>
					<SearchableSelectInput
						options={resumeList?.map((item) => {
							return {
								label: `${item.journal.resumeTitle || item.heading.resumeTitle} - Updated on ${dayjs(item.journal.lastUpdatedOn).format("DD MMMM, YYYY")}`,
								value: item._id,
							};
						})}
						onSelect={(value) => {
							setJobPreferences({
								...jobPreferences,
								primaryResumeReferenceId: value,
							});
						}}
						value={jobPreferences?.primaryResumeReferenceId}
						defaultLabel="Select primary resume"
					/>
				</div>
			</div>
		</Modal>
	);
};

export default JobPreferencesModal;
