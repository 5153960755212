import React from "react";
import { Link } from "react-router-dom";

import { useRecommendedJobs, useUserAccount } from "../../../services/create-resume/queries";
import JobListingItem from "../../JobsPage/components/JobListingItem";

function RecommendedJobs({ setShowPreferencesModal, setSelectedJob, setShowJobDetailsModal }) {
	const { data: account, isLoading: isLoadingAccount } = useUserAccount();

	const { data: recommendedJobs, isLoading: isLoadingRecommendedJobs } = useRecommendedJobs(
		account?.jobPreferences.areasOfExpertise,
	);

	if (isLoadingAccount || isLoadingRecommendedJobs) {
		return (
			<div className="animate-pulse">
				{[...new Array(5)].map((_, index) => (
					<div className="h-24 bg-gray-900 opacity-10 mb-3 rounded-md" key={index} />
				))}
			</div>
		);
	}

	return (
		<div>
			{account?.job_preference_progress_status.completeness_pct === 0 ? (
				<div className="bg-blue-50/50 rounded-lg p-6 border border-blue-100 h-full flex flex-col justify-between">
					<div className="flex justify-between items-center gap-8">
						<div className="flex-[2]">
							<span className="text-sm font-medium text-primary-600">Profile Completion</span>
							<h2 className="text-xl font-semibold text-gray-900 mt-1 mb-2">Get better job matches</h2>
							<p className="text-gray-800 text-sm mb-8">
								Complete your profile to receive personalized job recommendations that match your skills and
								preferences.
							</p>
						</div>
						<div className="flex-1">
							<div className="w-full aspect-[2/1] relative">
								<img
									alt="Complete your profile"
									loading="lazy"
									decoding="async"
									data-nimg="fill"
									className="object-contain absolute h-full w-full left-0 top-0 bottom-0 right-0"
									src="/images/solo-creation-transparent.png"
								/>
							</div>
						</div>
					</div>
					<Link
						className="inline-flex items-center justify-center bg-white text-gray-700 px-4 h-9 rounded hover:bg-gray-50 transition-colors text-sm font-medium border border-gray-300 w-full mt-8"
						onClick={() => setShowPreferencesModal(true)}
					>
						Complete Profile
					</Link>
				</div>
			) : (
				<div className="divide-y divide-gray-200">
					{recommendedJobs?.map((job, index) => (
						<JobListingItem
							key={index}
							job={{
								...job,
								logo: job.company.logo_url,
							}}
							onApply={() => {
								setSelectedJob(job);
								setShowJobDetailsModal(true);
							}}
							onClick={() => {
								setSelectedJob(job);
								setShowJobDetailsModal(true);
							}}
							showCompany={true}
							titleClassName="text-base"
							showMatchScore
						/>
					))}
				</div>
			)}
		</div>
	);
}

export default RecommendedJobs;
