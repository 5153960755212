import React, { useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import tips from "../../../../assets/copy/tips";
import ErrorToast from "../../../../components/ErrorToast";
import Spinner from "../../../../components/Spinner";
import TextEditor from "../../../../components/TextEditor";
import Tips from "../../../../components/Tips";
import ResumeContext from "../../../../context/ResumeContext";
import { convertArrayToUl } from "../../../../handlers/utilityFunctions";
import useHasChange from "../../../../hooks/use-HasChange";
import { AdditionalInfo } from "../../../../models/resume/additionalInfo";
import { StaticValue } from "../../../../seed/constants";
import { ResumeDataService } from "../../../../services/create-resume/resume-data";

const AdditionalInformation = () => {
	const [cookies] = useCookies(["resume"]);
	const navigate = useNavigate();
	const { hasChanges } = useHasChange();
	const [serverErrors, setServerErrors] = useState([]);

	const staticValue = new StaticValue();
	const staticValues = staticValue.getDefaultValues();
	const resumeDataService = new ResumeDataService();
	const [loading, setLoading] = useState(false);

	//Declaring the additional information state
	const { resumeData, setResumeData } = useContext(ResumeContext);

	const [additionalInfo, setAdditionalInfo] = useState(resumeData.extras.additionalInfo);

	const [, setUserInput] = useState(resumeData.contactInfo.resumeTitle);

	useEffect(() => {
		setAdditionalInfo(resumeData.extras.additionalInfo);
	}, [resumeData]);

	//submission handler
	const addAdditionalInfo = () => {
		const payload = new AdditionalInfo(additionalInfo);
		const request = payload.getAdditionalInfoRequest();

		const resumeId = cookies[staticValues.localStorageResumeId];

		const section = "ADDITIONAL_INFO";

		if (hasChanges("ADDITIONAL_INFO", request)) {
			setLoading(true);
			resumeDataService.patchResumeLedger(
				resumeId,
				request,
				section,
				successPatchAdditionalInfo,
				errorPatchAdditionalInfo,
			);
		} else {
			navigate("/resume/extras");
		}
	};

	const dispatch = useDispatch();

	function successPatchAdditionalInfo(data) {
		setLoading(false);
		const resArray = data.data.additionInfo.data;

		const additionalInfoList = convertArrayToUl(resArray);

		//TODO update redux store with whole resume data.data
		dispatch({
			type: "RESUME_DATA",
			payload: data.data,
		});

		setResumeData((prevState) => {
			return {
				...prevState,
				extras: {
					...prevState.extras,
					additionalInfo: additionalInfoList,
				},
			};
		});

		navigate("/resume/extras");
	}

	function errorPatchAdditionalInfo(data) {
		setLoading(false);
		const serverResponse = data.response.data;
		Array.isArray(serverResponse.message)
			? setServerErrors((prev) => {
					return [...prev, ...serverResponse.message];
				})
			: setServerErrors((prev) => {
					return [...prev, serverResponse.message];
				});
	}

	useEffect(() => {
		setUserInput(resumeData.contactInfo.resumeTitle);
	}, [resumeData]);

	return (
		<>
			<div className="container min-vh-100">
				<div className="section additional-information">
					{serverErrors.map((item, index) => {
						return <ErrorToast error={item} key={index} />;
					})}
					<p className="mb-3 text-medium">Add anything else you want employers to know.</p>
					<div className="form-wrapper">
						<div className="row">
							<div className="col-md-8">
								<div className="d-flex align-items-center justify-content-between mb-1">
									<div className="" />
									<Tips content={tips.additionalInformation} />
								</div>
								<div className="editor-wrapper">
									<TextEditor
										value={additionalInfo}
										setValue={setAdditionalInfo}
										placeholder="Add your details here..."
									/>
								</div>
								<div className="mt-4">
									<div className=" d-flex align-items-center justify-content-end">
										<button
											onClick={() => navigate("/resume/extras")}
											className="btn btn-dark rounded-pill btn-lg mr-2"
										>
											CANCEL
										</button>
										<button onClick={addAdditionalInfo} className="btn btn-primary">
											{loading ? (
												<>
													Loading <Spinner />
												</>
											) : (
												"Continue"
											)}
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default AdditionalInformation;
