import React, { useEffect, useState } from "react";
import { toast } from "sonner";

import Spinner from "../../components/Spinner";
import TextEditor from "../../components/TextEditor";
import { stripHTML } from "../../handlers/validationFunctions";
import { ResumeDataService } from "../../services/create-resume/resume-data";

const EXTRACTJOBSKILLS_FLAG = false;

function JobDescriptionModal({ setJobDescription, jobDescription, setShowJDModal }) {
	const jobService = new ResumeDataService();
	const [lookingToHire, setLookingToHire] = useState("We are looking to hire ");
	const [responsibilities, setResponsibilities] = useState("");
	const [qualifications, setQualifications] = useState("");

	const [errors, setErrors] = useState([]);

	const [stage, setStage] = useState("lookingToHire"); // "lookingToHire", "responsibilities", "qualifications", "validate"

	const [skillLoading, setSkillLoading] = useState(false);
	const [extractedSkills, setExtractedSkills] = useState([]);
	function onSuccess(data) {
		setStage("validate");
		setSkillLoading(false);
		const skills = [...data.data].map((item) => {
			return { skill: item[0], skillCategory: item[2] };
		});
		setExtractedSkills(skills);
	}

	function onError() {
		setErrors(["Error extracting skills"]);
		toast.error("Something went wrong");
		setSkillLoading(false);
	}

	useEffect(() => {
		let aboutJob = jobDescription.lookingToHire;
		let responsibilities = jobDescription.responsibilities.split("<p><strong>Responsibilities:</strong></p>")[1];
		let qualifications = jobDescription.qualifications.split("<p><strong>Qualifications:</strong></p>")[1];

		setLookingToHire(aboutJob);
		setResponsibilities(responsibilities);
		setQualifications(qualifications);
	}, [jobDescription]);

	const [showImage, setShowImage] = useState(false);

	function formatToHTML() {
		//responsibilities

		setErrors([]);
		if (lookingToHire.trim() === "" || lookingToHire.trim() === "We are looking to hire") {
			setErrors(["Please enter About Role"]);
			setStage("lookingToHire");
			return;
		}
		if (lookingToHire.trim().length < 30) {
			setErrors(["About Role must be at least 30 characters long"]);
			setStage("lookingToHire");
			return;
		}
		if (lookingToHire.trim().length >= 1999) {
			setErrors(["About Role cannot be longer than 2000"]);
			setStage("lookingToHire");
			return;
		}

		if (
			responsibilities
				.replace("<ul>", "")
				.replace("</ul>", "")
				.replace("<li>", "")
				.replace("</li>", "")
				.replace("<br>", "")
				.trim().length < 10
		) {
			setErrors(["Please enter some responsibilities"]);
			setStage("responsibilities");
			return;
		}

		if (
			qualifications
				.replace("<ul>", "")
				.replace("</ul>", "")
				.replace("<li>", "")
				.replace("</li>", "")
				.replace("<br>", "")
				.trim().length < 10
		) {
			setErrors(["Please enter some qualifications"]);
			setStage("qualifications");
			return;
		}

		setSkillLoading(true);
		if (EXTRACTJOBSKILLS_FLAG) {
			jobService.getJobSkills(
				{
					about_role: stripHTML(lookingToHire),
					responsibilities: stripHTML(responsibilities),
					qualification: stripHTML(qualifications),
				},
				onSuccess,
				onError,
			);
		} else {
			setJobDescription({
				lookingToHire: `<p>${lookingToHire}</p><p><br>`,
				responsibilities: `</p><p><strong>Responsibilities:</strong></p><ul>${responsibilities}</ul><p><br>`,
				qualifications: `</p><p><strong>Qualifications:</strong></p><ul>${qualifications}</ul>`,
			});
			setShowJDModal(false);
		}
	}
	return (
		<div>
			<div>
				<div
					style={{
						maxHeight: "80vh",
						overflowY: "scroll",
					}}
				>
					<div>
						<div className="modal-body px-0">
							<ul className="nav nav-tabs">
								<li className="nav-item">
									<div
										className={`nav-link ${stage === "lookingToHire" && "active"}`}
										aria-current="page"
										onClick={() => setStage("lookingToHire")}
										style={{
											fontSize: "0.9rem",
											padding: "8px",
										}}
									>
										About Role
									</div>
								</li>
								<li className="nav-item">
									<div
										className={`nav-link ${stage === "responsibilities" && "active"}`}
										onClick={() => setStage("responsibilities")}
										style={{
											fontSize: "0.9rem",
											padding: "8px",
										}}
									>
										Responsibilities
									</div>
								</li>
								<li className="nav-item">
									<div
										className={`nav-link ${stage === "qualifications" && "active"}`}
										onClick={() => setStage("qualifications")}
										style={{
											fontSize: "0.9rem",
											padding: "8px",
										}}
									>
										Qualifications/Requirements
									</div>
								</li>
								{stage === "validate" && (
									<li className="nav-item">
										<div
											className={`nav-link ${stage === "validate" && "active"}`}
											onClick={() => setStage("validate")}
											style={{
												fontSize: "0.9rem",
												padding: "8px",
											}}
										>
											Extracted Skills
										</div>
									</li>
								)}
							</ul>
							{errors.length > 0 && (
								<div className="alert alert-danger">
									{errors.map((error, i) => (
										<p className="mb-0" key={i}>
											{error}
										</p>
									))}
								</div>
							)}
							{stage === "lookingToHire" && (
								<div className="mb-3">
									<label className="tr-label d-flex justify-content-between">
										<span />
										<i className="bi bi-question-circle" onClick={() => setShowImage(true)} />
									</label>
									<TextEditor
										value={lookingToHire}
										setValue={setLookingToHire}
										id="about_role"
										placeHolder="We are looking to hire"
									/>
								</div>
							)}
							{stage === "responsibilities" && (
								<div className="mb-3">
									<label className="tr-label d-flex justify-content-between">
										<span />
										<i className="bi bi-question-circle" onClick={() => setShowImage(true)} />
									</label>
									<TextEditor
										value={responsibilities}
										setValue={setResponsibilities}
										id="responsibilities"
										placeHolder="Responsibilities"
									/>
								</div>
							)}
							{stage === "qualifications" && (
								<div className="mb-3">
									<label className="tr-label d-flex justify-content-between">
										<span />
										<i className="bi bi-question-circle" onClick={() => setShowImage(true)} />
									</label>
									<TextEditor
										value={qualifications}
										setValue={setQualifications}
										id="qualifications"
										placeHolder="Qualifications"
									/>
								</div>
							)}
							{stage === "validate" && (
								<div className="mb-3">
									{extractedSkills.length > 0 ? (
										<table className="table">
											<thead>
												<tr>
													<th>#</th>
													<th>Skill</th>
													<th>Skill Category</th>
												</tr>
											</thead>
											<tbody>
												{extractedSkills.map((item, index) => (
													<tr key={index}>
														<td>{index + 1}</td>
														<td>{item.skill}</td>
														<td>{item.skillCategory}</td>
													</tr>
												))}
											</tbody>
										</table>
									) : (
										<p>No skills extracted</p>
									)}
								</div>
							)}
							{showImage && (
								<div
									className="modal fade show"
									style={{
										display: "block",
										position: "relative",
									}}
								>
									<div className="modal-dialog modal-dialog-centered d-flex flex-col">
										<div className="modal-content">
											<div className="d-flex justify-content-between">
												<span />
												<button
													type="button"
													className="close bi bi-x"
													data-dismiss="modal"
													aria-label="Close"
													onClick={() => setShowImage(false)}
												/>
											</div>
											<img
												src={
													stage === "lookingToHire"
														? "/images/about-role.png"
														: stage === "responsibilities"
															? "/images/responsibilities.png"
															: stage === "qualifications" && "/images/requirements.png"
												}
												alt=""
												// width={"300px"}
											/>
										</div>
									</div>
								</div>
							)}
							<div className="">
								<button
									type="button"
									className="btn btn-success"
									onClick={() => {
										if (stage === "lookingToHire") {
											setStage("responsibilities");
										} else if (stage === "responsibilities") {
											setStage("qualifications");
										} else if (stage === "qualifications") {
											formatToHTML();
										} else if (stage === "validate") {
											setJobDescription({
												lookingToHire: `<p>${lookingToHire}</p><p><br>`,
												responsibilities: `</p><p><strong>Responsibilities:</strong></p><ul>${responsibilities}</ul><p><br>`,
												qualifications: `</p><p><strong>Qualifications:</strong></p><ul>${qualifications}</ul>`,
											});
											setShowJDModal(false);

											// if (extractedSkills.length > 0) {
											// 	const output = `<p>${lookingToHire}</p><p><br></p><p><strong>Responsibilities:</strong></p><ul>${responsibilities}</ul><p><br></p><p><strong>Qualifications:</strong></p><ul>${qualifications}</ul>`;
											// 	setJobDescription(output);
											// 	setShowJDModal(false);
											// }
										}
									}}
								>
									{skillLoading ? (
										<Spinner />
									) : (
										<>
											{stage === "lookingToHire" && "Next"}
											{stage === "responsibilities" && "Next"}
											{stage === "qualifications" && "Next"}
											{stage === "validate" && "Save"}
										</>
									)}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default JobDescriptionModal;
