import { EyeIcon } from "lucide-react";
import React, { useState } from "react";

import { Badge } from "../../../../components/Badge/Badge";
import Icon from "../../../../components/Icon";
import { actions, docSubTypes } from "../../../../handlers/enums";
import useDSPProfileState from "../../../../hooks/use-DSPProfileState";
import { Button } from "../Button";
import DSPModal from "./DSPModal";

function DocumentsConfirmation({ onPrev, dsp, onDSPChange }) {
	const [showDSPModal, setShowDSPModal] = useState(false);
	const [documents, setDocuments] = useState(dsp.documents);
	const dspProfileState = useDSPProfileState();

	const dspModalData = dspProfileState.dspModalData(actions.UPDATE);

	const handleSelectedDoc = (doc) => {
		const _dsp = { ...dsp };
		const _documents = [...documents];
		const docIndex = documents.findIndex((document) => document.id === doc.id);

		_documents[docIndex].included = !_documents[docIndex].included;
		_dsp.documents = _documents;

		onDSPChange(_dsp);
		setDocuments(_documents);
	};

	const handleDSPModalSelect = (option) => {
		dspProfileState.onDSPSelect(option);
		setShowDSPModal(false);
	};

	return (
		<div className="mx-auto">
			<div className="flex justify-between">
				<h2 className="text-md font-inter-600 mb-4">
					Which Smart Profile (DSP) do you want to use for this application?
				</h2>
				<h2 className="text-sm font-bold font-inter mb-4 text-blue-500 cursor-pointer" onClick={() => onPrev()}>
					Change
				</h2>
			</div>
			<div className="space-y-2">
				<div className="border rounded-lg p-3 cursor-pointer transition-all border-gray-300">
					<div className="flex justify-between items-start">
						<div>
							<h3 className="text-md font-inter font-bold"> {dsp.title} </h3>
							<p className="text-xs font-inter font-medium text-gray-500"> {dsp.email} </p>
							<ul className="mt-1 text-xs text-gray-700">
								{dsp.documents.map((doc) => (
									<li key={doc.id} className="flex items-center gap-1">
										<Icon iconName={docSubTypes[doc.type].icon} size={15} />
										<span>
											<span className="font-inter text-xs font-semibold text-gray-600">
												{docSubTypes[doc.type].name}:
											</span>
											<span className="font-inter text-xs font-medium text-gray-600">{` ${doc.name}`}</span>
										</span>
									</li>
								))}
							</ul>
						</div>
					</div>
				</div>
			</div>

			{/* Documents List */}
			<div>
				<div className="mt-4 mb-4 flex justify-between">
					<span>
						<h3 className="text-md font-inter-600">My Documents</h3>
						<p className="text-sm font-inter font-medium text-gray-600">
							You can check or uncheck files to include them in your application
						</p>
					</span>
					<h2
						className="text-sm font-bold font-inter mb-4 text-blue-500 cursor-pointer"
						onClick={() => setShowDSPModal(true)}
					>
						Add
					</h2>
				</div>

				<div className="space-y-2">
					{dsp.documents.map((doc) => (
						<div
							key={doc.id}
							className="flex items-center justify-between p-3.5 bg-gray-50 rounded-lg transition-colors hover:bg-gray-100"
						>
							<div className="flex items-center gap-3">
								<div className="bg-primary-50 rounded-md">
									<button
										id={doc.id}
										className="w-8 h-8 border flex items-center justify-center hover:opacity-80 transition-opacity"
										onClick={() => handleSelectedDoc(doc)}
										style={{
											background: "#f8f9fa",
											borderWidth: "2px",
											...(doc.included && { background: "#0A88ED", borderWidth: "none" }),
										}}
									>
										{doc.included && <i className="fa fa-check text-white" />}
									</button>
								</div>
								<div>
									<div className="flex align-items-start">
										<h4 className="text-[14px] font-medium text-gray-900 font-inter mb-0.5">{doc.name}</h4>
										<Badge
											variant={doc.included ? "success" : "danger"}
											className={`text-xs font-medium ml-2 ${doc.included ? "bg-green-100 text-green-700" : "bg-red-100 text-red-700"} border-0 rounded-md`}
										>
											{`${doc.included ? "Included" : "Not Included"}`}
										</Badge>
									</div>

									<div className="flex items-center gap-3">
										<span className="text-xs text-gray-600">Uploaded on {doc.uploadDate}</span>
										<span className="text-xs text-gray-600">{doc.size}</span>
									</div>
								</div>
							</div>

							<div className="flex items-center gap-6">
								<Badge variant="secondary" className="text-xs font-medium bg-gray-100 text-gray-700">
									{docSubTypes[doc.type].name}
								</Badge>
								<div className="flex items-center gap-2">
									<Button variant="ghost" size="sm" title="Download" className="h-8 w-8 p-0 hover:text-gray-1000">
										<EyeIcon className="w-4 h-4 text-gray-600" />
										<span className="sr-only">View</span>
									</Button>
									<Button variant="ghost" size="sm" title="Delete" className="h-8 w-8 p-0 hover:text-black-1000">
										{/*<Trash className="w-4 h-4" />*/}
										<i className="fa-regular pre-text fa-pen-to-square" />
										<span className="sr-only">Edit</span>
									</Button>
								</div>
							</div>
						</div>
					))}
				</div>

				<DSPModal
					data={dspModalData}
					isOpen={showDSPModal}
					onSubmit={handleDSPModalSelect}
					onClose={() => setShowDSPModal(false)}
				/>
			</div>
		</div>
	);
}

export default DocumentsConfirmation;
