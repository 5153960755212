import { useEffect, useRef } from "react";

const useScrollIntoView = (trigger) => {
	const elementRef = useRef(null);

	useEffect(() => {
		if (trigger && elementRef.current) {
			elementRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
		}
	}, [trigger]);

	return elementRef;
};

export default useScrollIntoView;
