import dayjs from "dayjs";
import React, { useRef, useState } from "react";
import Reaptcha from "reaptcha";
import { toast } from "sonner";

import {
	ClipIcon,
	DocumentIcon,
	Feeling1,
	Feeling2,
	Feeling3,
	Feeling4,
	Feeling5,
	Feeling6,
	Feeling7,
	Feeling8,
	XIcon,
} from "../../assets/icons/interface/interface";
import { cn } from "../../handlers/cn.utils";
import { getBrowserName, getOperatingSystem } from "../../handlers/utilityFunctions";
import { isValidEmail } from "../../handlers/validationFunctions";
import useIsLoggedIn from "../../hooks/use-IsLoggedIn";
import { Button } from "../../Views/JobsPage/components/Button";
import { Label } from "../../Views/JobsPage/components/Label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../Views/JobsPage/components/Select";
import Drawer from "../Drawer/Drawer";
import InputField from "../UIComponents/InputField";
import { RadioGroupCards } from "../UIComponents/radio-group-cards";

const requestCategories = [
	{
		label: "Issue",
		value: "Issue",
		description: "Something isn’t working as expected.",
	},
	{
		label: "Feature Request",
		value: "Feature Request",
		description: "Something is missing or not quite right.",
	},
];

function CandidateSupportModal({ show, close }) {
	const isLoggedIn = useIsLoggedIn();
	const user = JSON.parse(localStorage.getItem("profileData"));
	const captchaRef = useRef(null);
	const [submiting, setSubmiting] = useState(false);
	const [captchaToken, setCaptchaToken] = useState(null);
	const templateName = localStorage.getItem("templateName");
	const currentLink = location.pathname.split("/")[2];
	const [file, setFile] = useState(null);
	const [contactData, setContactData] = useState({
		date: dayjs().format("YYYY-MM-DD"),
		name: `${user?.firstName || ""} ${user?.lastName || ""}`,
		email: user?.email || "",
		inquiry: "page activity: " + currentLink + " , template Name: " + templateName,
		message: "",
		phone: user?.phoneNumber || "",
		requestCategory: "",
		howDoYouFeel: "",
		moreExplanation: "",
		isThereAlternative: "",
		browser: getBrowserName(),
		operatingSystem: getOperatingSystem(),
		fileUrl: "",
		source: "candidate support requests",
	});
	const [errors, setErrors] = useState({
		name: "",
		email: "",
		requestCategory: "",
		message: "",
		moreExplanation: "",
		howDoYouFeel: "",
		isThereAlternative: "",
	});

	const removeErrorMessage = (key) => {
		setErrors((prevState) => {
			return {
				...prevState,
				[key]: "",
			};
		});
	};

	const validateForm = (data) => {
		var formStatus = true;

		//validate full name
		if (data.name === "" || data.name > 100) {
			setErrors((prevState) => {
				return {
					...prevState,
					name: data.name === "" ? "Full Name is required" : "Full Name should be less than 100 characters",
				};
			});
			formStatus = false;
		} else if (!(data.name === "" && data.name > 100)) {
			removeErrorMessage("name");
		}

		//validation email
		if (!isValidEmail(data.email)) {
			setErrors((prevState) => {
				return {
					...prevState,
					email: data.email === "" ? "Email Address is required" : "Please enter a valid email address",
				};
			});
			formStatus = false;
		} else if (isValidEmail(data.email)) {
			removeErrorMessage("email");
		}

		//validate request category
		if (data.requestCategory === "") {
			setErrors((prevState) => {
				return {
					...prevState,
					requestCategory: "Select Request Category",
				};
			});
			formStatus = false;
		} else if (!(data.requestCategory === "")) {
			removeErrorMessage("requestCategory");
		}

		//validate message
		if (data.message === "" || data.message > 2000) {
			setErrors((prevState) => {
				return {
					...prevState,
					message:
						data.message === "" ? "What Happened is required" : "What Happened should be less than 2000 characters",
				};
			});
			formStatus = false;
		} else if (!(data.message === "" && data.message > 100)) {
			removeErrorMessage("message");
		}

		//validate how do you feel
		if (data.howDoYouFeel === "") {
			setErrors((prevState) => {
				return {
					...prevState,
					howDoYouFeel: "Select How You Feel",
				};
			});
			formStatus = false;
		} else if (!(data.howDoYouFeel === "")) {
			removeErrorMessage("howDoYouFeel");
		}

		if (formStatus === true) {
			return true;
		} else {
			return false;
		}
	};

	const verify = () => {
		captchaRef.current.getResponse().then(setCaptchaToken);
	};

	function submitSupportRequest(contactData, successCallback, failureCallback) {
		let data = JSON.stringify(contactData);

		let URL =
			"https://script.google.com/macros/s/AKfycby-3kyn6G80z6AVvQqLGCnUZ01fUrOAZe7ogBqrR4rm3UWI_YW5ZpGVMmYHv9dnE0hc/exec";

		fetch(URL, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"Access-Control-Allow-Origin": "*",
			},
			body: data,
			mode: "no-cors",
		})
			.then((response) => {
				successCallback(response.data);
			})
			.catch((error) => {
				failureCallback(error);
			});
	}

	const handleSubmit = () => {
		setSubmiting(true);
		const validate = validateForm(contactData);
		if (!validate) {
			setSubmiting(false);
			return;
		}
		if (!isLoggedIn) {
			if (!captchaToken) {
				setSubmiting(false);
				return;
			}
		}
		submitSupportRequest(
			contactData,
			() => {
				toast.success(
					"Thank you! Your support request has been submitted successfully. Our team will review it and get back to you as soon as possible.",
				);
				setSubmiting(false);
				close();
			},
			() => {
				toast.error(
					"Oops! Something went wrong while submitting your request. Please try again later or contact our support team if the issue persists.",
				);
				setSubmiting(false);
			},
		);
	};

	const processDataOfUploadedFile = (fileUploaded) => {
		if (fileUploaded) {
			setSubmiting(true);
			setFile(fileUploaded);

			const reader = new FileReader();
			reader.readAsDataURL(fileUploaded);
			reader.onloadend = async () => {
				const base64File = reader.result;

				const formData = new FormData();
				formData.append("file", base64File);
				formData.append("fileName", fileUploaded.name);

				try {
					const response = await fetch(
						"https://script.google.com/macros/s/AKfycbyM4N-zbshfTR4buYpeUcFQbjvWiQ0NtdrrQNYfVdLc1nMh3tISJnuB1SV6PsJXJ_o-qg/exec",
						{
							method: "POST",
							body: new URLSearchParams(formData),
						},
					);

					const data = await response.json();

					if (data.success) {
						setContactData((prevState) => {
							return {
								...prevState,
								fileUrl: data.fileUrl,
							};
						});
					}
					setSubmiting(false);
				} catch (error) {
					setSubmiting(false);
				}
			};
		}
	};

	return (
		<Drawer isOpen={show} onClose={close}>
			<div className="flex flex-col">
				<div className="flex-1 overflow-y-auto px-2 py-4">
					<div className="space-y-6">
						<div className="flex items-center justify-between">
							<h2 className="text-lg font-medium">Something not working? Need a feature? Let us know!</h2>
							<Button disabled={submiting} type="button" onClick={handleSubmit}>
								{submiting ? "Submiting..." : "Submit"}
							</Button>
						</div>
						<p className="text-gray-700">
							Help us improve your experience! If something isn’t working as expected, tell us what happened and share a
							screenshot. If you need a feature, let us know your goal, why it matters, and any workarounds you’re using
							while we evaluate.
						</p>
						<div className="space-y-[24px]">
							<div className={cn("flex flex-col gap-[12px]", { "flex flex-row gap-4": isLoggedIn })}>
								<div className="flex-1">
									<InputField
										disabled={isLoggedIn}
										type="text"
										className="text-field"
										placeholder="Full Name"
										label="Full Name*"
										value={contactData.name}
										required
										onChange={(event) => {
											setContactData((prevState) => {
												return {
													...prevState,
													name: event.target.value,
												};
											});
											validateForm({
												...contactData,
												name: event.target.value,
											});
										}}
										error={errors.name}
									/>
								</div>
								<div className="flex-1">
									<InputField
										disabled={isLoggedIn}
										type="email"
										className="text-field"
										placeholder="Email Address"
										label="Email Address*"
										value={contactData.email}
										required
										onChange={(event) => {
											setContactData((prevState) => {
												return {
													...prevState,
													email: event.target.value,
												};
											});
											validateForm({
												...contactData,
												email: event.target.value,
											});
										}}
										error={errors.email}
									/>
								</div>
							</div>
							<div className="space-y-2">
								<Label>How would you categorize this request?</Label>
								<RadioGroupCards
									options={requestCategories}
									value={contactData.requestCategory}
									onChange={(value) => {
										setContactData((prevState) => {
											return {
												...prevState,
												requestCategory: value,
											};
										});
										validateForm({
											...contactData,
											requestCategory: value,
										});
									}}
									className="grid-cols-2"
								/>
							</div>
							{contactData.requestCategory && (
								<>
									<div className="space-y-1">
										<Label>
											{contactData.requestCategory === requestCategories[1].value
												? "What are you trying to accomplish?"
												: "What happened? (Describe the issue you’re facing.)"}
										</Label>
										<textarea
											value={contactData.message}
											rows={4}
											placeholder="Description..."
											className="flex w-full rounded-md border bg-transparent px-3 py-1 text-sm transition-colors placeholder:text-muted-foreground"
											onChange={(event) => {
												setContactData((prevState) => {
													return {
														...prevState,
														message: event.target.value,
													};
												});
												validateForm({
													...contactData,
													message: event.target.value,
												});
											}}
										/>
										{errors.message && <div className="text-sm text-red-600 font-medium mb-3">{errors.message}</div>}
									</div>
									<div className="space-y-1">
										<Label>
											{contactData.requestCategory === requestCategories[1].value
												? "Why is this important to you?"
												: "What were you doing before this happened? (Steps leading up to the issue.)"}
										</Label>
										<textarea
											value={contactData.moreExplanation}
											rows={4}
											placeholder="Description..."
											className="flex w-full rounded-md border bg-transparent px-3 py-1 text-sm transition-colors placeholder:text-muted-foreground"
											onChange={(event) => {
												setContactData((prevState) => {
													return {
														...prevState,
														moreExplanation: event.target.value,
													};
												});
												validateForm({
													...contactData,
													moreExplanation: event.target.value,
												});
											}}
										/>
										{errors.moreExplanation && (
											<div className="text-sm text-red-600 font-medium mb-3">{errors.message}</div>
										)}
									</div>
									{contactData.requestCategory === requestCategories[1].value && (
										<div className="space-y-1">
											<Label>Are there any alternatives you can use while we evaluate? (Or is this a must-have?)</Label>
											<Select
												onValueChange={(value) => {
													setContactData((prevState) => {
														return {
															...prevState,
															isThereAlternative: value,
														};
													});
													validateForm({
														...contactData,
														isThereAlternative: value,
													});
												}}
												value={contactData.isThereAlternative}
											>
												<SelectTrigger className="w-full h-9 bg-white border border-input ring-offset-background focus:outline-none focus:ring-1 focus:ring-primary-500 focus:ring-offset-0 tracking-[0em]">
													{contactData.isThereAlternative ? (
														contactData.isThereAlternative
													) : (
														<SelectValue placeholder="Select an option" />
													)}
												</SelectTrigger>
												<SelectContent className="z-[1000]">
													{[
														{
															label: "Yes",
															value: "Yes",
														},
														{
															label: "No",
															value: "No",
														},
													].map((option) => (
														<SelectItem
															key={option.value}
															value={option.value}
															className="hover:bg-gray-100 data-[state=checked]:bg-primary-100 data-[state=checked]:text-primary-800"
														>
															{option.label}
														</SelectItem>
													))}
												</SelectContent>
											</Select>
										</div>
									)}
								</>
							)}
							<div className="space-y-1">
								<Label>How are you feeling about this issue?</Label>
								<Select
									onValueChange={(value) => {
										setContactData((prevState) => {
											return {
												...prevState,
												howDoYouFeel: value,
											};
										});
										validateForm({
											...contactData,
											howDoYouFeel: value,
										});
									}}
									value={contactData.howDoYouFeel}
								>
									<SelectTrigger className="w-full h-9 bg-white border border-input ring-offset-background focus:outline-none focus:ring-1 focus:ring-primary-500 focus:ring-offset-0 tracking-[0em]">
										{contactData.howDoYouFeel ? (
											contactData.howDoYouFeel
										) : (
											<SelectValue placeholder="Select an option" />
										)}
									</SelectTrigger>
									<SelectContent className="z-[1000]">
										{[
											{
												label: (
													<span className="flex items-center gap-2">
														<Feeling1 />
														Not a big deal – Just a minor issue.
													</span>
												),
												value: " Not a big deal – Just a minor issue.",
											},
											{
												label: (
													<span className="flex items-center gap-2">
														<Feeling2 /> A bit frustrating – I can manage, but it’s annoying.
													</span>
												),
												value: "  A bit frustrating – I can manage, but it’s annoying.",
											},
											{
												label: (
													<span className="flex items-center gap-2">
														<Feeling3 />
														It’s becoming a real problem – This is affecting my work.
													</span>
												),
												value: "It’s becoming a real problem – This is affecting my work.",
											},
											{
												label: (
													<span className="flex items-center gap-2">
														<Feeling4 />
														Frustrated – I need this fixed ASAP.
													</span>
												),
												value: "Frustrated – I need this fixed ASAP.",
											},
											{
												label: (
													<span className="flex items-center gap-2">
														<Feeling5 />
														I’m confused – I need help understanding or fixing this ASAP.
													</span>
												),
												value: "I’m confused – I need help understanding or fixing this ASAP.",
											},
											{
												label: (
													<span className="flex items-center gap-2">
														<Feeling6 />
														I’m worried – This might cause bigger issues if not fixed soon.
													</span>
												),
												value: " I’m worried – This might cause bigger issues if not fixed soon.",
											},
											{
												label: (
													<span className="flex items-center gap-2">
														<Feeling7 />
														I’m upset – This is disrupting my experience, and I need help now.
													</span>
												),
												value: "I’m upset – This is disrupting my experience, and I need help now.",
											},
											{
												label: (
													<span className="flex items-center gap-2">
														<Feeling8 />
														I’m angry – This is unacceptable, and I need immediate support.
													</span>
												),
												value: "I’m angry – This is unacceptable, and I need immediate support.",
											},
										].map((option) => (
											<SelectItem
												key={option.value}
												value={option.value}
												className="hover:bg-gray-100 data-[state=checked]:bg-primary-100 data-[state=checked]:text-primary-800"
											>
												{option.label}
											</SelectItem>
										))}
									</SelectContent>
								</Select>
								{errors.howDoYouFeel && (
									<div className="text-sm text-red-600 font-medium mb-3">{errors.howDoYouFeel}</div>
								)}
							</div>
							{contactData.requestCategory === requestCategories[0].value && (
								<div className="space-y-2">
									<div className="flex justify-between items-center">
										<Label className="font-medium">Attach a Screenshot</Label>
										<span className="text-[13px] text-gray-500">Optional</span>
									</div>
									<div
										className={`relative w-full h-10 flex items-center group border border-dashed border-gray-200 hover:border-gray-300 rounded-lg transition-colors ${
											file ? "bg-primary-50 border-primary-200 border-solid" : ""
										}`}
									>
										{file ? (
											<div className="w-full h-full flex items-center px-3 pr-20">
												<DocumentIcon className="h-4 w-4 text-primary-500 mr-2 flex-shrink-0" />
												<span className="text-[13px] font-medium text-primary-700 truncate">{file.name}</span>
												<div className="absolute right-2 flex items-center space-x-1">
													<Button
														type="button"
														variant="ghost"
														className="h-8 w-8 p-0 text-primary-500 hover:text-primary-700"
														onClick={() => setFile(null)}
													>
														<XIcon className="h-4 w-4" />
													</Button>
												</div>
											</div>
										) : (
											<label className="w-full h-full flex items-center justify-between px-3 cursor-pointer">
												<span className="text-[13px] text-gray-500">Attach a Screenshot (PNG, JPG, JPEG)</span>
												<ClipIcon className="h-4 w-4 text-gray-400" />
												<input
													type="file"
													className="hidden"
													accept="image/*"
													onChange={(e) => {
														processDataOfUploadedFile(e.target.files?.[0]);
													}}
												/>
											</label>
										)}
									</div>
								</div>
							)}
							{!isLoggedIn && (
								<Reaptcha sitekey="6LcCqZAkAAAAABvxLKxrPXAUlaSOx4kQnr-gyzOy" ref={captchaRef} onVerify={verify} />
							)}
						</div>
					</div>
				</div>
				<div className="flex-none px-8 py-4 border-t border-gray-200 bg-white">
					<div className="flex items-center justify-end gap-3">
						<Button type="button" variant="outline" onClick={close}>
							Cancel
						</Button>

						<Button disabled={submiting} type="button" onClick={handleSubmit}>
							{submiting ? "Submiting..." : "Submit"}
						</Button>
					</div>
				</div>
			</div>
		</Drawer>
	);
}

export default CandidateSupportModal;
