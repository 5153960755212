import React from "react";

import Header from "../../components/Header";
import Footer from "../StaticPages/Footer";

function PrivacyPolicy() {
	return (
		<>
			<Header />
			<section className="custom-banner">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-md-6">
							<div className="banner-content">
								<h1 className="text-white">Privacy Policy</h1>
							</div>
						</div>
					</div>
				</div>
			</section>
			<div className="fancy-border-radius" />
			<div className="fancy-box">
				<div className="fancy-border-radius-right bg-white" />
			</div>

			<div className="all-view section-padding minus-margin">
				<div className="container">
					<p className="mb-3 text-larger">Introduction:</p>
					<p className="mb-3 text-larger">
						Welcome! This Privacy Statement(“Policy”) explains the online data practices, your data options, and the
						disclosure of the information you submit to Dproz in connection with our services. To the extent permissible
						by law, by visiting Dproz you agree to the processing of your information data as explained in this Policy,
						herein incorporated by reference.
					</p>

					<p className="mb-3 text-larger">
						If you need any support or have questions, you can reach out to our exceptional customer service team
						through info@dproz.com
					</p>

					<p className="mb-3 text-larger">
						Dproz is a product and brand part of TE-VENTURES INC LTD. Dproz is a convenient, time-saving, and
						one-stop-shop platform that makes it easier for professionals to prepare and stay prepared to capitalize on
						career-building opportunities.
					</p>
					<p className="mb-3 text-larger">
						You can request to remove all the information belonging to you or your account from Dproz platform through
						info@dproz.com and all information related to your account will be deleted.
					</p>

					<p className="mb-3 text-larger">Personal Information we Collect:</p>
					<p className="mb-3 text-larger">
						When you register an account on Dproz, use our services, or download a resume you voluntarily share certain
						Information with us, including, but not limited to, your name, email address, address, professional title,
						and mobile number.
					</p>

					<p className="mb-3 text-larger">
						We may also be able to determine your personal information from other information you supply, including your
						resume. If you do not want us to collect such personal information, kindly do not provide it to us.
					</p>

					<p className="mb-3 text-larger">
						We may collect some basic information when you access the Site with third-party login credentials such as
						your name, user name, and your email address.
					</p>

					<p className="mb-3 text-larger">Update Notifications:</p>
					<p className="mb-3 text-larger">
						Through your contact information, we can reach out or respond to your questions and requests, as well as
						respond to your requests to send you newsletters and other communications.
					</p>

					<p className="mb-3 text-larger">
						You agree that we can notify you about material changes to this Privacy Policy by posting notices on this
						Site.
					</p>

					<p className="mb-3 text-larger">Your Contents:</p>
					<p className="mb-3 text-larger">
						Your content means any text or other material you choose to display on this Website. By displaying Your
						Content, you grant Dproz a non-exclusive, worldwide irrevocable, sub licensable license to use, reproduce,
						adapt, publish, translate and distribute it in any and all media.
					</p>

					<p className="mb-3 text-larger">
						Your Content must be your own and must not be invading any third-party&#39;s rights. Dproz reserves the
						right to remove any of Your Content from this Website at any time without notice.
					</p>

					<p className="mb-3 text-larger">Access and Review Option:</p>
					<p className="mb-3 text-larger">
						You can access, update, and delete your information at any time you commit to doing so.
					</p>

					<p className="mb-3 text-larger">Tracking Technologies:</p>
					<p className="mb-3 text-larger">
						We use tracking technologies such as cookies to measure usage and provide information that we believe will
						be of most interest to you.
					</p>

					<p className="mb-3 text-larger">Analyze and Aggregate Non-Personal Information: </p>
					<p className="mb-3 text-larger">
						We use aggregate information about our users and non-personal information to analyze Site and user behavior
						and prepare aggregated reports
					</p>

					<p className="mb-3 text-larger">Restrictions:</p>
					<p className="mb-3 text-larger">
						You are expressly prohibited from doing the following on the Dproz website:
					</p>
					<p className="mb-3 text-larger">
						Creating more than one account on this Website, One account should belong to One Individual and not
						otherwise.
					</p>
					<p className="mb-3 text-larger">
						{" "}
						illegal activities like fraud, establish security breaches or defend a legal claim
					</p>
					<p className="mb-3 text-larger">
						selling, sublicensing, and/or otherwise commercializing any Website material;
					</p>
					<p className="mb-3 text-larger">
						engaging in any data mining, data harvesting, data extracting, or any other similar activity in relation to
						this Website;
					</p>
					<p className="mb-3 text-larger">using this Website to engage in any advertising or marketing.</p>

					<p className="mb-3 text-larger">
						Any account ID and password you may have for this Website are confidential and you must maintain
						confidentiality as well.
					</p>

					<p className="mb-3 text-larger">Marketing & Communications: </p>
					<p className="mb-3 text-larger">
						We use your information to contact you to maintain the Site, and your account, comply with your stated
						communication preferences or provide other services as described at the time when we collect the information
						from you or contact you for other purposes authorized by law.{" "}
					</p>

					<p className="mb-3 text-larger">
						The information you share with us can be provided to third parties such as employers or recruiters who can
						contact you about career opportunities, employers or other individuals or companies seeking candidates,
						and/or other career service providers.
					</p>

					<p className="mb-3 text-larger">Third-Party Privacy Policies:</p>
					<p className="mb-3 text-larger">
						Dproz Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult
						the respective Privacy Policies of these third-party ad servers for more detailed information. It may
						include their practices and instructions about how to opt out of certain options.
					</p>

					<p className="mb-3 text-larger">Registration details:</p>
					<p className="mb-3 text-larger">
						Contact and other details you supply as part of the registration process are stored by us and used to
						provide you with the information you have requested.{" "}
					</p>

					<p className="mb-3 text-larger">Indemnification:</p>
					<p className="mb-3 text-larger">
						You hereby fully hold Dproz harmless from and against any and/or all liabilities, charges, claims, causes of
						action, damages, and expenditures resulting from your violation of any of these Terms.
					</p>
					<p className="mb-3 text-larger">Security:</p>
					<p className="mb-3 text-larger">
						We take the security of your personal information very seriously and have appropriate technical and
						organizational measures in place. The site is protected against unauthorized access using the latest
						security devices and &#39;firewalls&#39;.
					</p>

					<p className="mb-3 text-larger">Agreement:</p>
					<p className="mb-3 text-larger">
						By using our website, you hereby consent and agree to our Privacy Policy stated herein.
					</p>
				</div>
			</div>

			<Footer />
		</>
	);
}

export default PrivacyPolicy;
