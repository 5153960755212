import "../css/global.css";
import "../css/home.css";
import "../css/responsive.css";

import React from "react";
import { Helmet } from "react-helmet";

import useIsLoggedIn from "../../../hooks/use-IsLoggedIn";
import LinkedInCallback from "../../Auth/SigninScreen/LinkedInCallback";
import Header from "../../Profile/components/Header";
import Footer from "../Footer";
import FeaturedEmployers from "./components/FeaturedEmployers";
import JobsByCategory from "./components/JobsByCategory";
import LatestJobs from "./components/LatestJobs";
import OverTheFold from "./components/OverTheFold";
import PreferencesUpsell from "./components/PreferencesUpsell";
import RecommendedJobs from "./components/RecommendedJobs";
import Resources from "./components/Resources";

function HomePage() {
	const isLoggedIn = useIsLoggedIn();

	return (
		<>
			<Helmet>
				<meta name="description" content="The best job board in Tanzania" />
				<meta property="og:description" content="The best job board in Tanzania" />
				<meta property="og:image" content="https://www.dproz.com/images/meta.png" />
				<meta property="og:title" content="Dproz: Your One-Stop Shop for Job Search in Tanzania" />
				<meta property="og:url" content="https://www.dproz.com/" />
				<meta property="og:type" content="website" />
				<meta property="og:site_name" content="DProz" />
				<meta property="og:locale" content="en_US" />
				<meta property="og:image:secure_url" content="https://www.dproz.com/images/meta.png" />
				<meta property="og:image:width" content="932" />
				<meta property="og:image:height" content="273" />
				<meta property="og:image:alt" content="DProz" />
				<meta property="og:image:type" content="image/png" />
			</Helmet>
			<Header isLoggedIn={isLoggedIn} />
			<OverTheFold />
			<LatestJobs />
			<FeaturedEmployers />
			<JobsByCategory />
			<RecommendedJobs />
			<PreferencesUpsell />
			<Resources />
			<section className="bg-gray-900 pt-24 pb-16 px-4 md:px-8">
				<div className="max-w-4xl mx-auto text-center">
					<h2 className="text-3xl md:text-4xl font-bold mb-4 text-white font-plus-jakarta">
						Are you an employer looking for top talent?
					</h2>
					<p className="text-xl text-gray-400 opacity-80 mb-8 font-inter">
						Broaden your reach. Hire smarter. Dproz connects you with a diverse and qualified talent pool across
						Tanzania while simplifying your hiring process.
					</p>
					<a
						className="inline-block bg-primary-600 text-white px-8 h-10 leading-10 rounded-lg text-base font-medium hover:bg-primary-700 transition-colors font-inter"
						href="https://hiring.dproz.com/signup"
						target="_blank"
						rel="noreferrer"
					>
						Post Your Job Now
					</a>
				</div>
			</section>
			<Footer />
			<LinkedInCallback />
		</>
	);
}

export default HomePage;
