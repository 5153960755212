import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
import { toast } from "sonner";

import Accordion from "../../components/Accordion";
import ExpertResumesLayout from "../../components/ExpertResumesLayout/ExpertResumesLayout";
import LoadingBars from "../../components/LoadingBars";
import TextEditor from "../../components/TextEditor";
import { getJobsRoutes } from "../../handlers/navigationItems";
import { convertArrayToUl, convertUlToArray, getLinkFromString } from "../../handlers/utilityFunctions";
import { stripHTML } from "../../handlers/validationFunctions";
import { returnDocumentMIMEType } from "../../handlers/valueTransformation";
import dropDownOptions from "../../seed/dropDownOptions";
import { ResumeDataService } from "../../services/create-resume/resume-data";
import FelidsOfStudy from "../JobPostFormComponents/FeildsOfStudy";
import RelevantMajors from "../JobPostFormComponents/RelevantMajors";
import LeadsList from "../SharedLeads/LeadsList";
import JobDescriptionModal from "./JobDescriptionModal";

const workEnvironments = ["Remote", "In-office", "Hybrid"];
const employmentTypes = ["Full-Time", "Part-Time", "Contractor", "Internship", "Temporary"];

function JobPostForm({ ignoreLayout, disableJobDescription, jobItem, setJobLink }) {
	const jobService = new ResumeDataService();

	localStorage.setItem("isCollapsed", "true");

	const [companies, setCompanies] = useState([]);
	const [errors, setErrors] = useState([]);
	const [loading, setLoading] = useState(true);
	const [jobLoading, setJobLoading] = useState(false);

	function getCompanies(companyName) {
		setLoading(true);
		jobService.getCompanies(
			"",
			companyName,
			"",
			(response) => {
				setCompanies(response.data);
				setLoading(false);
			},
			(error) => {
				setLoading(false);
				setErrors((prev) => {
					return error?.response?.data?.message ? [...prev, ...error.response.data.message] : prev;
				});
			},
		);
	}

	const [companyName, setCompanyName] = useState("");

	useEffect(() => {
		if (companyName.length > 2) {
			getCompanies(companyName);
		}
	}, [companyName]);

	const [companyOptions, setCompanyOptions] = useState([]);

	useEffect(() => {
		if (companies.length > 0) {
			const data = companies.map((company) => {
				return {
					value: company._id,
					label: company.company_name,
				};
			});

			setCompanyOptions(data);
		}
	}, [companies]);

	useEffect(() => {}, [companyOptions]);

	const [jobDescription, setJobDescription] = useState({ lookingToHire: "", responsibilities: "", qualifications: "" });
	const [additionalInformation, setAdditionalInformation] = useState("");
	const [benefits, setBenefits] = useState("");
	const [applicationProcess, setApplicationProcess] = useState("");
	const [travelRequirements, setTravelRequirements] = useState("");
	const [workHours, setWorkHours] = useState("");
	const [diversityAndInclusionStatement, setDiversityAndInclusionStatement] = useState("");

	const [jobDetails, setJobDetails] = useState({
		jobTitle: "",
		jobProcessingType: "",
		jdReferenceId: "",
		hiringPointOfContact: {
			emailAddress: "",
			firstName: "",
			lastName: "",
			phoneNumber: "",
		},
		numberOfOpening: 1,
		employmentType: "",
		companyReferenceId: "",
		workEnvironment: "",
		jobLocation: {
			city: "",
			region: "",
			country: "",
		},
		jobStreetAddress: "",
		jobExpertise: "",
		jobDescription: {
			lookingToHire: "",
			responsibilities: [],
			qualifications: [],
		},
		additionalInformation: "",
		deadlineDate: "",
		startingDate: "",
		experienceQualification: {
			minimumLevelOfExperience: "",
			preferredYearsOfExperience: "",
		},
		skillsQualifications: [],
		educationQualification: {
			minEducationLevel: "",
			preferredEducationLevel: "",
			relevantFieldOfStudies: [],
			relevantMajors: [],
		},
		salary: {
			payOption: "",
			minPay: "1",
			maxPay: "1",
			payCurrency: "TZS",
			payFrequency: "",
		},
		benefits: "",
		applicationProcess: "",
		travelRequirements: "",
		workHours: "",
		diversityAndInclusionStatement: "",
	});

	const [jobDescriptionID, setJobDescriptionID] = useState("");
	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const jdID = urlParams.get("jdID");
		if (jdID) {
			setJobDetails({
				...jobDetails,
				jdReferenceId: jdID,
			});
			setJobDescriptionID(jdID);
		}
	}, []);

	const [jobDescFile, setJobDescFile] = useState({
		fileName: "",
		data: "",
	});

	useEffect(() => {
		if (jobDescriptionID === "") return;
		jobService.getJobDescriptionByID(
			jobDescriptionID,
			(res) => {
				const { data } = res;
				setJobDetails({
					...jobDetails,
					jobTitle: data.title,
					jobProcessingType: data.screeningMode,
					hiringPointOfContact: {
						...jobDetails?.hiringPointOfContact,
						emailAddress: data.hiringPointOfContact.emailAddress,
						firstName: data.hiringPointOfContact.firstName,
						lastName: data.hiringPointOfContact.lastName,
					},
				});
				setJobDescFile({
					fileName: data.fileName,
					data: data.detailBase64OrURL,
				});
			},
			(e) => {
				setLoading(false);
				const error = e.response.data;
				setErrors(error);
				toast.error("Something went wrong!");
			},
		);
	}, [jobDescriptionID]);

	useEffect(() => {
		if (jobItem?._id) {
			setJobLoading(true);
			jobService.getJobById(
				jobItem?._id,
				(response) => {
					setJobLoading(false);
					const data = response.data;
					const responsibilities = Array.isArray(data.jobDescription.responsibilities)
						? [...data.jobDescription.responsibilities]
						: data.jobDescription.responsibilities
							? JSON.parse(data.jobDescription.responsibilities).map((item) => {
									return {
										bp: true,
										value: item,
									};
								})
							: [];
					const qualifications = Array.isArray(data.jobDescription.qualifications)
						? [...data.jobDescription.qualifications]
						: [];

					setJobLink(getLinkFromString(data.additionalInformation));
					setJobDetails({
						jobTitle: data.jobTitle,
						numberOfOpening: data.numberOfOpening,
						additionalInformation: data.additionalInformation,
						employmentType: data.employmentType,
						companyReferenceId: data.company,
						companyName: data.companyName,
						workEnvironment: data.workEnvironment || workEnvironments[1],
						jobLocation: {
							city: data.jobLocation.city,
							region: data.jobLocation.region,
							country: data.jobLocation.country,
						},
						jobProcessingType: data.jobProcessingType,
						hiringPointOfContact: {
							emailAddress: data.hiringPointOfContact.emailAddress,
							firstName: data.hiringPointOfContact.firstName,
							lastName: data.hiringPointOfContact.lastName,
							phoneNumber: data.hiringPointOfContact.phoneNumber,
						},
						jobStreetAddress: data.jobStreetAddress,
						jobExpertise: data.jobExpertise,
						jobDescription: {
							lookingToHire: data.jobDescription.lookingToHire,
							responsibilities: responsibilities,
							qualifications: qualifications,
						},
						deadlineDate: dayjs(`${data.deadlineDate.year}-${data.deadlineDate.month}-${data.deadlineDate.day}`).format(
							"YYYY-MM-DD",
						),
						startingDate: dayjs(`${data.startingDate.year}-${data.startingDate.month}-${data.startingDate.day}`).format(
							"YYYY-MM-DD",
						),
						experienceQualification: {
							minimumLevelOfExperience: data.experienceQualification.minimumLevelOfExperience,
							preferredYearsOfExperience: data.experienceQualification.preferredYearsOfExperience,
						},
						skillsQualifications: data.skillsQualifications ? [...data.skillsQualifications] : [],
						educationQualification: {
							minEducationLevel: data.educationQualification.minEducationLevel,
							preferredEducationLevel: data.educationQualification.preferredEducationLevel,
							relevantFieldOfStudies: [...data.educationQualification.relevantFieldOfStudies],
							relevantMajors: [...data.educationQualification.relevantMajors],
						},
						salary: {
							payOption: data.salary.payOption,
							minPay: data.salary.minPay ? data.salary.minPay : "1",
							maxPay: data.salary.maxPay ? data.salary.maxPay : "1",
							payCurrency: data.salary.payCurrency,
							payFrequency: data.salary.payFrequency,
						},
						benefits: convertArrayToUl(data?.benefits || []),
						applicationProcess: data?.applicationProcess || "",
						travelRequirements: data?.travelRequirements,
						workHours: data?.workHours,
						diversityAndInclusionStatement: data?.diversityAndInclusionStatement,
					});

					setAdditionalInformation(data.additionalInformation);
					setSkills(data.skillsQualifications ? data.skillsQualifications.join("\n") : "");
					setRelevantFieldOfStudies(data.educationQualification.relevantFieldOfStudies);
					setRelevantMajors(data.educationQualification.relevantMajors);
					setJobDescription({
						lookingToHire: `<p>${data.jobDescription.lookingToHire}</p><p><br>`,
						responsibilities: `</p><p><strong>Responsibilities:</strong></p>${convertArrayToUl(
							responsibilities,
						)}<p><br>`,
						qualifications: `</p><p><strong>Qualifications:</strong></p>${convertArrayToUl(qualifications)}`,
					});
					setBenefits(convertArrayToUl(data?.benefits || []));
					setApplicationProcess(data?.applicationProcess || "");
					setTravelRequirements(data?.travelRequirements || "");
					setWorkHours(data?.workHours || "");
					setDiversityAndInclusionStatement(data?.diversityAndInclusionStatement || "");
				},
				onError,
			);
		}
	}, [jobItem?._id]);

	const [skills, setSkills] = useState("");
	const [relevantFieldOfStudies, setRelevantFieldOfStudies] = useState([]);
	const [relevantMajors, setRelevantMajors] = useState([]);
	const [submitPhase, setSubmitPhase] = useState(false);

	useEffect(() => {
		setJobDetails({
			...jobDetails,
			educationQualification: {
				...jobDetails?.educationQualification,
				relevantFieldOfStudies: relevantFieldOfStudies,
			},
		});
	}, [relevantFieldOfStudies]);

	useEffect(() => {
		setJobDetails({
			...jobDetails,
			educationQualification: {
				...jobDetails?.educationQualification,
				relevantMajors: relevantMajors,
			},
		});
	}, [relevantMajors]);

	useEffect(() => {
		setJobDetails({
			...jobDetails,
			skillsQualifications: skills,
		});
	}, [skills]);

	useEffect(() => {}, [jobDetails]);

	const [submitLoading, setSubmitLoading] = useState(false);
	const [successJobMarketID, setSuccessJobMarketID] = useState("");
	const onSuccess = (data) => {
		setSubmitLoading(false);
		setSubmitPhase(false);

		if (jobItem?._id || jobDescriptionID) {
			toast.success("Job Updated Successfully");
		} else {
			setJobDetails({
				jobTitle: "",
				numberOfOpening: 1,
				employmentType: "",
				companyReferenceId: "",
				workEnvironment: "",
				jobProcessingType: "",
				hiringPointOfContact: {
					emailAddress: "",
					firstName: "",
					lastName: "",
					phoneNumber: "",
				},
				jobLocation: {
					city: "",
					region: "",
					country: "",
				},
				jobStreetAddress: "",
				jobExpertise: "",
				jobDescription: {
					lookingToHire: "",
					responsibilities: [],
					qualifications: [],
				},
				deadlineDate: "",
				startingDate: "",
				experienceQualification: {
					minimumLevelOfExperience: "",
					preferredYearsOfExperience: "",
				},
				skillsQualifications: [],
				educationQualification: {
					minEducationLevel: "",
					preferredEducationLevel: "",
					relevantFieldOfStudies: [],
					relevantMajors: [],
				},
				salary: {
					payOption: "",
					minPay: "",
					maxPay: "",
					payCurrency: "TZS",
					payFrequency: "",
				},
				benefits: "",
				applicationProcess: "",
				travelRequirements: "",
				workHours: "",
				diversityAndInclusionStatement: "",
			});
			setSkills("");
			setRelevantFieldOfStudies([]);
			setRelevantMajors([]);
			setJobDescription({ lookingToHire: "", responsibilities: "", qualifications: "" });
			toast.success("Job Posted Successfully");
			setSuccessJobMarketID(data.data._id);
		}
	};

	const onError = (error) => {
		setSubmitLoading(false);
		setJobLoading(false);
		setErrors(error?.response?.data?.message);
		toast.error(error?.response?.data?.message);
	};

	function onSubmit(e) {
		e.preventDefault();
		setSubmitPhase(true);

		setErrors([]);

		let newErrors = [];
		let validationErrors = {};
		let conditionalExclude = [];

		if (jobDetails?.companyReferenceId === "") {
			newErrors.push("Company is required");
			validationErrors.companyReferenceId = "Please select a company";
		}

		if (jobDetails?.jobProcessingType === "") {
			newErrors.push("Job processing type is required");
			validationErrors.jobProcessingType = "Please select job processing type";
		}

		if (jobDetails?.jobTitle === "") {
			newErrors.push("Job Title is required");
			validationErrors.jobTitle = "Please enter a job title";
		}

		if (!employmentTypes.includes(jobDetails?.employmentType)) {
			newErrors.push("Employment Type is required");
			validationErrors.employmentType = "Please select an employment type";
		}

		if (!workEnvironments.includes(jobDetails?.workEnvironment)) {
			newErrors.push("Work Environment is required");
			validationErrors.workEnvironment = "Please select work environment";
		}

		if (jobDetails?.jobLocation?.city === "") {
			newErrors.push("City is required");
			validationErrors.jobLocation = {
				...validationErrors.jobLocation,
				city: "Please enter a city",
			};
		}

		if (jobDetails?.jobLocation?.region === "") {
			newErrors.push("Region is required");
			validationErrors.jobLocation = {
				...validationErrors.jobLocation,
				region: "Please enter a region",
			};
		}

		if (jobDetails?.jobLocation?.country === "") {
			newErrors.push("Country is required");
			validationErrors.jobLocation = {
				...validationErrors.jobLocation,
				country: "Please enter a country",
			};
		}

		if (jobDetails?.hiringPointOfContact?.emailAddress === "") {
			//  newErrors.push("Email is required");
			setJobDetails({
				...jobDetails,
				hiringPointOfContact: {
					...jobDetails?.hiringPointOfContact,
					emailAddress: "NA",
				},
			});
		}
		if (jobDetails?.hiringPointOfContact?.firstName === "") {
			// newErrors.push("First Name is required");
			setJobDetails({
				...jobDetails,
				hiringPointOfContact: {
					...jobDetails?.hiringPointOfContact,
					firstName: "NA",
				},
			});
		}
		if (jobDetails?.hiringPointOfContact?.lastName === "") {
			// newErrors.push("Last Name is required");
			setJobDetails({
				...jobDetails,
				hiringPointOfContact: {
					...jobDetails?.hiringPointOfContact,
					lastName: "NA",
				},
			});
		}
		if (jobDetails?.hiringPointOfContact?.phoneNumber === "") {
			// newErrors.push("Phone number is required");
			setJobDetails({
				...jobDetails,
				hiringPointOfContact: {
					...jobDetails?.hiringPointOfContact,
					phoneNumber: "NA",
				},
			});
		}

		if (!dropDownOptions.jobExpertiseOptions.find((item) => item === jobDetails?.jobExpertise)) {
			newErrors.push("Job Expertise is required");
			validationErrors.jobExpertise = "Please select a job expertise";
		}

		if (jobDetails?.deadlineDate === "" || jobDetails?.deadlineDate === "Invalid Date") {
			newErrors.push("Deadline Date is required");
			validationErrors.deadlineDate = "Please enter a deadline date";
		}

		if (jobDetails?.educationQualification?.minEducationLevel === "") {
			newErrors.push("Minimum Education Level is required");
			validationErrors.educationQualification = {
				...validationErrors.educationQualification,
				minEducationLevel: "Please enter a minimum education level",
			};
		}

		if (jobDetails?.salary?.payOption !== "range") {
			conditionalExclude.push("minPay");
		}

		if (newErrors.length > 0) {
			setErrors((prev) => {
				return {
					...prev,
					...validationErrors,
				};
			});
			window.scrollTo(0, 0);
			return;
		}

		const lookingToHire = jobDescription.lookingToHire;

		const newJobDetails = {
			...jobDetails,
			additionalInformation: stripHTML(additionalInformation),
			jobDescription: {
				...jobDetails?.jobDescription,
				lookingToHire: lookingToHire,
				responsibilities: convertUlToArray(
					jobDescription?.responsibilities?.split("<p><strong>Responsibilities:</strong></p>")?.[1] || "",
				),
				qualifications: convertUlToArray(
					jobDescription?.qualifications?.split("<p><strong>Qualifications:</strong></p>")?.[1] || "",
				),
			},
		};

		const requestData = {
			jobTitle: newJobDetails.jobTitle,
			numberOfOpening: newJobDetails.numberOfOpening,
			employmentType: newJobDetails.employmentType,
			companyReferenceId: newJobDetails.companyReferenceId,
			jdReferenceId: jobDescriptionID,
			additionalInformation: newJobDetails.additionalInformation,
			workEnvironment: newJobDetails.workEnvironment,
			jobProcessingType: newJobDetails.jobProcessingType,
			hiringPointOfContact: {
				emailAddress: newJobDetails.hiringPointOfContact.emailAddress,
				firstName: newJobDetails.hiringPointOfContact.firstName,
				lastName: newJobDetails.hiringPointOfContact.lastName,
				phoneNumber: newJobDetails.hiringPointOfContact.phoneNumber,
			},
			jobLocation: {
				city: newJobDetails.jobLocation.city,
				region: newJobDetails.jobLocation.region,
				country: newJobDetails.jobLocation.country,
			},
			jobStreetAddress: newJobDetails.jobStreetAddress,
			jobExpertise: newJobDetails.jobExpertise,
			jobDescription: {
				lookingToHire: newJobDetails.jobDescription.lookingToHire,
				responsibilities: [...newJobDetails.jobDescription.responsibilities],
				qualifications: [...newJobDetails.jobDescription.qualifications],
			},
			deadlineDate: {
				day: dayjs(newJobDetails.deadlineDate).format("DD"),
				month: dayjs(newJobDetails.deadlineDate).format("MM"),
				year: dayjs(newJobDetails.deadlineDate).format("YYYY"),
			},
			startingDate: {
				day: dayjs(newJobDetails.startingDate).format("DD"),
				month: dayjs(newJobDetails.startingDate).format("MM"),
				year: dayjs(newJobDetails.startingDate).format("YYYY"),
			},
			experienceQualification: {
				minimumLevelOfExperience: newJobDetails.experienceQualification.minimumLevelOfExperience,
				preferredYearsOfExperience: newJobDetails.experienceQualification.preferredYearsOfExperience,
			},
			// TODO: Fix this
			skillsQualifications: [],
			// skillsQualifications: [...newJobDetails.skillsQualifications].map((item) => ({ bp: item.bp, value: item.value })),
			educationQualification: {
				minEducationLevel: newJobDetails.educationQualification.minEducationLevel,
				preferredEducationLevel: newJobDetails.educationQualification.preferredEducationLevel,
				relevantFieldOfStudies: [...newJobDetails.educationQualification.relevantFieldOfStudies],
				relevantMajors: [...newJobDetails.educationQualification.relevantMajors],
			},
			salary: {
				payOption: newJobDetails.salary.payOption,
				minPay: newJobDetails.salary.minPay,
				maxPay: newJobDetails.salary.maxPay,
				payCurrency: newJobDetails.salary.payCurrency,
				payFrequency: newJobDetails.salary.payFrequency,
			},
			benefits: convertUlToArray(benefits),
			applicationProcess: applicationProcess,
			travelRequirements: travelRequirements,
			workHours: workHours,
			diversityAndInclusionStatement: diversityAndInclusionStatement,
		};

		setSubmitLoading(true);

		if (jobItem?._id) {
			jobService.updateJobPost(jobItem?._id, requestData, onSuccess, onError);
		} else {
			jobService.createJobPost(requestData, onSuccess, onError);
		}
	}

	useEffect(() => {}, [errors]);
	useEffect(() => {}, [jobDescFile]);

	const [showJDModal, setShowJDModal] = useState(false);

	const [loadingJobDescription, setLoadingJobDescription] = useState(false);

	function searchForJD() {
		setLoadingJobDescription(true);
		jobService.getJobDescriptions(
			(response) => {
				setLoadingJobDescription(false);
				const jobDescriptions = response.data;
				const selectedJobDescription = jobDescriptions.find(
					(jobDescription) => jobDescription.jobMarketReferenceId === jobItem?._id,
				);
				if (!selectedJobDescription) {
					toast.error("No Job Description Found");
					return;
				}
				setJobDescFile({
					fileName: selectedJobDescription.fileName,
					data: selectedJobDescription.detailBase64OrURL,
				});
			},
			(error) => {
				setLoadingJobDescription(false);
				toast.error(error?.response?.data?.message);
			},
		);
	}

	const jobPostForm = (
		<div className="row">
			<div className={`col left ${!ignoreLayout && "p-4 container border rounded shadow"}`}>
				{!ignoreLayout && (
					<div className="d-flex justify-content-between mb-4 align-items-center">
						<h3 className="mt-4">Post a Job {!jobItem?._id && !jobDescriptionID && "Without Job Description"}</h3>
					</div>
				)}
				{(errors?.length || 0) > 0 && (
					<div className="alert alert-danger">
						{Array.isArray(errors) ? errors.map((error, index) => <div key={index}>{error}</div>) : errors}
					</div>
				)}

				{jobLoading ? (
					<div
						style={{
							width: "100%",
							padding: "100px 0",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<LoadingBars />
					</div>
				) : (
					<form className="needs-validation row" onSubmit={(e) => e.preventDefault()}>
						<div className="col-12 mb-4">
							<button
								className="btn btn-sm btn-primary rounded-pill p-2 px-3 lh-1 d-flex align-items-center justify-content-center"
								onClick={onSubmit}
							>
								<i
									className="bi bi-pencil-square mr-2 p-0"
									style={{
										fontSize: "1rem",
									}}
								/>
								{submitLoading ? <Spinner /> : "Save"}
							</button>
						</div>
						<div className={`${!ignoreLayout ? "col-md-7" : "col-md-12"} space-y-6 mb-6`}>
							{/* Footer  */}
							<Accordion
								label="Metadata"
								errorStatus={
									(errors?.companyReferenceId && errors?.companyReferenceId !== "") ||
									(errors?.jobTitle && errors?.jobTitle !== "") ||
									(errors?.employmentType && errors?.employmentType !== "") ||
									(errors?.jobExpertise && errors?.jobExpertise !== "") ||
									(errors?.workEnvironment && errors?.workEnvironment !== "") ||
									(errors?.deadlineDate && errors?.deadlineDate !== "") ||
									(errors?.startingDate && errors?.startingDate !== "")
								}
							>
								<div className="row">
									<div className="col-12 mb-3">
										<label htmlFor="companyReferenceId" className="form-label font-bold">
											<i className="bi bi-building mr-2" />
											Company
										</label>
										{(jobDetails?.companyReferenceId?.length || 0) > 0 ||
											(errors?.companyReferenceId && errors?.companyReferenceId !== "" && (
												<div className="text-danger m-0">{errors?.companyReferenceId}</div>
											))}
										{jobDetails?.companyReferenceId === "" ? (
											<Select
												className="basic-single "
												classNamePrefix="select"
												isLoading={loading}
												isSearchable={true}
												name="companyReferenceId"
												onInputChange={(e) => setCompanyName(e)}
												placeholder="Search Company"
												options={companyOptions}
												onChange={(e) => {
													setJobDetails({
														...jobDetails,
														companyReferenceId: e.value,
														companyName: e.label,
													});
												}}
											/>
										) : (
											<select
												className={`${
													!((jobDetails?.companyReferenceId?.length || 0) > 0) &&
													false &&
													"select-box-not-selected-error"
												} form-select form-control `}
												value={jobDetails?.companyReferenceId}
												onChange={(e) =>
													setJobDetails({
														...jobDetails,
														companyReferenceId: e.target.value,
													})
												}
											>
												<option value="">Change Company</option>
												<option value={jobDetails?.companyReferenceId}>{jobDetails?.companyName}</option>
											</select>
										)}
									</div>
									<div className="mb-3 col-12">
										<div
											style={{
												display: "flex",
												flexDirection: "row",
												alignContent: "flex-end",
											}}
										>
											<div
												style={{
													width: "100%",
												}}
											>
												<label htmlFor="jobTitle" className="form-label font-bold">
													<i className="bi bi-briefcase mr-2" />
													Job Title
												</label>
												{jobDetails?.jobTitle?.toString()?.replace(/\s/g, "")?.length > 0 ||
													(errors?.jobTitle && errors?.jobTitle !== "" && (
														<div className="text-danger m-0">{errors?.jobTitle}</div>
													))}
												<input
													type="text"
													className="form-control"
													onChange={(e) =>
														setJobDetails({
															...jobDetails,
															jobTitle: e.target.value,
														})
													}
													value={jobDetails?.jobTitle}
													id="jobTitle"
												/>
											</div>
											<div className="input-cross-tick-container">
												<i
													className={`fa text-input-cross-tick ${
														(submitPhase && !(errors?.jobTitle !== "")) ||
														(jobDetails?.jobTitle?.toString()?.replace(/\s/g, "")?.length > 0 &&
															"fa-check text-success")
													}`}
												/>
											</div>
										</div>
									</div>

									<div className="mb-3 col-6">
										<label htmlFor="employmentType" className="form-label font-bold">
											<i className="bi bi-bag mr-2" />
											Employment Type
										</label>
										{errors?.employmentType && errors?.employmentType !== "" && (
											<div className="text-danger m-0">{errors?.employmentType}</div>
										)}
										<select
											className={`${
												jobDetails?.employmentType?.length > 0 && false && "select-box-not-selected-error"
											} form-select form-control `}
											onChange={(e) =>
												setJobDetails({
													...jobDetails,
													employmentType: e.target.value,
												})
											}
											value={jobDetails?.employmentType}
											id="employmentType"
										>
											<option value="">Select Employment Type</option>
											<option value="Full-Time">Full-Time</option>
											<option value="Part-Time">Part-Time</option>
											<option value="Contractor">Contractor</option>
											<option value="Internship">Internship</option>
											<option value="Temporary">Temporary</option>
										</select>
									</div>

									<div className="mb-3 col-md-6">
										<div
											style={{
												display: "flex",
												flexDirection: "row",
												alignContent: "flex-end",
											}}
										>
											<div style={{ width: "100%" }}>
												<label htmlFor="numberOfOpening" className="form-label font-bold">
													<i className="bi bi-people mr-2" />
													Number of Openings
												</label>
												{jobDetails?.numberOfOpening > 0 ||
													(errors?.numberOfOpening && errors?.numberOfOpening !== "" && (
														<div className="text-danger m-0">{errors?.numberOfOpening}</div>
													))}
												<input
													type="number"
													className="form-control"
													onChange={(e) =>
														setJobDetails({
															...jobDetails,
															numberOfOpening: e.target.value,
														})
													}
													value={jobDetails?.numberOfOpening}
													id="numberOfOpening"
												/>
											</div>
											<div className="input-cross-tick-container">
												{/* <i
														className={`fa text-input-cross-tick ${
															!(errors?.numberOfOpening && errors?.numberOfOpening !== "")
																? "fa-check text-success"
																: "fa-times text-danger"
														}`}
													/> */}
												<i
													className={`fa text-input-cross-tick ${
														(submitPhase && !(errors?.numberOfOpening !== "")) ||
														(jobDetails?.numberOfOpening > 0 && "fa-check text-success")
													}`}
												/>
											</div>
										</div>
									</div>

									<div className="mb-3 col-6">
										<label htmlFor="jobExpertise" className="form-label font-bold">
											<i className="bi bi-bag-plus mr-2" />
											Job Expertise
										</label>
										{errors?.jobExpertise && errors?.jobExpertise !== "" && (
											<div className="text-danger m-0">{errors?.jobExpertise}</div>
										)}
										<select
											className={`${
												jobDetails?.jobExpertise?.length > 0 && false && "select-box-not-selected-error"
											} form-select form-control `}
											id="jobExpertise"
											onChange={(e) =>
												setJobDetails({
													...jobDetails,
													jobExpertise: e.target.value,
												})
											}
											value={jobDetails?.jobExpertise}
										>
											<option value="">Select Job Expertise</option>
											{dropDownOptions.jobExpertiseOptions.map((val, index) => (
												<option value={val} key={index}>
													{val}
												</option>
											))}
										</select>
									</div>

									<div className="mb-3 col-6">
										<label htmlFor="workEnvironment" className="form-label font-bold">
											<i className="bi bi-pin-map mr-2" />
											Work Environment
										</label>
										{errors?.workEnvironment && errors?.workEnvironment !== "" && (
											<div className="text-danger m-0">{errors?.workEnvironment}</div>
										)}
										<select
											className="form-select form-control "
											onChange={(e) =>
												setJobDetails({
													...jobDetails,
													workEnvironment: e.target.value,
												})
											}
											value={jobDetails?.workEnvironment}
											id="workEnvironment"
										>
											<option value="">Select</option>
											<option value="Remote">Remote</option>
											<option value="In-office">In-office</option>
											<option value="Hybrid">Hybrid</option>
										</select>
									</div>

									{/* dates */}
									<div className="col-6">
										<div className="mb-3">
											<label htmlFor="deadlineDate" className="form-label font-bold">
												<i className="bi bi-calendar-date mr-2" />
												Application Deadline
											</label>
											{errors?.deadlineDate && errors?.deadlineDate !== "" && (
												<div className="text-danger m-0">{errors?.deadlineDate}</div>
											)}
											<input
												type="date"
												className={`${
													jobDetails?.deadlineDate?.length > 0 && false && "select-box-not-selected-error"
												}  form-control `}
												onChange={(e) =>
													setJobDetails({
														...jobDetails,
														deadlineDate: e.target.value,
													})
												}
												value={jobDetails?.deadlineDate}
												id="deadlineDate"
											/>
										</div>
									</div>
									<div className="col-6">
										<div className="mb-3">
											<label htmlFor="startingDate" className="form-label font-bold">
												<i className="bi bi-calendar mr-2" />
												Starting Date
											</label>
											{jobDetails?.startingDate?.toString()?.replace(/\s/g, "")?.length > 0 ||
												(errors?.startingDate && errors?.startingDate !== "" && (
													<div className="text-danger m-0">{errors?.startingDate}</div>
												))}
											<input
												type="date"
												className={`${
													jobDetails?.startingDate?.length > 0 && false && "select-box-not-selected-error"
												}  form-control `}
												onChange={(e) =>
													setJobDetails({
														...jobDetails,
														startingDate: e.target.value,
													})
												}
												value={jobDetails?.startingDate}
												id="startingDate"
											/>
										</div>
									</div>
								</div>
							</Accordion>
							{/* Job Description */}
							<Accordion label="Job Description">
								<div className="">
									<div className="mb-3">
										<label htmlFor="lookingToHire" className="form-label font-bold mb-0 d-flex justify-content-between">
											<span>
												<i className="bi bi-bag-check mr-2" />
												Job Description
											</span>
											<button className="btn btn-sm btn-success" onClick={() => setShowJDModal(!showJDModal)}>
												{!showJDModal && (
													<i
														className="bi bi-pencil-square mr-2 p-0"
														style={{
															fontSize: "1rem",
														}}
													/>
												)}
												{jobDescription === ""
													? "Add Job Description"
													: !showJDModal
														? "Edit Job Description"
														: "Close Edit Job Description"}
											</button>
										</label>
										{errors?.jobDescription &&
											errors?.jobDescription?.lookingToHire &&
											errors?.jobDescription?.lookingToHire !== "" && (
												<div className="text-danger m-0">{errors?.jobDescription?.lookingToHire}</div>
											)}
										{errors?.jobDescription &&
											errors?.jobDescription?.responsibilities &&
											errors?.jobDescription?.responsibilities !== "" && (
												<div className="text-danger m-0">{errors?.jobDescription?.responsibilities}</div>
											)}
										{errors?.jobDescription &&
											errors?.jobDescription?.qualifications &&
											errors?.jobDescription?.qualifications !== "" && (
												<div className="text-danger m-0">{errors?.jobDescription?.qualifications}</div>
											)}

										{!showJDModal && jobDescription !== "" && (
											<div
												className="h-96 overflow-y-auto job-description-contents"
												dangerouslySetInnerHTML={{
													__html:
														jobDescription.lookingToHire +
														jobDescription.responsibilities +
														jobDescription.qualifications,
												}}
											/>
										)}
										{showJDModal && (
											<JobDescriptionModal
												setJobDescription={setJobDescription}
												jobDescription={jobDescription}
												setShowJDModal={setShowJDModal}
											/>
										)}
									</div>
								</div>
							</Accordion>
							<Accordion
								label="Computed Qualifications"
								errorStatus={
									(errors?.jobStreetAddress && errors?.jobStreetAddress !== "") ||
									(errors?.jobLocation && errors?.jobLocation?.city && errors?.jobLocation?.city !== "") ||
									(errors?.jobLocation && errors?.jobLocation?.region && errors?.jobLocation?.region !== "") ||
									(errors?.jobLocation && errors?.jobLocation?.country && errors?.jobLocation?.country !== "") ||
									(errors?.experienceQualification &&
										errors?.experienceQualification?.minimumLevelOfExperience &&
										errors?.experienceQualification?.minimumLevelOfExperience !== "") ||
									(errors?.experienceQualification &&
										errors?.experienceQualification?.preferredYearsOfExperience &&
										errors?.experienceQualification?.preferredYearsOfExperience !== "") ||
									(errors?.educationQualification &&
										errors?.educationQualification?.minEducationLevel &&
										errors?.educationQualification?.minEducationLevel !== "") ||
									(errors?.educationQualification &&
										errors?.educationQualification?.preferredEducationLevel &&
										errors?.educationQualification?.preferredEducationLevel !== "")
								}
							>
								<div className="row">
									{/* Location  */}
									<div className="col-12">
										<label htmlFor="" className="form-label font-bold">
											{" "}
											<i className="bi bi-geo-alt mr-2" /> Job Location
										</label>
									</div>
									<div className="col-6">
										<div className="col">
											<div className="mb-3">
												<div
													style={{
														display: "flex",
														flexDirection: "row",
														alignContent: "flex-end",
													}}
												>
													<div style={{ width: "100%" }}>
														<label htmlFor="jobStreetAddress" className="form-label font-bold">
															Street
														</label>
														{jobDetails?.jobStreetAddress?.toString()?.replace(/\s/g, "")?.length > 0 ||
															(errors?.jobStreetAddress && errors?.jobStreetAddress !== "" && (
																<div className="text-danger m-0">{errors?.jobStreetAddress}</div>
															))}
														<input
															type="text"
															className="form-control"
															onChange={(e) =>
																setJobDetails({
																	...jobDetails,
																	jobStreetAddress: e.target.value,
																})
															}
															value={jobDetails?.jobStreetAddress}
															id="jobStreetAddress"
														/>
													</div>
													<div className="input-cross-tick-container">
														<i
															className={`fa text-input-cross-tick ${
																(submitPhase && !(errors?.jobStreetAddress !== "")) ||
																(jobDetails?.jobStreetAddress?.toString()?.replace(/\s/g, "")?.length > 0 &&
																	"fa-check text-success")
															}`}
														/>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="col-6">
										<div className="mb-3">
											<div
												style={{
													display: "flex",
													flexDirection: "row",
													alignContent: "flex-end",
												}}
											>
												<div style={{ width: "100%" }}>
													<label htmlFor="city" className="form-label font-bold">
														City
													</label>
													{jobDetails?.jobLocation?.city?.toString()?.replace(/\s/g, "")?.length > 0 ||
														(errors?.jobLocation && errors?.jobLocation?.city && errors?.jobLocation?.city !== "" && (
															<div className="text-danger m-0">{errors?.jobLocation?.city}</div>
														))}
													<input
														type="text"
														className="form-control"
														onChange={(e) =>
															setJobDetails({
																...jobDetails,
																jobLocation: {
																	...jobDetails?.jobLocation,
																	city: e.target.value,
																},
															})
														}
														value={jobDetails?.jobLocation?.city}
														id="city"
													/>
												</div>
												<div className="input-cross-tick-container">
													<i
														className={`fa text-input-cross-tick ${
															(submitPhase && !(errors?.jobLocation && errors?.jobLocation?.city !== "")) ||
															(jobDetails?.jobLocation?.city?.toString()?.replace(/\s/g, "")?.length > 0 &&
																"fa-check text-success")
														}`}
													/>
												</div>
											</div>
										</div>
									</div>
									<div className="col-6">
										<div className="mb-3">
											<div
												style={{
													display: "flex",
													flexDirection: "row",
													alignContent: "flex-end",
												}}
											>
												<div style={{ width: "100%" }}>
													<label htmlFor="region" className="form-label font-bold">
														Region
													</label>
													{jobDetails?.jobLocation?.region?.toString()?.replace(/\s/g, "")?.length > 0 ||
														(errors?.jobLocation &&
															errors?.jobLocation?.region &&
															errors?.jobLocation?.region !== "" && (
																<div className="text-danger m-0">{errors?.jobLocation?.region}</div>
															))}
													<input
														type="text"
														className="form-control"
														onChange={(e) =>
															setJobDetails({
																...jobDetails,
																jobLocation: {
																	...jobDetails?.jobLocation,
																	region: e.target.value,
																},
															})
														}
														value={jobDetails?.jobLocation?.region}
														id="region"
													/>
												</div>
												<div className="input-cross-tick-container">
													<i
														className={`fa text-input-cross-tick ${
															(submitPhase && !(errors?.jobLocation && errors?.jobLocation?.region !== "")) ||
															(jobDetails?.jobLocation?.region?.toString()?.replace(/\s/g, "")?.length > 0 &&
																"fa-check text-success")
														}`}
													/>
												</div>
											</div>
										</div>
									</div>
									<div className="col-6">
										<div className="mb-3">
											<div
												style={{
													display: "flex",
													flexDirection: "row",
													alignContent: "flex-end",
												}}
											>
												<div style={{ width: "100%" }}>
													<label htmlFor="country" className="form-label font-bold">
														Country
													</label>
													{jobDetails?.jobLocation?.country?.toString()?.replace(/\s/g, "")?.length > 0 ||
														(errors?.jobLocation &&
															errors?.jobLocation?.country &&
															errors?.jobLocation?.country !== "" && (
																<div className="text-danger m-0">{errors?.jobLocation?.country}</div>
															))}
													<input
														type="text"
														className="form-control"
														onChange={(e) =>
															setJobDetails({
																...jobDetails,
																jobLocation: {
																	...jobDetails?.jobLocation,
																	country: e.target.value,
																},
															})
														}
														value={jobDetails?.jobLocation?.country}
														id="country"
													/>
												</div>
												<div className="input-cross-tick-container">
													<i
														className={`fa text-input-cross-tick ${
															(submitPhase && !(errors?.jobLocation && errors?.jobLocation?.country !== "")) ||
															(jobDetails?.jobLocation?.country?.toString()?.replace(/\s/g, "")?.length > 0 &&
																"fa-check text-success")
														}`}
													/>
												</div>
											</div>
										</div>
									</div>

									{/* Experience and Qualifications */}
									<div className="col-6">
										<div className="mb-3">
											<label htmlFor="minimumLevelOfExperience" className="form-label font-bold">
												<i className="bi bi-bag mr-2" />
												Minimum Level of Experience
											</label>
											{jobDetails?.experienceQualification?.minimumLevelOfExperience?.toString()?.replace(/\s/g, "")
												.length > 0 ||
												(errors?.experienceQualification &&
													errors?.experienceQualification?.minimumLevelOfExperience &&
													errors?.experienceQualification?.minimumLevelOfExperience !== "" && (
														<div className="text-danger m-0">
															{errors?.experienceQualification?.minimumLevelOfExperience}
														</div>
													))}
											<select
												className={`${
													jobDetails?.experienceQualification?.minimumLevelOfExperience?.length > 0 &&
													false &&
													"select-box-not-selected-error"
												} form-select form-control `}
												id="minimumLevelOfExperience"
												onChange={(e) =>
													setJobDetails({
														...jobDetails,
														experienceQualification: {
															...jobDetails?.experienceQualification,
															minimumLevelOfExperience: e.target.value,
														},
													})
												}
												value={jobDetails?.experienceQualification?.minimumLevelOfExperience}
											>
												<option value="">Select Minimum Level of Experience</option>
												{[
													"Entry-level(0-1 yr)",
													"Junior-level(1-2 yrs)", // >1 & < 3
													"Mid-level(3-5 yrs)",
													"Senior-level(6+ yrs)",
												].map((val, index) => (
													<option value={val} key={index}>
														{val}
													</option>
												))}
											</select>
										</div>
									</div>
									<div className="col-6">
										<div className="mb-3">
											<div
												style={{
													display: "flex",
													flexDirection: "row",
													alignContent: "flex-end",
												}}
											>
												<div style={{ width: "100%" }}>
													<label htmlFor="preferredYearsOfExperoience" className="form-label font-bold">
														<i className="bi bi-bag-check mr-2" />
														Preferred Years of Experience
													</label>
													{jobDetails?.experienceQualification?.preferredYearsOfExperience
														?.toString()
														?.replace(/\s/g, "")?.length > 0 ||
														(errors?.experienceQualification &&
															errors?.experienceQualification?.preferredYearsOfExperience &&
															errors?.experienceQualification?.preferredYearsOfExperience !== "" && (
																<div className="text-danger m-0">
																	{errors?.experienceQualification?.preferredYearsOfExperience}
																</div>
															))}
													<input
														type="number"
														className="form-control"
														id="preferredYearsOfExperience"
														onChange={(e) =>
															setJobDetails({
																...jobDetails,
																experienceQualification: {
																	...jobDetails?.experienceQualification,
																	preferredYearsOfExperience: e.target.value,
																},
															})
														}
														value={jobDetails?.experienceQualification?.preferredYearsOfExperience}
													/>
												</div>
												<div className="input-cross-tick-container">
													<i
														className={`fa text-input-cross-tick ${
															(submitPhase &&
																!(
																	errors?.experienceQualification &&
																	errors?.experienceQualification?.preferredYearsOfExperience !== ""
																)) ||
															(jobDetails?.experienceQualification?.preferredYearsOfExperience
																?.toString()
																?.replace(/\s/g, "")?.length > 0 &&
																"fa-check text-success")
														}`}
													/>
												</div>
											</div>
										</div>
									</div>
									{/* Education */}
									<div className="col-6">
										<div className="mb-3">
											<label htmlFor="minEducationLevel" className="form-label font-bold">
												<i className="bi bi-mortarboard mr-2" />
												Minimum Education Level
											</label>
											{jobDetails?.educationQualification?.minEducationLevel?.toString()?.replace(/\s/g, "")?.length >
												0 ||
												(errors?.educationQualification &&
													errors?.educationQualification?.minEducationLevel &&
													errors?.educationQualification?.minEducationLevel !== "" && (
														<div className="text-danger m-0">{errors?.educationQualification?.minEducationLevel}</div>
													))}
											<select
												className={`${
													jobDetails?.educationQualification?.minEducationLevel?.length > 0 &&
													false &&
													"select-box-not-selected-error"
												} form-select form-control `}
												id="minEducationLevel"
												onChange={(e) =>
													setJobDetails({
														...jobDetails,
														educationQualification: {
															...jobDetails?.educationQualification,
															minEducationLevel: e.target.value,
														},
													})
												}
												value={jobDetails?.educationQualification?.minEducationLevel}
											>
												<option value="">Select Minimum Education Level</option>
												{[
													"O-Level",
													"A-Level",
													"CERTIFICATE",
													"DIPLOMA",
													"ADVANCED DIPLOMA",
													"BACHELOR",
													"MASTERS",
													"MBA",
													"PHD",
												].map((edu) => (
													<option value={edu} key={edu}>
														{edu}
													</option>
												))}
											</select>
										</div>
									</div>
									<div className="col-6">
										<div className="mb-3">
											<label htmlFor="preferredEducationLevel" className="form-label font-bold">
												<i className="bi bi-mortarboard mr-2" />
												Preferred Education Level
											</label>
											{jobDetails?.educationQualification?.preferredEducationLevel?.toString()?.replace(/\s/g, "")
												?.length > 0 ||
												(errors?.educationQualification &&
													errors?.educationQualification?.preferredEducationLevel &&
													errors?.educationQualification?.preferredEducationLevel !== "" && (
														<div className="text-danger m-0">
															{errors?.educationQualification?.preferredEducationLevel}
														</div>
													))}
											<select
												className={`${
													jobDetails?.educationQualification?.preferredEducationLevel?.length > 0 &&
													false &&
													"select-box-not-selected-error"
												} form-select form-control `}
												id="preferredEducationLevel"
												onChange={(e) =>
													setJobDetails({
														...jobDetails,
														educationQualification: {
															...jobDetails?.educationQualification,
															preferredEducationLevel: e.target.value,
														},
													})
												}
												value={jobDetails?.educationQualification?.preferredEducationLevel}
											>
												<option value="">Select Preferred Education Level</option>
												{[
													"O-Level",
													"A-Level",
													"CERTIFICATE",
													"DIPLOMA",
													"ADVANCED DIPLOMA",
													"BACHELOR",
													"MASTERS",
													"MBA",
													"PHD",
												].map((edu) => (
													<option value={edu} key={edu}>
														{edu}
													</option>
												))}
											</select>
										</div>
									</div>
									<div className="col-12">
										<FelidsOfStudy
											relevantFieldOfStudies={relevantFieldOfStudies}
											setRelevantFieldOfStudies={setRelevantFieldOfStudies}
											errors={errors}
										/>
									</div>
									<div className="col-12">
										<RelevantMajors
											relevantMajors={relevantMajors}
											setRelevantMajors={setRelevantMajors}
											errors={errors}
										/>
									</div>
								</div>
							</Accordion>
						</div>

						<div className={`${!ignoreLayout ? "col-md-5" : "col-md-12"} space-y-6`}>
							{/* Salary  */}
							<Accordion
								label="Pay Information"
								errorStatus={
									submitPhase &&
									!(
										jobDetails?.salary?.payOption?.toString()?.replace(/\s/g, "")?.length > 0 &&
										jobDetails?.salary?.payCurrency?.toString()?.replace(/\s/g, "")?.length > 0 &&
										jobDetails?.salary?.payFrequency?.toString()?.replace(/\s/g, "")?.length > 0 &&
										jobDetails?.salary?.minPay > 0 &&
										jobDetails?.salary?.maxPay > 0
									) &&
									(errors?.salary ||
										errors?.salary?.payCurrency !== "" ||
										errors?.salary?.payFrequency !== "" ||
										errors?.salary?.payOption !== "" ||
										errors?.salary?.minPay !== "" ||
										errors?.salary?.maxPay !== "")
								}
							>
								<div className="row">
									<div className="col-6">
										<div className="mb-3">
											<label htmlFor="option" className="form-label font-bold">
												<i className="bi bi-wallet mr-2" />
												Pay Option
											</label>
											{jobDetails?.salary?.payOption?.toString()?.replace(/\s/g, "")?.length > 0 ||
												(errors?.salary && errors?.salary?.payOption && errors?.salary?.payOption !== "" && (
													<div className="text-danger m-0">{errors?.salary?.payOption}</div>
												))}
											<select
												className={`${
													!(jobDetails?.salary?.payOption?.toString()?.replace(/\s/g, "")?.length > 0) &&
													"select-box-not-selected-error"
												} form-select form-control `}
												id="option"
												onChange={(e) =>
													setJobDetails({
														...jobDetails,
														salary: {
															...jobDetails?.salary,
															payOption: e.target.value,
														},
													})
												}
												value={jobDetails?.salary?.payOption}
											>
												<option value="">Select Pay Option</option>
												<option value="Range">Range</option>
												<option value="Exact Amount">Exact Amount</option>
											</select>
										</div>
									</div>

									<div className="col-6">
										<div className="mb-3">
											<label htmlFor="payCurrency" className="form-label font-bold">
												<i className="bi bi-cash mr-2" />
												Currency
											</label>
											{jobDetails?.salary?.payCurrency?.toString()?.replace(/\s/g, "")?.length > 0 ||
												(errors?.salary && errors?.salary?.payCurrency && errors?.salary?.payCurrency !== "" && (
													<div className="text-danger m-0">{errors?.salary?.payCurrency}</div>
												))}
											<select
												className={`${
													!(jobDetails?.salary?.payCurrency?.toString()?.replace(/\s/g, "")?.length > 0) &&
													"select-box-not-selected-error"
												} form-select form-control `}
												id="payCurrency"
												onChange={(e) =>
													setJobDetails({
														...jobDetails,
														salary: {
															...jobDetails?.salary,
															payCurrency: e.target.value,
														},
													})
												}
												value={jobDetails?.salary?.payCurrency}
											>
												<option value="">Select Currency</option>
												<option value="USD">USD</option>
												<option value="TZS">TZS</option>
												<option value="EUR">EUR</option>
												<option value="GBP">GBP</option>
											</select>
										</div>
									</div>
									{jobDetails?.salary?.payOption != "" && (
										<div className="col-6">
											<div className="mb-3">
												<div
													style={{
														display: "flex",
														flexDirection: "row",
														alignContent: "flex-end",
													}}
												>
													<div style={{ width: "100%" }}>
														<label htmlFor="minPay" className="form-label font-bold">
															<i className="bi bi-cash-stack mr-2" />
															{jobDetails?.salary?.payOption == "Exact Amount" ? "Pay Amount" : "Minimum Pay"}
														</label>
														{jobDetails?.salary?.minPay?.toString()?.replace(/\s/g, "")?.length > 0 ||
															(errors?.salary && errors?.salary?.minPay && errors?.salary?.minPay !== "" && (
																<div className="text-danger m-0">{errors?.salary?.minPay}</div>
															))}
														<input
															type="number"
															className="form-control"
															id="minPay"
															onChange={(e) =>
																setJobDetails({
																	...jobDetails,
																	salary: {
																		...jobDetails?.salary,
																		minPay: e.target.value,
																	},
																})
															}
															value={jobDetails?.salary?.minPay}
														/>
													</div>
													<div className="input-cross-tick-container">
														<i
															className={`fa text-input-cross-tick ${
																(submitPhase && !(errors?.salary && errors?.salary?.minPay !== "")) ||
																(jobDetails?.salary?.minPay?.toString()?.replace(/\s/g, "")?.length > 0 &&
																	"fa-check text-success")
															}`}
														/>
													</div>
												</div>
											</div>
										</div>
									)}
									{jobDetails?.salary?.payOption == "Range" && (
										<div className="col-6">
											<div className="mb-3">
												<div
													style={{
														display: "flex",
														flexDirection: "row",
														alignContent: "flex-end",
													}}
												>
													<div style={{ width: "100%" }}>
														<label htmlFor="maxPay" className="form-label font-bold">
															<i className="bi bi-cash-stack mr-2" />
															Maximum Pay
														</label>
														{jobDetails?.salary?.maxPay?.length > 0 ||
															(errors?.salary && errors?.salary?.maxPay && errors?.salary?.maxPay !== "" && (
																<div className="text-danger m-0">{errors?.salary?.maxPay}</div>
															))}
														<input
															type="number"
															className="form-control"
															id="maxPay"
															onChange={(e) =>
																setJobDetails({
																	...jobDetails,
																	salary: {
																		...jobDetails?.salary,
																		maxPay: e.target.value,
																	},
																})
															}
															value={jobDetails?.salary?.maxPay}
														/>
													</div>
													<div className="input-cross-tick-container">
														<i
															className={`fa text-input-cross-tick ${
																(submitPhase && !(errors?.salary && errors?.salary?.maxPay !== "")) ||
																(jobDetails?.salary?.maxPay?.length > 0 && "fa-check text-success")
															}`}
														/>
													</div>
												</div>
											</div>
										</div>
									)}

									<div className="col">
										<div className="mb-3">
											<label htmlFor="payFrequency" className="form-label font-bold">
												<i className="bi bi-cash-stack mr-2" />
												Pay Frequency
											</label>
											{jobDetails?.salary?.payFrequency?.toString()?.replace(/\s/g, "")?.length > 0 ||
												(errors?.salary && errors?.salary?.payFrequency && errors?.salary?.payFrequency !== "" && (
													<div className="text-danger m-0">{errors?.salary?.payFrequency}</div>
												))}
											<select
												className={`${
													jobDetails?.salary?.payFrequency === "" && "select-box-not-selected-error"
												} form-select form-control `}
												id="payFrequency"
												onChange={(e) =>
													setJobDetails({
														...jobDetails,
														salary: {
															...jobDetails?.salary,
															payFrequency: e.target.value,
														},
													})
												}
												value={jobDetails?.salary?.payFrequency}
											>
												<option value="">Select Pay Frequency</option>
												<option value="Daily">Daily</option>
												<option value="Weekly">Weekly</option>
												<option value="Monthly">Monthly</option>
											</select>
										</div>
									</div>
								</div>
							</Accordion>
							<Accordion label="Additional Information">
								{/* Mode of Application */}
								{jobDescription !== "" && (
									<div className="mb-3">
										<label htmlFor="additionalInformation" className="form-label font-bold">
											<i className="bi bi-info-circle mr-2" />
											Additional Information
										</label>
										{errors?.additionalInformation && errors?.additionalInformation !== "" && (
											<div className="text-danger m-0">{errors?.additionalInformation}</div>
										)}
										<TextEditor
											id="additionalInformation"
											setValue={setAdditionalInformation}
											value={additionalInformation}
											placeholder="Additional Information"
										/>
									</div>
								)}
							</Accordion>
							{/* hiringPointOfContact  */}
							<Accordion
								label="Hiring Point of Contact"
								errorStatus={
									(errors?.jobProcessingType && errors?.jobProcessingType !== "") ||
									(errors?.hiringPointOfContact &&
										errors?.hiringPointOfContact?.firstName &&
										errors?.hiringPointOfContact?.firstName !== "") ||
									(errors?.hiringPointOfContact &&
										errors?.hiringPointOfContact?.lastName &&
										errors?.hiringPointOfContact?.lastName !== "") ||
									(errors?.hiringPointOfContact &&
										errors?.hiringPointOfContact?.emailAddress &&
										errors?.hiringPointOfContact?.emailAddress !== "") ||
									(errors?.hiringPointOfContact &&
										errors?.hiringPointOfContact?.phoneNumber &&
										errors?.hiringPointOfContact?.phoneNumber !== "")
								}
							>
								<div className="row bg-light p-3 m-1">
									<div className="col-12 mb-3">
										{jobDetails?.jobProcessingType?.length > 0 ||
											(errors?.jobProcessingType && errors?.jobProcessingType !== "" && (
												<div className="text-danger m-0">{errors?.jobProcessingType}</div>
											))}
										<select
											className={`${
												submitPhase && jobDetails?.jobProcessingType === "" && "select-box-not-selected-error"
											} form-select form-control `}
											onChange={(e) =>
												setJobDetails({
													...jobDetails,
													jobProcessingType: e.target.value,
												})
											}
											value={jobDetails?.jobProcessingType}
											id="jobProcessingType"
										>
											<option value="">Job Processing Type</option>
											<option value="SCREEN_ONLY">Screen Only</option>
											<option value="SMART_APPLY_AND_SCREEN">Smart Apply</option>
											<option value="NO_EMPLOYER_YET_RECOMMEND_ONLY">No Employee yet, Recommended Only</option>
										</select>
									</div>

									{!(jobDetails?.jobProcessingType === "NO_EMPLOYER_YET_RECOMMEND_ONLY") && (
										<>
											<div className="col-6">
												<div className="mb-3">
													<div
														style={{
															display: "flex",
															flexDirection: "row",
															alignContent: "flex-end",
														}}
													>
														<div
															style={{
																width: "100%",
															}}
														>
															<label htmlFor="region" className="form-label font-bold">
																<i className="bi bi-person mr-2" />
																First name
															</label>
															{jobDetails?.hiringPointOfContact?.firstName?.toString()?.replace(/\s/g, "")?.length >
																0 ||
																(errors?.hiringPointOfContact &&
																	errors?.hiringPointOfContact?.firstName &&
																	errors?.hiringPointOfContact?.firstName !== "" && (
																		<div className="text-danger m-0">{errors?.hiringPointOfContact?.firstName}</div>
																	))}
															<input
																type="text"
																className="form-control"
																onChange={(e) =>
																	setJobDetails({
																		...jobDetails,
																		hiringPointOfContact: {
																			...jobDetails?.hiringPointOfContact,
																			firstName: e.target.value,
																		},
																	})
																}
																value={jobDetails?.hiringPointOfContact?.firstName}
																id="firstName"
															/>
														</div>
														<div className="input-cross-tick-container bg-white">
															<i
																className={`fa text-input-cross-tick ${
																	(submitPhase &&
																		!(
																			errors?.hiringPointOfContact && errors?.hiringPointOfContact?.firstName !== ""
																		)) ||
																	(jobDetails?.hiringPointOfContact?.firstName?.toString()?.replace(/\s/g, "")?.length >
																		0 &&
																		"fa-check text-success")
																}`}
															/>
														</div>
													</div>
												</div>
											</div>
											<div className="col-6">
												<div className="mb-3">
													<div
														style={{
															display: "flex",
															flexDirection: "row",
															alignContent: "flex-end",
														}}
													>
														<div
															style={{
																width: "100%",
															}}
														>
															<label htmlFor="country" className="form-label font-bold">
																<i className="bi bi-person mr-2" />
																Last Name
															</label>
															{jobDetails?.hiringPointOfContact?.lastName?.toString()?.replace(/\s/g, "")?.length > 0 ||
																(errors?.hiringPointOfContact &&
																	errors?.hiringPointOfContact?.lastName &&
																	errors?.hiringPointOfContact?.lastName !== "" && (
																		<div className="text-danger m-0">{errors?.hiringPointOfContact?.lastName}</div>
																	))}
															<input
																type="text"
																className="form-control"
																onChange={(e) =>
																	setJobDetails({
																		...jobDetails,
																		hiringPointOfContact: {
																			...jobDetails?.hiringPointOfContact,
																			lastName: e.target.value,
																		},
																	})
																}
																value={jobDetails?.hiringPointOfContact?.lastName}
																id="lastName"
															/>
														</div>
														<div className="input-cross-tick-container">
															<i
																className={`fa text-input-cross-tick ${
																	(submitPhase &&
																		!(errors?.hiringPointOfContact && errors?.hiringPointOfContact?.lastName !== "")) ||
																	(jobDetails?.hiringPointOfContact?.lastName?.toString()?.replace(/\s/g, "")?.length >
																		0 &&
																		"fa-check text-success")
																}`}
															/>
														</div>
													</div>
												</div>
											</div>
											<div className="col-6">
												<div className="mb-3">
													<div
														style={{
															display: "flex",
															flexDirection: "row",
															alignContent: "flex-end",
														}}
													>
														<div
															style={{
																width: "100%",
															}}
														>
															<label htmlFor="city" className="form-label font-bold">
																<i className="bi bi-envelope mr-2" />
																Email
															</label>
															{jobDetails?.hiringPointOfContact?.emailAddress?.toString()?.replace(/\s/g, "")?.length >
																0 ||
																(errors?.hiringPointOfContact &&
																	errors?.hiringPointOfContact?.emailAddress &&
																	errors?.hiringPointOfContact?.emailAddress !== "" && (
																		<div className="text-danger m-0">{errors?.hiringPointOfContact?.emailAddress}</div>
																	))}
															<input
																type="text"
																className="form-control"
																onChange={(e) =>
																	setJobDetails({
																		...jobDetails,
																		hiringPointOfContact: {
																			...jobDetails?.hiringPointOfContact,
																			emailAddress: e.target.value,
																		},
																	})
																}
																value={jobDetails?.hiringPointOfContact?.emailAddress}
																id="emailAddress"
															/>
														</div>
														<div className="input-cross-tick-container">
															<i
																className={`fa text-input-cross-tick ${
																	(submitPhase &&
																		!(
																			errors?.hiringPointOfContact && errors?.hiringPointOfContact?.emailAddress !== ""
																		)) ||
																	(jobDetails?.hiringPointOfContact?.emailAddress?.toString()?.replace(/\s/g, "")
																		?.length > 0 &&
																		"fa-check text-success")
																}`}
															/>
														</div>
													</div>
												</div>
											</div>
											<div className="col-6">
												<div className="mb-3">
													<div
														style={{
															display: "flex",
															flexDirection: "row",
															alignContent: "flex-end",
														}}
													>
														<div
															style={{
																width: "100%",
															}}
														>
															<label htmlFor="city" className="form-label font-bold">
																<i className="bi bi-telephone mr-2" />
																Phone
															</label>
															{jobDetails?.hiringPointOfContact?.phoneNumber?.toString()?.replace(/\s/g, "")?.length >
																0 ||
																(errors?.hiringPointOfContact &&
																	errors?.hiringPointOfContact?.phoneNumber &&
																	errors?.hiringPointOfContact?.phoneNumber !== "" && (
																		<div className="text-danger m-0">{errors?.hiringPointOfContact?.phoneNumber}</div>
																	))}
															<input
																type="text"
																className="form-control"
																name="phone"
																onChange={(e) =>
																	setJobDetails({
																		...jobDetails,
																		hiringPointOfContact: {
																			...jobDetails?.hiringPointOfContact,
																			phoneNumber: e.target.value,
																		},
																	})
																}
																value={jobDetails?.hiringPointOfContact?.phoneNumber}
																id="phoneNumber"
															/>
														</div>
														<div className="input-cross-tick-container">
															<i
																className={`fa text-input-cross-tick ${
																	(submitPhase &&
																		!(
																			errors?.hiringPointOfContact && errors?.hiringPointOfContact?.phoneNumber !== ""
																		)) ||
																	(jobDetails?.hiringPointOfContact?.phoneNumber?.toString()?.replace(/\s/g, "")
																		?.length > 0 &&
																		"fa-check text-success")
																}`}
															/>
														</div>
													</div>
												</div>
											</div>
										</>
									)}
								</div>
							</Accordion>
							<div className="mb-3">
								<Accordion label="Benefits">
									<TextEditor id="benefits" setValue={setBenefits} value={benefits} placeholder="Benefits" />
								</Accordion>
							</div>
							<div className="mb-3">
								<Accordion label="Application Process">
									<TextEditor
										id="applicationProcess"
										setValue={setApplicationProcess}
										value={applicationProcess}
										placeholder="Application Process"
									/>
								</Accordion>
							</div>
							<div className="mb-3">
								<Accordion label="Travel Requirements">
									<TextEditor
										id="travelRequirements"
										setValue={setTravelRequirements}
										value={travelRequirements}
										placeholder="Travel Requirements"
									/>
								</Accordion>
							</div>
							<div className="mb-3">
								<Accordion label="Work Hours">
									<TextEditor id="workHours" setValue={setWorkHours} value={workHours} placeholder="Work Hours" />
								</Accordion>
							</div>
							<div className="">
								<Accordion label="Diversity and Inclusion Statement">
									<TextEditor
										id="diversityAndInclusionStatement"
										setValue={setDiversityAndInclusionStatement}
										value={diversityAndInclusionStatement}
										placeholder="Diversity and Inclusion Statement"
									/>
								</Accordion>
							</div>
						</div>
						<div className="col-12 mb-4 mt-6">
							<button
								className="btn btn-sm btn-primary rounded-pill p-2 px-3 lh-1 d-flex align-items-center justify-content-center"
								onClick={onSubmit}
							>
								<i
									className="bi bi-pencil-square mr-2 p-0"
									style={{
										fontSize: "1rem",
									}}
								/>
								{submitLoading ? <Spinner /> : "Save"}
							</button>
						</div>
					</form>
				)}
			</div>
			{disableJobDescription ? null : jobItem?._id || jobDescriptionID ? (
				<div className="col">
					{loadingJobDescription ? (
						<LoadingBars />
					) : (
						<>
							{jobDescFile.fileName ? (
								<div className="card card-body p-3">
									{returnDocumentMIMEType(jobDescFile.fileName) === "application/pdf" ? (
										<embed
											src={jobDescFile.data}
											type={returnDocumentMIMEType(jobDescFile.fileName)}
											width="100%"
											height="900px"
										/>
									) : (
										<Link to={jobDescFile.data} target="_blank" className="btn btn-primary">
											Download Job Description
										</Link>
									)}
								</div>
							) : (
								<div className="card card-body p-3">
									{jobItem?._id && (
										<div className="d-flex justify-content-center">
											<button className="btn btn-primary" onClick={searchForJD}>
												Get Job Description
											</button>
										</div>
									)}
								</div>
							)}
						</>
					)}
				</div>
			) : (
				<div className="col-4">
					{successJobMarketID && (
						<div className="card card-body mb-4">
							<h6 className="m-0">Use this Job Market Reference ID to decision this job</h6>
							<p className="m-0">
								Job Market Reference ID:{" "}
								<span
									className="text-primary cursor-pointer"
									onClick={() => {
										window.navigator.clipboard.writeText(successJobMarketID);
										toast.success("Copied to clipboard");
									}}
								>
									{successJobMarketID}
								</span>
							</p>
						</div>
					)}
					<LeadsList />
				</div>
			)}
		</div>
	);

	return !ignoreLayout ? (
		<ExpertResumesLayout navItems={getJobsRoutes()} headingTitle="Post a Job" headingIcon="arrow-left">
			<div
				className="container px-5 mt-5"
				style={{
					margin: 0,
					maxWidth: "100vw",
				}}
			>
				{jobPostForm}
			</div>
		</ExpertResumesLayout>
	) : (
		jobPostForm
	);
}

export default JobPostForm;
