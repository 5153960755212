import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";

import { ResumeDataService } from "../../../services/create-resume/resume-data";
import AddReviewers from "./AddReviewers";

function CreateReviewRequest({
	resumeTitle,
	resumeID,
	mode = "create",
	setCurrentStep,
	currentStep,
	reviewData,
	setCurrentReviewData,
}) {
	const resumeDataService = new ResumeDataService();

	const navigate = useNavigate();

	useEffect(() => {
		if (reviewData) {
			setReviewRequestData({
				...reviewRequestData,
				messageToReviewers: reviewData.messageToReviewers,
				reviewerEmails: reviewData.reviewerEmails,
				targetOpportunityInfo: reviewData.targetOpportunityInfo,
			});
		}
	}, [reviewData]);

	const [fetchLoading, setFetchLoading] = useState(true);
	const [userReviewers, setUserReviewers] = useState([]);
	const [showAddReviewer, setShowAddReviewer] = useState(false);

	function successCallBack(data) {
		setFetchLoading(false);
		setUserReviewers(data.data);
		if (data.data.length === 0) {
			setShowAddReviewer(true);
		} else {
			setShowAddReviewer(false);
		}
	}

	function errorCallBack(data) {
		setFetchLoading(false);
		setErrors(data.response.data.message);
	}

	function fetchReviewers() {
		setFetchLoading(true);
		resumeDataService.getReviewers(successCallBack, errorCallBack);
	}

	useEffect(() => {
		fetchReviewers();
	}, []);

	const [errors, setErrors] = useState([]);

	const [reviewRequestErrors, setReviewRequestErrors] = useState([]);

	const [reviewRequestData, setReviewRequestData] = useState({
		messageToReviewers:
			"I'm exploring roles in [1. FIELD_NAME], I'm keen on [2. COMPANY], where my skills, experience, and track record align.",
		targetOpportunityInfo: "",
		reviewerEmails: [],
	});

	function onChangeHandler(e) {
		setReviewRequestErrors("");
		setReviewRequestData({
			...reviewRequestData,
			[e.target.name]: e.target.value,
		});

		if (reviewRequestData.targetOpportunityInfo.length > 150) {
			setReviewRequestErrors("Target Opportunity Info cannot be more than 150 characters");
			return;
		}
		if (reviewRequestData.messageToReviewers.length > 150) {
			setReviewRequestErrors("Message to Reviewers cannot be more than 150 characters");
			return;
		}
	}

	function handleCreateReviewRequest(e) {
		e.preventDefault();

		setReviewRequestErrors("");

		if (reviewRequestData.messageToReviewers === "") {
			setReviewRequestErrors("Message to Reviewers is required");
			return;
		}

		if (reviewRequestData.messageToReviewers.includes("[") || reviewRequestData.messageToReviewers.includes("]")) {
			setReviewRequestErrors("Please edit and remove the [ and ] from your message");
			return;
		}

		if (reviewRequestData.targetOpportunityInfo === "") {
			setReviewRequestErrors("Target Opportunity Info is required");
			return;
		}

		if (reviewRequestData.reviewerEmails.length === 0) {
			setReviewRequestErrors("Please select at least one reviewer");
			return;
		}

		setReviewLoading(true);

		const data = {
			resumeLedgerReferenceId: resumeID,
			messageToReviewers: reviewRequestData.messageToReviewers,
			targetOpportunityInfo: reviewRequestData.targetOpportunityInfo,
			reviewerEmails: reviewRequestData.reviewerEmails,
		};

		if (mode === "edit") {
			const id = reviewData._id;
			resumeDataService.updateResumeReview(data, id, onSuccessReview, onErrorReview);
		} else {
			resumeDataService.createResumeReview(data, onSuccessReview, onErrorReview);
		}
	}

	function onSuccessReview(data) {
		setReviewLoading(false);

		toast.success(`Review Request Created For ${resumeTitle}`);

		setCurrentReviewData(data.data);

		//update local storage
		const storedReviews = JSON.parse(localStorage.getItem("reviewsList"));
		if (storedReviews) {
			if (storedReviews.find((review) => review._id === data.data._id)) {
				//update review
				const updatedReviews = storedReviews.map((review) => {
					if (review._id === data.data._id) {
						return data.data;
					}
					return review;
				});
				localStorage.setItem("reviewsList", JSON.stringify(updatedReviews));
			} else {
				//add review
				storedReviews.push(data.data);
				localStorage.setItem("reviewsList", JSON.stringify(storedReviews));
			}
		} else {
			localStorage.setItem("reviewsList", JSON.stringify([data.data]));
		}
		setCurrentStep(3);
	}

	function onErrorReview(data) {
		setReviewLoading(false);
		setErrors(data.response.data.message);
		if (data.response.status === 402) {
			setTimeout(() => {
				navigate("/payments");
			}, 3000);
		}
	}

	const [reviewLoading, setReviewLoading] = useState(false);

	return (
		<form onSubmit={(e) => e.preventDefault()}>
			{errors.length > 0 && (
				<>
					<div className="alert alert-danger">{errors}</div>
				</>
			)}

			{reviewRequestErrors.length > 0 && <div className="alert alert-danger">{reviewRequestErrors}</div>}

			<div className="mb-3">
				{currentStep === 1 && (
					<>
						<p className="mb-3 font-weight-bold">Add your message.</p>
						<div className="form-group">
							<label htmlFor="messageToReviewers">
								Intro* (This will be sent to your reviewers, make sure to include 1, 2 and 3)
							</label>
							<textarea
								className="form-control"
								id="messageToReviewers"
								rows="6"
								value={reviewRequestData.messageToReviewers}
								name="messageToReviewers"
								onChange={onChangeHandler}
							/>
						</div>
						<div className="form-group">
							<label htmlFor="targetOpportunityInfo">More information about the opportunity you are targeting</label>
							<textarea
								className="form-control"
								id="targetOpportunityInfo"
								rows="6"
								value={reviewRequestData.targetOpportunityInfo}
								name="targetOpportunityInfo"
								placeholder="Add Information...	"
								onChange={onChangeHandler}
							/>
						</div>
						<div className="d-flex justify-content-end">
							<button
								type="button"
								className="btn rounded-sm text-uppercase bg-primary text-white"
								onClick={(e) => {
									e.preventDefault();
									if (reviewRequestData.messageToReviewers === "") {
										toast.error("Message to Reviewers is required");
										return;
									}

									if (
										reviewRequestData.messageToReviewers.includes("[") ||
										reviewRequestData.messageToReviewers.includes("]")
									) {
										toast.error("Please edit and remove the [ and ] from your message");
										return;
									}

									if (reviewRequestData.targetOpportunityInfo === "") {
										toast.error("Target Opportunity Info is required");
										return;
									}

									setCurrentStep(2);
								}}
								disabled={reviewLoading}
							>
								Next
							</button>
						</div>
					</>
				)}
				{currentStep === 2 && (
					<>
						{fetchLoading ? (
							<div className="spinner-border text-primary ml-2" role="status">
								<span className="sr-only">Loading</span>
							</div>
						) : (
							!showAddReviewer &&
							userReviewers.length > 0 && (
								<>
									<h5 className="fs-16 m-0">Select reviewers.</h5>
									<p className="pre-text m-0 fs-14">Select the reviewers you wish to get feedback from.</p>
									<div className="my-3">
										{userReviewers.map((reviewer, index) => {
											return (
												<div
													className="form-check d-flex flex-row align-items-center p-0 mb-1"
													key={index}
													onClick={() => {
														if (reviewRequestData.reviewerEmails.includes(reviewer.emailAddress)) {
															setReviewRequestData({
																...reviewRequestData,
																reviewerEmails: reviewRequestData.reviewerEmails.filter((email) => {
																	return email !== reviewer.emailAddress;
																}),
															});
														} else {
															setReviewRequestData({
																...reviewRequestData,
																reviewerEmails: [...reviewRequestData.reviewerEmails, reviewer.emailAddress],
															});
														}
													}}
												>
													<input
														type="checkbox"
														checked={reviewRequestData.reviewerEmails.includes(reviewer.emailAddress)}
														value={reviewer.emailAddress}
														key={index}
														onClick={() => {
															if (reviewRequestData.reviewerEmails.includes(reviewer.emailAddress)) {
																setReviewRequestData({
																	...reviewRequestData,
																	reviewerEmails: reviewRequestData.reviewerEmails.filter((email) => {
																		return email !== reviewer.emailAddress;
																	}),
																});
															} else {
																setReviewRequestData({
																	...reviewRequestData,
																	reviewerEmails: [...reviewRequestData.reviewerEmails, reviewer.emailAddress],
																});
															}
														}}
													/>
													<span className="checkmark" />
													<div className="ml-4 pl-2">
														<p className="form-check-label fs-14 m-0 font-weight-semi">
															{reviewer.fullName} ({reviewer.relationship})
														</p>
														<p className="m-0 pre-text fs-12">{reviewer.emailAddress}</p>
													</div>
												</div>
											);
										})}
									</div>
								</>
							)
						)}
						{userReviewers.length > 0 && (
							<button
								onClick={() => setShowAddReviewer(!showAddReviewer)}
								className="border-0 pl-0 font-weight-semi fs-14 text-primary d-block"
							>
								{showAddReviewer ? "- " : "+ "}
								Add reviewer
							</button>
						)}
						{showAddReviewer ? (
							<>
								<h5 className="fs-16 m-0">Add a new reviewer.</h5>
								<p className="pre-text m-0 fs-14">Select the reviewers you wish to get feedback from.</p>
								<AddReviewers fetchReviewers={fetchReviewers} />
							</>
						) : (
							<div className="d-flex justify-content-end">
								<button
									type="button"
									className="btn rounded-sm bg-primary font-weight-medium text-white"
									onClick={handleCreateReviewRequest}
									disabled={reviewLoading}
								>
									{reviewLoading ? (
										<div className="spinner-border text-primary ml-2" role="status">
											<span className="sr-only">Loading</span>
										</div>
									) : (
										"Next"
									)}
								</button>
							</div>
						)}
					</>
				)}
			</div>
		</form>
	);
}

export default CreateReviewRequest;
