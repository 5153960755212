import React, { useEffect, useState } from "react";
import { toast } from "sonner";

import ExpertResumesLayout from "../../../components/ExpertResumesLayout/ExpertResumesLayout";
import Loader from "../../../components/Loader";
import SearchableSelectInput from "../../../components/SearchableSelectInput/SearchableSelectInput";
import Table from "../../../components/Table/Table";
import { getJobsRoutes } from "../../../handlers/navigationItems";
import { isValidURL } from "../../../handlers/validationFunctions";
import dropDownOptions from "../../../seed/dropDownOptions";
import { scrappedCompanies } from "../../../seed/scrapped-companies";
import { ResumeDataService } from "../../../services/create-resume/resume-data";
import CreateModal from "./CreateModal";
import ViewCompany from "./ViewCompany";

const columns = [
	{
		key: "company_name",
		label: "Company Name",
		icon: "account",
		editable: false,
		showProfile: true,
		image: "logo_url",
		imageClassName: "w-6",
		visible: true,
	},
	{ key: "company_industry", label: "Company industry", icon: "account", editable: true, visible: true },
	{ key: "company_size_approx", label: "Company Size", icon: "account", editable: true, visible: true },
	{ key: "headquarters", label: "Headquarters", icon: "account", editable: true, visible: true },
];

function Companies() {
	const companyIndustries = dropDownOptions.companyIndustriesOptions;
	const [showAddCompany] = useState(false);
	const [showProfile, setShowProfile] = useState(false);
	const jobService = new ResumeDataService();
	const [industryFilterValue, setIndustryFilterValue] = useState("Non-profit Organizations");
	const [showCreateCompanyDialog, setShowCreateCompanyDialog] = React.useState(false);
	const [submitPhase, setSubmitPhase] = useState(false);
	const [loading, setLoading] = useState(false);
	const [loadingAction, setLoadingAction] = useState(false);
	const [hadFetch, setHadFetch] = useState(false);
	const [companies, setCompanies] = useState(scrappedCompanies);
	const [errors, setErrors] = useState([]);
	const [companyName, setCompanyName] = useState("");
	const initalCompanyDetails = {
		company_name: "",
		linkedin_url: "",
		linkedin_followers_count: 0,
		about_us: "",
		num_of_employees: "",
		company_website: "",
		company_industry: "",
		company_size_approx: "",
		headquarters: "",
		type: "",
		founded: "",
		specialties: "",
		funding: "",
		funding_total_rounds: "",
		funding_option: "",
		last_funding_round: "",
		locations: [],
		logo_url: "",
	};

	const [companyDetails, setCompanyDetails] = useState({ ...initalCompanyDetails });
	const [selectedCompany, setSelectedCompany] = React.useState({
		...initalCompanyDetails,
		_id: "",
	});

	function onUpdate(key, value) {
		const updatedCompany = { ...selectedCompany };

		updatedCompany[key] = value;
		setSelectedCompany(updatedCompany);
	}

	function sortByColumn(key, direction = "asc") {
		const sorted = companies.sort((a, b) => {
			const valueA = a[key] || "";
			const valueB = b[key] || "";

			if (direction === "asc") {
				return valueA.localeCompare(valueB);
			} else {
				return valueB.localeCompare(valueA);
			}
		});

		setCompanies([...sorted]);
	}

	const handleCloseCreateCompanyDialog = () => {
		setShowCreateCompanyDialog(false);
	};

	function getCompanies(companyName, companyIndustry) {
		setLoading(true);

		jobService.getCompanies(
			"",
			companyIndustry === "ALL" ? companyName : "",
			companyIndustry === "ALL" ? "" : companyIndustry,
			(response) => {
				setCompanies(response.data);
				setLoading(false);
				setHadFetch(true);
			},
			(error) => {
				setLoading(false);
				setErrors((prev) => {
					return [...prev, ...error.response.data.message];
				});
			},
		);
	}

	function onSubmit(e) {
		e.preventDefault();
		setSubmitPhase(true);
		setLoadingAction(true);

		if (showProfile && !showCreateCompanyDialog) {
			const payload = { ...selectedCompany };
			payload["company_id"] = payload._id;

			payload.locations = payload.locations.map((location) => {
				return { bp: true, value: location };
			});
			jobService.updateCompany(payload.company_id, formatCompanyPayload(payload), onSuccess, onError);
		}

		if (showCreateCompanyDialog && !showProfile) {
			setLoading(true);
			jobService.createCompany(formatCompanyPayload(companyDetails), onSuccess, onError);
		}
	}

	const formatCompanyPayload = (payload) => {
		//Delete unwanted fields
		delete payload._id;
		delete payload.index;
		delete payload.company_website;

		return payload;
	};

	const onSuccess = () => {
		if (showProfile) {
			setCompanyDetails({ ...initalCompanyDetails });
			setLoading(false);
			setSubmitPhase(false);
			toast.success("Company Details Updated Successfully!");
		} else {
			setCompanyDetails({ ...initalCompanyDetails });
			setLoading(false);
			setSubmitPhase(false);
			handleCloseCreateCompanyDialog();
		}
		setLoadingAction(false);
	};

	const onError = (error) => {
		setLoading(false);
		setSubmitPhase(true);
		setLoadingAction(false);
		setErrors(error.response.data.message);
		toast.error(error.response.data.message);
	};

	useEffect(() => {
		getCompanies(companyName, industryFilterValue);
	}, [companyName, industryFilterValue]);

	React.useEffect(() => {
		let validationErrors = { ...initalCompanyDetails };

		if (companyDetails.company_name.length < 5) {
			validationErrors.company_name =
				companyDetails.company_name === "" ? "Company name is required" : "Provide valid company name";
		}

		if (companyDetails.company_industry === "") {
			validationErrors.company_industry = "Company industry is required";
		}

		if (companyDetails.company_size_approx === "") {
			validationErrors.company_size_approx = "Company size is required";
		}

		if (companyDetails.headquarters === "") {
			validationErrors.headquarters = "Company headquarters is required";
		}

		if (!isValidURL(companyDetails.logo_url)) {
			validationErrors.logo_url =
				companyDetails.logo_url === "" ? "Company log url is required" : "Provide valid company logo url";
		}

		setErrors((prev) => {
			return {
				...prev,
				...validationErrors,
			};
		});
	}, [companyDetails]);

	return (
		<ExpertResumesLayout navItems={getJobsRoutes()} headingTitle="Companies" headingIcon="arrow-left">
			<CreateModal
				showCreateCompanyDialog={showCreateCompanyDialog}
				handleCloseCreateCompanyDialog={handleCloseCreateCompanyDialog}
				setShowCreateCompanyDialog={setShowCreateCompanyDialog}
				errors={errors}
				setCompanyDetails={setCompanyDetails}
				companyDetails={companyDetails}
				submitPhase={submitPhase}
				companyIndustries={companyIndustries}
				setErrors={setErrors}
				onSubmit={onSubmit}
			/>
			<div className="my-resumes-section pl-4">
				<div>
					<div className="flex items-center space-x-4 flex-wrap mb-2">
						<div className="min-w-60">
							<input
								type="text"
								className="form-control"
								style={{ borderRadius: 10 }}
								onChange={(e) => setCompanyName(e.target.value)}
								value={companyName}
								name="companyName"
								id="companyName"
								placeholder="Company Name"
							/>
						</div>
						<div className="min-w-60 mr-auto">
							<SearchableSelectInput
								defaultLabel="Filter by Industry"
								value={industryFilterValue}
								options={[
									{ label: "ALL", value: "ALL" },
									...companyIndustries.map((item) => {
										return { label: item, value: item };
									}),
								]}
								onSelect={(value) => setIndustryFilterValue(value)}
							/>
						</div>
						<button
							className="btn btn-sm btn-primary rounded"
							onClick={() => {
								setShowCreateCompanyDialog(true);
							}}
						>
							<span className="bi bi-plus-lg mr-2" />
							Add Company
						</button>
					</div>
					{loading ? (
						<Loader />
					) : (
						<div className="row px-3" style={{ padding: "10px" }}>
							{companies.length > 0 ? (
								<div className="w-full">
									<Table
										data={companies}
										columns={columns}
										onUpdate={onUpdate}
										sortByColumn={sortByColumn}
										showAddForm={showAddCompany}
										showProfile={showProfile}
										ProfileComponent={ViewCompany}
										setShowProfile={setShowProfile}
										setSelectedItem={setSelectedCompany}
										selectedItem={selectedCompany}
										loadingAction={loadingAction}
										onSubmit={onSubmit}
									/>
								</div>
							) : (
								<center>
									{hadFetch ? (
										<div className="text-lg">No Companies found</div>
									) : (
										<div className="text-lg"> Search for a Company here</div>
									)}
								</center>
							)}
						</div>
					)}
				</div>
			</div>
		</ExpertResumesLayout>
	);
}

export default Companies;
