import React from "react";

import { DocumentIcon, Upload, XIcon } from "../../../assets/icons/interface/interface";
import { Button } from "../../JobsPage/components/Button";

function FileUpload({
	processDataOfUploadedFile,
	removeFile,
	fileName,
	multiple,
	index,
	fileError,
	setFileError,
	supportedFiles,
	disableFileInput,
}) {

	const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB in bytes
	const [isDragOver, setIsDragOver] = React.useState(false);

	// Check file type
	const isValidFileType = (file) => {
		return supportedFiles.map((supportedFile) => supportedFile.mimeType).includes(file.type);
	};

	const readFile = (event) => {
		if (processDataOfUploadedFile) {
			event.preventDefault();
			const file = multiple ? Array.from(event.target.files) : event.target.files[0];

			// Check for single file
			if (!multiple) {
				if (file.size > MAX_FILE_SIZE) {
					setFileError(`File size for ${file.name} exceeds 2MB`);
					return;
				}

				if (!isValidFileType(file)) {
					setFileError(`Invalid file type for ${file.name}. Only .pdf, .docx, and .doc are allowed.`);
					return;
				}
			}

			// Check for multiple files
			if (multiple) {
				for (let i = 0; i < file.length; i++) {
					if (file[i].size > MAX_FILE_SIZE) {
						setFileError(`File size for ${file[i].name} exceeds 2MB`);
						return;
					}

					if (!isValidFileType(file[i])) {
						setFileError(`Invalid file type: ${file[i].name}. Only .pdf, .docx, and .doc are allowed.`);
						return;
					}
				}
			}

			setFileError(null);
			processDataOfUploadedFile(file, index);
		}
	};

	const handleOnDragOver = (event) => {
		event.preventDefault();
		setIsDragOver(true);
	};

	const handleOnDragLeave = (event) => {
		event.preventDefault();
		setIsDragOver(false);
	};

	const handleOnDrop = (event) => {
		event.preventDefault();
		setIsDragOver(false);

		const file = multiple ? Array.from(event.dataTransfer.files) : event.dataTransfer.files[0];
		if (processDataOfUploadedFile) {
			processDataOfUploadedFile(file, index);
		}
	};

	const inputRef = React.useRef(null);

	const onClick = () => {
		inputRef.current.click();
	};

	return (
		<div className="mb-4">
			{fileName ? (
				<div
					className={`relative w-full h-10 flex items-center group border border-dashed border-gray-200 hover:border-gray-300 my-3 rounded-lg transition-colors ${
						fileName ? "bg-primary-50 border-primary-200 border-solid" : ""
					}`}
				>
					<div className="w-full h-full flex items-center px-3 pr-20">
						<DocumentIcon className="h-4 w-4 text-primary-500 mr-2 flex-shrink-0" />
						<span className="text-[13px] font-medium text-primary-700 truncate">{fileName}</span>
						<div className="absolute right-2 flex items-center space-x-1">
							<Button
								type="button"
								variant="ghost"
								className="h-8 w-8 p-0 text-primary-500 hover:text-primary-700"
								onClick={removeFile}
							>
								<XIcon className="h-4 w-4" />
							</Button>
						</div>
					</div>
				</div>
			) : (
				<div
					className={`border-2 border-dashed rounded-lg p-6 transition-colors hover:border-primary-500/50 hover:bg-gray-50 ${isDragOver && "border-2 border-solid border-primary-500 transition-all ease-in-out"}`}
					onDragOver={handleOnDragOver}
					onDragLeave={handleOnDragLeave}
					onDrop={handleOnDrop}
				>
					{!disableFileInput && (
						<div className="flex flex-col items-center justify-center space-y-3 text-center" onClick={onClick}>
							<div className="p-2 bg-primary-50 rounded-full">
								<Upload className="w-10 h-10 text-primary-600" />
							</div>
							<div>
								<p className="text-gray-600 text-sm">Drag and drop your file here, or</p>
								<Button variant="link" className="text-primary-600 text-sm h-auto p-0">
									click to browse
								</Button>
							</div>
							<p className="text-xs text-gray-500">
								Supported formats:
								{supportedFiles.map(
									(supportedFile, index) => ` ${supportedFile.name}
									${index === supportedFiles.length - 1 ? " " : ", "}`,
								)}
								(Max size: 2MB)
							</p>
							<input
								disabled={disableFileInput}
								multiple={multiple}
								type="file"
								accept={supportedFiles.map(
									(supportedFile, index) => ` ${supportedFile.extension}
										${index === supportedFiles.length - 1 ? " " : ", "}`,
								)}
								className="hidden"
								id="uploadFile"
								ref={inputRef}
								onChange={readFile}
							/>
						</div>
					)}
				</div>
			)}
			<div className="text-red-500">{fileError}</div>
		</div>
	);
}

export default FileUpload;
