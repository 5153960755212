import React from "react";
import { Modal } from "react-bootstrap";
import { toast } from "sonner";

import { isValidEmail, isValidPhoneNumberWithTZCode } from "../../handlers/validationFunctions";
import { ResumeDataService } from "../../services/create-resume/resume-data";
import Spinner from "../Spinner";
import InputField from "../UIComponents/InputField";

const candidateFormFields = {
	emailAddress: "Email Address*",
	fullName: "Full Name*",
	phoneNumber: "Phone Number (255xxxxxxxxx)",
};

function ReferFriendModal({
	showReferralFriendDialog,
	handleCloseReferralFriendDialog,
	getPaymentsRewards,
	setSuccess,
}) {
	const apiService = new ResumeDataService();
	const [loading, setLoading] = React.useState(false);
	const [candidate, setCandidate] = React.useState({
		fullName: "",
		emailAddress: "",
		phoneNumber: "",
	});
	const [errors, setErrors] = React.useState({
		fullName: "",
		emailAddress: "",
		phoneNumber: "",
		upload: "",
	});

	const createPaymentReward = () => {
		setLoading(true);
		setSuccess("");
		apiService.createPaymentReward(
			{
				activityType: "REFERRAL",
				referredPhoneNumber: candidate.phoneNumber,
				referredEmailAddress: candidate.emailAddress,
				referredFullName: candidate.fullName,
			},
			() => {
				setSuccess("Thanks for spreading the word by referring " + candidate.fullName + " to the Dproz community");
				setLoading(false);
				getPaymentsRewards();
				handleCloseReferralFriendDialog();
			},
			(e) => {
				setLoading(false);
				const error = e.response.data.message;
				setErrors(error);
				toast.error(error);
			},
		);
	};

	React.useEffect(() => {
		let validationErrors = {
			fullName: "",
			emailAddress: "",
			phoneNumber: "",
		};

		if (candidate.fullName.toString().trim().split(" ").length < 2) {
			validationErrors.fullName = candidate.fullName === "" ? "Full Name is required" : "Provide Valid Full Name";
		}
		if (!isValidEmail(candidate.emailAddress)) {
			validationErrors.emailAddress =
				candidate.emailAddress === "" ? "Email Address is required" : "Provide Valid Email Address";
		}
		if (candidate.phoneNumber !== "" && !isValidPhoneNumberWithTZCode(candidate.phoneNumber)) {
			validationErrors.phoneNumber =
				candidate.phoneNumber === "" ? "Phone Number is required" : "Provide Valid Phone Number";
		}

		setErrors((prev) => {
			return {
				...prev,
				...validationErrors,
			};
		});
	}, [candidate]);

	return (
		<Modal
			show={showReferralFriendDialog}
			onHide={handleCloseReferralFriendDialog}
			centered
			size="lg"
			backdrop="static"
		>
			<Modal.Header>
				<Modal.Title>Refer Friend</Modal.Title>
				<button
					type="button"
					className="btn btn-secondary text-center"
					data-dismiss="modal"
					aria-label="Close"
					onClick={handleCloseReferralFriendDialog}
				>
					<i className="fa fa-close ml-2" />
				</button>
			</Modal.Header>
			<Modal.Body>
				<div className="row">
					{Object.entries(candidateFormFields).map(([key, value]) => {
						return (
							<div key={key} className="col-xl-12 mb-2">
								<div className="form-group">
									<InputField
										type="text"
										className="form-control"
										placeholder={value}
										label={value}
										value={candidate?.[key]}
										required
										onChange={(event) => {
											setCandidate((prevState) => {
												return {
													...prevState,
													[key]: event.target.value,
												};
											});
										}}
										confirmedStatus={key === "phoneNumber" && candidate[key] === "" ? null : (errors[key] || "") === ""}
										error={errors[key] || ""}
									/>
								</div>
							</div>
						);
					})}
				</div>
			</Modal.Body>
			<Modal.Footer>
				<div className="d-flex justify-content-end">
					<button
						type="button"
						className="btn btn-secondary mx-2"
						data-dismiss="modal"
						onClick={handleCloseReferralFriendDialog}
					>
						Cancel
					</button>

					<button
						type="button"
						className="btn btn-primary"
						onClick={() => {
							createPaymentReward();
						}}
						disabled={!(errors.fullName === "" && errors.emailAddress === "" && errors.phoneNumber === "") || loading}
					>
						{loading ? (
							<>
								Loading <Spinner />
							</>
						) : (
							"Refer Friend"
						)}
					</button>
				</div>
			</Modal.Footer>
		</Modal>
	);
}

export default ReferFriendModal;
