import { combineReducers } from "redux";

import EducationInfoReducer from "./education-info-reducers";
import EducationSummaryReducer from "./education-summary-reducers";
import ExpertReviewReducers from "./expert-review-reducers";
import ExtrasReducers from "./extras-reducers";
import FeedbackReducers from "./feedbackReducer";
import JobsCountReducers from "./jobsCountReducer";
import JobsInteractionReducers from "./jobsInteractionReducer";
import JobsTabsReducers from "./jobsTabsReducer";
import LanguagesReducer from "./langauages-reducers";
import PersonalLinkReducers from "./personal-links-reducers";
import PersonalProfileReducer from "./personal-profile-reducers";
import ProfileDSPReducer from "./profile-dsp-reducers";
import ReferenceInfoReducer from "./reference-info-reducers";
import ReferenceSummaryReducer from "./reference-summary-reducers";
import ResumeFromApiReducer from "./resume-from-api";
import ResumeReducer from "./resume-object";
import SettingsReducer from "./settings-reducers";
import TemplateSelectionReducer from "./template-selection-reducer";
import TipsReducer from "./tips-state-reducers";
import UseReviewFeedbackReducer from "./use-review-feedback-reducers";

const reducers = combineReducers({
	ResumeReducer,
	ExtrasReducers,
	LanguagesReducer,
	EducationInfoReducer,
	PersonalLinkReducers,
	EducationSummaryReducer,
	TemplateSelectionReducer,
	ReferenceInfoReducer,
	ReferenceSummaryReducer,
	PersonalProfileReducer,
	SettingsReducer,
	ResumeFromApiReducer,
	ExpertReviewReducers,
	JobsCountReducers,
	TipsReducer,
	FeedbackReducers,
	JobsTabsReducers,
	ProfileDSPReducer,
	JobsInteractionReducers,
	UseReviewFeedbackReducer,
});

export default reducers;
