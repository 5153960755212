import { motion } from "framer-motion";
import React, { useState } from "react";

import { Button } from "../../../JobsPage/components/Button";
import ApplicationConfirmation from "../../../JobsPage/components/jobApplication/ApplicationConfirmation";
import DocumentsConfirmation from "../../../JobsPage/components/jobApplication/DocumentsConfirmation";
import DSPSelection from "../../../JobsPage/components/jobApplication/DSPSelection";
import ScreeningQuestions from "../../../JobsPage/components/jobApplication/ScreeningQuestions";
import { Modal } from "../../../Profile/components/Modal";

const initialApplicationState = {
	dsp: {
		dsp_id: null,
		dsp_title: "",
		dsp_docs: [],
	},
	screeningQuestions: [],
	emailTemplate: "",
	email:JSON.parse(localStorage.getItem("profileData")).email
};

const ApplyModal = ({
	isOpen,
	onClose,
	jobTitle,
	companyName,
	applicationDetails,
	onDrawer = false,
}) => {
	const [stage, setStage] = useState(0);
	const [smartApplication, setSmartApplication] = useState(initialApplicationState);

	const handleDSPChange = (data) => {
		const _smartApplication = { ...smartApplication };

		if (stage === 0 || stage === 1) _smartApplication.dsp = data;
		if (stage === 2) _smartApplication.screeningQuestions = data;

		setSmartApplication(_smartApplication);
	};

	const nextStage = () => {
		if (stage < stages.length - 1) setStage(stage + 1);
	};

	const prevStage = (steps) => {
		if (stage > 0) setStage(stage - steps);
	};

	const stages = [
		{
			title: "Select your DSP",
			content: <DSPSelection smartApplication={smartApplication} onDSPChange={handleDSPChange} />,
		},
		{
			title: "Select documents to include in the DSP",
			content: (
				<DocumentsConfirmation dsp={smartApplication.dsp} onDSPChange={handleDSPChange} onPrev={() => prevStage(1)} />
			),
		},
		{
			title: "Fill in the screening questions",
			content: (
				<ScreeningQuestions application={smartApplication} onDSPChange={handleDSPChange} onPrev={() => prevStage(1)} />
			),
		},
		{
			title: "Preview and Apply",
			content: <ApplicationConfirmation dsp={smartApplication.dsp} onPrev={() => prevStage(2)} />,
		},
	];

	return (
		<Modal
			isOpen={isOpen}
			size="medium-large"
			progress={applicationDetails.applicationType === "URL" ? undefined : { stages, stage }}
			stage={1}
			onClose={onClose}
			onDrawer={onDrawer && applicationDetails.applicationType !== "URL"}
			title={`Apply to ${jobTitle} at ${companyName}`}
			secondaryAction={
				applicationDetails.applicationType === "URL"
					? {
							label: "Continue",
							onClick: () => {
								window.open(applicationDetails.link, "_blank");
								onClose();
							},
						}
					: null
			}
		>
			<div className="overflow-y-auto">
				<div className="">
					<div className="pb-3 max-h-[calc(100vh-70px)] overflow-y-auto pr-2">
						<div className="space-y-3 mb-3">
							{applicationDetails.applicationType === "URL" ? (
								<>
									<h3 className="text-[14px] font-medium font-inter text-gray-950">You&#39;re Leaving Dproz.</h3>
									<h3 className="text-[14px] font-medium font-inter text-gray-950">
										This Job application will take you to an external site. Ensure you follow the instructions on the
										site to complete your application. Your progress on Dproz will not be affected.
									</h3>
								</>
							) : (
								<>
									<div className="flex-grow flex items-center justify-center p-3">
										<motion.div
											key={stage}
											initial={{ opacity: 0, x: 50 }}
											animate={{ opacity: 1, x: 0 }}
											exit={{ opacity: 0, x: -50 }}
											transition={{ duration: 0.3 }}
											className="w-full"
										>
											{stages[stage].content}
										</motion.div>
									</div>

									{/* Navigation Buttons */}
									<div className="flex d-flex mt-4 p-4 bg-white border-gray-200 bottom-0">
										<Button
											onClick={nextStage}
											disabled={
												(stage === 0 && smartApplication.dsp.id === null) ||
												(stage === 2 && smartApplication.screeningQuestions.length === 0)
											}
											className="w-full"
										>
											{stage === stages.length - 1 ? "Submit Application" : "Next"}
										</Button>
									</div>
								</>
							)}
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default ApplyModal;
