import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";

import CustomRadioField from "../../components/UIComponents/CustomRadioField/CustomRadioField";
import InputField from "../../components/UIComponents/InputField";
import SelectField from "../../components/UIComponents/SelectField";
import { getJobFunctions } from "../../seed/getMetadata";

function ExpertHelpProfessionalSummary() {
	const navigate = useNavigate();

	const options = useMemo(() => {
		const data = getJobFunctions();

		return data.map((item) => ({
			value: item,
			name: item,
		}));
	}, []);

	const yearsOfExperience = [
		{ value: "lessThanAYear", label: "Less than 1 year" },
		{ value: "1-5", label: "1-5 years" },
		{ value: "5-10", label: "5-10 years" },
		{ value: "10+", label: "10+ years" },
	];

	const [selectedExperience, setSelectedExperience] = React.useState("");

	const [details, setDetails] = React.useState({
		professionalTitle: "",
		industry: "",
	});

	const [errors, setErrors] = React.useState({
		professionalTitle: "",
		industry: "",
		yearsOfExperience: "",
	});

	function handleChange(e) {
		const { name, value } = e.target;
		setDetails((prev) => ({
			...prev,
			[name]: value,
		}));
		setErrors((prev) => ({
			...prev,
			[name]: "",
		}));
	}

	function onSubmit(e) {
		e.preventDefault();

		if (!details.professionalTitle) {
			setErrors((prev) => ({
				...prev,
				professionalTitle: "This field is required",
			}));
		}

		if (!details.industry) {
			setErrors((prev) => ({
				...prev,
				industry: "This field is required",
			}));
		}

		if (!selectedExperience) {
			setErrors((prev) => ({
				...prev,
				yearsOfExperience: "This field is required",
			}));
		}

		const hasErrors = Object.values(errors).filter((error) => error).length > 0;

		if (hasErrors) {
			return toast.error("Please fill in all required fields");
		} else {
			navigate("/expert-help/upload-resume");
		}
	}

	return (
		<div>
			<h1 className="fs-28 text-gray-950 font-weight-bold">Professional Summary</h1>
			<p className="text-gray-700 font-weight-semi mb-5">Give us an overview of your professional background.</p>

			<form className="container p-0">
				<div className="mb-4">
					<InputField
						label="Professional Title"
						placeholder="Your most recent or current job title..."
						type="text"
						name="professionalTitle"
						onChange={handleChange}
						value={details.professionalTitle}
						error={errors.professionalTitle}
					/>
				</div>

				<div className="mb-4">
					<SelectField
						label="Industry"
						options={[{ value: "", name: "Select the industry that best aligns with your target job" }, ...options]}
						name="industry"
						onChange={handleChange}
						value={details.industry}
						error={errors.industry}
					/>
				</div>

				<div className="form-group">
					<label className="mb-2">Years of experience</label>
					<div className="">
						{yearsOfExperience.map((item) => (
							<CustomRadioField
								key={item.value}
								name="yearsOfExperience"
								value={item.value}
								label={item.label}
								checked={selectedExperience === item.value}
								onChange={() => {
									setSelectedExperience(item.value);
									setErrors((prev) => ({
										...prev,
										yearsOfExperience: "",
									}));
								}}
							/>
						))}
						{errors.yearsOfExperience && <div className="error">{errors.yearsOfExperience}</div>}
					</div>
				</div>
				<button className="btn btn-blue w-100" onClick={onSubmit}>
					Continue
				</button>
			</form>
		</div>
	);
}

export default ExpertHelpProfessionalSummary;
