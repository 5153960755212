import dayjs from "dayjs";
import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Drawer from "../../../../components/Drawer/Drawer";
import SaveButton from "../../../../components/SaveButton";
import { useAuth } from "../../../../context/Auth/AuthContext";
import { cn } from "../../../../handlers/cn.utils";
import { useLatestJobs, useRecommendedJobs } from "../../../../services/create-resume/queries";
import ViewJob from "./ViewJob";

function RecommendedJobs() {
	const navigate = useNavigate();

	const [showJobDetailsModal, setShowJobDetailsModal] = useState(false);
	const [selectedJob, setSelectedJob] = useState(null);

	const [jobs, setJobs] = useState([]);

	const { userData } = useAuth();

	const { data: response, isLoading } = useLatestJobs();
	const {
		data: recommended,
		isLoading: recommendedJobsLoading,
		refetch,
	} = useRecommendedJobs(userData?.jobPreferences?.areasOfExpertise);
	const [showingRecommended, setShowingRecommended] = useState(false);

	useEffect(() => {
		if (!isLoading && response) {
			setJobs(response.data);
		}
	}, [response, isLoading]);

	useEffect(() => {
		if (!recommendedJobsLoading && recommended) {
			if (recommended.length > 0) {
				setShowingRecommended(true);
			}
		}
	}, [recommended, recommendedJobsLoading]);

	useEffect(() => {
		if (!userData) {
			setShowingRecommended(false);
		} else {
			refetch();
		}
	}, [userData]);

	const filteredMatches = useMemo(() => {
		if (!showingRecommended) return jobs.slice(4, 8);
		const rec = recommended.slice(0, 4);

		if (rec.length === 0) {
			return jobs.slice(4, 8);
		}
		return rec;
	}, [jobs, userData, showingRecommended]);

	return (
		<section className="bg-white py-12 px-4 md:px-8">
			<Drawer
				isOpen={showJobDetailsModal}
				onClose={() => {
					setShowJobDetailsModal(false);
				}}
			>
				<ViewJob
					data={selectedJob}
					jobs={jobs}
					setData={setSelectedJob}
					setShowJobDetailsModal={setShowJobDetailsModal}
				/>
			</Drawer>
			<div className="max-w-6xl mx-auto">
				<div className="flex justify-between items-center mb-8">
					<h2 className="text-3xl font-bold text-gray-900 font-plus-jakarta">Personalized Job Recommendations</h2>
				</div>
				<div className="grid col-span-1 md:grid-cols-3 gap-6">
					{isLoading && recommendedJobsLoading ? (
						<div className="col-span-2 grid grid-cols-2 gap-6">
							{[...Array(4)].map((_, index) => (
								<div className="bg-white rounded-lg p-6 animate-pulse shadow" key={index}>
									<div className="flex items-center mb-4">
										<div className="w-14 h-14 rounded-lg overflow-hidden flex-shrink-0 relative border border-gray-100 bg-gray-200" />
										<div className="ml-4">
											<div className="h-4 bg-gray-200 rounded w-2/3 mb-2" />
											<div className="h-4 bg-gray-200 rounded w-1/2" />
										</div>
									</div>
									<div className="mb-4">
										<div className="inline-flex items-center h-6 px-2.5 py-0.5 rounded text-xs font-medium text-green-800 border border-green-200 font-inter bg-gray-200" />
									</div>
									<div className="text-gray-600 text-sm mb-2 font-inter flex items-center">
										<div className="h-4 bg-gray-200 rounded w-1/2" />
									</div>
									<div className="text-gray-600 text-sm mb-4 font-inter flex items-center">
										<div className="h-4 bg-gray-200 rounded w-1/2" />
									</div>
									<div className="flex space-x-2">
										<button className="inline-flex items-center justify-center bg-gray-200 text-gray-700 h-9 rounded transition-colors text-sm w-[70px] relative overflow-hidden">
											<div className="h-4 bg-gray-200 w-full rounded" />
										</button>
										<span className="cursor-pointer inline-flex items-center bg-gray-200 text-white px-4 h-9 rounded transition-colors duration-300 text-sm flex-grow group" />
									</div>
								</div>
							))}
						</div>
					) : (
						<div className="grid grid-cols-1 md:grid-cols-2 gap-6 col-span-2">
							{filteredMatches.map((job, index) => (
								<div
									className="bg-white rounded-lg p-6"
									style={{
										boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.04) 0px 2px 2px",
									}}
									key={index}
								>
									<div className="flex items-center mb-4">
										<div className="w-14 h-14 rounded-lg overflow-hidden flex-shrink-0 relative border border-gray-100">
											<img
												alt={`${job.companyName} logo`}
												loading="lazy"
												width="56"
												height="56"
												decoding="async"
												data-nimg="1"
												className="w-full h-full object-contain"
												src={job.company ? job.company.logo_url : "https://via.placeholder.com/150"}
											/>
										</div>
										<div className="ml-4">
											<h3 className="text-base font-semibold font-inter leading-tight">{job.jobTitle}</h3>
											<p className="text-[14px] text-gray-700 font-inter">{job.companyName}</p>
										</div>
									</div>
									<div className="mb-4">
										{job.matchScore && (
											<span
												className={cn(
													"inline-flex items-center h-6 px-2.5 py-0.5 rounded text-xs font-medium border font-inter",
													{
														"bg-green-100 text-green-800 border-green-200": job?.matchScore >= 80,
														"bg-blue-100 text-blue-800 border-blue-200": job?.matchScore >= 60 && job?.matchScore < 80,
														"bg-yellow-100 text-yellow-800 border-yellow-200": job?.matchScore < 60,
													},
												)}
											>
												{job.matchScore}% Match
											</span>
										)}
									</div>
									<div className="text-gray-600 text-sm mb-2 font-inter flex items-center">
										<i className="fa-solid fa-location-dot mr-2 text-gray-500" />
										{job.jobLocation.city}, {job.jobLocation.region}
									</div>
									<div className="text-gray-600 text-sm mb-4 font-inter flex items-center">
										<i className="fa-regular fa-calendar mr-2 text-gray-500" />
										Posted on {dayjs(job.createdAt).format("MMM DD, YYYY")}
									</div>
									<div className="md:flex grid grid-cols-2 space-x-2">
										<SaveButton />
										<span
											className="cursor-pointer inline-flex items-center justify-center bg-primary-600 text-white px-4 h-9 rounded hover:bg-primary-700 transition-colors duration-300 text-sm font-inter flex-grow group"
											onClick={() => {
												setSelectedJob(job);
												setShowJobDetailsModal(true);
											}}
										>
											<span className="mr-1">Apply Now</span>
											<span className="transition-transform duration-300 transform group-hover:translate-x-1">›</span>
										</span>
									</div>
								</div>
							))}
						</div>
					)}

					<div className="bg-blue-50/50 rounded-lg p-6 border border-blue-100 h-full flex flex-col justify-between">
						<div className="flex flex-col">
							<div>
								<span className="text-sm font-medium text-primary-600">Profile Completion</span>
								<h2 className="text-xl font-semibold text-gray-900 mt-1 mb-2">Get better job matches</h2>
								<p className="text-gray-800 text-sm mb-8">
									Complete your profile to receive personalized job recommendations that match your skills and
									preferences.
								</p>
							</div>
							<div className="flex-grow flex items-center justify-center mb-8">
								<div className="w-full h-48 relative">
									<img
										alt="Complete your profile"
										loading="lazy"
										decoding="async"
										data-nimg="fill"
										className="object-contain absolute h-full w-full left-0 top-0 right-0 bottom-0"
										src="/images/solo-creation-transparent.png"
									/>
								</div>
							</div>
						</div>
						<Link
							className="inline-flex items-center justify-center bg-white text-gray-700 px-4 h-9 rounded hover:bg-gray-50 transition-colors text-sm font-medium border border-gray-300 w-full"
							to="/profile"
						>
							Complete Profile
						</Link>
					</div>
				</div>
				{filteredMatches.length > 0 && userData && (
					<div className="flex items-center justify-center my-8 w-full">
						<button
							className="inline-block bg-[#fff] border border-gray-200 text-gray-700 px-6 py-2 rounded hover:bg-gray-50 transition-colors font-inter"
							onClick={() => {
								navigate("/profile", { state: { currentSection: "job-recommendations" } });
							}}
						>
							See more job matches
						</button>
					</div>
				)}
			</div>
		</section>
	);
}

export default RecommendedJobs;
