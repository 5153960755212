import { GoogleOAuthProvider } from "@react-oauth/google";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import React, { useEffect } from "react";
import { CookiesProvider } from "react-cookie";
import ReactGA from "react-ga4";
import { useDispatch } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { Toaster } from "sonner";

import { AuthProvider } from "./app/context/Auth/AuthContext";
import RouteHandler from "./app/router/RouteHandler";
import resume from "./app/seed/buildResume"; //we could use this to build a sample resume.

const TRACKING_ID = "G-Y75YMD4HJM";
ReactGA.initialize(TRACKING_ID);

export const queryClient = new QueryClient();

function App() {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch({ type: "SAMPLE_DATA", payload: resume.getResume() });
	}, [dispatch]);

	return (
		<BrowserRouter>
			<QueryClientProvider client={queryClient}>
				<AuthProvider>
					<GoogleOAuthProvider clientId="509917624249-5ociqc44vp23feb7i78ifgq1g6aiupel.apps.googleusercontent.com">
						<CookiesProvider>
							<RouteHandler />
						</CookiesProvider>
						<Toaster position="top-right" richColors />
					</GoogleOAuthProvider>
				</AuthProvider>
				<ReactQueryDevtools initialIsOpen={false} />
			</QueryClientProvider>
		</BrowserRouter>
	);
}

export default App;
