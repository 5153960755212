import { useCookies } from "react-cookie";

const useIsLoggedIn = () => {
	const [cookies] = useCookies(["resume"]);
	const result = cookies["Authorization"] ? cookies["Authorization"].substring(0, 4) : "";

	if (result === "CO::" || result === "GO::" || result === "LI::") {
		return true;
	}

	return false;
};

export default useIsLoggedIn;
