import React, { useEffect, useState } from "react";

import Icon from "../../../components/Icon";
import { sections } from "../../../handlers/enums";
import { useDSPInfo } from "../../../services/create-resume/queries";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../JobsPage/components/Select";
import Loader from "../../StaticPages/HomePage/components/Loader";
import DSPSection from "./DSPSection";

function DSPItem({ dspList, setDSPId }) {
	const [selectedDSPId, setSelectedDSPId] = useState(dspList.length > 0 ? dspList[0].dsp_id : null);
	const { data: dspInfoData, isPending: loading } = useDSPInfo(selectedDSPId);
	const [dspSections, setDspSections] = useState([]);

	useEffect(() => {
		setDSPId(selectedDSPId);
	}, [selectedDSPId]);
	useEffect(() => {
		if (dspInfoData && Object.keys(dspInfoData).length > 0) {
			const _dspSections = [];

			_dspSections.push({ section: sections.EXPERIENCE, title: "Experience", value: dspInfoData.workHistory });
			_dspSections.push({
				section: sections.SUMMARY,
				title: "About",
				value: {
					firstName: dspInfoData.firstName,
					lastName: dspInfoData.lastName,
					middleName: dspInfoData.middleName,
					emailAddress: dspInfoData.emailAddress,
					phoneNumber: dspInfoData.phoneNumber,
					linkedInProfile: dspInfoData.linkedInProfile,
					location: dspInfoData.location,
					professionalSummary: dspInfoData.professionalSummary,
					seniorityLevel: dspInfoData.seniorityLevel,
					workedProfessionallySince: dspInfoData.workedProfessionallySince,
					yearsOfExperience: dspInfoData.yearsOfExperience,
					areaOfExpertise: dspInfoData.areaOfExpertise,
				},
			});
			_dspSections.push({ section: sections.SKILLS, title: "Skills", value: dspInfoData.skills });
			_dspSections.push({ section: sections.EDUCATION, title: "Education", value: dspInfoData.educationHistory });
			_dspSections.push({
				section: sections.CERTIFICATION,
				title: "Certifications",
				value: dspInfoData.certificationsOrLicenses,
			});
			_dspSections.push({ section: sections.LANGUAGES, title: "Languages", value: dspInfoData.languagesSpoken });
			_dspSections.push({ section: sections.REFERENCE, title: "References", value: dspInfoData.references });
			_dspSections.push({ section: sections.CUSTOM, title: "Volunteer Work", value: dspInfoData.volunteerWork });

			setDspSections(_dspSections);
		}
	}, [dspInfoData]);

	return (
		<div className="space-y-[16px] w-full">
			<div className="flex items-center justify-between">
				<div className="text-[20px] font-[700]">DSP</div>
				<Select value={selectedDSPId} onValueChange={(value) => setSelectedDSPId(value)}>
					<SelectTrigger className="max-w-[500px]">
						<SelectValue placeholder="Select DSP" />
					</SelectTrigger>
					<SelectContent>
						{dspList.map((dsp) => (
							<SelectItem key={dsp.dsp_id} value={dsp.dsp_id} className="cursor-pointer">
								<div className="flex items-center gap-3 text-left">
									<div className="flex w-10 items-center justify-center rounded-lg border-gray-200 bg-white">
										<Icon iconName="invoice" size={16} />
									</div>
									<div className="flex-1">
										<p className="text-sm font-medium text-gray-900">{dsp.dsp_title}</p>
										{/*<p className="text-sm text-gray-500">{item.updatedAt}</p>*/}
									</div>
								</div>
							</SelectItem>
						))}
					</SelectContent>
				</Select>
			</div>
			{loading && <Loader />}
			{!loading &&
				dspSections.map((dspSection, index) => {
					return <DSPSection key={index} index={index} data={dspSection} />;
				})}
		</div>
	);
}

export default DSPItem;
