import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";

import AutoSuggestion from "../../components/AutoSuggestion";
import InputField from "../../components/UIComponents/InputField";
import { useAuth } from "../../context/Auth/AuthContext";
import mapping from "../../seed/metadata/filemapping";

const locations = mapping["districtsRegions"][0].districtRegions;

function ExpertHelpPersonalInfo() {
	const navigate = useNavigate();
	const [district, setDistrict] = React.useState("");
	const { userData } = useAuth();

	const [details, setDetails] = React.useState({
		firstName: "",
		lastName: "",
		email: "",
		phoneNumber: "",
		country: "Tanzania",
		address: district,
	});

	useEffect(() => {
		if (userData) {
			setDetails((prev) => ({
				...prev,
				firstName: userData.firstName,
				lastName: userData.lastName,
				email: userData.email,
			}));
		}
	}, [userData]);

	const [errors, setErrors] = React.useState({
		firstName: "",
		lastName: "",
		email: "",
		phoneNumber: "",
		country: "",
		address: "",
	});

	useEffect(() => {
		setDetails((prev) => ({
			...prev,
			address: district,
		}));
	}, [district]);

	function handleChange(e) {
		const { name, value } = e.target;
		setDetails((prev) => ({
			...prev,
			[name]: value,
		}));
		setErrors((prev) => ({
			...prev,
			[name]: "",
		}));
	}

	function onSubmit(e) {
		e.preventDefault();

		const { firstName, lastName, email, phoneNumber, address } = details;

		if (!firstName) {
			setErrors((prev) => ({
				...prev,
				firstName: "First name is required",
			}));
		}

		if (!lastName) {
			setErrors((prev) => ({
				...prev,
				lastName: "Last name is required",
			}));
		}

		if (!email) {
			setErrors((prev) => ({
				...prev,
				email: "Email is required",
			}));
		}

		if (!phoneNumber) {
			setErrors((prev) => ({
				...prev,
				phoneNumber: "Phone number is required",
			}));
		}

		if (!address) {
			setErrors((prev) => ({
				...prev,
				address: "Address is required",
			}));
		}

		const hasErrors = Object.values(errors).filter((error) => error).length > 0;

		if (hasErrors) {
			return toast.error("Please fill in all required fields");
		} else {
			navigate("/expert-help/summary");
		}
	}
	return (
		<div>
			<h1 className="fs-28 text-gray-950 font-weight-bold">Personal Information</h1>
			<p className="text-gray-700 font-weight-semi mb-5">Provide your contact details to help us reach you.</p>

			<form className="container">
				<div className="row">
					<div className="col-6 pl-0">
						<InputField
							label="First name"
							placeholder="First name"
							type="text"
							name="firstName"
							onChange={handleChange}
							value={details.firstName}
							error={errors.firstName}
						/>
					</div>
					<div className="col-6 pl-0">
						<InputField
							label="Last name"
							placeholder="Last name"
							type="text"
							name="lastName"
							onChange={handleChange}
							value={details.lastName}
							error={errors.lastName}
						/>
					</div>
					<div className="col-12 pl-0">
						<InputField
							label="Email"
							placeholder="Email"
							type="email"
							name="email"
							onChange={handleChange}
							value={details.email}
							error={errors.email}
						/>
					</div>
					<div className="col-12 pl-0">
						<InputField
							label="Phone number"
							placeholder="255 xx xxx xxx"
							type="text"
							name="phoneNumber"
							value={details.phoneNumber}
							error={errors.phoneNumber}
							onChange={handleChange}
						/>
					</div>
					<div className="col-6 pl-0">
						<InputField
							label="Country"
							placeholder="Country"
							type="text"
							name="country"
							value={details.country}
							error={errors.country}
							onChange={handleChange}
							disabled
						/>
					</div>
					<div className="col-6 pl-0">
						<AutoSuggestion
							suggestions={locations}
							label="Eg: District, Region"
							type="text"
							name="address"
							userInput={district}
							setUserInput={setDistrict}
							isForm={true}
						/>
					</div>
					<div className="col-12 pl-0 my-2">
						<button className="btn w-100 btn-blue text-white font-weight-medium" onClick={onSubmit}>
							Continue
						</button>
					</div>
					<div className="col-12 pl-0 mt-4">
						<p className="text-center px-4 text-gray-700 font-weight-medium">
							By continuing, I confirm that the above information is correct as it appears on my educational documents.
						</p>
					</div>
				</div>
			</form>
		</div>
	);
}

export default ExpertHelpPersonalInfo;
