import React, { useContext, useEffect, useState } from "react";
import { toast } from "sonner";

import Header from "../../../components/Header";
import Loader from "../../../components/Loader";
import ReviewComments from "../../../components/Reviews/ReviewComments";
import ResumeContext from "../../../context/ResumeContext";
import { ResumeDataService } from "../../../services/create-resume/resume-data";
import Resume from "../../ResumeBuilder/Review/Resume";

function CandidateReview() {
	const resumeDataService = new ResumeDataService();
	const { resumeData } = useContext(ResumeContext);
	const [loading, setLoading] = useState(true);
	const [reviewData] = useState([]);
	const [expandClass, setExpandClass] = useState("content-expand");
	const feedbackRequestId = "63f43d780e214a76d3f4e001";

	const onSuccessLoad = () => {
		setLoading(false);
		// setReviewData(response.data.reviews);
	};

	const onErrorLoad = () => {
		setLoading(false);
		toast.error("Something went wrong!");
	};

	useEffect(() => {
		resumeDataService.getResumeFeedback(feedbackRequestId, onSuccessLoad, onErrorLoad);
	}, []);

	const handleExpand = () => {
		const _expandClass = expandClass === "content-expand" ? "" : "content-expand";
		setExpandClass(_expandClass);
	};

	return (
		<>
			<Header />
			<div style={{ minHeight: "80vh" }}>
				<div className="container-fluid px-0 h-100">
					{loading ? (
						<div className="d-flex p-4 align-center h-75 justify-content-center center-loader">
							<Loader />
						</div>
					) : (
						<div className="row collapse no-gutters d-flex h-100 position-relative">
							<div
								className={`col resume-main-section bg-white final-resume-section-wrapper content-margin ${expandClass} py-3`}
							>
								<div className="col-lg-12">
									<div
										className="top p-4 rounded shadow-sm mb-3 border"
										style={{ backgroundColor: "rgb(248, 249, 250)" }}
									>
										<div className="d-flex align-items-center justify-content-between">
											<div className="edit-resume">
												<h6 className="mb-2 font-weight-bold text-dark">Message from</h6>
												<p className="mb-2">
													Dear , I value your guidance and advice in my career growth. That&#39;s why I&#39;m requesting
													your candid feedback to help me strengthen my CV below for targeted opportunities.
												</p>
												<p className="mb-2">
													<strong>The Opportunity: </strong>
												</p>
												<p>
													<strong>The Ask: </strong>Review my CV below, just click any of the section to add your
													feedback. Thanks!
												</p>
											</div>
											{reviewData.length > 0 ? (
												<div className="col px-3 px-md-0 d-flex justify-content-end">
													<a
														data-toggle="collapse"
														href="#"
														data-target=".collapse"
														role="button"
														className="p-1 mr-3"
														onClick={handleExpand}
													>
														<span style={{ textDecoration: "underline" }}>view Feedback</span>
													</a>
												</div>
											) : null}
										</div>
									</div>
								</div>
								<Resume resumeData={resumeData} isReview={true} reviewData={reviewData} />
							</div>
							<div className="col-3 p-0 vh-100 h-100 w-sidebar navbar-collapse collapse d-none d-md-flex sidebar">
								<div className="pt-5 resume-main-section bg-white border shadow-sm rounded sidebar-container h-100 w-sidebar">
									<div className="d-flex justify-content-between pr-4 pl-4 pt-2 mb-1" style={{ width: "inherit" }}>
										<h3 className="text-uppercase comments-header">Review Feedbacks</h3>
										<i
											className="fa fa-window-close fa-lg"
											aria-hidden="true"
											data-toggle="collapse"
											href="#"
											data-target=".collapse"
											role="button"
											onClick={handleExpand}
											style={{ color: "rgb(3, 169, 244)" }}
										/>
									</div>
									{reviewData.map((data, index) => (
										<>
											<ReviewComments className="mt-5" reviewData={data} key={index} />
										</>
									))}
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		</>
	);
}

export default CandidateReview;
