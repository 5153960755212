import React, { createContext, useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

import { StaticValue } from "../../seed/constants";
import { useUserAccount } from "../../services/create-resume/queries";

const staticValue = new StaticValue();
const staticValues = staticValue.getDefaultValues();

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
	const navigate = useNavigate();

	const storedProfile = localStorage.getItem("profileData");
	const [userData, setUserData] = useState(storedProfile ? JSON.parse(storedProfile) : null);

	const [_, __, removeCookie] = useCookies(["Authorization"]);
	const { data, isLoading, refetch, error } = useUserAccount();

	useEffect(() => {
		if (isLoading) return;
		if (data) {
			setUserData(data);
			const isLoggingIn = localStorage.getItem("isLoggingIn");
			if (isLoggingIn) {
				navigate("/profile");
				localStorage.removeItem("isLoggingIn");
			}
		} else if (error) {
			setUserData(null);
		}
	}, [data, isLoading, error]);

	useEffect(() => {
		if (!userData) {
			localStorage.removeItem("profileData");
			return;
		}
		localStorage.setItem("profileData", JSON.stringify(userData));
	}, [userData]);

	const clearUserData = () => {
		setUserData(null);
		removeCookie("Authorization");
		localStorage.removeItem("profileData");
		localStorage.removeItem("isDropzOpsUser");
		localStorage.removeItem("jobPreferences");

		removeCookie(staticValues.localStorageResumeId);

		const allLocalStorageKeys = Object.keys(localStorage);
		allLocalStorageKeys.forEach((key) => {
			if (key.includes("resume")) {
				localStorage.removeItem(key);
			}
		});
	};

	const value = {
		userData,
		setUserData,
		isLoading,
		refetch,
		logout: clearUserData,
	};

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
	const context = useContext(AuthContext);
	if (!context) {
		throw new Error("useAuth must be used within an AuthProvider");
	}
	return context;
};
