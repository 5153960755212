import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate, useParams } from "react-router-dom";

import {
	BookmarkIcon,
	BriefcaseIcon,
	BuildingIcon,
	CalendarIcon,
	DollarSign,
	GlobeIcon,
	MapPinIcon,
	UsersIcon,
} from "../../../../assets/icons/interface/interface";
import SocialShare from "../../../../components/SocialShare";
import { cn } from "../../../../handlers/cn.utils";
import { actions } from "../../../../handlers/enums";
import { currencyFormat } from "../../../../handlers/utilityFunctions";
import { isValidEmail, stripHTML } from "../../../../handlers/validationFunctions";
import { wrapLinksAndEmails } from "../../../../handlers/valueTransformation";
import useDSPProfileState from "../../../../hooks/use-DSPProfileState";
import useIsLoggedIn from "../../../../hooks/use-IsLoggedIn";
import { useFeatureFlag } from "../../../../hooks/useFeatureFlag";
import useScrollIntoView from "../../../../hooks/useScrollIntoView";
import {
	useLatestJobs,
	useRecommendedJobs,
	useSavedJobs,
	useSmartProfile,
} from "../../../../services/create-resume/queries";
import { ResumeDataService } from "../../../../services/create-resume/resume-data";
import { Card, CardContent, CardHeader, CardTitle } from "../../../JobsPage/components/Card";
import DSPModal from "../../../JobsPage/components/jobApplication/DSPModal";
import JobListingItem from "../../../JobsPage/components/JobListingItem";
import ApplyModal from "./ApplyModal";
import Loader from "./Loader";

function ViewJob({ data, setData, setShowJobDetailsModal }) {
	const baseUrl = `${window.location.origin}/`;
	const { id } = useParams();
	const navigate = useNavigate();
	const emailRegex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})/;
	const urlRegex = /(https?:\/\/\S+)/g;
	const jobService = new ResumeDataService();
	const [jobDetails, setJobDetails] = useState({});
	const [moreJobs, setMoreJobs] = useState([]);
	const isUserLoggedIn = useIsLoggedIn();
	const [loading, setLoading] = useState(true);
	const { data: response, isLoading } = useLatestJobs();
	const [submitting, setSubmitting] = useState(false);
	const { refetch: refetchRecommendedJobs } = useRecommendedJobs();
	const { refetch: refetchSavedJobs } = useSavedJobs();
	const currentPath = window.location.pathname;
	const isOnJobPage = /^\/jobs\/[a-zA-Z0-9]+$/.test(currentPath);
	const [applicationDetails, setApplicationDetails] = useState({
		link: null,
		applicationType: "URL",
	});
	const [showDSPModal, setShowDSPModal] = useState(false);
	const [showApplyModal, setShowApplyModal] = useState(false);
	const dspProfileState = useDSPProfileState();
	const dspModalData = dspProfileState.dspModalData(actions.CREATE);
	const { data: smartProfileData } = useSmartProfile();

	const saveJobInteraction = async (interaction) => {
		setSubmitting(true);
		jobService.saveJobInteraction(
			data?._id || id,
			{ interactionType: interaction },
			() => {
				refetchRecommendedJobs();
				refetchSavedJobs();
				setSubmitting(false);
			},
			() => {
				setSubmitting(false);
			},
		);
	};

	const handleBackButton = () => {
		if (isOnJobPage) {
			navigate("/jobs");
		} else {
			setShowJobDetailsModal(false);
		}
	};

	const handleDSPModalSelect = (option) => {
		dspProfileState.onDSPSelect(option);
		setShowDSPModal(false);
	};

	const isJobExpired = (jobCloseDate) => {
		const currentDate = new Date();
		const expDate = new Date(jobCloseDate);

		return currentDate > expDate;
	};

	const [scrollToEmail, setScrollToEmail] = useState(false);
	const applicationRef = useScrollIntoView(scrollToEmail);

	const oneClickApply = useFeatureFlag("oneClickApply");

	const onApply = (applicationLink) => {

		if (applicationLink) {

			const link = stripHTML(applicationLink).replace(/\W+$/, "");

			if (isValidEmail(link)) {
				//check if user is logged in
				if (!isUserLoggedIn) return navigate("/signin");

				//check if the user has a DSP
				const hasDSPData = Object.keys(smartProfileData).length > 0;

				if (hasDSPData) {
					!oneClickApply && setScrollToEmail(!scrollToEmail);
					oneClickApply && setShowApplyModal(true);
				}

				if (!hasDSPData) {
					setShowDSPModal(true);
				}

			}

			if (!isValidEmail(link)) {
				setApplicationDetails({ link, applicationType: "URL", });
				setShowApplyModal(true);
			}

		}

	};

	function getApplicationLink(applicationDetails) {
		// Extract email
		const emailMatch = applicationDetails.match(emailRegex);

		// Extract URLs
		const urlMatches = applicationDetails.match(urlRegex);

		if (urlMatches) return { link: urlMatches[0], applicationType: "URL" };

		if (emailMatch) return { link: emailMatch[0], applicationType: "EMAIL" };

		return { link: null, applicationType: "NOT_AVAILABLE" };
	}

	useEffect(() => {
		if (!isLoading && response) {
			setMoreJobs(response.data);
		}
	}, [response, isLoading]);

	useEffect(() => {
		setLoading(true);
		jobService.getSelectedJob(
			data?._id || id,
			(response) => {
				setJobDetails(response.data);
				setApplicationDetails(getApplicationLink(response.data.applicationProcess));
				setLoading(false);
			},
			() => {
				setLoading(false);
			},
		);
	}, [data, id]);

	return (
		<>
			{loading && (
				<div className="flex items-center justify-center min-h-screen">
					<Loader />
				</div>
			)}
			{!loading && (
				<>
					<div className="min-h-screen">
						<Helmet>
							<title>
								{jobDetails?.jobTitle} job at {jobDetails?.companyName} in {jobDetails?.jobLocation?.region}, Tanzania
							</title>
							<meta name="description" content={stripHTML(jobDetails?.jobDescription?.lookingToHire)} />
							<meta
								name="keywords"
								content={`${jobDetails?.jobTitle} job, ${jobDetails?.companyName}, ${jobDetails?.jobLocation?.region} Tanzania`}
							/>
							<meta property="og:title" content={jobDetails?.jobTitle} />
							<meta property="og:description" content={stripHTML(jobDetails?.jobDescription?.lookingToHire)} />
							<meta property="og:image" content={jobDetails?.company?.logo_url} />
							<meta property="og:url" content={window.location.href} />
						</Helmet>
						<div className="max-w-6xl mx-auto px-3 sm:px-4 lg:px-6 py-8">
							<div className="flex items-start justify-between flex-wrap mb-8">
								<button
									className="text-primary-600 hover:text-primary-700 inline-block text-sm"
									onClick={handleBackButton}
								>
									&larr; Back to Job Listings
								</button>
								<SocialShare
									message={`Check out this opportunity for a ${jobDetails?.jobTitle} at ${jobDetails?.companyName} ${baseUrl}companies/${jobDetails?.company?._id}`}
									link={`${baseUrl}jobs/${jobDetails?._id}`}
									onClick={() => {
										saveJobInteraction("Shared");
									}}
								/>
							</div>
							<div className="mb-8">
								<div className="flex flex-col sm:flex-row items-start sm:items-center justify-between mb-6">
									<div className="flex items-center mb-4 sm:mb-0">
										<Link to={`/companies/${jobDetails?.company?._id}`} state={jobDetails?.company}>
											<img
												alt={`${jobDetails?.companyName} logo`}
												loading="lazy"
												width="64"
												height="64"
												decoding="async"
												data-nimg="1"
												className="rounded-lg mr-4"
												style={{ color: "transparent" }}
												src={jobDetails?.company ? jobDetails?.company?.logo_url : "/images/co-placeholder.png"}
												onError={(e) => (e.target.src = "/images/co-placeholder.png")}
											/>
										</Link>
										<div>
											<h1 className="text-2xl font-bold text-gray-900 font-plus-jakarta">{jobDetails?.jobTitle}</h1>
											<Link to={`/companies/${jobDetails?.company?._id}`} state={jobDetails?.company}>
												<p className="text-lg text-gray-700 font-inter">{jobDetails?.companyName}</p>
											</Link>
										</div>
									</div>
								</div>
								<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-6">
									<div className="flex items-center">
										<MapPinIcon className="w-5 h-5 mr-2 text-gray-500" />
										<span className="text-gray-700 text-sm">
											{jobDetails?.jobLocation?.city}, {jobDetails?.jobLocation?.region}
										</span>
									</div>
									<div className="flex items-center">
										<CalendarIcon className="w-5 h-5 mr-2 text-gray-500" />
										<span className="text-gray-700 text-sm">
											Posted on {dayjs(jobDetails?.createdAt).format("YYYY-MM-DD")}
										</span>
									</div>
									<div className="flex items-center">
										<BriefcaseIcon className="w-5 h-5 mr-2 text-gray-500" />
										<span className="text-gray-700 text-sm">{jobDetails?.employmentType}</span>
									</div>
									<div className="flex items-center">
										<UsersIcon className="w-5 h-5 mr-2 text-gray-500" />
										<span className="text-gray-700 text-sm">{jobDetails?.company?.company_size_approx} Employees</span>
									</div>
									<div className="flex items-center">
										<DollarSign className="w-5 h-5 mr-2 text-gray-500" />
										<span className="text-gray-700 text-sm">
											{jobDetails?.salary?.minPay > 1 ? (
												<>
													{jobDetails?.salary?.payCurrency}{" "}
													{jobDetails.salary.payOption === "Exact Amount" ? (
														<>
															{currencyFormat(jobDetails?.salary?.minPay)} {jobDetails?.salary?.payFrequency}
														</>
													) : (
														<>
															{currencyFormat(jobDetails?.salary?.minPay)} - {jobDetails?.salary?.payCurrency}{" "}
															{currencyFormat(jobDetails?.salary?.maxPay)} {jobDetails?.salary?.payFrequency}
														</>
													)}
												</>
											) : (
												"Negotiable"
											)}
										</span>
									</div>
								</div>
								{!isJobExpired(jobDetails.closeAt) && (
									<div className="flex items-center justify-between mb-8">
										<div className="flex items-center space-x-2">
											<button
												disabled={submitting}
												className="inline-flex items-center justify-center bg-gray-200 text-gray-700 h-9 rounded-lg hover:bg-gray-300 transition-colors text-sm font-inter w-[70px] relative overflow-hidden"
												onClick={() => {
													saveJobInteraction("Saved");
												}}
											>
												<span className="transition-all duration-300">{submitting ? "Saving..." : "Save"}</span>
												<span className="absolute inset-0 flex items-center justify-center transition-all duration-300 opacity-0 transform translate-y-8">
													<BookmarkIcon className="w-5 h-5" />
												</span>
											</button>
											<button
												className="inline-flex items-center justify-center bg-primary-700 text-white px-4 h-9 rounded-lg hover:bg-primary-800 transition-colors duration-300 text-sm font-inter w-[90px] group relative overflow-hidden"
												onClick={() => onApply(applicationDetails.link)}
											>
												<span className="mr-1 group-hover:mr-3 transition-all duration-300">Apply</span>
												<span className="absolute right-4 opacity-0 transition-all duration-300 transform translate-x-2 group-hover:opacity-100 group-hover:translate-x-0">
													›
												</span>
											</button>
										</div>
										<button
											onClick={() => {
												saveJobInteraction("Irrevant");
											}}
											className="text-sm text-gray-500 hover:text-gray-700 transition-colors duration-300"
										>
											{submitting ? "Loading..." : "Not Relevant"}
										</button>
									</div>
								)}
								<div className="pt-5" style={{ borderTopWidth: "1px" }}>
									<h2 className="text-base font-semibold mb-2 font-inter">Job Description</h2>
									<div
										className="text-gray-700 font-inter"
										dangerouslySetInnerHTML={{
											__html: jobDetails?.jobDescription?.lookingToHire,
										}}
									/>
								</div>
								<div className="mb-8">
									<h2 className="text-base font-semibold mb-2 font-inter">Responsibilities</h2>
									<ul className="list-disc text-gray-700 font-inter pl-4">
										{jobDetails?.jobDescription?.responsibilities?.map((item, index) => {
											return (
												<li key={index}>
													<div
														dangerouslySetInnerHTML={{
															__html: item.value,
														}}
													/>
												</li>
											);
										})}
									</ul>
								</div>
								<div className="mb-8">
									<h2 className="text-base font-semibold mb-2 font-inter">Requirements</h2>
									<ul className="list-disc text-gray-700 font-inter pl-4">
										{jobDetails?.jobDescription?.qualifications?.map((item, index) => {
											return (
												<li key={index}>
													<div
														dangerouslySetInnerHTML={{
															__html: item.value,
														}}
													/>
												</li>
											);
										})}
									</ul>
								</div>

								{jobDetails?.applicationProcess &&
									jobDetails?.applicationProcess !== "" &&
									!isJobExpired(jobDetails.closeAt) && (
										<div
											className={cn("mb-8", {
												"bg-primary-100 p-2 px-3 rounded": scrollToEmail,
											})}
											id="applicationProcess"
											ref={applicationRef}
										>
											<h2 className="text-base font-semibold mb-2 font-inter">Application Process</h2>
											<div
												className="text-gray-700 font-inter content-wrapper"
												dangerouslySetInnerHTML={{
													__html: wrapLinksAndEmails(jobDetails?.applicationProcess),
												}}
											/>
											{applicationDetails.applicationType === "URL" && (
												<button
													className="inline-flex mt-4 items-center justify-center bg-primary-700 text-white px-4 h-9 rounded-lg hover:bg-primary-800 transition-colors duration-300 text-sm font-inter w-[90px] group relative overflow-hidden"
													onClick={() => onApply(applicationDetails.link)}
												>
													<span className="mr-1 group-hover:mr-3 transition-all duration-300">Apply</span>
													<span className="absolute right-4 opacity-0 transition-all duration-300 transform translate-x-2 group-hover:opacity-100 group-hover:translate-x-0">
														›
													</span>
												</button>
											)}
										</div>
									)}
							</div>
							<Card className="shadow-md overflow-hidden">
								<CardHeader className="bg-gray-50 border-b border-gray-200">
									<CardTitle className="text-base font-semibold font-plus-jakarta">
										About {jobDetails?.companyName}
									</CardTitle>
								</CardHeader>
								<CardContent className="p-6">
									<div className="flex items-center mb-4">
										<div className="w-16 h-16 rounded-lg overflow-hidden flex-shrink-0 border border-gray-100 mr-4">
											<Link to={`/companies/${jobDetails?.company?._id}`} state={jobDetails?.company}>
												<img
													alt={`${jobDetails?.companyName} logo`}
													loading="lazy"
													width="64"
													height="64"
													decoding="async"
													data-nimg="1"
													className="w-full h-full object-cover"
													style={{ color: "transparent" }}
													src={jobDetails?.company ? jobDetails?.company?.logo_url : "https://via.placeholder.com/150"}
													onError={(e) => (e.target.src = "/images/co-placeholder.png")}
												/>
											</Link>
										</div>
										<div>
											<p className="text-gray-700 mt-1 font-inter text-sm">{jobDetails?.company?.about_us_summary}</p>
										</div>
									</div>
									<div className="flex flex-wrap gap-2 text-sm text-gray-600 font-inter mb-4">
										<span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-200/50 text-gray-800">
											<BuildingIcon className="w-3 h-3 mr-1 text-gray-600" />
											{jobDetails?.company?.company_industry}
										</span>
										<span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-200/50 text-gray-800">
											<UsersIcon className="w-3 h-3 mr-1 text-gray-600" />
											{jobDetails?.company?.company_size_approx} Employees
										</span>
										<span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-200/50 text-gray-800">
											<MapPinIcon className="w-3 h-3 mr-1 text-gray-600" />
											{jobDetails?.jobLocation?.city}, {jobDetails?.jobLocation?.region}
										</span>
										<span
											className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium text-green-800"
											style={{ color: "#34663D", backgroundColor: "#E2FBE8" }}
										>
											<BriefcaseIcon className="w-3 h-3 mr-1 text-green-600" />
											Actively Hiring
										</span>
									</div>
									<div className="flex items-center">
										<GlobeIcon className="w-5 h-5 mr-2 text-gray-600" />
										<span className="font-semibold mr-1 text-sm">Website:</span>
										<a
											href={jobDetails?.company?.company_website}
											target="_blank"
											rel="noopener noreferrer"
											className="text-primary-700 hover:underline text-sm"
										>
											{jobDetails?.company?.company_website}
										</a>
									</div>
								</CardContent>
							</Card>
							<div className="mt-12">
								<h2 className="text-lg font-semibold mb-4 font-plus-jakarta">More Job Opportunities</h2>
								<div>
									{moreJobs
										.filter((item) => item?._id !== jobDetails?._id)
										.map((item, index) => {
											return (
												<JobListingItem
													key={index}
													job={{
														...item,
														logo: item.company?.logo_url ?  item.company.logo_url : "/images/co-placeholder.png",
													}}
													onApply={() => {
														window.scrollTo(0, 0);
														if (setData && data) {
															setData(item);
														} else {
															navigate(`/jobs/${item?._id}`);
														}
													}}
													onClick={() => {
														window.scrollTo(0, 0);
														if (setData && data) {
															setData(item);
															setShowJobDetailsModal(true);
														} else {
															navigate(`/jobs/${item?._id}`);
														}
													}}
													showCompany={true}
													titleClassName="text-base"
													hideSalary={true}
												/>
											);
										})}
								</div>
							</div>
							{/* Apply Modal */}
							<ApplyModal
								isOpen={showApplyModal}
								onClose={() => setShowApplyModal(false)}
								jobTitle={jobDetails.jobTitle}
								companyName={jobDetails.companyName}
								applicationDetails={applicationDetails}
								onDrawer={true}
							/>

							<DSPModal
								data={dspModalData}
								isOpen={showDSPModal}
								onSubmit={handleDSPModalSelect}
								onClose={() => setShowDSPModal(false)}
							/>
						</div>
					</div>
				</>
			)}
		</>
	);
}

export default ViewJob;
