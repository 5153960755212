import React, { useEffect, useState } from "react";

import Icon from "../../../../components/Icon";
import { docSubTypes } from "../../../../handlers/enums";
import { Label } from "../Label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../Select";
import { Textarea } from "../TextArea";

const SAMPLE_DATA = {
	yearsOfExperience: [
		{ value: "0-1", label: "In two weeks" },
		{ value: "1-3", label: "In a month" },
		{ value: "3-5", label: "In three months" },
	],
};

function ScreeningQuestions({ onPrev, application, onDSPChange }) {
	const { dsp, screeningQuestions } = application;

	const [formData, setFormData] = useState({
		interests: screeningQuestions.find((screen) => screen.question === "Why are you interested in this role?")?.answer,
		startingDuration: screeningQuestions.find(
			(screen) => screen.question === "Are you available to start immediately? If not, when can you begin?",
		)?.answer,
		professionalBackground: screeningQuestions.find(
			(screen) => screen.question === "Briefly describe your professional background or career goals.",
		)?.answer,
	});

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData((prev) => ({ ...prev, [name]: value }));
	};

	useEffect(() => {
		const { interests, startingDuration, professionalBackground } = formData;
		if (interests !== "" && startingDuration !== "" && startingDuration !== "" && professionalBackground !== "") {
			const questions = [
				{
					question: "Why are you interested in this role?",
					answer: interests,
				},
				{
					question: "Briefly describe your professional background or career goals.",
					answer: professionalBackground,
				},
				{
					question: "Are you available to start immediately? If not, when can you begin?",
					answer: startingDuration,
				},
			];

			onDSPChange(questions);
		}
	}, [formData]);

	return (
		<div className="mx-auto">
			<div className="flex justify-between">
				<h2 className="text-sm font-semibold mb-4">My Documents</h2>
				<h2 className="text-sm font-semibold mb-4 text-blue-500 cursor-pointer" onClick={() => onPrev()}>
					Edit
				</h2>
			</div>
			<div className="space-y-2">
				<div className="border rounded-lg p-3 cursor-pointer transition-all border-gray-300">
					<div className="flex justify-between items-start">
						<div>
							<h3 className="text-md font-inter font-bold"> {dsp.title} </h3>
							<p className="text-xs font-inter font-medium text-gray-500"> {dsp.email} </p>
							<ul className="mt-1 text-xs text-gray-700">
								{dsp.documents.map((doc, docIndex) => (
									<li key={docIndex} className="flex items-center gap-1">
										<Icon iconName={docSubTypes[doc.type].icon} size={15} />
										<span>
											<span className="font-inter text-xs font-semibold text-gray-600">
												{docSubTypes[doc.type].name}:
											</span>
											<span className="font-inter text-xs font-medium text-gray-600">{` ${doc.name}`}</span>
										</span>
									</li>
								))}
							</ul>
						</div>
					</div>
				</div>
			</div>

			{/* Documents List */}
			<div>
				<div className="mt-4 mb-4">
					<h3 className="text-sm font-semibold">Additional Details (Job-Specific Questions)</h3>
				</div>

				<div className="space-y-4">
					<div className="pt-1">
						{" "}
						{/* Changed from no padding to pt-1 */}
						<Label htmlFor="interests" className="text-sm">
							1. Why are you interested in this role?
							<span className="text-red-500"> (Required)</span>
						</Label>
						<Textarea
							id="interests"
							name="interests"
							value={formData.interests}
							onChange={handleInputChange}
							rows={4}
							className="mt-1.5 placeholder:text-gray-600 h-[50px]" // Added darker placeholder
							placeholder=""
						/>
					</div>
					<div className="pt-1">
						{" "}
						{/* Changed from no padding to pt-1 */}
						<Label htmlFor="interests" className="text-sm">
							2. Briefly describe your professional background or career goals.
							<span className="text-red-500"> (Required)</span>
						</Label>
						<Textarea
							id="professionalBackground"
							name="professionalBackground"
							value={formData.professionalBackground}
							onChange={handleInputChange}
							rows={4}
							className="mt-1.5 placeholder:text-gray-600" // Added darker placeholder
							placeholder=""
						/>
					</div>
					<div className="pt-1">
						{" "}
						<div>
							<Label htmlFor="yearsOfExperience" className="text-sm">
								3. Are you available to start immediately? If not, when can you begin?
								<span className="text-red-500"> (Required)</span>
							</Label>
							<Select
								value={formData.startingDuration}
								onValueChange={(value) => setFormData((prev) => ({ ...prev, startingDuration: value }))}
							>
								<SelectTrigger id="yearsOfExperience" className="mt-1.5">
									<SelectValue placeholder="Select Duration" className="text-gray-600" />{" "}
									{/* Updated placeholder color */}
								</SelectTrigger>
								<SelectContent>
									{SAMPLE_DATA.yearsOfExperience.map((option) => (
										<SelectItem key={option.value} value={option.value} className="text-gray-700">
											{option.label}
										</SelectItem>
									))}
								</SelectContent>
							</Select>
						</div>
						<div />
					</div>
				</div>
			</div>
		</div>
	);
}

export default ScreeningQuestions;
