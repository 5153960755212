import { useMutation, useQuery } from "@tanstack/react-query";
import { Cookies } from "react-cookie";
import { decodeToken } from "react-jwt";

import { queryClient } from "../../../App";
import { API } from "../apiService";

export function useJobs({ params }) {
	return useQuery({
		queryKey: ["jobs", JSON.stringify(params)],
		queryFn: async () => await API.get("jobs", { params }),
		staleTime: 60 * 1000,
		retry: 0,
	});
}

export function useCompanies() {
	return useQuery({
		queryKey: ["companies"],
		queryFn: async () => await API.get("companies"),
		staleTime: Infinity,
	});
}

export function useCompany(id) {
	return useQuery({
		queryKey: ["companies", id],
		queryFn: async () => await API.get(`companies?companyId=${id}`),
		staleTime: Infinity,
	});
}

export function useCompanyJobs(id) {
	return useQuery({
		queryKey: ["companies", id, "jobs"],
		queryFn: async () => await API.get(`/jobs/company?companyId=${id}`),
		staleTime: 86400000,
	});
}

export function useActivelyHiringCompanies() {
	return useQuery({
		queryKey: ["companies", "active"],
		queryFn: async () => await API.get("/jobs/employers-actively-hiring"),
		staleTime: 60 * 1000,
	});
}

export function useJobCategories() {
	return useQuery({
		queryKey: ["jobs", "categories"],
		queryFn: async () => await API.get("jobs/categories"),
		staleTime: Infinity,
	});
}

export function useLatestJobs() {
	return useQuery({
		queryKey: ["jobs", "latest"],
		queryFn: async () => await API.get("jobs/latest"),
		staleTime: 60 * 1000,
	});
}

export function useUpcomingJobs() {
	return useQuery({
		queryKey: ["jobs", "upcoming"],
		queryFn: async () => await API.get("jobs/upcoming"),
		staleTime: 60 * 1000,
	});
}

export function useFeaturedEmployers() {
	return useQuery({
		queryKey: ["employers", "featured"],
		queryFn: async () => await API.get("jobs/employers/featured"),
		staleTime: 60 * 1000,
	});
}

const cookie = new Cookies();
const auth = cookie.get("Authorization")
	? cookie.get("Authorization").replaceAll("GO::", "").replaceAll("CO::", "")
	: null;

const isExpired = cookie.get("Authorization")?.includes("LI::")
	? false
	: auth
		? decodeToken(auth)?.exp < Date.now() / 1000
		: true;

export function useRecommendedJobs(areasOfExpertise = "") {
	return useQuery({
		queryKey: ["jobs", "recommended"],
		queryFn: async () => {
			const { data } = await API.get("jobs/recommended");
			return data;
		},
		staleTime: 86400 * 1000,
		enabled: !isExpired && !!areasOfExpertise,
		retry: 0,
	});
}

export function useSavedJobs() {
	return useQuery({
		queryKey: ["jobs", "saved"],
		queryFn: async () => {
			const { data } = await API.get("jobs/saved");
			return data;
		},
		retry: 0,
		staleTime: 60 * 1000,
		enabled: !isExpired,
	});
}

export function useDeleteJobMutation(id) {
	return useMutation({
		mutationFn: async () => await API.delete(`jobs/${id}`),
		onSuccess: () => {
			queryClient.invalidateQueries(["jobs", "saved", "recommended"]);
		},
	});
}

export function useUserAccount() {
	return useQuery({
		queryKey: ["user", "account"],
		queryFn: async () => {
			const { data } = await API.get("users/account");

			const baseUserData = {
				email: data.emailAddress,
				userID: data._id,
				lastName: data.lastName,
				firstName: data.firstName,
				picture: data.picture,
				isDropzOpsUser: data.isDropzOpsUser || false,
				jobPreferences: data.jobPreferences || {},
				job_preference_progress_status: data.job_preference_progress_status || {},
			};

			// LinkedIn users should use their own profile data
			if (cookie.get("Authorization")?.includes("LI::")) {
				return baseUserData;
			}

			// For other auth methods (Google, Cognito)
			const token = cookie.get("Authorization")?.replaceAll("GO::", "").replaceAll("CO::", "");
			if (data.isDropzOpsUser) {
				localStorage.setItem("isDropzOpsUser", true);
			}

			try {
				const decodedToken = decodeToken(token);
				const data = {
					...baseUserData,
					lastName: decodedToken?.family_name || baseUserData.lastName,
					firstName: decodedToken?.given_name || baseUserData.firstName,
					picture: decodedToken?.picture || baseUserData.picture,
				};
				return data;
			} catch (error) {
				return baseUserData;
			}
		},
		retry: 0,
		enabled: !!cookie.get("Authorization"),
		staleTime: 60 * 30 * 1000,
	});
}

export function useResumes() {
	return useQuery({
		queryKey: ["resumes"],
		queryFn: async () => {
			const { data } = await API.get("resume-ledger");
			return data;
		},
		retry: 0,
		staleTime: 30 * 60 * 1000,
	});
}

export function useResume(id) {
	return useQuery({
		queryKey: ["resume", id],
		queryFn: async () => {
			const { data } = await API.get(`resume-ledger/${id}`);
			return data;
		},
		retry: 0,
		staleTime: 30 * 60 * 1000,
		enabled: !!id,
	});
}

export function useSmartProfile() {
	return useQuery({
		queryKey: ["smartProfile"],
		queryFn: async () => {
			const { data } = await API.get("smart-profiles");
			return data;
		},
		retry: 0,
		staleTime: 30 * 60 * 1000,
		enabled: !!cookie.get("Authorization"),
	});
}

export function useDSPInfo(dspId) {
	return useQuery({
		queryKey: ["dspInfo", dspId],
		queryFn: async () => {
			const { data } = await API.get("smart-profiles", { params: { dspId: dspId } });
			return data;
		},
		retry: 0,
		staleTime: 30 * 60 * 1000,
		enabled: !!dspId,
	});
}

export function useCreateSmartProfile(options) {
	return useMutation({
		mutationFn: async (payload) => {
			const { data: response } = await API.post("smart-profiles", payload);
			return response;
		},
		onSuccess: (data) => {
			queryClient.invalidateQueries(["smartProfile"]);
			options?.onSuccess?.(data);
		},
		onError: (error) => {
			options?.onError?.(error);
		},
	});
}

export function useUpdateSmartProfile(options) {
	return useMutation({
		mutationFn: async (payload) => {
			const _data = { ...payload };
			const dspId = payload.dspId;
			delete _data.dspId;

			const { data: response } = await API.patch(`smart-profiles/${dspId}`, _data);
			return response;
		},
		onSuccess: (data) => {
			queryClient.invalidateQueries(["smartProfile", ["dspInfo"]]);
			options?.onSuccess?.(data);
		},
		onError: (error) => {
			options?.onError?.(error);
		},
	});
}

export function useDeleteDocument(options) {
	return useMutation({
		mutationFn: async (payload) => {
			const params = {
				docId: payload.doc_id,
				docType: payload.doc_type,
				dspId: payload.dspId,
			};
			return await API.delete(`smart-profiles/docs/${payload.doc_id}`, { params });
		},
		onSuccess: (data) => {
			queryClient.invalidateQueries(["smartProfile", "dspInfo"]);
			options?.onSuccess?.(data);
		},
		onError: (error) => {
			options?.onError?.(error);
		},
	});
}
